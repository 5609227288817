import React, { useCallback } from 'react';
import { Typography, Grid, Button, IconButton, Tooltip } from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowEditStopReasons,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import {
  Circle as CircleIcon,
  Add as AddIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';

import DynamicPromptModal from '../../../DynamicPromptModal';
import {
  useApproveHeadlinesMutation,
  useCreateHeadlinesMutation,
  useDeleteHeadlinesMutation,
  useFetchHeadlinesQuery,
  useRegenerateAllHeadlinesMutation,
  useRegenerateHeadlinesMutation,
  useUnapproveHeadlinesMutation,
  useUpdateHeadlineMutation,
  useUnassignHeadlinesFromAdgroupMutation,
  useToggleIncludeDynamicKeywordMutation,
  useConvertToGlobalMutation,
} from '../../../../slices/headlineSlice';
import ApprovedCell from '../table/ApprovedCell';
import ButtonFooter from '../table/ButtonFooter';
import { getActions } from '../../../../helpers/getActions';
import { GridToolbarContainer } from '@mui/x-data-grid-premium';
import { GridRowsProp } from '@mui/x-data-grid-premium';
import { GridRowModel } from '@mui/x-data-grid-premium';
import { getStatusColor } from '../../../../helpers/getStatusColor';
import PinDropdown from '../../../TableCells/PinDropdown';
import CircularProgress from '@mui/material/CircularProgress';
import PinCell from '../../../TableCells/PinCell';
import getFilteredSelectedPins from '../../../../helpers/getFilteredSelectedPins';
import getOrderedRows from '../../../../helpers/getOrderedRows';
import { headlineValidationSchema } from '../../../../utils/validation';
import buildCharacterCountColumn from '../../../../utils/buildCharacterCountColumn';
import {
  CharacterLimitForContent,
  ContentType,
  PinType,
} from '../../../../types/enums';
import buildTextWithCharacterCountColumn from '../../../../utils/buildTextWithCharacterCountColumn';
import { getApprovedCount } from '../../../../utils/getApprovedCount';
import { Approvable } from '../../../../types/adGroups';
import { Headline } from '../../../../types/pinnable';
import { useSnackbar } from 'notistack';
import EditPromptDrawer from '../../../InteractivePromptingModal/EditPromptDrawer';

interface DataRow extends Headline {
  isNew?: boolean;
}

interface EditToolbarProps {
  approvedCount: number;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, approvedCount } = props;

  const handleClick = () => {
    const id = Math.floor(Math.random() * 1000000000);
    setRows((oldRows) => [
      ...oldRows,
      { id, approved: false, headline: '', isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'headline' },
    }));
  };

  if (approvedCount >= 15) {
    return null;
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add manual headline
      </Button>
    </GridToolbarContainer>
  );
}

const HeadlinesSection: React.FC<{
  adgroup_id: string;
  togglePromptPreview: (contentType: ContentType) => void;
}> = ({ adgroup_id, togglePromptPreview }) => {
  const { data, isFetching } = useFetchHeadlinesQuery(adgroup_id);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [promptModalOpen, setPromptModalOpen] = React.useState(false);

  const [primaryPromptModalOpen, setPrimaryPromptModalOpen] =
    React.useState(false);
  const [currentHeadlines, setCurrentHeadlines] = React.useState<DataRow[]>([]);
  const [updateHeadline] = useUpdateHeadlineMutation();
  const [createHeadlines] = useCreateHeadlinesMutation();
  const [approveHeadlines] = useApproveHeadlinesMutation();
  const [deleteHeadlines] = useDeleteHeadlinesMutation();
  const [unapproveHeadlines] = useUnapproveHeadlinesMutation();
  const [unassignHeadlines] = useUnassignHeadlinesFromAdgroupMutation();
  const [convertToGlobal] = useConvertToGlobalMutation();
  const [toggleKeywordInsertion] = useToggleIncludeDynamicKeywordMutation();
  const [regenerateHeadlines, { isLoading: isLoadingRegenerate }] =
    useRegenerateHeadlinesMutation();
  const [regenerateAllHeadlines, { isLoading: isLoadingRegenerateAll }] =
    useRegenerateAllHeadlinesMutation({ fixedCacheKey: 'regenerateHeadlines' });
  const { enqueueSnackbar } = useSnackbar();
  const isLoading = isFetching || isLoadingRegenerate || isLoadingRegenerateAll;
  const approvedCount = getApprovedCount(currentHeadlines as Approvable[]);
  React.useEffect(() => {
    if (data) {
      setCurrentHeadlines(data);
    }
  }, [data]);

  const handleRegenerateClick = useCallback(
    (ids: GridRowId[]) => () => {
      regenerateHeadlines({
        ids,
        ad_group_id: adgroup_id,
      });
    },
    [regenerateHeadlines, adgroup_id]
  );

  const handleRegenerateAllClick = useCallback(() => {
    regenerateAllHeadlines({
      ad_group_id: adgroup_id,
    });
  }, [regenerateAllHeadlines, adgroup_id]);

  const handleApproveClick = useCallback(
    (ids: GridRowId[]) => () => {
      approveHeadlines({
        ids,
        ad_group_id: adgroup_id,
      });
    },
    [approveHeadlines, adgroup_id]
  );

  const handleUnapproveClick = useCallback(
    (ids: GridRowId[]) => () => {
      unapproveHeadlines({
        ids,
        ad_group_id: adgroup_id,
      });
    },
    [unapproveHeadlines, adgroup_id]
  );

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [setRowModesModel, rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [setRowModesModel, rowModesModel]
  );

  const doCreateHeadlines = useCallback(
    async (row: any) => {
      await createHeadlines({
        headlines: [
          {
            headline: row.headline,
            pinned: row.pinned,
            is_global: false,
            is_primary: true,
            global_pinned: '',
          },
        ],
        adgroup_id,
      }).unwrap();
    },
    [createHeadlines, adgroup_id]
  );

  const handleConvertLocalClick = useCallback(
    (id: GridRowId, row: any) => async () => {
      await unassignHeadlines({
        headlineIds: [id],
        adGroupIds: [adgroup_id],
      });
      await doCreateHeadlines(row);
      enqueueSnackbar('Headline successfully converted to Global', {
        variant: 'success',
      });
    },
    [doCreateHeadlines, enqueueSnackbar, unassignHeadlines, adgroup_id]
  );

  const handleConvertToGlobalClick = useCallback(
    (ids: GridRowId[]) => async () => {
      await convertToGlobal({
        headlineIds: ids,
        ad_group_id: adgroup_id,
      });
      enqueueSnackbar('Headline successfully converted to Global', {
        variant: 'success',
      });
    },
    [convertToGlobal, enqueueSnackbar, adgroup_id]
  );

  const handleToggleKeywordInsertionClick = useCallback(
    (id: GridRowId) => async () => {
      await toggleKeywordInsertion({
        headlineId: id,
      });
      enqueueSnackbar('Keyword insertion toggled successfully', {
        variant: 'success',
      });
    },
    [adgroup_id, toggleKeywordInsertion, enqueueSnackbar]
  );

  const handleDeleteClick = useCallback(
    (ids: GridRowId[]) => async () => {
      const rows = currentHeadlines.filter((row) => ids.includes(row.id));
      const idsToDelete = [];
      const idsToUnAssign = [];
      const updatedRowModesModel = { ...rowModesModel };

      for (const row of rows) {
        if (row.is_global) {
          idsToUnAssign.push(row.id);
        } else {
          idsToDelete.push(row.id);
        }
        updatedRowModesModel[row.id] = { mode: GridRowModes.View };
      }
      if (idsToUnAssign.length) {
        await unassignHeadlines({
          headlineIds: idsToUnAssign,
          adGroupIds: [adgroup_id as GridRowId],
        });

        enqueueSnackbar(
          `${idsToUnAssign.length} headlines unassigned successfully`,
          {
            variant: 'success',
          }
        );
      }

      if (idsToDelete.length) {
        await deleteHeadlines({ ids: idsToDelete });
        enqueueSnackbar(
          `${idsToDelete.length} headlines deleted successfully`,
          {
            variant: 'success',
          }
        );
      }

      setRowModesModel({ ...updatedRowModesModel });
    },
    [
      setRowModesModel,
      deleteHeadlines,
      unassignHeadlines,
      rowModesModel,
      adgroup_id,
      currentHeadlines,
      enqueueSnackbar,
    ]
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = currentHeadlines.find((row) => row.id === id);
      if (editedRow?.isNew) {
        setCurrentHeadlines((oldRows) =>
          oldRows.filter((row) => row.id !== id)
        );
      }
    },
    [setRowModesModel, rowModesModel, currentHeadlines]
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    if (updatedRow!.isNew) {
      doCreateHeadlines(updatedRow);
    } else {
      updateHeadline({
        id: updatedRow.id,
        headline: updatedRow.headline,
        pinned: updatedRow.pinned,
        ad_group_id: adgroup_id,
      });
    }

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const actions = getActions(rowModesModel, {
    handleRegenerateClick,
    handleApproveClick,
    handleUnapproveClick,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    handleDeleteClick,
    handleConvertLocalClick,
    handleToggleKeywordInsertionClick,
  });

  const columns: GridColDef[] = [
    {
      field: 'approved',
      headerName: 'Approved',
      renderCell: (params: GridCellParams) => <ApprovedCell {...params} />,
    },
    buildTextWithCharacterCountColumn(
      'headline',
      'Headline',
      CharacterLimitForContent.Headline,
      headlineValidationSchema,
      true
    ),
    {
      field: 'pinned',
      headerName: 'Pinned',
      width: 150,
      editable: true,
      renderCell: (params: GridCellParams) => <PinCell {...params} />,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <PinDropdown
          {...params}
          selectedPins={getFilteredSelectedPins(data)}
          pinType={PinType.HEADLINE}
        />
      ),
    },
    buildCharacterCountColumn(
      'headline',
      'Characters',
      CharacterLimitForContent.Headline
    ),
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Action',
      cellClassName: 'actions',
      width: 190,
      getActions: actions,
    },
  ];

  return (
    <React.Fragment>
      <Typography variant="body1" sx={{ display: 'inline' }}>
        <span style={{ fontWeight: 'bold' }}>
          <CircleIcon
            color={getStatusColor(currentHeadlines)}
            fontSize="inherit"
          />{' '}
          Headlines {approvedCount > 0 ? `(${approvedCount}/15)` : ''}
        </span>
      </Typography>
      <IconButton onClick={() => togglePromptPreview(ContentType.Headline)}>
        <Tooltip title="Open General Headline Conversations">
          <RateReviewIcon
            sx={
              !isLoadingRegenerate
                ? {
                    color: '#1976d2',
                  }
                : {}
            }
          />
        </Tooltip>
      </IconButton>
      <IconButton
        onClick={() => togglePromptPreview(ContentType.PrimaryHeadline)}
      >
        <Tooltip title="Open Primary Headline Conversations">
          <RateReviewIcon
            sx={
              !isLoadingRegenerate
                ? {
                    color: 'green',
                  }
                : {}
            }
          />
        </Tooltip>
      </IconButton>
      <IconButton
        sx={{ color: 'white' }}
        aria-label="close"
        onClick={() => handleRegenerateAllClick()}
      >
        <RefreshIcon
          sx={{
            color: '#FF0000',
          }}
        />
      </IconButton>
      {isLoading && !currentHeadlines?.length && (
        <>
          <IconButton sx={{ color: 'white' }} aria-label="close">
            <CircularProgress size={16} />
          </IconButton>
        </>
      )}

      <Grid alignItems="center" container>
        <Grid item xs={12} sx={{ minHeight: 100, overflow: 'hidden' }}>
          <DataGridPremium
            density="compact"
            getRowHeight={() => 'auto'}
            loading={isLoading}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            isCellEditable={(params) => !params.row.is_global}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            columnVisibilityModel={{
              id: false,
            }}
            rows={getOrderedRows(currentHeadlines)}
            columns={columns}
            checkboxSelection={true}
            disableRowSelectionOnClick={true}
            getRowClassName={(params) =>
              `headlines-section-${params.row.pinned === 'HEADLINE_1' ? 'primary' : 'secondary'}`
            }
            slots={{
              footer: ButtonFooter,
              toolbar: EditToolbar,
            }}
            slotProps={{
              footer: {
                onRegenerate: (ids: GridRowId[]) => {
                  handleRegenerateClick(ids)();
                },
                onApprove: (ids: GridRowId[]) => {
                  handleApproveClick(ids)();
                },
                onUnApprove: (ids: GridRowId[]) => {
                  handleUnapproveClick(ids)();
                },
                onDelete: (ids: GridRowId[]) => {
                  handleDeleteClick(ids)();
                },
                onAddToGlobal: (ids: GridRowId[]) => {
                  handleConvertToGlobalClick(ids)();
                },
                adgroup_id,
              },
              toolbar: {
                setRows: setCurrentHeadlines,
                setRowModesModel,
                approvedCount,
              },
            }}
          />
        </Grid>
      </Grid>
      <DynamicPromptModal
        open={promptModalOpen}
        handleClose={() => setPromptModalOpen((prev) => !prev)}
        adGroupId={adgroup_id}
        promptName={'headlines'}
        type="Headlines"
      />
      <EditPromptDrawer
        drawerOpen={primaryPromptModalOpen}
        setDrawerOpen={setPrimaryPromptModalOpen}
        adGroupId={adgroup_id}
        contentType={ContentType.PrimaryHeadline}
      />
    </React.Fragment>
  );
};

export default HeadlinesSection;
