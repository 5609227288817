import { Box, Button, Card, Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import SettingsField from './SettingsField';
import {
  useFetchGlobalSettingsQuery,
  useUpdateGlobalSettingsMutation,
} from '../../slices/globalSettingSlice';
import { enqueueSnackbar } from 'notistack';
import * as Yup from 'yup';

interface GlobalSetting {
  label: string;
  key: string;
  tooltip: string;
  maxWidth: number;
}

interface Settings {
  [key: string]: string;
}

interface ValidationErrors {
  [key: string]: string;
}

const globalSettingKeys: GlobalSetting[] = [
  {
    label: 'Default Ad Group Bid',
    key: 'default_ad_group_bid',
    tooltip: 'The default bid for ad groups when a bid is not set.',
    maxWidth: 300,
  },
];

const GlobalSettingsPanel: React.FC = () => {
  const { data = {} } = useFetchGlobalSettingsQuery();
  const [updateGlobalSettings] = useUpdateGlobalSettingsMutation();
  const [settings, setSettings] = useState<Settings>({});
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    {}
  );

  useEffect(() => {
    setSettings(data);
  }, [data]);

  useEffect(() => {
    setValidationErrors(validateSettings());
  }, [settings]);

  const validateSettings = () => {
    const schema = Yup.object().shape({
      default_ad_group_bid: Yup.number()
        .typeError('Valid number required. Example: 2.00')
        .required('Required'),
    });

    try {
      schema.validateSync(settings, { abortEarly: false });
      return {};
    } catch (error: any) {
      return error.inner.reduce(
        (acc: any, { path, message }: any) => ({
          ...acc,
          [path]: message,
        }),
        {}
      );
    }
  };

  const hasValidationError = () => {
    for (const validationError of Object.values(validationErrors)) {
      if (validationError) {
        return true;
      }
    }
    return false;
  };

  const handleUpdateSettings = (key: string, value: string) => {
    setSettings({ ...settings, [key]: value });
  };

  const handleSaveGlobalSettings = async () => {
    try {
      await updateGlobalSettings({ globalSettings: settings }).unwrap();
      enqueueSnackbar('Global settings updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error updating global settings', { variant: 'error' });
    }
  };

  return (
    <Card sx={{ marginTop: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {globalSettingKeys.map(({ label, key, tooltip, maxWidth }) => (
              <Box sx={{ maxWidth: maxWidth || 'auto' }}>
                <SettingsField
                  key={key}
                  label={label}
                  value={settings[key] || ''}
                  onChange={(value) => handleUpdateSettings(key, value)}
                  tooltip={tooltip}
                  error={validationErrors[key]}
                />
              </Box>
            ))}
            <Box
              mt={3}
              sx={{
                width: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveGlobalSettings}
                disabled={hasValidationError()}
              >
                Save Global Settings
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Card>
  );
};

export default GlobalSettingsPanel;
