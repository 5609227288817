const PriceAssetTypeCell = (params: any) => {
  let priceAssetType = params.value as string;
  return (
    <>
      {priceAssetType ? (
        <span>
          {priceAssetType.replace(
            /^(.)(.*)$/,
            (_, firstChar, restOfString) =>
              firstChar.toUpperCase() +
              restOfString.toLowerCase().replace(/_/g, ' ')
          )}
        </span>
      ) : null}
    </>
  );
};

export default PriceAssetTypeCell;
