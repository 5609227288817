import { emptySplitApi } from './baseApi';
import { GridFilterModel, GridRowId } from '@mui/x-data-grid-premium';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { Sitelink } from '../types/sitelinks';
import { formatFilters } from '../utils/formatFilters';

interface SitelinksData {
  results: Sitelink[];
  total: number;
}

export interface PreCreatedSitelink {
  id?: string;
  header: string;
  description_one: string;
  description_two: string;
  url: string;
  meta_description: string;
}

export const sitelinkApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSitelinks: builder.query<Sitelink[], string | void>({
      query(adgroup_id) {
        return `/sitelinks/${adgroup_id}`;
      },
      providesTags: ['Sitelinks'],
    }),
    createSitelinks: builder.mutation<
      void,
      {
        sitelinks: Pick<
          Sitelink,
          | 'description_one'
          | 'description_two'
          | 'header'
          | 'url'
          | 'meta_description'
          | 'is_global'
        >[];
        ad_group_id?: string;
      }
    >({
      query: ({ sitelinks, ad_group_id }) => ({
        url: `/sitelinks/many`,
        method: 'POST',
        body: { sitelinks: sitelinks, ad_group_id },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Sitelinks',
          'AdGroups',
          'AdGroupCounts',
          'PromptPreviewElements',
          'GlobalSitelinks',
        ]),
    }),
    fetchSitelink: builder.query<Sitelink, { sitelinkId: string }>({
      query: ({ sitelinkId }) => ({
        url: `/sitelinks/`,
        method: 'GET',
        params: { sitelink_id: sitelinkId },
      }),
      providesTags: ['Sitelink'],
    }),
    fetchAllGlobalSitelinks: builder.query<
      SitelinksData,
      {
        page: number;
        pageSize: number;
        filterModel?: GridFilterModel;
      }
    >({
      query: ({ page, pageSize, filterModel }) => {
        return {
          url: '/sitelinks/global',
          method: 'POST',
          params: {
            page,
            pageSize,
            logic_operator: filterModel?.logicOperator || 'and',
          },
          body: {
            filters: formatFilters(filterModel),
          },
        };
      },
      providesTags: ['GlobalSitelinks'],
    }),
    updateSitelink: builder.mutation<Sitelink, Sitelink>({
      query: (body) => ({
        url: `/sitelinks/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Sitelink',
          'Sitelinks',
          'SitelinkGroup',
          'SitelinkGroups',
          'PromptPreviewElements',
          'GlobalSitelinks',
        ]),
    }),
    regenerateSitelinks: builder.mutation<
      Sitelink[],
      { ad_group_id: string; ids: string[] | GridRowId[] }
    >({
      query: ({ ad_group_id, ids }) => ({
        url: `/sitelinks/regenerate`,
        method: 'POST',
        body: { ad_group_id, ids },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Sitelinks',
          'AdGroupCounts',
          'PromptPreviewElements',
        ]),
    }),
    regenerateAllSitelinks: builder.mutation<
      Sitelink[],
      { ad_group_id: string }
    >({
      query: (body) => ({
        url: `/sitelinks/regenerate_all`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Sitelinks',
          'AdGroupCounts',
          'PromptPreviewElements',
        ]),
    }),
    approveSitelinks: builder.mutation<
      Sitelink[],
      { sitelink_ids: string[] | GridRowId[]; ad_group_id: string }
    >({
      query: (body) => ({
        url: `/sitelinks/approve`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Sitelinks',
          'GlobalSitelinks',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unapproveSitelinks: builder.mutation<
      Sitelink[],
      { sitelink_ids: string[] | GridRowId[]; ad_group_id: string }
    >({
      query: (body) => ({
        url: `/sitelinks/unapprove`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Sitelinks',
          'GlobalSitelinks',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    deleteSitelinks: builder.mutation<void, { ids: string[] }>({
      query: (body) => ({
        url: `/sitelinks/`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Sitelinks',
          'AdGroups',
          'SitelinkGroup',
          'SitelinkGroups',
          'PromptPreviewElements',
          'GlobalSitelinks',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    assignSitelinksToAdgroup: builder.mutation<
      void,
      { sitelinkIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ sitelinkIds, adGroupIds }) => ({
        url: `/sitelinks/assign`,
        method: 'POST',
        body: {
          sitelink_ids: sitelinkIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Sitelinks',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
          'GlobalSitelinks',
        ]),
    }),
    unassignSitelinksFromAdgroup: builder.mutation<
      void,
      { sitelinkIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ sitelinkIds, adGroupIds }) => ({
        url: `/sitelinks/unassign`,
        method: 'POST',
        body: {
          sitelink_ids: sitelinkIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Sitelinks',
          'PromptPreviewElements',
          'GlobalSitelinks',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    convertSitelinksToGlobal: builder.mutation<
      void,
      { sitelinkIds: GridRowId[] }
    >({
      query: ({ sitelinkIds }) => ({
        url: `/sitelinks/global/convert`,
        method: 'PUT',
        body: {
          sitelink_ids: sitelinkIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['Sitelink', 'Sitelinks', 'GlobalSitelinks']),
    }),
  }),
});

export const {
  useFetchSitelinkQuery,
  useFetchSitelinksQuery,
  useCreateSitelinksMutation,
  useUpdateSitelinkMutation,
  useApproveSitelinksMutation,
  useUnapproveSitelinksMutation,
  useRegenerateSitelinksMutation,
  useRegenerateAllSitelinksMutation,
  useDeleteSitelinksMutation,
  useFetchAllGlobalSitelinksQuery,
  useAssignSitelinksToAdgroupMutation,
  useUnassignSitelinksFromAdgroupMutation,
  useConvertSitelinksToGlobalMutation,
} = sitelinkApiSlice;
