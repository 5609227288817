import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useDeleteConversationMutation } from '../../../slices/conversationSlice';

interface DeleteConversationModalProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  name: string;
  conversationId: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

const DeleteConversationModal: React.FC<DeleteConversationModalProps> = ({
  modalOpen,
  setModalOpen,
  name,
  conversationId,
}) => {
  const [deleteConversation] = useDeleteConversationMutation();
  const handleDelete = () => {
    deleteConversation({ conversationId });
  };
  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="delete-conversation-modal-title"
      aria-describedby="delete-conversation-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="delete-conversation-modal-title"
          variant="h6"
          component="h2"
        >
          Delete Conversation?
        </Typography>
        <Typography id="delete-conversation-modal-description" sx={{ mt: 2 }}>
          This will delete <b>{name}</b>.
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setModalOpen(false);
              handleDelete();
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteConversationModal;
