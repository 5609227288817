import React from 'react';
import { MenuItem, InputLabel, FormControl } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { PriceAssetType } from '../../../types/enums';

interface ChoosePriceAssetTypeProps {
  handleSetPriceAssetType: (priceAssetType: PriceAssetType) => void;
  selectedPriceAssetType: PriceAssetType;
}

const ChoosePriceAssetType: React.FC<ChoosePriceAssetTypeProps> = ({
  handleSetPriceAssetType,
  selectedPriceAssetType,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    handleSetPriceAssetType(event.target.value as PriceAssetType);
  };

  return (
    <FormControl sx={{ minWidth: '200px' }}>
      <InputLabel id="price-asset-type-label" sx={{ backgroundColor: 'white' }}>
        Ad Group Price Assets Type
      </InputLabel>
      <Select
        labelId="price-asset-type-label"
        id="price-asset-type-select"
        value={selectedPriceAssetType}
        label="Price Asset Type"
        onChange={handleChange}
      >
        <MenuItem value={PriceAssetType.Brands}>Brands</MenuItem>
        <MenuItem value={PriceAssetType.ProductCategories}>
          Product Categories
        </MenuItem>
        <MenuItem value={PriceAssetType.ProductTiers}>Product Tiers</MenuItem>
      </Select>
    </FormControl>
  );
};
export default ChoosePriceAssetType;
