import {
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import TextWithCharacterCountCell from '../components/TableCells/TextWithCharacterCountCell';
import { AnyObject, StringSchema } from 'yup';
import TableCellWithValidation from '../components/TableCells/TableCellWithValidation';
import LockIcon from '@mui/icons-material/Lock';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box, Tooltip } from '@mui/material';

const buildTextWithCharacterCountColumn = (
  field: string,
  headerName: string,
  maxCharacters: number,
  validationSchema: StringSchema<string, AnyObject, undefined, ''>,
  showGlobalLock?: boolean
): GridColDef => {
  return {
    field,
    headerName,
    flex: 1,
    minWidth: 200,
    editable: true,
    renderCell: (params: GridRenderEditCellParams) => (
      <Box position="relative" paddingLeft="4px">
        {showGlobalLock && params.row.is_global && (
          <Tooltip
            title="Go to Asset specific tool if you want to edit global assets. Alternatively, make this asset local."
            arrow
          >
            <span
              style={{
                position: 'absolute',
                color: 'rgba(0, 0, 0, 0.87)',
                left: '-11px',
                top: '-3px',
                fontSize: '12px',
                height: '13px',
                width: '13px',
              }}
            >
              <LockIcon sx={{ fontSize: '13px' }} />
            </span>
          </Tooltip>
        )}
        {params.row.groups && params.row.groups.length > 0 && (
          <Tooltip
            title={`${params.row.groups.map((group_name: string) => group_name).join(', ')}`}
            arrow
          >
            <span
              style={{
                position: 'absolute',
                color: 'rgba(0, 0, 0, 0.87)',
                left: '-11px',
                bottom: '-3px',
                fontSize: '12px',
                height: '13px',
                width: '13px',
              }}
            >
              <GroupsIcon sx={{ fontSize: '13px' }} />
            </span>
          </Tooltip>
        )}
        <TextWithCharacterCountCell
          params={params}
          maxCharacters={maxCharacters}
        />
      </Box>
    ),
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      let hasError = false;

      try {
        validationSchema.validateSync(params.props.value);
      } catch (e) {
        hasError = true;
      }

      return { ...params, error: hasError };
    },
    renderEditCell: (params: GridRenderCellParams) => (
      <TableCellWithValidation
        {...params}
        limit={maxCharacters}
        validationSchema={validationSchema}
      />
    ),
  };
};
export default buildTextWithCharacterCountColumn;
