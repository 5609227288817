import { emptySplitApi } from './baseApi';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';

export interface ContentUrl {
  id?: string;
  type: ContentUrlType;
  url: string;
  max_products: number;
}

export enum ContentUrlType {
  Product = 'PRODUCT',
}

export const contentUrlApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchContentUrls: builder.query<ContentUrl[], { contentUrlIds: string[] }>({
      query: ({ contentUrlIds }) => ({
        url: `/content_urls/by_ids`,
        method: 'POST',
        body: {
          content_url_ids: contentUrlIds,
        },
      }),
      providesTags: ['ContentUrls'],
    }),
    createContentUrl: builder.mutation<ContentUrl, { contentUrl: ContentUrl }>({
      query: ({ contentUrl }) => ({
        url: `/content_urls/`,
        method: 'POST',
        body: {
          ...contentUrl,
        },
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['ContentUrl']),
    }),
    updateContentUrl: builder.mutation<ContentUrl, { contentUrl: ContentUrl }>({
      query: ({ contentUrl }) => ({
        url: `/content_urls/${contentUrl.id}`,
        method: 'PUT',
        body: contentUrl,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['ContentUrl', 'ContentUrls']),
    }),
  }),
});

export const {
  useFetchContentUrlsQuery,
  useCreateContentUrlMutation,
  useUpdateContentUrlMutation,
} = contentUrlApiSlice;
