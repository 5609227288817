import React from 'react';
import { Drawer, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ContentType } from '../../../types/enums';
import EditPromptContent from '../../EditPromptContent';

interface EditPromptDrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  adGroupId: string;
  contentType: ContentType;
}
const EditPromptDrawer: React.FC<EditPromptDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  adGroupId,
  contentType,
}) => {
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={drawerOpen}
      sx={{
        width: '70vw',
        zIndex: 1300,
      }}
      onClose={() => setDrawerOpen(false)}
    >
      <Box>
        <IconButton onClick={() => setDrawerOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box minWidth="400px" maxWidth="700px">
        {drawerOpen && (
          <EditPromptContent adGroupId={adGroupId} promptName={contentType} />
        )}
      </Box>
    </Drawer>
  );
};

export default EditPromptDrawer;
