import { useState } from 'react';
import BaseAssignToolDataTable from '../BaseAssignToolDataTable';
import { GridFilterModel, GridCellParams } from '@mui/x-data-grid-premium';
import {
  useFetchAllHeadlineGroupsQuery,
  useFetchHeadlineGroupQuery,
  useCreateHeadlineGroupMutation,
  useUpdateHeadlineGroupMutation,
  useDeleteHeadlineGroupsMutation,
} from '../../slices/headlineGroupSlice';
import { groupNameValidationSchema } from '../../utils/validation';
import { IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { HeadlineGroup } from '../../types/groups';
import { GlobalAssetType, MaxAllowableContent } from '../../types/enums';
import { Headline } from '../../types/pinnable';
import * as Yup from 'yup';

interface initialValuesShape {
  headline_group: HeadlineGroup;
  members: Headline[];
}

const HeadlineGroupSection = () => {
  const [deleteHeadlineGroups] = useDeleteHeadlineGroupsMutation();
  const [updateHeadlineGroup] = useUpdateHeadlineGroupMutation();
  const [createHeadlineGroup] = useCreateHeadlineGroupMutation();
  const [selectedId, setSelectedId] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 24,
  });
  const [queryOptions, setQueryOptions] = useState<{
    filterModel?: GridFilterModel;
  }>();

  const initialValues: initialValuesShape = {
    headline_group: {
      id: '',
      group_name: '',
      assigned_to_count: 0,
      headline_ids: [],
    },
    members: [],
  };
  const { data: selectedIdData = initialValues } = useFetchHeadlineGroupQuery(
    { headlineGroupId: selectedId },
    {
      skip: !selectedId,
    }
  );
  const formatSelectedIdData = (selectedIdData: initialValuesShape) => ({
    id: selectedIdData.headline_group.id,
    group_name: selectedIdData.headline_group.group_name,
    headlines: selectedIdData.members,
  });
  const { data = { total: 0, results: [] }, isFetching } =
    useFetchAllHeadlineGroupsQuery();

  const getColumns = (handleOpen: (id: string) => void) => [
    {
      field: 'id',
      headerName: 'Edit',
      width: 90,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <IconButton
            onClick={() => {
              handleOpen(params.row.id);
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        );
      },
    },
    {
      field: 'group_name',
      flex: 1,
      headerName: 'Headline Group Name',
      editable: true,
    },
    {
      field: 'member_count',
      headerName: '# of members',
      width: 110,
      renderCell: (params: GridCellParams) => {
        return params.row.headlines.length;
      },
    },
    {
      field: 'assigned_to_count',
      headerName: 'Assigned To',
      width: 90,
    },
  ];

  const validationSchema = Yup.object().shape({
    group_name: groupNameValidationSchema,
  });

  const fieldNames = ['group_name'];
  return (
    <BaseAssignToolDataTable
      data={data}
      isFetching={isFetching}
      assetType={GlobalAssetType.HeadlineGroup}
      getColumns={getColumns}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      setQueryOptions={setQueryOptions}
      updateAsset={async (asset: any) => {
        await updateHeadlineGroup(asset).unwrap();
      }}
      deleteAssets={deleteHeadlineGroups}
      createAsset={async (asset: any) => {
        await createHeadlineGroup(asset).unwrap();
      }}
      selectedIdData={formatSelectedIdData(selectedIdData)}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      validationSchema={validationSchema}
      fieldNames={fieldNames}
      maxAllowableContent={MaxAllowableContent.Headlines}
    />
  );
};

export default HeadlineGroupSection;
