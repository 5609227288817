import { useState } from 'react';
import BaseAssignToolDataTable from '../BaseAssignToolDataTable';
import { GridFilterModel, GridCellParams } from '@mui/x-data-grid-premium';
import {
  useFetchAllSitelinkGroupsQuery,
  useFetchSitelinkGroupQuery,
  useCreateSitelinkGroupMutation,
  useUpdateSitelinkGroupMutation,
  useDeleteSitelinkGroupsMutation,
} from '../../slices/sitelinkGroupSlice';
import { groupNameValidationSchema } from '../../utils/validation';
import { IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { SitelinkGroup } from '../../types/groups';
import { GlobalAssetType, MaxAllowableContent } from '../../types/enums';
import { Sitelink } from '../../types/sitelinks';
import * as Yup from 'yup';

interface initialValuesShape {
  sitelink_group: SitelinkGroup;
  members: Sitelink[];
}

const SitelinkGroupSection = () => {
  const [deleteSitelinkGroups] = useDeleteSitelinkGroupsMutation();
  const [updateSitelinkGroup] = useUpdateSitelinkGroupMutation();
  const [createSitelinkGroup] = useCreateSitelinkGroupMutation();
  const [selectedId, setSelectedId] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 24,
  });
  const [_, setQueryOptions] = useState<{
    filterModel?: GridFilterModel;
  }>();

  const initialValues: initialValuesShape = {
    sitelink_group: {
      id: '',
      group_name: '',
      assigned_to_count: 0,
      sitelink_ids: [],
    },
    members: [],
  };
  const { data: selectedIdData = initialValues } = useFetchSitelinkGroupQuery(
    { sitelinkGroupId: selectedId },
    {
      skip: !selectedId,
    }
  );
  const formatSelectedIdData = (selectedIdData: initialValuesShape) => ({
    id: selectedIdData.sitelink_group.id,
    group_name: selectedIdData.sitelink_group.group_name,
    sitelinks: selectedIdData.members,
  });
  const { data = { total: 0, results: [] }, isFetching } =
    useFetchAllSitelinkGroupsQuery();

  const getColumns = (handleOpen: (id: string) => void) => [
    {
      field: 'id',
      headerName: 'Edit',
      width: 90,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <IconButton
            onClick={() => {
              handleOpen(params.row.id);
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        );
      },
    },
    {
      field: 'group_name',
      flex: 1,
      headerName: 'Sitelink Group Name',
      editable: true,
    },
    {
      field: 'member_count',
      headerName: '# of members',
      width: 110,
      renderCell: (params: GridCellParams) => {
        return params.row.sitelinks.length;
      },
    },
    {
      field: 'assigned_to_count',
      headerName: 'Assigned To',
      width: 90,
    },
  ];

  const validationSchema = Yup.object().shape({
    group_name: groupNameValidationSchema,
  });

  const fieldNames = ['group_name'];
  return (
    <BaseAssignToolDataTable
      data={data}
      isFetching={isFetching}
      assetType={GlobalAssetType.SitelinkGroup}
      getColumns={getColumns}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      setQueryOptions={setQueryOptions}
      updateAsset={async (asset: any) => {
        await updateSitelinkGroup(asset).unwrap();
      }}
      deleteAssets={deleteSitelinkGroups}
      createAsset={async (asset: any) => {
        await createSitelinkGroup(asset).unwrap();
      }}
      selectedIdData={formatSelectedIdData(selectedIdData)}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      validationSchema={validationSchema}
      fieldNames={fieldNames}
      maxAllowableContent={MaxAllowableContent.Sitelinks}
    />
  );
};

export default SitelinkGroupSection;
