import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import {
  DataGridPremium,
  GridFilterModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridRowId,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import AdGroupModal from '../AdGroupModal';
import {
  CheckCircle as CheckCircleIcon,
  NotInterested as NotInterestedIcon,
  Help as HelpIcon,
  Visibility as VisibilityIcon,
  PauseCircle as PauseCircleIcon,
  Google as GoogleIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
} from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import { useTitle } from '../../contexts/TitleContext';
import { useFetchAdGroupsQuery } from '../../slices/adGroupSlice';
import { useFetchFiltersQuery } from '../../slices/filterSlice';
import {
  useCreateAdsMutation,
  useSyncAllAdGroupsGoogleStatusMutation,
} from '../../slices/adSlice';
import {
  getExclusiveFilterOperator,
  getInclusiveFilterOperator,
} from '../MultiselectInputValue';
import {
  filterColumns,
  getColumnForNewFilter,
} from '../../utils/filterColumns';
import { StatusEnum } from '../../types/enums';
import getNumberFilterOperators from '../../utils/getNumberFilterOperators';
import DateCell from '../TableCells/DateCell';
import { useSnackbar } from 'notistack';
import GoogleActiveResultsDialog from '../GoogleActivateResultsDialog';

const getGoogleStatus = (params: any) => {
  if (params.value === 'PAUSED') {
    return (
      <Box display="flex" alignItems="center">
        <Tooltip title="Paused" arrow>
          <PauseCircleIcon color="warning" />
        </Tooltip>
      </Box>
    );
  }

  if (params.value === 'ENABLED') {
    return (
      <Box display="flex" alignItems="center">
        <Tooltip title="Enabled" arrow>
          <CheckCircleIcon color="success" />
        </Tooltip>
      </Box>
    );
  }

  if (params.value === 'REMOVED') {
    return (
      <Box display="flex" alignItems="center">
        <Tooltip title="Removed" arrow>
          <NotInterestedIcon color="success" />
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title="Unknown / Unspecified" arrow>
        <HelpIcon color="primary" />
      </Tooltip>
    </Box>
  );
};

const getInternalStatus = (params: any) => {
  if (params.value === StatusEnum.Approved) {
    return (
      <Box display="flex" alignItems="center">
        <Tooltip title="Approved" arrow>
          <CheckCircleIcon color="success" />
        </Tooltip>
      </Box>
    );
  }

  if (params.value === StatusEnum.Paused) {
    return (
      <Box display="flex" alignItems="center">
        <Tooltip title="Paused" arrow>
          <PauseCircleIcon color="warning" />
        </Tooltip>
      </Box>
    );
  }

  if (params.value === StatusEnum.ReadyToApprove) {
    return (
      <Box display="flex" alignItems="center">
        <Tooltip title="Ready To Approve" arrow>
          <VisibilityIcon color="warning" />
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title="Pending" arrow>
        <PendingIcon color="warning" />
      </Tooltip>
    </Box>
  );
};

interface FilterToolbarProps {
  handleShowApproved: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleShowEnabled: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleShowInactive: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleShowOutOfDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
  adGroupIds: GridRowId[];
}

function FilterToolbar(props: FilterToolbarProps) {
  const {
    handleShowApproved,
    handleShowEnabled,
    handleShowInactive,
    handleShowOutOfDate,
    adGroupIds,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [createAds, { isLoading }] = useCreateAdsMutation();
  const [
    syncAllAdGroupsGoogleStatus,
    { isLoading: syncAllGoogleStatusLoading },
  ] = useSyncAllAdGroupsGoogleStatusMutation();
  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState({
    success: [],
    failed: [],
    results: [],
  });

  const handleCreateAds = () => {
    createAds({ ad_group_ids: adGroupIds })
      .unwrap()
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        setResponse(res);
        setModalOpen(true);
      })
      .catch((error) => {
        enqueueSnackbar(error?.message || 'Something went wrong', {
          variant: 'error',
        });
      });
  };

  const handleSyncAllAdGroupsGoogleStatus = () => {
    syncAllAdGroupsGoogleStatus()
      .unwrap()
      .then((_) => {
        enqueueSnackbar('Google status synced for all ad groups.', {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(error?.message || 'Something went wrong', {
          variant: 'error',
        });
      });
  };

  return (
    <>
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <FormControlLabel
          control={<Checkbox onChange={handleShowApproved} />}
          label="Approved"
        />
        <FormControlLabel
          control={<Checkbox onChange={handleShowOutOfDate} />}
          label="Out of Date"
        />
        <FormControlLabel
          control={<Checkbox onChange={handleShowEnabled} />}
          label="Active (Google)"
        />
        <FormControlLabel
          control={<Checkbox onChange={handleShowInactive} />}
          label="Inactive (Google)"
        />
        <Button
          onClick={handleCreateAds}
          variant="contained"
          color="success"
          startIcon={<GoogleIcon />}
          disabled={isLoading || adGroupIds.length === 0}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Activate'
          )}
        </Button>

        <Button
          onClick={handleSyncAllAdGroupsGoogleStatus}
          variant="contained"
          color="primary"
          startIcon={<GoogleIcon />}
          disabled={syncAllGoogleStatusLoading}
        >
          {syncAllGoogleStatusLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Sync All Google Status'
          )}
        </Button>
      </GridToolbarContainer>
      <GoogleActiveResultsDialog
        open={modalOpen}
        success={response.success}
        failed={response.failed}
        results={response.results}
        handleClose={() => setModalOpen(false)}
      />
    </>
  );
}

export default function AdGroupDetails() {
  const apiRef = useGridApiRef();
  const { setTitle } = useTitle();
  const [open, setOpen] = useState(false);
  const [modalAdgroupId, setModelAdGroupId] = useState('');
  const [showApproved, setShowApproved] = useState(false);
  const [showEnabled, setShowEnabled] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [showOutOfDate, setShowOutOfDate] = useState(false);
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);

  useEffect(() => {
    setTitle('Ad Groups');
  }, [setTitle]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 24,
  });

  const [queryOptions, setQueryOptions] = useState<{
    filterModel?: GridFilterModel;
  }>();

  const { data = { total: 0, results: [] }, isFetching } =
    useFetchAdGroupsQuery({
      ...paginationModel,
      ...queryOptions,
      showApproved,
      showEnabled,
      showInactive,
      showOutOfDate,
    });

  const {
    data: filters = {
      word_combination_filters: [],
      word_group_category_filters: [],
    },
  } = useFetchFiltersQuery({});

  const handleOpen = (adGroupId: string) => {
    setModelAdGroupId(adGroupId);
    setOpen(true);
  };

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const columns = [
    {
      field: 'adgroup_name',
      headerName: 'Ad Group',
      flex: 1,
      filterOperators: [
        ...getInclusiveFilterOperator(filters.word_combination_filters),
        ...getExclusiveFilterOperator(filters.word_combination_filters),
      ],
      renderCell: (params: any) => (
        <Link component="button" onClick={() => handleOpen(params.id)}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'word_category_combination',
      headerName: 'Word Category',
      filterOperators: [
        ...getExclusiveFilterOperator(filters.word_group_category_filters),
        ...getInclusiveFilterOperator(filters.word_group_category_filters),
      ],
      flex: 1,
    },
    {
      field: 'status',
      width: 96,
      headerName: 'Approved',
      renderCell: getInternalStatus,
    },
    {
      field: 'google_status',
      width: 110,
      headerName: 'Google Status',
      filterable: false,
      renderCell: getGoogleStatus,
    },
    {
      field: 'google_status_phrase',
      width: 110,
      headerName: 'Phrase Match',
      filterable: false,
      renderCell: getGoogleStatus,
    },
    {
      field: 'google_status_broad',
      width: 110,
      headerName: 'Broad Match',
      filterable: false,
      renderCell: getGoogleStatus,
    },
    {
      field: 'last_update',
      width: 112,
      headerName: 'Last Update',
      filterable: false,
      renderCell: DateCell,
    },
    {
      field: 'google_last_update',
      width: 112,
      headerName: 'Last Google Update',
      filterable: false,
      renderCell: DateCell,
    },
    {
      field: 'primary_headline_count',
      width: 124,
      type: 'number',
      headerName: 'Primary Headline',
      filterOperators: getNumberFilterOperators(),
    },
    {
      field: 'headlines_count',
      width: 96,
      type: 'number',
      headerName: 'Headlines',
      filterOperators: getNumberFilterOperators(),
    },
    {
      field: 'descriptions_count',
      width: 96,
      type: 'number',
      headerName: 'Descriptions',
      filterOperators: getNumberFilterOperators(),
    },
    {
      field: 'sitelinks_count',
      width: 96,
      type: 'number',
      headerName: 'Sitelinks',
      filterOperators: getNumberFilterOperators(),
    },
    {
      field: 'price_assets_count',
      width: 96,
      type: 'number',
      headerName: 'Price Assets',
      filterOperators: getNumberFilterOperators(),
    },
    {
      field: 'image_assets_count',
      width: 100,
      type: 'number',
      headerName: 'Image Assets',
      filterOperators: getNumberFilterOperators(),
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowApproved = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowApproved(event.target.checked);
  };

  const handleShowEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowEnabled(event.target.checked);
  };

  const handleShowInactive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowInactive(event.target.checked);
  };

  const handleShowOutOfDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOutOfDate(event.target.checked);
  };

  return (
    <Card sx={{ marginTop: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ width: '100%' }}>
              <DataGridPremium
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(selections) =>
                  setSelectedIds(selections)
                }
                autoHeight={true}
                rows={data.results}
                columns={columns}
                rowCount={data.total}
                paginationMode="server"
                loading={isFetching}
                apiRef={apiRef}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                pageSizeOptions={[24, 48, 96]}
                pagination={true}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                slots={{ toolbar: FilterToolbar }}
                slotProps={{
                  filterPanel: {
                    filterFormProps: {
                      filterColumns: (params) =>
                        filterColumns({
                          ...params,
                          uniqueFilterColumns: [
                            'adgroup_name',
                            'word_category_combination',
                          ],
                        }),
                    },
                    sx: {
                      '& .MuiDataGrid-filterFormValueInput   ': {
                        width: '500px',
                      },
                      position: 'absolute',
                      top: `-110px !important`,
                      left: '100px',
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                    },
                    getColumnForNewFilter: (params) =>
                      getColumnForNewFilter({
                        ...params,
                        uniqueFilterColumns: [
                          'adgroup_name',
                          'word_category_combination',
                        ],
                      }),
                    onKeyDown: (event: KeyboardEvent) => {
                      if (
                        event.key === 'Escape' ||
                        (event.key === 'Enter' && !event.shiftKey)
                      ) {
                        apiRef.current.hideFilterPanel();
                      }
                    },
                  },
                  toolbar: {
                    handleShowApproved,
                    handleShowEnabled,
                    handleShowInactive,
                    handleShowOutOfDate,
                    adGroupIds: selectedIds,
                  },
                }}
              />
            </div>
            <AdGroupModal
              open={open}
              handleClose={handleClose}
              adgroup_id={modalAdgroupId}
            />
          </Paper>
        </Grid>
      </Grid>
    </Card>
  );
}
