import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import {
  IconButton,
  Input,
  InputAdornment,
  Typography,
  Tooltip,
  Box,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import {
  Save as SaveIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Help as HelpIcon,
} from '@mui/icons-material';
import { useUpdateBidsMutation } from '../../../slices/bidManagementSlice';

interface AdGroupBidProps {
  adgroupId: string;
  bid: number;
}

const AdGroupBid: React.FC<AdGroupBidProps> = ({ adgroupId, bid }) => {
  const [updateAdGroupBid] = useUpdateBidsMutation();

  const [editedAdGroupBid, setEditedAdGroupBid] = React.useState<string>(
    bid.toString()
  );
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  React.useEffect(() => {
    setEditedAdGroupBid(bid.toString());
  }, [bid]);

  const handleUpdateBid = () => {
    try {
      updateAdGroupBid({
        bidUpdates: [
          {
            ad_group_id: adgroupId,
            bid: Number(editedAdGroupBid),
          },
        ],
      })
        .unwrap()
        .then(() => {
          setIsEditing(false);
          enqueueSnackbar('Ad Group Bid Updated', { variant: 'success' });
        });
    } catch (e) {
      enqueueSnackbar('Error Updating Ad Group Bid', { variant: 'error' });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const decimalIndex = value.indexOf('.');

    if (decimalIndex !== -1 && value.length > decimalIndex + 3) {
      value = value.substring(0, decimalIndex + 3);
    }

    setEditedAdGroupBid(value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCloseClick = () => {
    setIsEditing(false);
  };

  return (
    <Box mb={2}>
      <Typography variant="body1">
        <span style={{ fontWeight: 'bold' }}>Bid Override:</span>
        <Tooltip
          title={
            'If this value is set greater than 0, it will override the default ad group bid amount.'
          }
        >
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <FormControl sx={{ flexGrow: 1 }}>
          <Input
            id="ad-group-bid"
            aria-describedby="ad-group-bid-helper-text"
            type="number"
            inputProps={{ step: '.01' }}
            value={editedAdGroupBid}
            onChange={handleInputChange}
            disabled={!isEditing}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        {!isEditing ? (
          <IconButton aria-label="edit" onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        ) : (
          <>
            <IconButton aria-label="update" onClick={handleUpdateBid}>
              <SaveIcon />
            </IconButton>
            <IconButton aria-label="close" onClick={handleCloseClick}>
              <CloseIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AdGroupBid;
