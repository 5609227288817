import { getGridStringOperators } from '@mui/x-data-grid-premium';

const getContainsFilterOperator = () => {
  return getGridStringOperators().filter(
    (operator) =>
      operator.value !== 'isEmpty' &&
      operator.value !== 'isNotEmpty' &&
      operator.value !== 'isAnyOf' &&
      operator.value !== 'startsWith' &&
      operator.value !== 'endsWith' &&
      operator.value !== 'equals'
  );
};

export default getContainsFilterOperator;
