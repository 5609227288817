import { emptySplitApi } from './baseApi';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { GridRowId, GridFilterModel } from '@mui/x-data-grid-premium';
import { formatFilters } from '../utils/formatFilters';
import {
  AggregatedPerformanceReport,
  AdGroupBids,
} from '../types/bidManagement';

export const bidManagementSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchReportsWithinDateRange: builder.query<
      {
        total: number;
        results: AggregatedPerformanceReport[];
        compare_results: AggregatedPerformanceReport[];
        ad_group_bids: AdGroupBids;
      },
      {
        start_date: string;
        end_date: string;
        compare_start_date?: string;
        compare_end_date?: string;
        page: number;
        pageSize: number;
        filterModel?: GridFilterModel;
        sortBy?: string;
        sortByDirection?: 'ASC' | 'DESC';
        includeAccuracyReport?: boolean;
      }
    >({
      query: ({
        page,
        pageSize,
        filterModel,
        start_date,
        end_date,
        compare_start_date,
        compare_end_date,
        sortBy,
        sortByDirection,
        includeAccuracyReport,
      }) => {
        return {
          url: '/bid_management/aggregated_reports',
          method: 'POST',
          body: {
            start_date,
            end_date,
            compare_start_date,
            compare_end_date,
            filters: formatFilters(filterModel),
            page,
            page_size: pageSize,
            logic_operator: filterModel?.logicOperator || 'and',
            sort_by: sortBy,
            sort_by_direction: sortByDirection,
            include_accuracy_report: includeAccuracyReport,
          },
        };
      },
      providesTags: ['AggregatedPerformanceReports'],
    }),
    updateBids: builder.mutation<
      void,
      {
        bidUpdates: { ad_group_id: GridRowId; bid: number }[];
      }
    >({
      query: ({ bidUpdates }) => ({
        url: `/bid_management/update_bids`,
        method: 'PUT',
        body: { bid_updates: bidUpdates },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'AggregatedPerformanceReports',
          'AdGroups',
          'AdGroup',
        ]),
    }),
    updateBidPercentages: builder.mutation<
      void,
      {
        bidPercentages: {
          ad_group_id: GridRowId;
          bid_percentage: number;
          match_type: string;
        }[];
      }
    >({
      query: ({ bidPercentages }) => ({
        url: `/bid_management/update_percentages`,
        method: 'PUT',
        body: { bid_percentages: bidPercentages },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'AggregatedPerformanceReports',
          'AdGroups',
          'AdGroup',
        ]),
    }),
  }),
});

export const {
  useFetchReportsWithinDateRangeQuery,
  useUpdateBidsMutation,
  useUpdateBidPercentagesMutation,
} = bidManagementSlice;
