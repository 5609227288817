import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import {
  ReportProblem as ReportProblemIcon,
  Check as CheckIcon,
} from '@mui/icons-material';
import { AnyObject, StringSchema } from 'yup';
import { Tooltip } from '@mui/material';

interface CharacterCountCellProps {
  params: GridRenderCellParams;
  rowValidation: RowValidation[];
}

export interface RowValidation {
  [key: string]: StringSchema<string, AnyObject, undefined, ''>;
}

const ValidationCell: React.FC<CharacterCountCellProps> = ({
  params,
  rowValidation,
}) => {
  let isValid;
  let currentField = '';
  let fieldError = '';
  try {
    for (const row of rowValidation) {
      for (const field in row) {
        currentField = field;
        row[field].validateSync(params.row[field]);
      }
    }
    isValid = true;
  } catch (error: any) {
    fieldError = error.message;
    isValid = false;
  }

  if (isValid === undefined) {
    return null;
  }

  return isValid ? (
    <CheckIcon color="success" />
  ) : (
    <Tooltip title={`${currentField}: ${fieldError}`}>
      <ReportProblemIcon color="error" />
    </Tooltip>
  );
};

export default ValidationCell;
