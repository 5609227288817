import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip } from '@mui/material';

interface AssignedCellProps {
  params: GridRenderCellParams;
}

const AssignedCell: React.FC<AssignedCellProps> = ({ params }) => {
  return params.value ? (
    <Tooltip title="assigned">
      <CheckCircleIcon color="success" />
    </Tooltip>
  ) : (
    <Tooltip title="not assigned">
      <CancelIcon color="error" />
    </Tooltip>
  );
};

export default AssignedCell;
