import { getGridNumericOperators } from '@mui/x-data-grid-premium';

const getNumberFilterOperators = () => {
  return getGridNumericOperators().filter(
    (operator) =>
      operator.value !== 'isEmpty' &&
      operator.value !== 'isNotEmpty' &&
      operator.value !== 'isAnyOf'
  );
};

export default getNumberFilterOperators;
