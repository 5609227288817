import { useEffect, useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-premium';
import { useUpdateBidPercentagesMutation } from '../../slices/bidManagementSlice';
import { useSnackbar } from 'notistack';
import BidPercentageInput from '../StyledMUIComponents/BidPercentageInput';

const InlineBidPercentageInput = (params: GridCellParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState<string>('');
  const [updateBidsPercentages] = useUpdateBidPercentagesMutation();

  const {
    match_type: matchType,
    bid_percentage_phrase_match: bidPercentagePhraseMatch,
    bid_percentage_broad_match: bidPercentageBroadMatch,
  } = params.row;

  useEffect(() => {
    if (matchType === 'PHRASE') {
      setValue(bidPercentagePhraseMatch);
    } else if (matchType === 'BROAD') {
      setValue(bidPercentageBroadMatch);
    } else if (matchType === 'EXACT') {
      setValue('100');
    }
  }, [matchType, bidPercentagePhraseMatch, bidPercentageBroadMatch]);

  const handleEnter = async () => {
    if (value === '') {
      return;
    }
    const bidPercentage = parseFloat(value);
    if (isNaN(bidPercentage)) {
      return;
    }
    try {
      await updateBidsPercentages({
        bidPercentages: [
          {
            ad_group_id: params.row.ad_group_id as string,
            match_type: params.row.match_type as string,
            bid_percentage: bidPercentage,
          },
        ],
      }).unwrap();
      enqueueSnackbar('Bid percentage updated successfully', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar('Failed to update bid percentage', { variant: 'error' });
    }
  };
  return (
    <BidPercentageInput
      value={value}
      onChange={setValue}
      onEnter={handleEnter}
      onBlur={handleEnter}
      disabled={matchType === 'EXACT'}
    />
  );
};

export default InlineBidPercentageInput;
