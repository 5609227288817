import { Sitelink } from '../types/sitelinks';
import { PriceAsset } from '../types/priceAssets';
import { Description, Headline } from '../types/pinnable';
import { ImageAsset } from '../types/imageAssets';

export const getStatusColor = (
  rows?: Headline[] | Description[] | Sitelink[] | PriceAsset[] | ImageAsset[]
) => {
  if (!rows || rows.length === 0) {
    return 'error';
  }

  return rows.some((row) => !row.approved) ? 'warning' : 'success';
};
