import React, { useEffect, useState } from 'react';
import {
  GridApi,
  GridRowModel,
  GridCellParams,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import {
  useUpdatePriceAssetMutation,
  useDeletePriceAssetsMutation,
  useCreatePriceAssetsMutation,
  useFetchPriceAssetQuery,
} from '../../slices/priceAssetSlice';
import { Box, IconButton, Tab, Tabs } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  priceAssetAndSitelinkHeaderValidationSchema,
  urlValidationSchema,
  priceAssetShortDescriptionValidationSchema,
} from '../../utils/validation';
import buildTextWithCharacterCountColumn from '../../utils/buildTextWithCharacterCountColumn';
import { useTitle } from '../../contexts/TitleContext';
import BaseAssignToolDataTable from '../BaseAssignToolDataTable';
import {
  GlobalAssetType,
  CharacterLimitForContent,
  MaxAllowableContent,
  PriceAssetType,
} from '../../types/enums';
import UrlCell from '../TableCells/UrlCell';
import TableCellWithValidation from '../TableCells/TableCellWithValidation';
import { PriceAsset } from '../../types/priceAssets';
import * as Yup from 'yup';
import PriceAssetPricesCell from '../TableCells/PriceAssetPrices';
import { formatCurrency } from '../../utils/formatNumbers';
import AssignToPriceAssetGroupModal from './AssignToPriceAssetGroupModal';

interface GlobalPriceAssetSectionProps {
  data: any;
  isFetching?: boolean;
  setQueryOptions?: any;
  paginationModel?: any;
  setPaginationModel?: any;
  isGroupView?: boolean;
  priceAssetType?: PriceAssetType;
  setPriceAssetType?: (val: PriceAssetType) => void;
}

const GlobalPriceAssetSection: React.FC<GlobalPriceAssetSectionProps> = ({
  data,
  isFetching = false,
  setQueryOptions,
  paginationModel,
  setPaginationModel,
  isGroupView = false,
  priceAssetType,
  setPriceAssetType,
}) => {
  const [updatePriceAsset] = useUpdatePriceAssetMutation();
  const [deletePriceAssets] = useDeletePriceAssetsMutation();
  const [createPriceAssets] = useCreatePriceAssetsMutation();
  const [selectedId, setSelectedId] = useState('');
  const handleChangeTab = (
    _event: React.SyntheticEvent,
    newValue: PriceAssetType
  ) => {
    if (setPriceAssetType) {
      setPriceAssetType(newValue);
    }
  };

  const { setTitle } = useTitle();
  const initialValues = {
    id: '',
    price: 0,
    header: '',
    short_description: '',
    url: '',
    is_global: true,
  } as PriceAsset;
  const { data: selectedIdData = initialValues } = useFetchPriceAssetQuery(
    { priceAssetId: selectedId },
    {
      skip: !selectedId,
    }
  );
  useEffect(() => {
    setTitle(`Price Asset Tool`);
  }, [setTitle]);

  const getColumns = (
    handleOpen: (id: string) => void,
    handleOpenSiteSurfModal?: (
      row: GridRowModel,
      apiRef?: React.MutableRefObject<GridApi>
    ) => void
  ) => [
    {
      field: 'id',
      headerName: 'Edit',
      width: 90,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <IconButton
            onClick={() => {
              handleOpen(params.row.id);
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        );
      },
    },
    {
      field: 'url',
      headerName: 'Url',
      editable: true,
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <UrlCell
          params={params}
          handleEditClick={handleOpenSiteSurfModal}
          allowGlobalEdit={true}
        />
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <>
          <UrlCell
            params={params}
            handleEditClick={handleOpenSiteSurfModal}
            allowGlobalEdit={true}
          />
          <TableCellWithValidation
            {...params}
            validationSchema={urlValidationSchema}
          />
        </>
      ),
    },
    {
      field: 'header',
      headerName: 'Header',
      editable: true,
      flex: 1,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <TableCellWithValidation
          {...params}
          limit={25}
          validationSchema={priceAssetAndSitelinkHeaderValidationSchema}
        />
      ),
    },
    buildTextWithCharacterCountColumn(
      'short_description',
      'Short Description',
      CharacterLimitForContent.PriceAssetDescription,
      priceAssetShortDescriptionValidationSchema
    ),
    {
      field: 'price_asset_type',
      headerName: 'Type',
      wdith: 140,
      renderCell: (params: GridCellParams) => {
        switch (params.row.price_asset_type) {
          case PriceAssetType.Brands:
            return 'Brands';
          case PriceAssetType.ProductCategories:
            return 'Categories';
          case PriceAssetType.ProductTiers:
            return 'Tiers';
        }
      },
    },
    {
      field: 'price',
      headerName: 'Override Price',
      maxWidth: 175,
      flex: 1,
      editable: true,
      type: 'number',
      renderCell: (params: GridCellParams) => {
        if (params.row.price === 0) {
          return `(none)`;
        }
        return formatCurrency(params.row.price);
      },
    },
    {
      field: 'max_products',
      headerName: 'Max Products',
      editable: true,
      type: 'number',
      flex: 0.5,
    },
    {
      field: 'all_prices',
      headerName: 'From / Up To / Avg  ',
      maxWidth: 250,
      flex: 1,
      editable: false,
      renderCell: (params: GridCellParams) => (
        <PriceAssetPricesCell {...params} />
      ),
    },
    {
      field: 'assigned_to_count',
      headerName: 'Assigned To',
      width: 90,
    },
  ];

  const validationSchema = Yup.object().shape({
    header: priceAssetAndSitelinkHeaderValidationSchema,
    short_description: priceAssetShortDescriptionValidationSchema,
    url: urlValidationSchema,
  });

  const fieldNames = [
    'url',
    'max_products',
    'header',
    'short_description',
    'price',
    'price_asset_type',
  ];

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '15px' }}>
        {!isGroupView && (
          <Tabs value={priceAssetType} onChange={handleChangeTab} centered>
            <Tab
              label="Brands"
              id="tab-1"
              aria-controls="tab-1"
              value={PriceAssetType.Brands}
            />
            <Tab
              label="Product Categories"
              id="tab-2"
              aria-controls="tab-2"
              value={PriceAssetType.ProductCategories}
            />
            <Tab
              label="Product Tiers"
              id="tab-3"
              aria-controls="tab-3"
              value={PriceAssetType.ProductTiers}
            />
          </Tabs>
        )}
      </Box>
      <BaseAssignToolDataTable
        data={data}
        isFetching={isFetching}
        assetType={GlobalAssetType.PriceAsset}
        getColumns={getColumns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        setQueryOptions={setQueryOptions}
        updateAsset={async (asset: any) => {
          await updatePriceAsset({
            ...asset,
            price_asset_type: priceAssetType,
          } as PriceAsset).unwrap();
        }}
        deleteAssets={deletePriceAssets}
        createAsset={async (asset: any) => {
          await createPriceAssets({
            price_assets: [
              {
                ...asset,
                is_global: true,
                price: asset.price ?? 0,
                price_asset_type: asset.price_asset_type ?? priceAssetType,
              },
            ] as PriceAsset[],
          });
        }}
        selectedIdData={selectedIdData}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        validationSchema={validationSchema}
        fieldNames={fieldNames}
        maxAllowableContent={MaxAllowableContent.PriceAssets}
        priceAssetType={priceAssetType}
        AssignToAssetGroupModal={AssignToPriceAssetGroupModal}
      />
    </Box>
  );
};

export default GlobalPriceAssetSection;
