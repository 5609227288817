export enum PremadeDateRange {
  Last30Days = 'Last 30 Days',
  Last31to60Days = 'Last 31 to 60 Days',
  Last7Days = 'Last 7 Days',
  LastWeek = 'Last Week',
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
  SamePeriodLastYear = 'Same Period Last Year (Matched By Day)',
  PreviousPeriod = 'Previous Period (Matched By Day)',
}
