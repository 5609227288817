import React, { useEffect } from 'react';

import { Box, InputLabel } from '@mui/material';
import { Prompt } from '../../../slices/promptSlice';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface SelectPromptProps {
  prompts?: Prompt[];
  promptName: string;
  selectedPromptId?: string;
  setPrompt: (promptData: Prompt) => void;
}

const SelectPrompt: React.FC<SelectPromptProps> = ({
  prompts,
  setPrompt,
  selectedPromptId = '',
}) => {
  useEffect(() => {
    if (prompts) {
      setPrompt(
        prompts.find((prompt) => prompt._id === selectedPromptId) as Prompt
      );
    }
  }, []);
  const handleChange = (event: SelectChangeEvent) => {
    if (prompts) {
      setPrompt(
        prompts.find((prompt) => prompt._id === event.target.value) as Prompt
      );
    }
  };
  return (
    <Box>
      <InputLabel id="select-prompt-label">Prompt</InputLabel>
      <Select
        labelId="select-prompt-label"
        id="prompt-select"
        value={selectedPromptId}
        label="Prompt"
        onChange={handleChange}
        fullWidth
      >
        {prompts?.map((prompt) => (
          <MenuItem value={prompt._id} key={prompt._id}>
            {prompt?.custom_name || prompt.type}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SelectPrompt;
