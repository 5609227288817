import { emptySplitApi } from './baseApi';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { SitelinkGroup } from '../types/groups';
import { Sitelink } from '../types/sitelinks';
import { GridRowId } from '@mui/x-data-grid-premium';

export const SitelinkGroupApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllSitelinkGroups: builder.query<
      {
        results: SitelinkGroup[];
        total: number;
      },
      void
    >({
      query: () => {
        return {
          url: '/sitelink_group/',
          method: 'GET',
        };
      },
      providesTags: ['SitelinkGroups'],
    }),
    fetchSitelinkGroup: builder.query<
      {
        sitelink_group: SitelinkGroup;
        members: Sitelink[];
      },
      { sitelinkGroupId: string }
    >({
      query: ({ sitelinkGroupId }) => {
        return {
          url: `/sitelink_group/${sitelinkGroupId}`,
          method: 'GET',
        };
      },
      providesTags: ['SitelinkGroup'],
    }),
    assignSitelinksToSitelinkGroups: builder.mutation<
      void,
      { sitelinkIds: GridRowId[]; sitelinkGroupIds: GridRowId[] }
    >({
      query: ({ sitelinkIds, sitelinkGroupIds }) => ({
        url: `/sitelink_group/assign`,
        method: 'POST',
        body: {
          sitelink_ids: sitelinkIds,
          sitelink_group_ids: sitelinkGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'SitelinkGroups',
          'SitelinkGroup',
          'Sitelinks',
          'GlobalSitelinks',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unassignSitelinksFromSitelinkGroups: builder.mutation<
      void,
      { sitelinkIds: GridRowId[]; sitelinkGroupIds: GridRowId[] }
    >({
      query: ({ sitelinkIds, sitelinkGroupIds }) => ({
        url: `/sitelink_group/unassign`,
        method: 'POST',
        body: {
          sitelink_ids: sitelinkIds,
          sitelink_group_ids: sitelinkGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'SitelinkGroups',
          'SitelinkGroup',
          'Sitelinks',
          'PromptPreviewElements',
          'GlobalSitelinks',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    assignSitelinkGroupsToAdGroups: builder.mutation<
      void,
      { sitelinkGroupIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ sitelinkGroupIds, adGroupIds }) => ({
        url: `/sitelink_group/ad_group/assign`,
        method: 'POST',
        body: {
          sitelink_group_ids: sitelinkGroupIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'SitelinkGroups',
          'SitelinkGroup',
          'Sitelinks',
          'GlobalSitelinks',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unassignSitelinkGroupsFromAdGroups: builder.mutation<
      void,
      { sitelinkGroupIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ sitelinkGroupIds, adGroupIds }) => ({
        url: `/sitelink_group/ad_group/unassign`,
        method: 'POST',
        body: {
          sitelink_group_ids: sitelinkGroupIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'SitelinkGroups',
          'SitelinkGroup',
          'Sitelinks',
          'PromptPreviewElements',
          'GlobalSitelinks',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    createSitelinkGroup: builder.mutation<
      SitelinkGroup,
      {
        group_name: string;
      }
    >({
      query: (body) => ({
        url: `/sitelink_group/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['SitelinkGroups']),
    }),
    updateSitelinkGroup: builder.mutation<
      SitelinkGroup,
      {
        id: string;
        group_name: string;
      }
    >({
      query: ({ id, group_name }) => ({
        url: `/sitelink_group/${id}`,
        method: 'PUT',
        body: { group_name },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'SitelinkGroup',
          'SitelinkGroups',
          'AdGroups',
          'Sitelink',
          'Sitelinks',
          'PromptPreviewElements',
          'GlobalSitelinks',
          'GlobalPrimarySitelinks',
          'GlobalGeneralSitelinks',
        ]),
    }),
    deleteSitelinkGroups: builder.mutation<void, { ids: GridRowId[] }>({
      query: (body) => ({
        url: `/sitelink_group/`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'SitelinkGroups',
          'Sitelinks',
          'GlobalSitelinks',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
  }),
});

export const {
  useFetchSitelinkGroupQuery,
  useFetchAllSitelinkGroupsQuery,
  useCreateSitelinkGroupMutation,
  useUpdateSitelinkGroupMutation,
  useAssignSitelinksToSitelinkGroupsMutation,
  useUnassignSitelinksFromSitelinkGroupsMutation,
  useAssignSitelinkGroupsToAdGroupsMutation,
  useUnassignSitelinkGroupsFromAdGroupsMutation,
  useDeleteSitelinkGroupsMutation,
} = SitelinkGroupApiSlice;
