import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LaptopIcon from '@mui/icons-material/Laptop';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import {
  DataGridPremium,
  GridRowId,
  GridRowModesModel,
  useGridApiRef,
  GridCellParams,
  GridRenderCellParams,
  GridColumnHeaderParams,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid-premium';
import {
  GridFilterModel,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import BulkNewBidInput from './BulkNewBidInput';
import InlineNewBidInput from './InlineNewBidInput';
import {
  useFetchReportsWithinDateRangeQuery,
  useUpdateBidPercentagesMutation,
  useUpdateBidsMutation,
} from '../../slices/bidManagementSlice';
import DateRangeFilter from './DateRangeFilter';
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from '../../utils/formatNumbers';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import dayjs, { Dayjs } from 'dayjs';
import DateCell from '../TableCells/DateCell';
import AdGroupModal from '../AdGroupModal';
import {
  getExclusiveFilterOperator,
  getInclusiveFilterOperator,
} from '../MultiselectInputValue';
import { useFetchFiltersQuery } from '../../slices/filterSlice';
import { DeviceType } from './types';
import './bidManagement.css';
import ReportLoadingDialog from './ReportLoadingDialog';
import { getDaysBetweenDates } from '../../utils/getDateRange';
import getNumberFilterOperators from '../../utils/getNumberFilterOperators';
import toCapitalCase from '../../utils/toCapitalCase';
import getContainsFilterOperator from '../../utils/getContainsFilterOperator';
import InlineBidPercentageInput from './InlineBidPercentageInput';
import BulkAdjustBidsByPercentageInput from './BulkAdjustBidByPercentage';
import BulkAdjustBidPercentagesInput from './BulkAdjustBidPercentages';

declare module '@mui/x-data-grid' {
  interface FilterPanelPropsOverrides {
    onKeyDown: (event: KeyboardEvent) => void;
    anchorEl: HTMLElement | null;
    placement: string;
  }
}

interface FilterToolbarProps {
  selectedIds: GridRowId[];
  bidAdjustmentMode: string;
  deviceType: DeviceType;
  handleDeviceTypeChange: (deviceType: DeviceType) => void;
  dateRange: DateRange<Dayjs>;
  handleDateRangeChange: (newDateRange: DateRange<Dayjs>) => void;
  adGroupBids: Record<
    string,
    { ad_group_bid: number; bid_last_updated: string }
  >;
  showCompare: boolean;
  handleShowCompare: () => void;
  compareDateRange: DateRange<Dayjs>;
  handleCompareDateRangeChange: (newDateRange: DateRange<Dayjs>) => void;
  includeAccuracyReportClicks: boolean;
  includeAccuracyReportImpressions: boolean;
  setIncludeAccuracyReportClicks: (value: boolean) => void;
  setIncludeAccuracyReportImpressions: (value: boolean) => void;
}

const renderCurrencyCell = (params: GridRenderCellParams) =>
  params.value === undefined || params.value === '-'
    ? '-'
    : formatCurrency(params.value as number);

const renderPercentageCell = (params: GridRenderCellParams) =>
  params.value === undefined || params.value === '-'
    ? '-'
    : formatPercentage(params.value as number);

const renderNumberCell = (params: GridRenderCellParams) =>
  params.value === undefined || params.value === '-'
    ? '-'
    : formatNumber(params.value as number);

const FilterToolbar: React.FC<FilterToolbarProps> = ({
  selectedIds,
  bidAdjustmentMode,
  handleDeviceTypeChange,
  deviceType,
  dateRange,
  handleDateRangeChange,
  adGroupBids,
  showCompare,
  handleShowCompare,
  compareDateRange,
  handleCompareDateRangeChange,
  includeAccuracyReportClicks,
  includeAccuracyReportImpressions,
  setIncludeAccuracyReportClicks,
  setIncludeAccuracyReportImpressions,
}) => {
  const [daysInInitialRange, setDaysInInitialRange] = useState(0);
  const [updateBids] = useUpdateBidsMutation();
  const [updateBidsPercentages] = useUpdateBidPercentagesMutation();
  const [calendarOneOpen, setCalendarOneOpen] = useState(false);
  const [calendarTwoOpen, setCalendarTwoOpen] = useState(false);

  useEffect(() => {
    if (dateRange) {
      setDaysInInitialRange(getDaysBetweenDates(dateRange[0], dateRange[1]));
    }
  }, [dateRange]);

  const handleUpdateBidsByPercent = async (percent: number) => {
    const bidUpdates = [];
    for (const id of selectedIds) {
      if (!String(id).includes('EXACT')) {
        throw new Error(
          'Bid amount adjustments are only applicable to exact match ad groups.'
        );
      }
      const adGroupId = String(id).replace('-EXACT', '');
      bidUpdates.push({
        ad_group_id: adGroupId,
        bid:
          adGroupBids[adGroupId].ad_group_bid +
          adGroupBids[adGroupId].ad_group_bid * (percent / 100),
      });
    }

    try {
      await updateBids({ bidUpdates }).unwrap();
      enqueueSnackbar('Bids updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error updating bids', { variant: 'error' });
    }
  };

  const handleUpdateBids = async (newBid: string) => {
    const bidUpdates = [];
    for (const id of selectedIds) {
      if (!String(id).includes('EXACT')) {
        throw new Error(
          'Bid amount adjustments are only applicable to exact match ad groups.'
        );
      }
      bidUpdates.push({
        ad_group_id: String(id).replace('-EXACT', ''),
        bid: parseFloat(newBid),
      });
    }

    try {
      await updateBids({ bidUpdates }).unwrap();
      enqueueSnackbar('Bids updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error updating bids', { variant: 'error' });
    }
  };

  const handleUpdateBidPercentages = async (percentage: number) => {
    const bidPercentages = [];
    for (const id of selectedIds) {
      let matchType;
      if (String(id).includes('BROAD')) {
        matchType = 'BROAD';
      } else if (String(id).includes('PHRASE')) {
        matchType = 'PHRASE';
      }
      if (!matchType) {
        throw new Error(
          'Bid percentage adjustments are only applicable to broad / phrase match ad groups.'
        );
      }
      bidPercentages.push({
        ad_group_id: String(id).replace(/-BROAD|-PHRASE/g, ''),
        match_type: matchType,
        bid_percentage: percentage,
      });
    }

    try {
      await updateBidsPercentages({ bidPercentages }).unwrap();
      enqueueSnackbar('Bid percentages updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error updating bid percentages', { variant: 'error' });
    }
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    deviceType: DeviceType
  ) => {
    if (deviceType !== null) {
      handleDeviceTypeChange(deviceType);
    }
  };
  return (
    <GridToolbarContainer>
      <Box width="100%" display="flex" alignItems="center">
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <ToggleButtonGroup
          value={deviceType}
          exclusive
          onChange={handleChange}
          aria-label="Device Type"
          color="primary"
        >
          <ToggleButton value="combined" aria-label="Combined">
            <PhonelinkIcon />
          </ToggleButton>
          <ToggleButton value="mobile" aria-label="Mobile">
            <PhoneAndroidIcon />
          </ToggleButton>
          <ToggleButton value="desktop" aria-label="Desktop">
            <LaptopIcon />
          </ToggleButton>
          <ToggleButton value="both" aria-label="Mobile + Desktop">
            <PhoneAndroidIcon />+<LaptopIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <DateRangeFilter
          dateRange={dateRange}
          onChange={handleDateRangeChange}
          calendarOpen={calendarOneOpen}
          setCalendarOpen={(open) => {
            setCalendarOneOpen(open);
            setCalendarTwoOpen(false);
          }}
        />
        <FormControlLabel
          slotProps={{ typography: { fontSize: '12px', lineHeight: 1 } }}
          labelPlacement="bottom"
          control={
            <Checkbox
              onChange={handleShowCompare}
              size="small"
              checked={showCompare}
              name="showCompare"
              sx={{ padding: '5px' }}
            />
          }
          label="Compare"
        />
        {!!daysInInitialRange && (
          <DateRangeFilter
            initialDateRange={dateRange}
            daysInInitialRange={daysInInitialRange}
            dateRange={compareDateRange}
            onChange={handleCompareDateRangeChange}
            disabled={!showCompare}
            calendarOpen={calendarTwoOpen}
            setCalendarOpen={(open) => {
              setCalendarTwoOpen(open);
              setCalendarOneOpen(false);
            }}
          />
        )}
      </Box>
      <Box width="100%" display="flex" alignItems="center">
        <Typography variant="body1" mr={2} ml={1.5} sx={{ fontWeight: 'bold' }}>
          Accuracy Report:
        </Typography>
        <Typography variant="body1" mr={2} ml={1.5}>
          {' '}
          Include Accuracy Report Clicks:
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeAccuracyReportClicks}
              onChange={(e) => setIncludeAccuracyReportClicks(e.target.checked)}
              name="includeAccuracyReportClicks"
            />
          }
          label=""
        />
        <Typography variant="body1" mr={2} ml={1.5}>
          {' '}
          Include Accuracy Report Impressions:
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeAccuracyReportImpressions}
              onChange={(e) =>
                setIncludeAccuracyReportImpressions(e.target.checked)
              }
              name="includeAccuracyReportImpressions"
            />
          }
          label=""
        />
      </Box>
      <Box width="100%" display="flex" alignItems="center">
        {selectedIds.length > 0 && bidAdjustmentMode === 'BID' && (
          <>
            <Typography
              variant="body1"
              mr={2}
              ml={1.5}
              sx={{ fontWeight: 'bold' }}
            >
              {' '}
              Bulk Update Bids:
            </Typography>
            <BulkAdjustBidsByPercentageInput
              handleClick={handleUpdateBidsByPercent}
            />
            <BulkNewBidInput handleClick={handleUpdateBids} />
          </>
        )}
        {selectedIds.length > 0 && bidAdjustmentMode === 'PERCENTAGE' && (
          <>
            <Typography
              variant="body1"
              mr={2}
              ml={1.5}
              sx={{ fontWeight: 'bold' }}
            >
              {' '}
              Set Bid Percentages:
            </Typography>
            <BulkAdjustBidPercentagesInput
              handleClick={handleUpdateBidPercentages}
              count={selectedIds.length}
            />
          </>
        )}
      </Box>
    </GridToolbarContainer>
  );
};

const BidManagementDataGrid: React.FC = () => {
  const [showCompare, setShowCompare] = useState(false);
  const [deviceType, setDeviceType] = useState<DeviceType>('combined');
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [open, setOpen] = useState(false);
  const [modalAdgroupId, setModelAdGroupId] = useState<string>('');
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 24,
  });
  const [queryOptions, setQueryOptions] = useState<{
    filterModel?: GridFilterModel;
  }>();
  const [debouncedQueryOptions] = useDebounce(queryOptions, 500);
  const [sortOptions, setSortOptions] = useState<{
    sortBy?: string;
    sortByDirection?: 'ASC' | 'DESC';
  }>();
  const [bidAdjustmentMode, setBidAdjustmentMode] = useState('');

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    const itemsWithValues = filterModel.items.filter(
      (item) =>
        item.value !== undefined &&
        item.value !== '' &&
        !(Array.isArray(item.value) && item.value.length === 0)
    );
    if (itemsWithValues.length === 0) {
      setQueryOptions(undefined);
      return;
    }
    setQueryOptions({
      filterModel: {
        ...filterModel,
        items: itemsWithValues,
      },
    });
  }, []);

  const onSortChange = React.useCallback((sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      setSortOptions(undefined);
      return;
    }
    const sort = {
      sortBy: sortModel[0].field as string,
      sortByDirection: sortModel[0].sort?.toUpperCase() as 'ASC' | 'DESC',
    };
    setSortOptions(sort);
  }, []);
  const yesterday = dayjs().subtract(1, 'day');
  const thirtyDaysAgo = yesterday.subtract(30, 'day');
  const sixtyDaysAgo = yesterday.subtract(60, 'day');
  const thirtyOneDaysAgo = yesterday.subtract(31, 'day');
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    thirtyDaysAgo,
    yesterday,
  ]);
  const [compareDateRange, setCompareDateRange] = useState<DateRange<Dayjs>>([
    sixtyDaysAgo,
    thirtyOneDaysAgo,
  ]);
  const [startDate, endDate] = dateRange;
  const [compareStartDate, compareEndDate] = compareDateRange;
  const [includeAccuracyReportClicks, setIncludeAccuracyReportClicks] =
    useState(false);
  const [
    includeAccuracyReportImpressions,
    setIncludeAccuracyReportImpressions,
  ] = useState(false);

  const {
    data = { total: 0, results: [], compare_results: [], ad_group_bids: {} },
    isFetching,
  } = useFetchReportsWithinDateRangeQuery(
    {
      start_date: startDate ? startDate.format('YYYY-MM-DD') : '',
      end_date: endDate ? endDate.format('YYYY-MM-DD') : '',
      compare_start_date:
        showCompare && compareStartDate
          ? compareStartDate.format('YYYY-MM-DD')
          : '',
      compare_end_date:
        showCompare && compareEndDate
          ? compareEndDate.format('YYYY-MM-DD')
          : '',
      ...paginationModel,
      ...debouncedQueryOptions,
      ...sortOptions,
      includeAccuracyReport:
        includeAccuracyReportClicks || includeAccuracyReportImpressions,
    },
    {
      skip: startDate === null || endDate === null,
    }
  );

  const getCompareData = (compareResult: any) => {
    if (!compareResult) return {};

    const result: { [key: string]: number } = {};
    const columns = [
      'cost',
      'clicks',
      'impressions',
      'avg_click_through_rate',
      'conversions',
      'conversion_value',
      'conversion_value_cost',
      'avg_cost_per_click',
    ];
    const accuracy_columns = [
      'top_word',
      'negative_word',
      'contain_one_word_group_from_ad_group',
      'contain_two_word_groups_from_ad_group',
      'contain_three_word_groups_from_ad_group',
      'contain_four_word_groups_from_ad_group',
      'contain_five_word_groups_from_ad_group',
      'attribute_air_handler_type',
      'attribute_configuration',
      'attribute_diy',
      'attribute_efficiency',
      'attribute_energy_source',
      'attribute_measure',
      'attribute_other',
      'attribute_size',
      'attribute_zone',
      'brand',
      'buying_intent',
      'competitor',
      'concept',
      'core_brand',
      'general',
      'location',
      'model',
      'negative',
      'no_word_group',
      'obsolete_or_related',
      'service_installation',
      'top_of_funnel',
    ];
    if (includeAccuracyReportClicks || includeAccuracyReportImpressions) {
      accuracy_columns.forEach((column) => {
        if (includeAccuracyReportClicks) {
          columns.push(`clicks_${column}`);
        }
        if (includeAccuracyReportImpressions) {
          columns.push(`impressions_${column}`);
        }
      });
    }

    columns.forEach((column) => {
      result[`compare_${column}`] = compareResult[`${column}`] || 0;
      result['compare_' + column + '_mobile'] =
        compareResult[`${column}_mobile`] || 0;
      result['compare_' + column + '_computer'] =
        compareResult[`${column}_computer`] || 0;
    });
    return result;
  };

  const getRows = () => {
    if (showCompare) {
      return data.results.map((result, index) => {
        const compare = data.compare_results.length
          ? data.compare_results[index]
          : null;
        return {
          ...result,
          ...getCompareData(compare),
        };
      });
    }
    return data.results;
  };

  const {
    data: filters = {
      word_combination_filters: [],
      word_group_category_filters: [],
    },
  } = useFetchFiltersQuery({});

  const handleDateRangeChange = (newDateRange: DateRange<Dayjs>) => {
    setDateRange(newDateRange);
  };

  const handleCompareDateRangeChange = (newDateRange: DateRange<Dayjs>) => {
    setCompareDateRange(newDateRange);
  };

  const handleShowCompare = () => {
    setShowCompare((prev) => !prev);
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleDeviceTypeChange = (deviceType: DeviceType) => {
    setDeviceType(deviceType);
  };

  const handleOpen = (adGroupId: string) => {
    setModelAdGroupId(adGroupId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderHeader = (params: GridColumnHeaderParams) => {
    return (
      <Box
        sx={{
          whiteSpace: 'normal',
          lineHeight: '1.2',
          height: 97,
        }}
      >
        {params.colDef.headerName}
      </Box>
    );
  };

  const buildCombinedColumn = (
    field = '',
    headerName = '',
    type = '',
    filterable = false,
    sortable = false,
    minWidth = 100
  ) => {
    let columnType;
    let renderCell: (params: GridRenderCellParams) => string | number;
    switch (type) {
      case 'currency':
        columnType = 'currency';
        renderCell = renderCurrencyCell;
        break;
      case 'percentage':
        columnType = 'percentage';
        renderCell = renderPercentageCell;
        break;
      case 'number':
      default:
        columnType = 'number';
        renderCell = renderNumberCell;
        break;
    }

    return [
      {
        field,
        minWidth,
        flex: 1,
        headerName,
        columnType,
        filterable,
        filterOperators: getNumberFilterOperators(),
        renderCell: (params: GridRenderCellParams) => renderCell(params),
        renderHeader: renderHeader,
        sortable,
        align: 'center',
      },
      ...(showCompare
        ? [
            {
              field: `compare_${field}`,
              minWidth: minWidth + 20,
              flex: 1,
              headerName: `Compare ${headerName}`,
              columnType,
              renderCell: (params: GridRenderCellParams) => renderCell(params),
              renderHeader: renderHeader,
              cellClassName: 'comparison',
              headerClassName: 'comparison',
              filterable: false,
              sortable: false,
            },
          ]
        : []),
    ];
  };

  const buildCombinedColumnsForPerformanceReport = (deviceType?: string) => {
    const defaultWidth = 90;
    let devicePostFix = '';
    let devicePostFixHeader = '';
    if (deviceType === 'mobile') {
      devicePostFix = '_mobile';
      devicePostFixHeader = ' (Mobile)';
    } else if (deviceType === 'desktop') {
      devicePostFix = '_computer';
      devicePostFixHeader = ' (Desktop)';
    }

    const columns = [
      {
        name: 'impressions',
        type: 'number',
        width: 110,
      },
      {
        name: 'clicks',
        type: 'number',
      },
      {
        name: 'avg_click_through_rate',
        header: 'CTR',
        type: 'percentage',
      },
      {
        name: 'cost',
        type: 'currency',
      },
      {
        name: 'avg_cost_per_click',
        header: 'CPC',
        type: 'currency',
      },
      {
        name: 'conversions',
        type: 'number',
        width: 110,
      },
      {
        name: 'conversion_value',
        type: 'currency',
        width: 110,
      },
      {
        name: 'conversion_value_cost',
        header: 'Conversion Value / Cost',
        type: 'currency',
        width: 110,
      },
    ];

    let finalColumns: any[] = [];

    columns.forEach((column) => {
      finalColumns = finalColumns.concat(
        buildCombinedColumn(
          `${column.name}${devicePostFix}`,
          `${column.header || toCapitalCase(column.name)}${devicePostFixHeader}`,
          column.type,
          true,
          true,
          column.width || defaultWidth
        )
      );
    });

    return finalColumns;
  };

  const buildCombinedColumnsForAccuracyReport = (
    includeClicks = true,
    includeImpressions = true,
    deviceType?: string
  ) => {
    let devicePostFix = '';
    let devicePostFixHeader = '';
    if (deviceType === 'mobile') {
      devicePostFix = '_mobile';
      devicePostFixHeader = ' (Mobile)';
    } else if (deviceType === 'desktop') {
      devicePostFix = '_computer';
      devicePostFixHeader = ' (Desktop)';
    }
    let columns = [
      {
        name: 'impressions_top_word',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_top_word',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_negative_word',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_negative_word',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_contain_one_word_group_from_ad_group',
        type: 'number',
        filterable: false,
        width: 170,
      },
      {
        name: 'clicks_contain_one_word_group_from_ad_group',
        type: 'number',
        filterable: false,
        width: 170,
      },
      {
        name: 'impressions_contain_two_word_groups_from_ad_group',
        type: 'number',
        filterable: false,
        width: 170,
      },
      {
        name: 'clicks_contain_two_word_groups_from_ad_group',
        type: 'number',
        filterable: false,
        width: 170,
      },
      {
        name: 'impressions_contain_three_word_groups_from_ad_group',
        type: 'number',
        filterable: false,
        width: 170,
      },
      {
        name: 'clicks_contain_three_word_groups_from_ad_group',
        type: 'number',
        filterable: false,
        width: 170,
      },
      {
        name: 'impressions_contain_four_word_groups_from_ad_group',
        type: 'number',
        filterable: false,
        width: 170,
      },
      {
        name: 'clicks_contain_four_word_groups_from_ad_group',
        type: 'number',
        filterable: false,
        width: 170,
      },
      {
        name: 'impressions_contain_five_word_groups_from_ad_group',
        type: 'number',
        filterable: false,
        width: 170,
      },
      {
        name: 'clicks_contain_five_word_groups_from_ad_group',
        type: 'number',
        filterable: false,
        width: 170,
      },
      {
        name: 'impressions_attribute_air_handler_type',
        type: 'number',
        filterable: false,
        width: 130,
      },
      {
        name: 'clicks_attribute_air_handler_type',
        type: 'number',
        filterable: false,
        width: 130,
      },
      {
        name: 'impressions_attribute_configuration',
        type: 'number',
        filterable: false,
        width: 130,
      },
      {
        name: 'clicks_attribute_configuration',
        type: 'number',
        filterable: false,
        width: 130,
      },
      {
        name: 'impressions_attribute_diy',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_attribute_diy',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_attribute_efficiency',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_attribute_efficiency',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_attribute_energy_source',
        type: 'number',
        filterable: false,
        width: 120,
      },
      {
        name: 'clicks_attribute_energy_source',
        type: 'number',
        filterable: false,
        width: 120,
      },
      {
        name: 'impressions_attribute_measure',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_attribute_measure',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_attribute_other',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_attribute_other',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_attribute_size',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_attribute_size',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_attribute_zone',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_attribute_zone',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_brand',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_brand',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_buying_intent',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_buying_intent',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_competitor',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_competitor',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_concept',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_concept',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_core_brand',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_core_brand',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_general',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_general',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_location',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_location',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_model',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_model',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_negative',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_negative',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_no_word_group',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_no_word_group',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_obsolete_or_related',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_obsolete_or_related',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_service_installation',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_service_installation',
        type: 'number',
        filterable: false,
      },
      {
        name: 'impressions_top_of_funnel',
        type: 'number',
        filterable: false,
      },
      {
        name: 'clicks_top_of_funnel',
        type: 'number',
        filterable: false,
      },
    ];

    columns = columns.filter((column) => {
      return includeClicks || !column.name.includes('clicks');
    });

    columns = columns.filter((column) => {
      return includeImpressions || !column.name.includes('impressions');
    });

    let finalColumns: any[] = [];

    columns.forEach((column) => {
      finalColumns = finalColumns.concat(
        buildCombinedColumn(
          `${column.name}${devicePostFix}`,
          `${toCapitalCase(column.name)}${devicePostFixHeader}`,
          column.type,
          column.filterable,
          false,
          column.width || 110
        )
      );
    });

    return finalColumns;
  };

  const combineDeviceColumns = (columns: any[]) => {
    const length = columns.length;
    const finalOrder = [];
    for (let i = 0; i < length / 2; i++) {
      finalOrder.push(columns[i]);
      finalOrder.push(columns[i + length / 2]);
    }
    return finalOrder;
  };

  const getDeviceTypeColumns = () => {
    const includeAccuracyReport =
      includeAccuracyReportClicks || includeAccuracyReportImpressions;
    const accuracyReportColumnsBothDevices = includeAccuracyReport
      ? combineDeviceColumns([
          ...buildCombinedColumnsForAccuracyReport(
            includeAccuracyReportClicks,
            includeAccuracyReportImpressions,
            'mobile'
          ),
          ...buildCombinedColumnsForAccuracyReport(
            includeAccuracyReportClicks,
            includeAccuracyReportImpressions,
            'desktop'
          ),
        ])
      : [];
    const accuracyReportColumnsMobile = includeAccuracyReport
      ? buildCombinedColumnsForAccuracyReport(
          includeAccuracyReportClicks,
          includeAccuracyReportImpressions,
          'mobile'
        )
      : [];
    const accuracyReportColumnsDesktop = includeAccuracyReport
      ? buildCombinedColumnsForAccuracyReport(
          includeAccuracyReportClicks,
          includeAccuracyReportImpressions,
          'desktop'
        )
      : [];
    const accuracyReportColumns = includeAccuracyReport
      ? buildCombinedColumnsForAccuracyReport(
          includeAccuracyReportClicks,
          includeAccuracyReportImpressions
        )
      : [];

    switch (deviceType) {
      case 'both':
        return [
          ...combineDeviceColumns([
            ...buildCombinedColumnsForPerformanceReport('mobile'),
            ...buildCombinedColumnsForPerformanceReport('desktop'),
          ]),
          ...accuracyReportColumnsBothDevices,
        ];
      case 'mobile':
        return [
          ...buildCombinedColumnsForPerformanceReport('mobile'),
          ...accuracyReportColumnsMobile,
        ];
      case 'desktop':
        return [
          ...buildCombinedColumnsForPerformanceReport('desktop'),
          ...accuracyReportColumnsDesktop,
        ];
      case 'combined':
        return [
          ...buildCombinedColumnsForPerformanceReport(),
          ...accuracyReportColumns,
        ];
      default:
        return [];
    }
  };

  const columns = [
    {
      field: 'new_bid',
      headerName: 'New Bid',
      width: 125,
      renderCell: (params: GridCellParams) => <InlineNewBidInput {...params} />,
      renderHeader: renderHeader,
      sortable: false,
      filterable: false,
    },
    {
      field: 'bid_percentage',
      headerName: 'Bid Percentage',
      width: 125,
      renderCell: (params: GridCellParams) => (
        <InlineBidPercentageInput {...params} />
      ),
      renderHeader: renderHeader,
      sortable: false,
      filterable: false,
    },
    {
      field: 'ad_group_bid',
      width: 85,
      headerName: 'Current Bid',
      renderCell: (params: GridRenderCellParams) =>
        formatCurrency(
          data.ad_group_bids[params.row.ad_group_id]?.ad_group_bid
        ) || 'N/A',
      renderHeader: renderHeader,
      sortable: false,
      type: 'number',
      filterable: false,
    },
    {
      field: 'bid_last_updated',
      width: 100,
      headerName: 'Last Updated',
      filterable: false,
      renderCell: (params: GridRenderCellParams) => (
        <DateCell
          {...params}
          value={
            data.ad_group_bids[params.row.ad_group_id]?.bid_last_updated ||
            'N/A'
          }
        />
      ),
      renderHeader: renderHeader,
      sortable: false,
    },
    {
      field: 'adgroup_name',
      flex: 2,
      maxWidth: 700,
      minWidth: 500,
      headerName: 'Ad Group Name',
      filterOperators: [
        ...getInclusiveFilterOperator(filters.word_combination_filters),
        ...getExclusiveFilterOperator(filters.word_combination_filters),
      ],
      sortable: true,
      filterable: true,
      renderCell: (params: any) => (
        <Link
          component="button"
          onClick={() => handleOpen(params.row.ad_group_id)}
        >
          {params.value}
        </Link>
      ),
      renderHeader: renderHeader,
      hideable: false,
    },
    {
      field: 'match_type',
      width: 90,
      headerName: 'Match Type',
      filterOperators: getContainsFilterOperator(),
      sortable: true,
      filterable: true,
      renderHeader: renderHeader,
      hideable: false,
    },
    ...getDeviceTypeColumns(),
  ];

  return (
    <Card sx={{ marginTop: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ width: '100%', display: 'flex' }}>
              <DataGridPremium
                sx={{
                  minWidth: '730px',
                  '.MuiDataGrid-columnHeaders': {
                    minHeight: 97,
                    height: 97,
                  },
                  '.MuiDataGrid-columnHeader': {
                    whiteSpace: 'normal',
                    lineHeight: '1.2',
                    height: '100%',
                    textAlign: 'center',
                    position: 'relative',
                  },
                  '.MuiDataGrid-columnHeaderTitleContainer': {
                    whiteSpace: 'normal',
                    lineHeight: '1.2',
                    height: 'auto',
                    display: 'flex',
                    padding: '0 4px',
                    paddingTop: '30px',
                    paddingBottom: '6px',
                  },
                  '.MuiDataGrid-iconButtonContainer': {
                    position: 'absolute',
                    bottom: '0',
                    left: '40%',
                  },
                  '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '.MuiDataGrid-menuIcon': {
                    position: 'absolute',
                    left: '40%',
                    top: '-10%',
                    opacity: 0,
                    transition: 'opacity 0.2s ease-in-out',
                    transform: 'rotate(90deg)',
                  },
                  '.MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon': {
                    opacity: 1,
                  },
                  '.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:nth-of-type(odd)':
                    {
                      backgroundColor: '#ffffff',
                    },
                  '.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:nth-of-type(even)':
                    {
                      backgroundColor: '#f5f5f5',
                    },
                  '.MuiDataGrid-cell:nth-of-type(odd)': {
                    backgroundColor: '#ffffff',
                  },
                  '.MuiDataGrid-cell:nth-of-type(even)': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
                columnHeaderHeight={97}
                checkboxSelection
                disableMultipleRowSelection={true}
                disableRowSelectionOnClick
                disableRowGrouping
                disableAggregation
                disableColumnReorder
                disableVirtualization
                autoHeight={true}
                rows={getRows()}
                columns={columns}
                rowCount={data.total}
                pagination={true}
                paginationMode="server"
                loading={isFetching}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                sortingMode="server"
                onSortModelChange={onSortChange}
                pageSizeOptions={[24, 48, 96]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                getRowId={(row) => row.unique_id}
                onRowSelectionModelChange={(selections) => {
                  if (selections.length === 0) {
                    setBidAdjustmentMode('');
                  } else if (selections[0].toString().includes('EXACT')) {
                    setBidAdjustmentMode('BID');
                  } else {
                    setBidAdjustmentMode('PERCENTAGE');
                  }
                  setSelectedIds(selections);
                }}
                isRowSelectable={(params) => {
                  if (bidAdjustmentMode === '') return true;

                  const mustInclude =
                    bidAdjustmentMode === 'BID'
                      ? ['EXACT']
                      : ['PHRASE', 'BROAD'];

                  return mustInclude.includes(params.row.match_type);
                }}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                slots={{ toolbar: FilterToolbar }}
                apiRef={apiRef}
                slotProps={{
                  toolbar: {
                    selectedIds,
                    bidAdjustmentMode,
                    handleDeviceTypeChange,
                    deviceType,
                    dateRange,
                    handleDateRangeChange,
                    adGroupBids: data.ad_group_bids,
                    showCompare,
                    handleShowCompare,
                    compareDateRange,
                    handleCompareDateRangeChange,
                    disableColumnGrouping: true,
                    disableAggregation: true,
                    includeAccuracyReportClicks,
                    includeAccuracyReportImpressions,
                    setIncludeAccuracyReportClicks,
                    setIncludeAccuracyReportImpressions,
                  },
                  filterPanel: {
                    onKeyDown: (event: KeyboardEvent) => {
                      if (
                        event.key === 'Escape' ||
                        (event.key === 'Enter' && !event.shiftKey)
                      ) {
                        apiRef.current.hideFilterPanel();
                      }
                    },
                    sx: {
                      position: 'absolute',
                      top: `-110px !important`,
                      right: '-310px',
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                      width: '650px',
                    },
                  },
                }}
              />
            </div>
            <AdGroupModal
              open={open}
              handleClose={handleClose}
              adgroup_id={modalAdgroupId}
            />
            <ReportLoadingDialog open={isFetching} onClose={() => {}} />
          </Paper>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BidManagementDataGrid;
