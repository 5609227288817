import React from 'react';
import { formatCurrency } from '../../utils/formatNumbers';
import { PriceAssetPriceQualifier } from '../../types/enums';

const displayCurrency = (
  value: any,
  row: any,
  priceAssetPriceQualifier: PriceAssetPriceQualifier
) => {
  if (value === 0) {
    switch (priceAssetPriceQualifier) {
      case PriceAssetPriceQualifier.From:
        return `(From ${formatCurrency(row.from_price)})`;
      case PriceAssetPriceQualifier.UpTo:
        return `(Up To ${formatCurrency(row.up_to_price)})`;
      case PriceAssetPriceQualifier.Average:
        return `(Avg ${formatCurrency(row.avg_price)})`;
      default:
        return `${formatCurrency(row.from_price)}/${formatCurrency(row.up_to_price)}/${formatCurrency(row.avg_price)}`;
    }
  }
  if (value !== undefined) {
    return formatCurrency(value);
  }
  return '';
};

const PriceCell = (params: any) => (
  <>
    {displayCurrency(params.value, params.row, params.priceAssetPriceQualifier)}
  </>
);

export default PriceCell;
