import React from 'react';
import Moment from 'react-moment';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

const DateCell = (params: GridRenderCellParams) => {
  if (params.value === 'N/A') {
    return params.value;
  }
  return params.value ? (
    <Moment fromNow utc>
      {params.value}
    </Moment>
  ) : (
    <></>
  );
};

export default DateCell;
