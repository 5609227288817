import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useDeleteSearchTermFinalUrlMutation } from '../../../slices/adSlice';

interface DeleteSearchTermFinalUrlModalProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  name: string;
  searchTermId: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

const DeleteSearchTermFinalUrlModal: React.FC<
  DeleteSearchTermFinalUrlModalProps
> = ({ modalOpen, setModalOpen, name, searchTermId }) => {
  const [deleteSearchTermFinalUrl] = useDeleteSearchTermFinalUrlMutation();
  const handleDelete = async () => {
    await deleteSearchTermFinalUrl({ id: searchTermId }).unwrap();
  };
  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="delete-conversation-modal-title"
      aria-describedby="delete-conversation-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="delete-conversation-modal-title"
          variant="h6"
          component="h2"
        >
          Delete Final Url?
        </Typography>
        <Typography id="delete-conversation-modal-description" sx={{ mt: 2 }}>
          This will delete the Final Url for the search term: <b>{name}</b>.
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setModalOpen(false);
              handleDelete();
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteSearchTermFinalUrlModal;
