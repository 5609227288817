import React, { useEffect } from 'react';
import { useTitle } from '../contexts/TitleContext';
import { Box } from '@mui/material';
import GlobalSettingsPanel from '../components/GlobalSettingsPanel/GlobalSettingsPanel';

const GlobalSettings: React.FC = () => {
  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle(`Global Settings`);
  }, [setTitle]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <GlobalSettingsPanel />
    </Box>
  );
};

export default GlobalSettings;
