import { emptySplitApi } from './baseApi';
import { GridRowId, GridFilterModel } from '@mui/x-data-grid-premium';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { Headline } from '../types/pinnable';
import { formatFilters } from '../utils/formatFilters';

export const headlineApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchHeadlines: builder.query<Headline[], string | void>({
      query(adgroup_id) {
        return `/headlines/${adgroup_id}`;
      },
      providesTags: ['Headlines'],
    }),
    createHeadlines: builder.mutation<
      Headline[],
      {
        adgroup_id?: string;
        headlines: Pick<
          Headline,
          'headline' | 'is_global' | 'pinned' | 'is_primary' | 'global_pinned'
        >[];
        approved?: boolean;
      }
    >({
      query: ({ headlines, adgroup_id, approved }) => ({
        url: `/headlines/many`,
        method: 'POST',
        body: {
          headlines,
          ad_group_id: adgroup_id,
          approved,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Headlines',
          'GlobalHeadlines',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    updateHeadline: builder.mutation<
      Headline,
      {
        id: string;
        headline: string;
        pinned: string;
        ad_group_id?: string;
        global_pinned?: string;
      }
    >({
      query: (body) => ({
        url: `/headlines/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'AdGroups',
          'Headline',
          'Headlines',
          'HeadlineGroup',
          'HeadlineGroups',
          'PromptPreviewElements',
          'GlobalHeadlines',
          'GlobalPrimaryHeadlines',
          'GlobalGeneralHeadlines',
        ]),
    }),
    regenerateHeadlines: builder.mutation<
      Headline[],
      { ad_group_id: string; ids: string[] | GridRowId[] }
    >({
      query: (body) => ({
        url: `/headlines/regenerate`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Headlines',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    regenerateAllHeadlines: builder.mutation<
      Headline[],
      { ad_group_id: string }
    >({
      query: (body) => ({
        url: `/headlines/regenerate_all`,
        method: 'POST',
        params: body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Headlines',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    approveHeadlines: builder.mutation<
      Headline[],
      { ids: string[] | GridRowId[]; ad_group_id: string }
    >({
      query: (body) => ({
        url: `/headlines/approve`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Headlines',
          'AdGroup',
          'AdGroupCounts',
          'GlobalHeadlines',
          'AdGroups',
        ]),
    }),
    unapproveHeadlines: builder.mutation<
      Headline[],
      { ids: string[] | GridRowId[]; ad_group_id: string }
    >({
      query: (body) => ({
        url: `/headlines/unapprove`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Headlines',
          'AdGroup',
          'AdGroupCounts',
          'GlobalHeadlines',
          'AdGroups',
        ]),
    }),
    deleteHeadlines: builder.mutation<void, { ids: GridRowId[] }>({
      query: (body) => ({
        url: `/headlines/`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Headlines',
          'GlobalHeadlines',
          'HeadlineGroup',
          'HeadlineGroups',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    fetchHeadline: builder.query<Headline, { headlineId: string }>({
      query: ({ headlineId }) => ({
        url: `/headlines/`,
        method: 'GET',
        params: { headline_id: headlineId },
      }),
      providesTags: ['Headline'],
    }),
    fetchAllGlobalGeneralHeadlines: builder.query<
      {
        results: Headline[];
        total: number;
      },
      {
        page: number;
        pageSize: number;
        filterModel?: GridFilterModel;
      }
    >({
      query: ({ page, pageSize, filterModel }) => {
        return {
          url: '/headlines/global/general',
          method: 'POST',
          params: {
            page,
            pageSize,
            logic_operator: filterModel?.logicOperator || 'and',
          },
          body: {
            filters: formatFilters(filterModel),
          },
        };
      },
      providesTags: ['GlobalHeadlines', 'GlobalGeneralHeadlines'],
    }),
    fetchAllGlobalPrimaryHeadlines: builder.query<
      {
        resuls: Headline[];
        total: number;
      },
      {
        page: number;
        pageSize: number;
        filterModel?: GridFilterModel;
      }
    >({
      query: ({ page, pageSize, filterModel }) => {
        return {
          url: '/headlines/global/primary',
          method: 'POST',
          params: {
            page,
            pageSize,
            logic_operator: filterModel?.logicOperator || 'and',
          },
          body: {
            filters: formatFilters(filterModel),
          },
        };
      },
      providesTags: ['GlobalHeadlines', 'GlobalPrimaryHeadlines'],
    }),
    assignHeadlinesToAdgroup: builder.mutation<
      void,
      { headlineIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ headlineIds, adGroupIds }) => ({
        url: `/headlines/assign`,
        method: 'POST',
        body: {
          headline_ids: headlineIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Headlines',
          'GlobalHeadlines',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unassignHeadlinesFromAdgroup: builder.mutation<
      void,
      { headlineIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ headlineIds, adGroupIds }) => ({
        url: `/headlines/unassign`,
        method: 'POST',
        body: {
          headline_ids: headlineIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Headlines',
          'PromptPreviewElements',
          'GlobalHeadlines',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    toggleIncludeDynamicKeyword: builder.mutation<
      void,
      { headlineId: GridRowId }
    >({
      query: ({ headlineId }) => ({
        url: `/headlines/dynamic/${headlineId}`,
        method: 'PUT',
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Headline',
          'Headlines',
          'GlobalHeadlines',
          'GlobalPrimaryHeadlines',
          'GlobalGeneralHeadlines',
        ]),
    }),
    convertToGlobal: builder.mutation<
      void,
      { headlineIds: GridRowId[]; ad_group_id: string }
    >({
      query: ({ headlineIds, ad_group_id }) => ({
        url: `/headlines/global/convert`,
        method: 'PUT',
        body: {
          headline_ids: headlineIds,
          ad_group_id,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Headline',
          'Headlines',
          'GlobalHeadlines',
          'GlobalPrimaryHeadlines',
          'GlobalGeneralHeadlines',
        ]),
    }),
  }),
});

export const {
  useFetchHeadlineQuery,
  useFetchHeadlinesQuery,
  useUpdateHeadlineMutation,
  useCreateHeadlinesMutation,
  useApproveHeadlinesMutation,
  useUnapproveHeadlinesMutation,
  useRegenerateHeadlinesMutation,
  useRegenerateAllHeadlinesMutation,
  useDeleteHeadlinesMutation,
  useFetchAllGlobalGeneralHeadlinesQuery,
  useFetchAllGlobalPrimaryHeadlinesQuery,
  useAssignHeadlinesToAdgroupMutation,
  useUnassignHeadlinesFromAdgroupMutation,
  useToggleIncludeDynamicKeywordMutation,
  useConvertToGlobalMutation,
} = headlineApiSlice;
