import React from 'react';
import { Description } from '../../types/pinnable';
import GlobalDescriptionSection from '../DescriptionToolComponents/GlobalDescriptionSection';

interface AssignedDescriptionProps {
  descriptions: Description[];
}

const AssignedDescription: React.FC<AssignedDescriptionProps> = ({
  descriptions,
}) => {
  const data = { results: descriptions, total: descriptions.length };
  return <GlobalDescriptionSection data={data} isGroupView={true} />;
};

export default AssignedDescription;
