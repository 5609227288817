import React, { useState } from 'react';
import {
  DataGridPremium,
  GridFilterModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridRowId,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {
  Card,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  PaperProps,
  Button,
} from '@mui/material';
import { useFetchFiltersQuery } from '../../slices/filterSlice';
import {
  getExclusiveFilterOperator,
  getInclusiveFilterOperator,
} from '../MultiselectInputValue';
import {
  filterColumns,
  getColumnForNewFilter,
} from '../../utils/filterColumns';
import Draggable from 'react-draggable';
import { GlobalAssetType } from '../../types/enums';

interface FilterToolbarProps {
  assetIds: GridRowId[];
  groupIds: GridRowId[];
  setModalOpen: (val: boolean) => void;
  assetType: GlobalAssetType;
  assignAssets: (
    assetIds: GridRowId[],
    assetGroupIds: GridRowId[]
  ) => Promise<string[] | null | void>;
  unassignAssets: (
    assetIds: GridRowId[],
    assetGroupIds: GridRowId[]
  ) => Promise<void>;
}

const FilterToolbar: React.FC<FilterToolbarProps> = ({
  assetIds,
  groupIds,
  setModalOpen,
  assetType,
  assignAssets,
  unassignAssets,
}) => {
  const countToUpdate = groupIds?.length || 0;
  const { enqueueSnackbar } = useSnackbar();
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      {!!countToUpdate && (
        <>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() =>
              assignAssets(assetIds, groupIds)
                .then((res) => {
                  if (res && Array.isArray(res)) {
                    res.forEach((r: string) => {
                      enqueueSnackbar(r, {
                        variant: 'error',
                      });
                    });
                  } else {
                    enqueueSnackbar(`${assetType} assigned successfully`, {
                      variant: 'success',
                    });
                  }
                  setModalOpen(false);
                })
                .catch(() => {
                  enqueueSnackbar('Something went wrong', {
                    variant: 'error',
                  });
                })
            }
          >
            Assign To {countToUpdate} {assetType} Groups
          </Button>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() =>
              unassignAssets(assetIds, groupIds)
                .then(() => {
                  enqueueSnackbar(`${assetType} unassigned successfully`, {
                    variant: 'success',
                  });
                  setModalOpen(false);
                })
                .catch(() => {
                  enqueueSnackbar('Something went wrong', {
                    variant: 'error',
                  });
                })
            }
          >
            Unassign From {countToUpdate} {assetType} Groups
          </Button>
        </>
      )}
    </GridToolbarContainer>
  );
};

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

export interface AssignToSpecificAssetGroupModalProps {
  setModalOpen: (val: boolean) => void;
  assetIds: GridRowId[];
  assetType: GlobalAssetType;
}

interface AssignToAssetGroupModalProps
  extends AssignToSpecificAssetGroupModalProps {
  data: {
    results: any[];
    total: number;
  };
  isFetching: boolean;
  assignAssets: (
    assetIds: GridRowId[],
    assetGroupIds: GridRowId[]
  ) => Promise<string[] | null | void>;
  unassignAssets: (
    assetIds: GridRowId[],
    assetGroupIds: GridRowId[]
  ) => Promise<void>;
}

const AssignToAssetGroupModal: React.FC<AssignToAssetGroupModalProps> = ({
  setModalOpen,
  assetIds,
  assetType,
  data,
  isFetching,
  assignAssets,
  unassignAssets,
}) => {
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 24,
  });

  const [_, setQueryOptions] = useState<{
    filterModel?: GridFilterModel;
  }>();

  const {
    data: filters = { word_group_filters: [], word_combination_filters: [] },
  } = useFetchFiltersQuery({});
  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const columns = [
    {
      field: 'group_name',
      headerName: `${assetType} Group Name`,
      flex: 1,
      filterOperators: [
        ...getInclusiveFilterOperator(filters.word_combination_filters),
        ...getExclusiveFilterOperator(filters.word_combination_filters),
      ],
    },
  ];

  return (
    <>
      <Dialog
        open
        onClose={() => setModalOpen(false)}
        fullWidth
        maxWidth={'md'}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          sx={{ bgcolor: 'primary.main', color: 'white' }}
          style={{
            cursor: 'move',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
          id="draggable-dialog-title"
        >
          Assign {assetIds.length} To Groups - {assetType}
          <IconButton
            sx={{ color: 'white' }}
            aria-label="close"
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card sx={{ overflow: 'auto' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ width: '100%' }}>
                  <DataGridPremium
                    apiRef={apiRef}
                    checkboxSelection
                    autoHeight={true}
                    rows={data.results}
                    columns={columns}
                    rowCount={data.total}
                    paginationMode="server"
                    loading={isFetching}
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    pageSizeOptions={[24, 48, 96, 200, 500, 1000, 2000]}
                    pagination={true}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    onRowSelectionModelChange={(selections) =>
                      setSelectedIds(selections)
                    }
                    slots={{ toolbar: FilterToolbar }}
                    slotProps={{
                      filterPanel: {
                        filterFormProps: {
                          filterColumns: (params) =>
                            filterColumns({
                              ...params,
                              uniqueFilterColumns: [
                                'adgroup_name',
                                'word_category_combination',
                              ],
                            }),
                        },
                        sx: {
                          '& .MuiDataGrid-filterFormValueInput   ': {
                            width: '500px',
                          },
                          position: 'absolute',
                          top: `-110px !important`,
                          left: '105px',
                          backgroundColor: '#fff',
                          border: '1px solid #ccc',
                        },
                        getColumnForNewFilter: (params) =>
                          getColumnForNewFilter({
                            ...params,
                            uniqueFilterColumns: [
                              'adgroup_name',
                              'word_category_combination',
                            ],
                          }),

                        onKeyDown: (event: KeyboardEvent) => {
                          if (
                            event.key === 'Escape' ||
                            (event.key === 'Enter' && !event.shiftKey)
                          ) {
                            apiRef.current.hideFilterPanel();
                          }
                        },
                      },
                      toolbar: {
                        assetIds,
                        groupIds: selectedIds,
                        setModalOpen,
                        assetType,
                        assignAssets,
                        unassignAssets,
                      },
                    }}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Card>
      </Dialog>
    </>
  );
};
export default AssignToAssetGroupModal;
