import React from 'react';
import {
  Pending as PendingIcon,
  Recommend as RecommendIcon,
} from '@mui/icons-material';

const ApprovedCell = (params: any) => (
  <React.Fragment>
    {params.value ? (
      <RecommendIcon color="success" />
    ) : (
      <PendingIcon color="warning" />
    )}
  </React.Fragment>
);

export default ApprovedCell;
