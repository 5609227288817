import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { AIModel, PromptingButtonType } from '../../../types/enums';

interface ChooseMessageAIProps {
  disabled: boolean;
  handleClick: () => void;
  handleSetModel: (model: AIModel) => void;
  selectedAIModel: AIModel;
  buttonType: PromptingButtonType;
}

const ChooseMessageAI: React.FC<ChooseMessageAIProps> = ({
  handleSetModel,
  selectedAIModel,
  handleClick,
  buttonType,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const getAIModelDisplayName = (model: AIModel) => {
    switch (model) {
      case AIModel.Claude3Haiku:
        return 'Claude-Haiku';
      case AIModel.Claude3Opus:
        return 'Claude-Opus';
      case AIModel.Claude3Sonnet:
        return 'Claude-Sonnet';
      case AIModel.Claude35Sonnet:
        return 'Claude-Sonnet (3.5)';
      case AIModel.ChatGPT4:
        return 'ChatGPT-4';
      case AIModel.ChatGPT4o:
        return 'ChatGPT-4-O';
    }
  };

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: AIModel
  ) => {
    handleSetModel(option);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        sx={{ width: '300px' }}
      >
        <Button
          variant="contained"
          color={
            buttonType === PromptingButtonType.AskAI ? 'success' : 'primary'
          }
          disabled={disabled}
          onClick={handleClick}
        >
          {buttonType === PromptingButtonType.AskAI
            ? 'Ask'
            : 'Start Conversation w/'}{' '}
          {getAIModelDisplayName(selectedAIModel)}
        </Button>
        <Button
          size="small"
          color={
            buttonType === PromptingButtonType.AskAI ? 'success' : 'primary'
          }
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {Object.values(AIModel).map((option) => (
                    <MenuItem
                      key={option}
                      selected={option === selectedAIModel}
                      onClick={(event) =>
                        handleMenuItemClick(event, option as AIModel)
                      }
                    >
                      {getAIModelDisplayName(option as AIModel)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
export default ChooseMessageAI;
