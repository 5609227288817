import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import formatDisplayName from '../../utils/formatDisplayName';

interface TextFieldInputProps {
  values: any;
  touched: any;
  errors: any;
  handleChange: any;
  handleBlur: any;
  setTouched: any;
  fieldName: string;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
}

const TextFieldInput: React.FC<TextFieldInputProps> = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setTouched,
  fieldName,
  disabled,
  placeholder,
  type,
}) => {
  return (
    <Grid item xs={12} mt={2}>
      <Typography variant="body1">
        <span style={{ fontWeight: 'bold' }}>
          {formatDisplayName(fieldName)}:
        </span>
      </Typography>
      <TextField
        name={fieldName}
        style={{ width: '100%' }}
        error={touched[fieldName] && Boolean(errors[fieldName])}
        helperText={touched[fieldName] && errors[fieldName]}
        value={values[fieldName]}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled ?? false}
        type={type ? type : 'text'}
        onFocus={() => setTouched({ ...touched, [fieldName]: true })}
        placeholder={placeholder}
      />
    </Grid>
  );
};

export default TextFieldInput;
