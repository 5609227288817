import React from 'react';
import { Grid, Typography, TextField, IconButton } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';

interface URLFieldInputProps {
  values: any;
  touched: any;
  errors: any;
  handleChange: any;
  handleBlur: any;
  setTouched: any;
  setSiteSurfModalOpen: (value: boolean) => void;
}

const URLFieldInput: React.FC<URLFieldInputProps> = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setTouched,
  setSiteSurfModalOpen,
}) => {
  return (
    <Grid item xs={12} mt={2}>
      <Typography variant="body1">
        <span style={{ fontWeight: 'bold' }}>URL:</span>
        <IconButton
          onClick={() => {
            setSiteSurfModalOpen(true);
          }}
        >
          <PublicIcon color="success" />
        </IconButton>
      </Typography>

      <TextField
        name="url"
        style={{ width: '100%' }}
        error={touched.url && Boolean(errors.url)}
        helperText={<>{touched.url && errors.url}</>}
        value={values.url}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={() => setTouched({ ...touched, url: true })}
      />
    </Grid>
  );
};

export default URLFieldInput;
