// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Nav */

.link {
    color: #1976d2;
    text-decoration: none;
}

.activeLink {
    background-color: #1976d2 !important;
    color: #fff !important;
    font-weight: bold !important;
}

.activeIcon {
    color: #fff !important;
}

.no-select {
    -webkit-user-select: none;
    user-select: none;
}

.closed-subheader {
    font-size: 0.6rem !important;
    padding: 0 6px !important;
    transition: font-size 0.3s ease;
}

/* Headlines Section */

.headlines-section-primary {
    border: 1px solid rgba(0, 128, 8, 1);
    border-left: 0;
    background-color: rgba(0, 128, 8, 0.2);
}

.headlines-section-primary:hover {
    background-color: rgba(0, 128, 8, .3) !important;
}

/* Price Assets Section */
.price_assets-section-warning {
    border: 1px solid #ffa726;
    border-left: 0;
    background-color: #ffe0b2;
}

.price_assets-section-warning:hover {
    background-color: #fbaa31 !important;
}`, "",{"version":3,"sources":["webpack://./src/main.css"],"names":[],"mappings":"AAAA,QAAQ;;AAER;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IAGzB,iBAAiB;AACrB;;AAEA;IACI,4BAA4B;IAC5B,yBAAyB;IACzB,+BAA+B;AACnC;;AAEA,sBAAsB;;AAEtB;IACI,oCAAoC;IACpC,cAAc;IACd,sCAAsC;AAC1C;;AAEA;IACI,gDAAgD;AACpD;;AAEA,yBAAyB;AACzB;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":["/* Nav */\n\n.link {\n    color: #1976d2;\n    text-decoration: none;\n}\n\n.activeLink {\n    background-color: #1976d2 !important;\n    color: #fff !important;\n    font-weight: bold !important;\n}\n\n.activeIcon {\n    color: #fff !important;\n}\n\n.no-select {\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n}\n\n.closed-subheader {\n    font-size: 0.6rem !important;\n    padding: 0 6px !important;\n    transition: font-size 0.3s ease;\n}\n\n/* Headlines Section */\n\n.headlines-section-primary {\n    border: 1px solid rgba(0, 128, 8, 1);\n    border-left: 0;\n    background-color: rgba(0, 128, 8, 0.2);\n}\n\n.headlines-section-primary:hover {\n    background-color: rgba(0, 128, 8, .3) !important;\n}\n\n/* Price Assets Section */\n.price_assets-section-warning {\n    border: 1px solid #ffa726;\n    border-left: 0;\n    background-color: #ffe0b2;\n}\n\n.price_assets-section-warning:hover {\n    background-color: #fbaa31 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
