import dayjs, { Dayjs } from 'dayjs';
import { PremadeDateRange } from '../types/premadeDateRange';

const getQuarterRange = (
  quarter: 1 | 2 | 3 | 4,
  year: number
): [Dayjs, Dayjs] => {
  switch (quarter) {
    case 1:
      return [dayjs(`${year}-01-01`), dayjs(`${year}-03-31`)];
    case 2:
      return [dayjs(`${year}-04-01`), dayjs(`${year}-06-30`)];
    case 3:
      return [dayjs(`${year}-07-01`), dayjs(`${year}-09-30`)];
    case 4:
      return [dayjs(`${year}-10-01`), dayjs(`${year}-12-31`)];
  }
};

export const getDateRange = (type: PremadeDateRange): [Dayjs, Dayjs] => {
  const today = dayjs();
  const yesterday = today.subtract(1, 'day');
  switch (type) {
    case PremadeDateRange.Last30Days:
      return [yesterday.subtract(30, 'day'), yesterday];
    case PremadeDateRange.Last31to60Days:
      return [yesterday.subtract(60, 'day'), yesterday.subtract(31, 'day')];
    case PremadeDateRange.Last7Days:
      return [yesterday.subtract(7, 'day'), yesterday];
    case PremadeDateRange.LastWeek:
      return [yesterday.subtract(14, 'day'), yesterday.subtract(7, 'day')];
    case PremadeDateRange.Q1:
      return getQuarterRange(
        1,
        today.month() < 3 ? today.year() - 1 : today.year()
      );
    case PremadeDateRange.Q2:
      return getQuarterRange(
        2,
        today.month() < 6 ? today.year() - 1 : today.year()
      );
    case PremadeDateRange.Q3:
      return getQuarterRange(
        3,
        today.month() < 9 ? today.year() - 1 : today.year()
      );
    case PremadeDateRange.Q4:
      return getQuarterRange(
        4,
        today.month() < 12 ? today.year() - 1 : today.year()
      );
    default:
      return [today, today];
  }
};

export const getByDayDateRange = (
  type: PremadeDateRange,
  initialStartDate: Dayjs,
  numberOfDaysInPeriod: number
): [Dayjs, Dayjs] | null => {
  switch (type) {
    case PremadeDateRange.SamePeriodLastYear:
      return getPreviousYearPeriodByDay(
        initialStartDate?.toDate(),
        numberOfDaysInPeriod
      );

    case PremadeDateRange.PreviousPeriod:
      return getPreviousPeriodByDay(
        initialStartDate?.toDate(),
        numberOfDaysInPeriod
      );

    default:
      return null;
  }
};

export const getDaysBetweenDates = (
  date1: Dayjs | null,
  date2: Dayjs | null
): number => {
  if (!date1 || !date2) {
    return 0;
  }
  return date2.diff(date1, 'day');
};

function getPreviousPeriodByDay(
  startDate: Date,
  number_of_days: number
): [Dayjs, Dayjs] {
  const duration = number_of_days * 24 * 60 * 60 * 1000;
  const previousStart = new Date(startDate.getTime() - duration);

  while (previousStart.getDay() !== startDate.getDay()) {
    previousStart.setDate(previousStart.getDate() - 1);
  }

  const previousEnd = new Date(previousStart.getTime() + duration);
  return [dayjs(previousStart), dayjs(previousEnd)];
}

const getPreviousYearPeriodByDay = (
  startDate: Date,
  number_of_days: number
): [Dayjs, Dayjs] => {
  const dateLastYear = getDateFromLastYear(startDate);
  const endDate = addDays(dateLastYear, number_of_days);
  return [dayjs(dateLastYear), dayjs(endDate)];
};

function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function getDateFromLastYear(inputDate: Date = new Date()): Date {
  const date: Date = new Date(inputDate);
  const year: number = date.getFullYear();
  const weekNumber: number = getWeekNumber(date);

  const lastYear: Date = new Date(year - 1, 0, 1);

  lastYear.setDate(lastYear.getDate() + (weekNumber - 1) * 7);

  const dayDiff: number = date.getDay() - lastYear.getDay();
  lastYear.setDate(lastYear.getDate() + dayDiff);

  return lastYear;
}

function getWeekNumber(d: Date): number {
  const target: Date = new Date(
    Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
  );
  target.setUTCDate(target.getUTCDate() + 4 - (target.getUTCDay() || 7));
  const yearStart: Date = new Date(Date.UTC(target.getUTCFullYear(), 0, 1));
  return Math.ceil(
    ((target.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );
}
