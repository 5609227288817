import React, { useCallback, useState, useEffect } from 'react';
import { Typography, Grid, Button, IconButton } from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import DynamicPromptModal from '../../../DynamicPromptModal';
import {
  useRegenerateSitelinksMutation,
  useApproveSitelinksMutation,
  useUnapproveSitelinksMutation,
  useFetchSitelinksQuery,
  useDeleteSitelinksMutation,
  useCreateSitelinksMutation,
  useUpdateSitelinkMutation,
  useRegenerateAllSitelinksMutation,
  useUnassignSitelinksFromAdgroupMutation,
  useConvertSitelinksToGlobalMutation,
} from '../../../../slices/sitelinkSlice';
import {
  Circle as CircleIcon,
  Add as AddIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridApi,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModesModel,
  GridToolbarContainer,
} from '@mui/x-data-grid-premium';
import ButtonFooter from '../table/ButtonFooter';
import ApprovedCell from '../table/ApprovedCell';
import { GridRowId } from '@mui/x-data-grid-premium';
import { GridRowModes } from '@mui/x-data-grid-premium';
import { GridRowsProp } from '@mui/x-data-grid-premium';
import TableCellWithValidation from '../../../TableCells/TableCellWithValidation';
import UrlCell from '../../../TableCells/UrlCell';
import CircularProgress from '@mui/material/CircularProgress';
import {
  sitelinkDescriptionValidationSchema,
  priceAssetAndSitelinkHeaderValidationSchema,
  urlValidationSchema,
} from '../../../../utils/validation';
import SiteSurfModal from '../../../SiteSurfModal';
import { CharacterLimitForContent, UrlType } from '../../../../types/enums';
import { useSnackbar } from 'notistack';
import buildTextWithCharacterCountColumn from '../../../../utils/buildTextWithCharacterCountColumn';
import { getApprovedCount } from '../../../../utils/getApprovedCount';
import { Approvable } from '../../../../types/adGroups';
import { getActions } from '../../../../helpers/getActions';
import { getStatusColor } from '../../../../helpers/getStatusColor';
import { Sitelink } from '../../../../types/sitelinks';
interface DataRow extends Sitelink {
  isNew?: boolean;
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
  approvedCount: number;
}

const EditToolbar = (props: EditToolbarProps) => {
  const { setRows, setRowModesModel, approvedCount } = props;

  const handleClick = () => {
    const id = Math.floor(Math.random() * 1000000000);
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        approved: false,
        description_one: '',
        description_two: '',
        header: '',
        url: '',
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'header' },
    }));
  };

  if (approvedCount >= 20) {
    return null;
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add manual sitelink
      </Button>
    </GridToolbarContainer>
  );
};

const SiteLinksSection: React.FC<{
  adgroup_id: string;
  togglePromptPreview: () => void;
}> = ({ adgroup_id, togglePromptPreview }) => {
  const [promptModalOpen, setPromptModalOpen] = React.useState(false);
  const { data, isFetching } = useFetchSitelinksQuery(adgroup_id);
  const { enqueueSnackbar } = useSnackbar();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [currentSitelinks, setCurrentSitelinks] = useState<DataRow[]>([]);
  const [siteSurfModalOpen, setSiteSurfModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedUrl, setSelectedUrl] = useState('');
  const [dataGridApiRef, setDataGridApiRef] =
    useState<React.MutableRefObject<GridApi>>();
  const [unassignSitelinks] = useUnassignSitelinksFromAdgroupMutation();
  const [createSitelinks] = useCreateSitelinksMutation();
  const [updateSitelink] = useUpdateSitelinkMutation();
  const [deleteSitelinks] = useDeleteSitelinksMutation();
  const [unapproveSitelinks] = useUnapproveSitelinksMutation();
  const [approveSitelinks] = useApproveSitelinksMutation();
  const [convertToGlobal] = useConvertSitelinksToGlobalMutation();
  const [regenerateSitelinks, { isLoading: isLoadingRegenerate }] =
    useRegenerateSitelinksMutation();
  const [regenerateAllSitelinks, { isLoading: isLoadingRegenerateAll }] =
    useRegenerateAllSitelinksMutation({ fixedCacheKey: 'regenerateSitelinks' });

  const isLoading = isFetching || isLoadingRegenerate || isLoadingRegenerateAll;
  const approvedCount = getApprovedCount(currentSitelinks as Approvable[]);

  useEffect(() => {
    if (data) {
      setCurrentSitelinks(data);
    }
  }, [data]);

  const handleRegenerateClick = useCallback(
    (ids: GridRowId[]) => () => {
      regenerateSitelinks({
        ids,
        ad_group_id: adgroup_id,
      });
    },
    [regenerateSitelinks, adgroup_id]
  );

  const handleRegenerateAllClick = useCallback(() => {
    regenerateAllSitelinks({
      ad_group_id: adgroup_id,
    });
  }, [regenerateAllSitelinks, adgroup_id]);

  const handleApproveClick = useCallback(
    (ids: GridRowId[]) => () => {
      approveSitelinks({
        sitelink_ids: ids,
        ad_group_id: adgroup_id,
      });
    },
    [approveSitelinks, adgroup_id]
  );

  const handleUnapproveClick = useCallback(
    (ids: GridRowId[]) => () => {
      unapproveSitelinks({
        sitelink_ids: ids,
        ad_group_id: adgroup_id,
      });
    },
    [unapproveSitelinks, adgroup_id]
  );

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [setRowModesModel, rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [setRowModesModel, rowModesModel]
  );

  const handleDeleteClick = useCallback(
    (ids: GridRowId[]) => async () => {
      const rows = currentSitelinks.filter((row) => ids.includes(row.id));
      const idsToDelete = [];
      const idsToUnAssign = [];
      const updatedRowModesModel = { ...rowModesModel };

      for (const row of rows) {
        if (row.is_global) {
          idsToUnAssign.push(row.id);
        } else {
          idsToDelete.push(row.id);
        }
        updatedRowModesModel[row.id] = { mode: GridRowModes.View };
      }

      if (idsToUnAssign.length) {
        await unassignSitelinks({
          sitelinkIds: idsToUnAssign,
          adGroupIds: [adgroup_id as GridRowId],
        });

        enqueueSnackbar(
          `${idsToUnAssign.length} sitelinks unassigned successfully`,
          {
            variant: 'success',
          }
        );
      }

      if (idsToDelete.length) {
        await deleteSitelinks({ ids: idsToDelete });
        enqueueSnackbar(
          `${idsToDelete.length} sitelinks deleted successfully`,
          {
            variant: 'success',
          }
        );
      }

      setRowModesModel({ ...updatedRowModesModel });
    },
    [
      setRowModesModel,
      rowModesModel,
      deleteSitelinks,
      unassignSitelinks,
      adgroup_id,
      enqueueSnackbar,
      currentSitelinks,
    ]
  );

  const doCreateSitelinks = useCallback(
    async (row: any) => {
      await createSitelinks({
        sitelinks: [
          {
            description_one: row.description_one,
            description_two: row.description_two,
            header: row.header,
            url: row.url,
            is_global: false,
          },
        ],
        ad_group_id: adgroup_id,
      }).unwrap();
    },
    [createSitelinks, adgroup_id]
  );

  const handleConvertLocalClick = useCallback(
    (id: GridRowId, row: any) => async () => {
      await unassignSitelinks({
        sitelinkIds: [id],
        adGroupIds: [adgroup_id],
      });
      await doCreateSitelinks(row);
      enqueueSnackbar('Sitelink successfully converted to Local', {
        variant: 'success',
      });
    },
    [doCreateSitelinks, enqueueSnackbar, unassignSitelinks, adgroup_id]
  );

  const handleConvertToGlobalClick = useCallback(
    (ids: GridRowId[]) => async () => {
      await convertToGlobal({
        sitelinkIds: ids,
      });
      enqueueSnackbar('Sitelinks successfully converted to Global', {
        variant: 'success',
      });
    },
    [convertToGlobal, enqueueSnackbar]
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      const editedRow = currentSitelinks.find((row) => row.id === id);
      if (editedRow!.isNew) {
        setCurrentSitelinks((oldRows) =>
          oldRows.filter((row) => row.id !== id)
        );
      }
    },
    [setRowModesModel, rowModesModel, currentSitelinks]
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    try {
      if (updatedRow!.isNew) {
        doCreateSitelinks(updatedRow);
      } else {
        await updateSitelink({
          id: updatedRow.id,
          description_one: updatedRow.description_one,
          description_two: updatedRow.description_two,
          header: updatedRow.header,
          url: updatedRow.url,
          approved: updatedRow.approved,
          is_global: updatedRow.is_global,
        }).unwrap();
      }
      setSiteSurfModalOpen(false);
      enqueueSnackbar(
        `Sitelink ${updatedRow!.isNew ? 'created' : 'updated '} successfully`,
        {
          variant: 'success',
        }
      );
    } catch (err) {
      enqueueSnackbar(
        `Failed to ${updatedRow!.isNew ? 'create' : 'update'} Sitelink`,
        {
          variant: 'error',
        }
      );
    }

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const actions = getActions(rowModesModel, {
    handleRegenerateClick,
    handleApproveClick,
    handleUnapproveClick,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    handleDeleteClick,
    handleConvertLocalClick,
  });

  const handleOpenSiteSurfModal = (
    row: GridRowModel,
    apiRef?: React.MutableRefObject<GridApi>
  ) => {
    if (apiRef) {
      setDataGridApiRef(apiRef);
    }
    setSelectedId(row.id as string);
    setSelectedUrl(row.url);
    setSiteSurfModalOpen(true);
  };

  const modifyRowUrl = (id: string, url: string) => {
    let modifiedRow: DataRow | undefined;
    const rows = [];
    for (const sitelink of currentSitelinks) {
      if (sitelink.id === id) {
        modifiedRow = { ...sitelink, url };
        rows.push(modifiedRow);
      } else {
        rows.push(sitelink);
      }
    }
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    if (modifiedRow && isInEditMode && dataGridApiRef) {
      dataGridApiRef.current.setEditCellValue({
        id: modifiedRow.id,
        field: 'url',
        value: modifiedRow.url,
      });
      setSiteSurfModalOpen(false);
    } else if (modifiedRow && !modifiedRow.isNew) {
      processRowUpdate(modifiedRow);
    }
    setCurrentSitelinks(rows);
  };

  const columns: GridColDef[] = [
    {
      field: 'approved',
      headerName: 'Approved',
      renderCell: (params: GridCellParams) => <ApprovedCell {...params} />,
    },
    buildTextWithCharacterCountColumn(
      'header',
      'Header',
      CharacterLimitForContent.SitelinkHeader,
      priceAssetAndSitelinkHeaderValidationSchema,
      true
    ),
    buildTextWithCharacterCountColumn(
      'description_one',
      'Description One',
      CharacterLimitForContent.SitelinkDescription,
      sitelinkDescriptionValidationSchema
    ),
    buildTextWithCharacterCountColumn(
      'description_two',
      'Description Two',
      CharacterLimitForContent.SitelinkDescription,
      sitelinkDescriptionValidationSchema
    ),
    {
      field: 'url',
      headerName: 'URL',
      width: 125,
      editable: true,
      renderCell: (params: GridRenderCellParams) => (
        <UrlCell params={params} handleEditClick={handleOpenSiteSurfModal} />
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        let hasError = false;

        try {
          urlValidationSchema.validateSync(params.props.value);
        } catch (e) {
          hasError = true;
        }

        return { ...params, error: hasError };
      },
      renderEditCell: (params: GridRenderCellParams) => (
        <>
          <UrlCell params={params} handleEditClick={handleOpenSiteSurfModal} />
          <TableCellWithValidation
            {...params}
            validationSchema={urlValidationSchema}
          />
        </>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Action',
      cellClassName: 'actions',
      width: 190,
      getActions: actions,
    },
  ];

  return (
    <>
      <Typography variant="body1" sx={{ display: 'inline' }}>
        <span style={{ fontWeight: 'bold' }}>
          <CircleIcon
            color={getStatusColor(currentSitelinks)}
            fontSize="inherit"
          />{' '}
          Site Links {approvedCount > 0 ? `(${approvedCount}/20)` : ''}
        </span>
      </Typography>
      <IconButton onClick={togglePromptPreview}>
        <RateReviewIcon
          sx={
            !isLoadingRegenerate
              ? {
                  color: '#1976d2',
                }
              : {}
          }
        />
      </IconButton>
      <IconButton
        sx={{ color: 'white' }}
        aria-label="close"
        onClick={() => handleRegenerateAllClick()}
      >
        <RefreshIcon
          sx={{
            color: '#FF0000',
          }}
        />
      </IconButton>
      {isLoading && !currentSitelinks?.length && (
        <>
          <IconButton sx={{ color: 'white' }} aria-label="close">
            <CircularProgress size={16} />
          </IconButton>
        </>
      )}
      <Grid alignItems="center" container>
        <Grid item xs={12} sx={{ minHeight: 100, overflow: 'hidden' }}>
          <DataGridPremium
            density="compact"
            loading={isLoading}
            isCellEditable={(params) => !params.row.is_global}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            columnVisibilityModel={{
              id: false,
            }}
            rows={currentSitelinks}
            columns={columns}
            checkboxSelection={true}
            disableRowSelectionOnClick={true}
            slots={{
              footer: ButtonFooter,
              toolbar: EditToolbar,
            }}
            slotProps={{
              footer: {
                onRegenerate: (ids: GridRowId[]) => {
                  handleRegenerateClick(ids)();
                },
                onApprove: (ids: GridRowId[]) => {
                  handleApproveClick(ids)();
                },
                onUnApprove: (ids: GridRowId[]) => {
                  handleUnapproveClick(ids)();
                },
                onDelete: (ids: GridRowId[]) => {
                  handleDeleteClick(ids)();
                },
                onAddToGlobal: (ids: GridRowId[]) => {
                  handleConvertToGlobalClick(ids)();
                },
                adgroup_id,
              },
              toolbar: {
                setRows: setCurrentSitelinks,
                setRowModesModel,
                approvedCount,
              },
            }}
          />
        </Grid>
      </Grid>
      <DynamicPromptModal
        open={promptModalOpen}
        handleClose={() => setPromptModalOpen((prev) => !prev)}
        adGroupId={adgroup_id}
        promptName={'sitelinks'}
        type="Site Links"
      />
      {siteSurfModalOpen && (
        <SiteSurfModal
          setModalOpen={setSiteSurfModalOpen}
          url={selectedUrl}
          urlType={UrlType.PreCreatedSitelink}
          title="Edit Sitelink URL"
          exportCallback={(value) => {
            modifyRowUrl(selectedId, value);
          }}
        />
      )}
    </>
  );
};

export default SiteLinksSection;
