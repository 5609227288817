import { emptySplitApi } from './baseApi';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { Conversation, PromptMessage } from '../types/conversations';

export const conversationApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchConversation: builder.query<Conversation, { conversationId: string }>({
      query: ({ conversationId }) => ({
        url: `/conversations/${conversationId}`,
        method: 'GET',
      }),
      providesTags: ['Conversation'],
    }),
    fetchConversationsByUser: builder.query<
      Conversation[],
      { adGroupId: string; contentType: string; userId: number }
    >({
      query: ({ adGroupId, contentType, userId }) => ({
        url: `/conversations/by_user`,
        method: 'GET',
        params: {
          ad_group_id: adGroupId,
          content_type: contentType,
          user_id: userId,
        },
      }),
      providesTags: ['Conversations'],
    }),
    createConversation: builder.mutation<
      Conversation,
      {
        adGroupId: string;
        contentType: string;
        messages: PromptMessage[];
        temperature: number;
        preferredAIModel: string;
        name: string;
        createdBy: number;
        createdByName: string;
      }
    >({
      query: ({
        adGroupId,
        contentType,
        messages,
        temperature,
        preferredAIModel,
        name,
        createdBy,
        createdByName,
      }) => ({
        url: `/conversations/`,
        method: 'POST',
        body: {
          ad_group_id: adGroupId,
          content_type: contentType,
          prompt_messages: messages,
          temperature,
          preferred_ai_model: preferredAIModel,
          name,
          created_by: createdBy,
          created_by_name: createdByName,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['Conversation', 'Conversations']),
    }),
    appendMessagesToConversation: builder.mutation<
      Conversation,
      {
        conversationId: string;
        messages: PromptMessage[];
      }
    >({
      query: ({ conversationId, messages }) => ({
        url: `/conversations/${conversationId}/append`,
        method: 'PUT',
        body: {
          prompt_messages: messages,
        },
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['Conversation']),
    }),
    deleteConversationMessage: builder.mutation<
      void,
      { conversationId: string; messageIndex: number }
    >({
      query: ({ conversationId, messageIndex }) => ({
        url: `/conversations/${conversationId}/${messageIndex}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['Conversation']),
    }),
    deleteConversation: builder.mutation<void, { conversationId: string }>({
      query: ({ conversationId }) => ({
        url: `/conversations/${conversationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['Conversation', 'Conversations']),
    }),
    updateConversationMessages: builder.mutation<
      void,
      {
        conversationId: string;
        content: string;
        messageIndex: number;
      }
    >({
      query: ({ conversationId, content, messageIndex }) => ({
        url: `/conversations/${conversationId}/${messageIndex}`,
        method: 'PUT',
        body: {
          content,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['Conversation', 'Conversations']),
    }),
    updateConversationName: builder.mutation<
      void,
      {
        conversationId: string;
        name: string;
      }
    >({
      query: ({ conversationId, name }) => ({
        url: `/conversations/${conversationId}/name`,
        method: 'PATCH',
        body: {
          name,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['Conversation', 'Conversations']),
    }),
    postConversationToAI: builder.mutation<
      string,
      {
        conversationId: string;
      }
    >({
      query: ({ conversationId }) => ({
        url: `/conversations/${conversationId}`,
        method: 'POST',
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['Conversation']),
    }),
  }),
});

export const {
  useUpdateConversationMessagesMutation,
  useFetchConversationQuery,
  usePostConversationToAIMutation,
  useDeleteConversationMessageMutation,
  useCreateConversationMutation,
  useAppendMessagesToConversationMutation,
  useFetchConversationsByUserQuery,
  useUpdateConversationNameMutation,
  useDeleteConversationMutation,
} = conversationApiSlice;
