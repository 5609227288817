import React from 'react';
import { GridRowId } from '@mui/x-data-grid-premium';
import AssignToAssetGroupModal, {
  AssignToSpecificAssetGroupModalProps,
} from '../AssignToAssetGroupModal';
import {
  useFetchAllHeadlineGroupsQuery,
  useAssignHeadlinesToHeadlineGroupsMutation,
  useUnassignHeadlinesFromHeadlineGroupsMutation,
} from '../../slices/headlineGroupSlice';

const AssignToHeadlineGroupModal: React.FC<
  AssignToSpecificAssetGroupModalProps
> = ({ setModalOpen, assetIds, assetType }) => {
  const [assignHeadlines] = useAssignHeadlinesToHeadlineGroupsMutation();
  const [unassignHeadlines] = useUnassignHeadlinesFromHeadlineGroupsMutation();
  const defaultData = { total: 0, results: [] };
  const { data = defaultData, isFetching } = useFetchAllHeadlineGroupsQuery();
  const handleAssign = (assetIds: GridRowId[], assetGroupIds: GridRowId[]) => {
    return assignHeadlines({
      headlineIds: assetIds,
      headlineGroupIds: assetGroupIds,
    }).unwrap();
  };
  const handleUnassign = (
    assetIds: GridRowId[],
    assetGroupIds: GridRowId[]
  ) => {
    return unassignHeadlines({
      headlineIds: assetIds,
      headlineGroupIds: assetGroupIds,
    }).unwrap();
  };
  return (
    <AssignToAssetGroupModal
      setModalOpen={setModalOpen}
      assetIds={assetIds}
      assetType={assetType}
      data={data}
      isFetching={isFetching}
      assignAssets={handleAssign}
      unassignAssets={handleUnassign}
    />
  );
};

export default AssignToHeadlineGroupModal;
