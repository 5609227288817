import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import SearchTermItem from './SearchTermItem';
import SiteSurfModal from '../../SiteSurfModal';
import { SearchTerm } from '../../../types/adGroups';
import { UrlType } from '../../../types/enums';
import {
  useFetchSearchTermsQuery,
  useUpdateSearchTermFinalUrlMutation,
} from '../../../slices/adSlice';

interface SearchTermSectionProps {
  ad_group_id: string;
}

const SearchTermSection: React.FC<SearchTermSectionProps> = ({
  ad_group_id,
}) => {
  const { data: searchTerms = [] as SearchTerm[] } =
    useFetchSearchTermsQuery(ad_group_id);
  const [updateSearchTermFinalUrl] = useUpdateSearchTermFinalUrlMutation();

  const [searchTermSiteSurfModalOpen, setSearchTermSiteSurfModalOpen] =
    useState(false);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedSearchTerm, setSelectedSearchTerm] = useState('');

  const modifySearchTermFinalUrl = async (id: string, final_url: string) => {
    await updateSearchTermFinalUrl({ id, final_url });
    setSearchTermSiteSurfModalOpen(false);
    setSelectedId('');
    setSelectedUrl('');
    setSelectedSearchTerm('');
  };
  return (
    <>
      {searchTerms &&
        searchTerms.map((searchTerm, index) => (
          <SearchTermItem
            key={`${searchTerm.search_term}_${index}`}
            index={index}
            searchTerm={searchTerm}
            setSelectedId={setSelectedId}
            setSelectedUrl={setSelectedUrl}
            setSelectedSearchTerm={setSelectedSearchTerm}
            setSearchTermSiteSurfModalOpen={setSearchTermSiteSurfModalOpen}
          />
        ))}
      {searchTermSiteSurfModalOpen && (
        <SiteSurfModal
          setModalOpen={setSearchTermSiteSurfModalOpen}
          url={selectedUrl}
          urlType={UrlType.SearchTerm}
          title={`Edit Search Term URL: Term "${selectedSearchTerm}"`}
          exportCallback={(value) => {
            modifySearchTermFinalUrl(selectedId, value);
          }}
        />
      )}
    </>
  );
};

export default SearchTermSection;
