import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useUpdateAdGroupStatusMutation } from '../../../slices/adSlice';
import { StatusEnum } from '../../../types/enums';

interface AdGroupStatusSelectProps {
  adgroupId: string;
  status: string;
}

const AdGroupStatusSelect: React.FC<AdGroupStatusSelectProps> = ({
  adgroupId,
  status,
}) => {
  const [updateStatus] = useUpdateAdGroupStatusMutation();

  const handleChange = (event: SelectChangeEvent) => {
    updateStatus({
      adgroup_id: adgroupId,
      status: event.target.value as string,
    });
  };

  return (
    <Box sx={{ minWidth: 120, marginRight: '1rem' }}>
      <FormControl fullWidth>
        <InputLabel id="status-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          value={status}
          label="Status"
          onChange={handleChange}
        >
          <MenuItem value={StatusEnum.Pending}>Pending</MenuItem>
          <MenuItem value={StatusEnum.ReadyToApprove}>
            Ready To Approve
          </MenuItem>
          <MenuItem value={StatusEnum.Approved}>Approved</MenuItem>
          <MenuItem value={StatusEnum.Paused}>Paused</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default AdGroupStatusSelect;
