import React from 'react';
import { GridRowId } from '@mui/x-data-grid-premium';
import AssignToAssetGroupModal, {
  AssignToSpecificAssetGroupModalProps,
} from '../AssignToAssetGroupModal';
import {
  useFetchAllDescriptionGroupsQuery,
  useAssignDescriptionsToDescriptionGroupsMutation,
  useUnassignDescriptionsFromDescriptionGroupsMutation,
} from '../../slices/descriptionGroupSlice';

const AssignToDescriptionGroupModal: React.FC<
  AssignToSpecificAssetGroupModalProps
> = ({ setModalOpen, assetIds, assetType }) => {
  const [assignDescriptions] =
    useAssignDescriptionsToDescriptionGroupsMutation();
  const [unassignDescriptions] =
    useUnassignDescriptionsFromDescriptionGroupsMutation();
  const defaultData = { total: 0, results: [] };
  const { data = defaultData, isFetching } =
    useFetchAllDescriptionGroupsQuery();
  const handleAssign = (assetIds: GridRowId[], assetGroupIds: GridRowId[]) => {
    return assignDescriptions({
      descriptionIds: assetIds,
      descriptionGroupIds: assetGroupIds,
    }).unwrap();
  };
  const handleUnassign = (
    assetIds: GridRowId[],
    assetGroupIds: GridRowId[]
  ) => {
    return unassignDescriptions({
      descriptionIds: assetIds,
      descriptionGroupIds: assetGroupIds,
    }).unwrap();
  };
  return (
    <AssignToAssetGroupModal
      setModalOpen={setModalOpen}
      assetIds={assetIds}
      assetType={assetType}
      data={data}
      isFetching={isFetching}
      assignAssets={handleAssign}
      unassignAssets={handleUnassign}
    />
  );
};

export default AssignToDescriptionGroupModal;
