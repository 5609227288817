import { GridFilterModel } from '@mui/x-data-grid';
import { emptySplitApi } from './baseApi';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { PromptPreviewElements } from '../types/prompts';

export interface Prompt {
  _id: string;
  adgroup_name: string;
  prompt_name: string;
  custom_name: string;
  type: PromptType;
  temperature: string;
  prompt_goal: string;
  prompt_details: string;
  prompt_return: string;
  include_product_data: boolean;
  include_keywords: boolean;
  include_headlines: boolean;
  include_descriptions: boolean;
  include_sitelinks: boolean;
  include_price: boolean;
  include_final_url: boolean;
  include_global_sitelinks: boolean;
  include_global_price_assets: boolean;
  include_category_links: boolean;
  include_ad_group_details: boolean;
  use_prompt_template: boolean;
  use_content_urls: boolean;
  values_multiplier: number;
}

export interface PromptTemplate {
  id: string;
  name: string;
  headlines_prompt_goal: string;
  descriptions_prompt_goal: string;
  site_links_prompt_goal: string;
  headlines_prompt_examples?: string;
  descriptions_prompt_examples?: string;
  site_links_prompt_examples?: string;
}
export interface PromptTemplateData {
  results: PromptTemplate[];
  total: number;
}

export enum PromptType {
  Base = 'base',
  Override = 'override',
  Global = 'global',
}

export interface PromptAssignment {
  adgroup_id: string;
  prompt_name: string;
  prompt_id: string;
}

interface TemplateForPrompt {
  goal_prompt: string;
  example_prompt: string;
}

interface PromptPreviewReturnType {
  system_prompt: string;
  user_prompt: string;
  temperature: number;
}

interface PromptsResponse {
  prompts: Prompt[];
  default_prompt_id: string;
}

export const promptApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchPrompt: builder.query<
      Prompt,
      { promptName: string; adGroupId: string }
    >({
      query: ({ promptName, adGroupId }) => ({
        url: `/ai/prompt`,
        method: 'GET',
        params: {
          prompt_name: promptName,
          adgroup_id: adGroupId,
        },
      }),
      providesTags: ['Prompt'],
    }),
    fetchPrompts: builder.query<
      PromptsResponse,
      { promptName: string; adGroupId?: string }
    >({
      query: ({ promptName, adGroupId }) => ({
        url: `/ai/prompts`,
        method: 'GET',
        params: {
          prompt_name: promptName,
          ad_group_id: adGroupId,
        },
      }),
      providesTags: ['Prompts'],
    }),
    savePrompt: builder.mutation<Prompt, { prompt: Prompt; saveId?: string }>({
      query: (body) => ({
        url: `/ai/prompt`,
        method: 'POST',
        body: {
          prompt: body.prompt,
          save_id: body.saveId,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['Prompt', 'Prompts', 'PromptPreview']),
    }),
    savePromptAssignment: builder.mutation<void, PromptAssignment>({
      query: (data) => ({
        url: `/ai/assigned_prompt`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['PromptAssignment']),
    }),
    fetchPromptAssignment: builder.query<
      PromptAssignment,
      { promptName: string; adGroupId: string }
    >({
      query: ({ promptName, adGroupId }) => ({
        url: `/ai/assigned_prompt`,
        method: 'GET',
        params: {
          prompt_name: promptName,
          adgroup_id: adGroupId,
        },
      }),
      providesTags: ['PromptAssignment'],
    }),
    fetchPromptTemplates: builder.query<
      PromptTemplateData,
      {
        page: number;
        pageSize: number;
        filterModel?: GridFilterModel;
        type: string;
      }
    >({
      query: ({ page, pageSize, filterModel, type }) => {
        const filters: any[] = [];

        if (filterModel) {
          filterModel.items.forEach((item) => {
            filters.push({
              field: item.field,
              operator: item.operator,
              value: item.value,
            });
          });
        }

        return {
          url: `/ai/prompt_templates/${type}`,
          method: 'POST',
          params: {
            page,
            pageSize,
            logic_operator: filterModel?.logicOperator || 'and',
          },
          body: {
            filters,
          },
        };
      },
      providesTags: ['PromptTemplates'],
    }),
    fetchPromptTemplate: builder.query<
      PromptTemplate,
      { templateType: string; templateId: string }
    >({
      query: ({ templateType, templateId }) => ({
        url: `/ai/prompt_templates/${templateType}/${templateId}`,
        method: 'GET',
      }),
      providesTags: ['PromptTemplate'],
    }),
    savePromptTemplate: builder.mutation<
      PromptTemplate,
      { templateType: string; promptTemplate: PromptTemplate }
    >({
      query: ({ templateType, promptTemplate }) => ({
        url: `/ai/prompt_templates/${templateType}/${promptTemplate.id}`,
        method: 'PUT',
        body: promptTemplate,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PromptTemplate',
          'PromptTemplates',
          'PromptTemplateForAdGroup',
        ]),
    }),
    fetchPromptTemplateForAdGroup: builder.query<
      TemplateForPrompt,
      { adGroupId: string; contentType: string }
    >({
      query: ({ adGroupId, contentType }) => ({
        url: `/ai/prompt_template`,
        method: 'GET',
        params: {
          ad_group_id: adGroupId,
          content_type: contentType,
        },
      }),
      providesTags: ['PromptTemplateForAdGroup'],
    }),
    fetchPromptPreview: builder.query<
      PromptPreviewReturnType,
      { adGroupId: string; contentType: string; promptId: string }
    >({
      query: ({ adGroupId, contentType, promptId }) => ({
        url: `/ai/prompt_preview`,
        method: 'GET',
        params: {
          ad_group_id: adGroupId,
          content_type: contentType,
          prompt_id: promptId,
        },
      }),
      providesTags: ['PromptPreview'],
    }),
    fetchPromptPreviewElements: builder.query<
      PromptPreviewElements,
      { adGroupId: string }
    >({
      query: ({ adGroupId }) => ({
        url: `/ai/prompt_preview/${adGroupId}`,
        method: 'GET',
      }),
      providesTags: ['PromptPreviewElements'],
    }),
  }),
});

export const {
  useFetchPromptQuery,
  useFetchPromptsQuery,
  useFetchPromptAssignmentQuery,
  useSavePromptMutation,
  useSavePromptAssignmentMutation,
  useFetchPromptTemplatesQuery,
  useFetchPromptTemplateQuery,
  useFetchPromptTemplateForAdGroupQuery,
  useFetchPromptPreviewQuery,
  useSavePromptTemplateMutation,
  useFetchPromptPreviewElementsQuery,
} = promptApiSlice;
