import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import BidPercentageInput from '../StyledMUIComponents/BidPercentageInput';

interface Props {
  handleClick: (percent: number) => void;
  count: number;
}

const BulkAdjustBidPercentagesInput: React.FC<Props> = ({
  handleClick,
  count,
}) => {
  const [percentage, setPercentage] = useState('-');
  return (
    <Box display="flex" alignItems="center">
      <BidPercentageInput
        value={percentage}
        onChange={(val) => setPercentage(val)}
      />
      <Box ml={1}>
        <Button
          variant="outlined"
          onClick={() => handleClick(parseFloat(percentage))}
        >
          Update {count} Ad Groups
        </Button>
      </Box>
    </Box>
  );
};

export default BulkAdjustBidPercentagesInput;
