import React from 'react';
import { Headline } from '../../types/pinnable';
import GlobalHeadlineSection from '../HeadlineToolComponents/GlobalHeadlineSection';
import { GlobalAssetType } from '../../types/enums';

interface AssignedHeadlineProps {
  headlines: Headline[];
}

const AssignedHeadline: React.FC<AssignedHeadlineProps> = ({ headlines }) => {
  const data = { results: headlines, total: headlines.length };
  return (
    <GlobalHeadlineSection
      data={data}
      assetType={GlobalAssetType.GeneralHeadline}
      isGroupView={true}
    />
  );
};

export default AssignedHeadline;
