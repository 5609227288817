import { useState } from 'react';
import { Grid, Button, Box, Typography, Tooltip } from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridRenderEditCellParams,
  GridRowId,
  GridSlotsComponentsProps,
  GridRowModel,
  GridCellParams,
} from '@mui/x-data-grid-premium';
import React from 'react';
import TableCellWithValidation from '../TableCells/TableCellWithValidation';
import {
  descriptionValidationSchema,
  headlineValidationSchema,
  priceAssetShortDescriptionValidationSchema,
  sitelinkDescriptionValidationSchema,
  priceAssetAndSitelinkHeaderValidationSchema,
  urlValidationSchema,
  displayPathValidationSchema,
} from '../../utils/validation';
import {
  CharacterLimitForContent,
  ContentType,
  PinType,
  MaxAllowableContent,
} from '../../types/enums';
import { useCreateHeadlinesMutation } from '../../slices/headlineSlice';
import { useCreateDescriptionsMutation } from '../../slices/descriptionSlice';
import {
  useAssignSitelinksToAdgroupMutation,
  useCreateSitelinksMutation,
} from '../../slices/sitelinkSlice';
import {
  useCreatePriceAssetsMutation,
  useAssignPriceAssetsToAdgroupMutation,
} from '../../slices/priceAssetSlice';
import { PriceAsset } from '../../types/priceAssets';
import { useUpdateDisplayPathMutation } from '../../slices/adSlice';
import { useUpdateConversationMessagesMutation } from '../../slices/conversationSlice';
import { useFetchAdGroupCountsQuery } from '../../slices/adGroupSlice';
import buildCharacterCountColumn from '../../utils/buildCharacterCountColumn';
import ValidationCell, { RowValidation } from '../TableCells/ValidationCell';
import { useSnackbar } from 'notistack';
import { Sitelink } from '../../types/sitelinks';
import LockIcon from '@mui/icons-material/Lock';
import PinCell from '../TableCells/PinCell';
import PinDropdown from '../TableCells/PinDropdown';
import { Description, Headline } from '../../types/pinnable';
import { formatReadableContentType } from '../../utils/formatReadableContentType';
import ToggleKeywordInsertionCell from '../TableCells/ToggleKeywordInsertionCell';
import { useCreateImageAssetsMutation } from '../../slices/imageAssetSlice';
import { ImageAsset } from '../../types/imageAssets';
import PriceAssetPricesCell from '../TableCells/PriceAssetPrices';
import { formatCurrency } from '../../utils/formatNumbers';

interface ContentDataGridProps {
  contentString: string;
  contentType: ContentType;
  adGroupId: string;
  messageIndex: number;
  conversationId: string;
  triggerScroll: () => void;
}

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    selectedRows: GridRowModel[];
  }
}

const getInitialHeader = (contentString: string) => {
  const parsedContent = JSON.parse(contentString);
  return Object.keys(parsedContent)[0];
};

const getInitialRows = (
  contentString: string,
  adGroupId: string,
  contentType: ContentType
) => {
  const parsedContent = JSON.parse(contentString);
  const header = Object.keys(parsedContent)[0];
  return parsedContent[header].map((desc: any, index: number) => {
    if (typeof desc === 'string') {
      if (
        contentType === ContentType.Headline ||
        contentType === ContentType.PrimaryHeadline
      ) {
        return {
          internalId: index,
          headline: desc,
          adgroup_id: adGroupId,
          approved: true,
          pinned:
            contentType === ContentType.PrimaryHeadline ? 'HEADLINE_1' : '',
        };
      } else {
        return {
          internalId: index,
          description: desc,
          adgroup_id: adGroupId,
          approved: true,
        };
      }
    } else {
      desc.internalId = desc.alpine_id ? desc.alpine_id : index;
      desc.adgroup_id = adGroupId;
      desc.approved = true;
      return desc;
    }
  });
};

const ContentDataGrid: React.FC<ContentDataGridProps> = ({
  contentString,
  contentType,
  adGroupId,
  messageIndex,
  conversationId,
  triggerScroll,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const columnHeader = getInitialHeader(contentString);
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [rows, setRows] = useState<GridRowModel[]>(
    getInitialRows(contentString, adGroupId, contentType)
  );
  const [updateConversationMessages] = useUpdateConversationMessagesMutation();
  const {
    data: adGroupCounts = {
      headlines_count: 0,
      descriptions_count: 0,
      sitelinks_count: 0,
      price_assets_count: 0,
      image_assets_count: 0,
    },
  } = useFetchAdGroupCountsQuery(adGroupId);

  const getMaxAllowableNewContent = () => {
    switch (contentType) {
      case ContentType.PrimaryHeadline:
      case ContentType.Headline:
        return MaxAllowableContent.Headlines - adGroupCounts.headlines_count;
      case ContentType.Description:
        return (
          MaxAllowableContent.Descriptions - adGroupCounts.descriptions_count
        );
      case ContentType.Sitelink:
        return MaxAllowableContent.Sitelinks - adGroupCounts.sitelinks_count;
      case ContentType.PriceAsset:
        return (
          MaxAllowableContent.PriceAssets - adGroupCounts.price_assets_count
        );
      case ContentType.ImageAsset:
        return (
          MaxAllowableContent.ImageAssets - adGroupCounts.image_assets_count
        );
      case ContentType.DisplayPath:
        return 1;
    }
  };

  const handleUpdateAssistantConversationMessage = (
    updatedRows: GridRowModel[]
  ) => {
    const formattedContent = JSON.stringify({
      [columnHeader]: updatedRows.map((row) => {
        const { internalId, adgroup_id, ...rest } = row;
        return rest;
      }),
    });
    setRows(updatedRows);

    updateConversationMessages({
      conversationId,
      messageIndex,
      content: formattedContent,
    });
  };

  function ButtonFooter(
    props: NonNullable<GridSlotsComponentsProps['footer']>
  ) {
    const [createHeadlines] = useCreateHeadlinesMutation();
    const [createDescriptions] = useCreateDescriptionsMutation();
    const [createSitelinks] = useCreateSitelinksMutation();
    const [assignSitelinksToAdgroup] = useAssignSitelinksToAdgroupMutation();
    const [createPriceAssets] = useCreatePriceAssetsMutation();
    const [assignPriceAssetsToAdgroup] =
      useAssignPriceAssetsToAdgroupMutation();
    const [updateDisplayPath] = useUpdateDisplayPathMutation();
    const [createImageAssets] = useCreateImageAssetsMutation();

    const handleRemove = () => {
      const updatedRows = rows.filter(
        (row) => !props.selectedRows?.includes(row as GridRowModel)
      );
      handleUpdateAssistantConversationMessage(updatedRows);
    };

    const handleClick = (is_global = false) => {
      const selectedRows = props.selectedRows as GridRowModel[];
      const contentToCreate = selectedRows.map((row) => ({
        ...row,
        is_global: row.is_global || is_global ? true : false,
      }));
      const ad_group_id = !is_global ? adGroupId : undefined;

      switch (contentType) {
        case ContentType.PrimaryHeadline:
        case ContentType.Headline:
          createHeadlines({
            headlines: contentToCreate as Headline[],
            adgroup_id: ad_group_id,
            approved: true,
          });
          break;
        case ContentType.Description:
          createDescriptions({
            descriptions: contentToCreate as Description[],
            adgroup_id: ad_group_id,
            approved: true,
          });
          break;
        case ContentType.Sitelink:
          let sitelinksToCreate = [];
          let sitelinksToAssign = [];
          for (let sitelink of contentToCreate as Sitelink[]) {
            if (sitelink.is_global) {
              sitelinksToAssign.push(sitelink.id);
            } else {
              sitelinksToCreate.push(sitelink);
            }
          }
          if (sitelinksToCreate.length !== 0) {
            createSitelinks({
              sitelinks: sitelinksToCreate,
              ad_group_id: ad_group_id,
            });
          }
          if (sitelinksToAssign.length !== 0) {
            assignSitelinksToAdgroup({
              sitelinkIds: sitelinksToAssign,
              adGroupIds: [adGroupId],
            });
          }
          break;
        case ContentType.PriceAsset:
          let priceAssetsToCreate = [];
          let priceAssetsToAssign = [];
          for (let priceAsset of contentToCreate as PriceAsset[]) {
            if (ad_group_id && priceAsset.is_global) {
              priceAssetsToAssign.push(priceAsset.id);
            } else {
              priceAssetsToCreate.push(priceAsset);
            }
          }
          if (priceAssetsToCreate.length !== 0) {
            createPriceAssets({
              price_assets: priceAssetsToCreate,
              ad_group_id,
            });
          }
          if (priceAssetsToAssign.length !== 0) {
            assignPriceAssetsToAdgroup({
              priceAssetIds: priceAssetsToAssign,
              adGroupIds: [adGroupId],
            });
          }
          break;
        case ContentType.ImageAsset:
          createImageAssets({
            image_assets: contentToCreate as ImageAsset[],
            ad_group_id,
            approved: true,
          });
          break;
        case ContentType.DisplayPath:
          if (selectedRows?.length === 1) {
            updateDisplayPath({
              id: selectedRows[0].adgroup_id,
              first_part: selectedRows[0].first_part,
              second_part: selectedRows[0].second_part,
            });
          }
          break;

        default:
          break;
      }

      setSelectedIds([]);
    };

    return props.selectedRows?.length !== 0 ? (
      <Box display="flex">
        <Button onClick={() => handleClick(false)} sx={{ flexGrow: 1 }}>
          {`Add ${props.selectedRows?.length}
          ${formatReadableContentType(contentType, props.selectedRows?.length)}
          to Ad Group`}
        </Button>
        {(contentType === ContentType.Headline ||
          contentType === ContentType.PrimaryHeadline ||
          contentType === ContentType.Description ||
          contentType === ContentType.PriceAsset) && (
          <Button onClick={() => handleClick(true)} sx={{ flexGrow: 1 }}>
            Create {props.selectedRows?.length} Global{' '}
            {formatReadableContentType(contentType, props.selectedRows?.length)}
          </Button>
        )}
        <Button onClick={handleRemove} sx={{ flexGrow: 1 }}>
          Remove from prompt
        </Button>
      </Box>
    ) : (
      <> </>
    );
  }

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    const toggledRow =
      contentType === ContentType.Sitelink ||
      contentType === ContentType.PriceAsset
        ? { ...newRow, is_global: false }
        : newRow;
    const updatedRows = rows.map((row) =>
      row.internalId === newRow.internalId ? toggledRow : row
    );
    handleUpdateAssistantConversationMessage(updatedRows);
    return toggledRow;
  };

  const getValidationSchemeForContentType = () => {
    switch (contentType) {
      case ContentType.PrimaryHeadline:
      case ContentType.Headline:
        return headlineValidationSchema;
      case ContentType.Description:
        return descriptionValidationSchema;
      case ContentType.Sitelink:
        return {
          header: priceAssetAndSitelinkHeaderValidationSchema,
          description_one: sitelinkDescriptionValidationSchema,
          description_two: sitelinkDescriptionValidationSchema,
          url: urlValidationSchema,
        };
      case ContentType.PriceAsset:
        return {
          header: priceAssetAndSitelinkHeaderValidationSchema,
          short_description: priceAssetShortDescriptionValidationSchema,
        };
      case ContentType.ImageAsset:
        return {};
      default:
        return;
    }
  };

  const buildColumn = (
    field: string,
    headerName: string,
    validationSchema?: any,
    limit?: number,
    renderCell?: any
  ): GridColDef => {
    return {
      field,
      headerName,
      flex: 1,
      minWidth: 200,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <TableCellWithValidation
          {...params}
          limit={limit}
          validationSchema={validationSchema}
        />
      ),
      renderCell,
    };
  };

  const buildImageColumn = (field: string, headerName: string): GridColDef => {
    return {
      field,
      headerName,
      flex: 1,
      minWidth: 200,
      maxWidth: 250,
      editable: false,
      renderCell: (params: GridCellParams) => (
        <img
          src={params.value as string}
          alt="thumbnail"
          style={{ width: 150, height: 150 }}
        />
      ),
    };
  };

  const buildRowValidationColumn = (
    headerName: string,
    rowValidation: RowValidation[]
  ): GridColDef => {
    return {
      field: `validation`,
      headerName,
      width: 125,
      renderCell: (params: GridRenderEditCellParams) => (
        <ValidationCell params={params} rowValidation={rowValidation} />
      ),
    };
  };

  const buildPinnedColumn = (pinType: PinType): GridColDef => {
    return {
      field: 'pinned',
      headerName: 'Pinned',
      width: 150,
      editable: true,
      renderCell: (params: GridCellParams) => <PinCell {...params} />,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <PinDropdown {...params} selectedPins={[]} pinType={pinType} />
      ),
    };
  };

  const defaultColumns: GridColDef[] = [
    {
      field: 'item',
      headerName: columnHeader,
      flex: 1,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <TableCellWithValidation
          {...params}
          limit={30}
          validationSchema={getValidationSchemeForContentType()}
        />
      ),
    },
  ];

  const headlineColumns: GridColDef[] = [
    buildColumn(
      'headline',
      'Headlines',
      headlineValidationSchema,
      CharacterLimitForContent.Headline
    ),
    buildPinnedColumn(PinType.HEADLINE),
    buildCharacterCountColumn(
      'headline',
      'Characters',
      CharacterLimitForContent.Headline
    ),
    buildRowValidationColumn('Validation', [
      {
        headline: headlineValidationSchema,
      },
    ]),
  ];

  const headlinePrimaryColumns: GridColDef[] = [
    buildColumn(
      'headline',
      'Headlines',
      headlineValidationSchema,
      CharacterLimitForContent.Headline
    ),
    {
      field: 'include_keyword_insertion',
      headerName: 'Include Keyword Insertion',
      width: 150,
      renderCell: (params: GridCellParams) => (
        <ToggleKeywordInsertionCell
          {...params}
          processRowUpdate={handleProcessRowUpdate}
        />
      ),
    },
    buildCharacterCountColumn(
      'headline',
      'Characters',
      CharacterLimitForContent.Headline
    ),
    buildRowValidationColumn('Validation', [
      {
        headline: headlineValidationSchema,
      },
    ]),
  ];

  const descriptionColumns: GridColDef[] = [
    buildColumn(
      'description',
      'Descriptions',
      descriptionValidationSchema,
      CharacterLimitForContent.Description
    ),
    buildPinnedColumn(PinType.DESCRIPTION),
    buildCharacterCountColumn(
      'description',
      'Characters',
      CharacterLimitForContent.Description
    ),
    buildRowValidationColumn('Validation', [
      {
        description: descriptionValidationSchema,
      },
    ]),
  ];

  const displayPathColumns: GridColDef[] = [
    buildColumn(
      'first_part',
      'First Part',
      displayPathValidationSchema,
      CharacterLimitForContent.DisplayPathPart
    ),
    buildCharacterCountColumn(
      'first_part',
      'Characters',
      CharacterLimitForContent.DisplayPathPart
    ),
    buildColumn(
      'second_part',
      'Second Part',
      displayPathValidationSchema,
      CharacterLimitForContent.DisplayPathPart
    ),
    buildCharacterCountColumn(
      'second_part',
      'Characters',
      CharacterLimitForContent.DisplayPathPart
    ),
    buildRowValidationColumn('Validation', [
      {
        first_part: displayPathValidationSchema,
        second_part: displayPathValidationSchema,
      },
    ]),
  ];

  const sitelinkColumns: GridColDef[] = [
    buildColumn(
      'header',
      'Header',
      priceAssetAndSitelinkHeaderValidationSchema,
      CharacterLimitForContent.SitelinkHeader,
      (params: GridCellParams) => (
        <Box position="relative">
          {params.row.is_global && (
            <Tooltip
              title="This references a global asset. If you edit it, you will convert it to a local asset."
              arrow
            >
              <span
                style={{
                  position: 'absolute',
                  color: 'rgba(0, 0, 0, 0.87)',
                  left: '-11px',
                  top: '-3px',
                  fontSize: '12px',
                  height: '13px',
                  width: '13px',
                }}
              >
                <LockIcon sx={{ fontSize: '13px' }} />
              </span>
            </Tooltip>
          )}
          {params.value}
        </Box>
      )
    ),
    buildCharacterCountColumn(
      'header',
      'Characters',
      CharacterLimitForContent.SitelinkHeader
    ),
    buildColumn(
      'description_one',
      'Description One',
      sitelinkDescriptionValidationSchema,
      CharacterLimitForContent.SitelinkDescription
    ),
    buildCharacterCountColumn(
      'description_one',
      'Characters',
      CharacterLimitForContent.SitelinkDescription
    ),
    buildColumn(
      'description_two',
      'Description Two',
      sitelinkDescriptionValidationSchema,
      CharacterLimitForContent.SitelinkDescription
    ),
    buildCharacterCountColumn(
      'description_two',
      'Characters',
      CharacterLimitForContent.SitelinkDescription
    ),
    buildColumn('url', 'URL', urlValidationSchema),
    buildRowValidationColumn('Validation', [
      {
        header: priceAssetAndSitelinkHeaderValidationSchema,
        description_one: sitelinkDescriptionValidationSchema,
        description_two: sitelinkDescriptionValidationSchema,
        url: urlValidationSchema,
      },
    ]),
  ];

  const priceAssetColumns: GridColDef[] = [
    buildColumn(
      'header',
      'Header',
      priceAssetAndSitelinkHeaderValidationSchema,
      CharacterLimitForContent.PriceAssetHeader,
      (params: GridCellParams) => (
        <Box position="relative">
          {params.row.is_global && (
            <Tooltip
              title="This references a global asset. If you edit it, you will convert it to a local asset."
              arrow
            >
              <span
                style={{
                  position: 'absolute',
                  color: 'rgba(0, 0, 0, 0.87)',
                  left: '-11px',
                  top: '-3px',
                  fontSize: '12px',
                  height: '13px',
                  width: '13px',
                }}
              >
                <LockIcon sx={{ fontSize: '13px' }} />
              </span>
            </Tooltip>
          )}
          {params.value}
        </Box>
      )
    ),
    buildColumn(
      'short_description',
      'Short Description',
      priceAssetShortDescriptionValidationSchema,
      CharacterLimitForContent.PriceAssetDescription
    ),
    {
      field: 'price',
      headerName: 'Override Price',
      minWidth: 175,
      editable: true,
      type: 'number',
      renderCell: (params: GridCellParams) => {
        if (params.row.price === 0) {
          return `(none)`;
        }
        return formatCurrency(params.row.price);
      },
    },
    {
      field: 'max_products',
      headerName: 'Max Products',
      editable: true,
      type: 'number',
      flex: 0.5,
    },
    {
      field: 'all_prices',
      headerName: 'From / Up To / Avg  ',
      minWidth: 250,
      flex: 1,
      editable: false,
      renderCell: (params: GridCellParams) => (
        <PriceAssetPricesCell {...params} />
      ),
    },
    buildColumn('url', 'URL', urlValidationSchema),
    buildRowValidationColumn('Validation', [
      {
        header: priceAssetAndSitelinkHeaderValidationSchema,
        short_description: priceAssetShortDescriptionValidationSchema,
        url: urlValidationSchema,
      },
    ]),
  ];

  const imageAssetColumns: GridColDef[] = [
    buildColumn('alpine_id', 'Alpine Id'),
    buildImageColumn('thumbnail_url', 'Thumbnail'),
    buildColumn('justification', 'Justification'),
  ];

  const getColumnsForContentType = (): GridColDef[] => {
    switch (contentType) {
      case ContentType.PrimaryHeadline:
        return headlinePrimaryColumns;
      case ContentType.Headline:
        return headlineColumns;
      case ContentType.Description:
        return descriptionColumns;
      case ContentType.Sitelink:
        return sitelinkColumns;
      case ContentType.PriceAsset:
        return priceAssetColumns;
      case ContentType.ImageAsset:
        return imageAssetColumns;
      case ContentType.DisplayPath:
        return displayPathColumns;
      default:
        return defaultColumns;
    }
  };

  function getRowId(row: GridRowModel) {
    return row.internalId;
  }

  const handleSelectIds = (selections: GridRowId[]) => {
    if (selections.length > getMaxAllowableNewContent()) {
      enqueueSnackbar(
        `You may reached the maximum allowable ${contentType.replace('_', ' ')} for this ad group.`,
        { variant: 'error' }
      );
      return;
    }
    if (contentType === ContentType.DisplayPath) {
      setSelectedIds(selections.slice(-1));
    } else {
      setSelectedIds(selections);
    }
  };

  return (
    <Grid alignItems="center" container>
      <Grid item xs={12} sx={{ minHeight: 100, overflow: 'hidden' }}>
        <DataGridPremium
          density="compact"
          getRowHeight={() => 'auto'}
          getRowId={getRowId}
          columnVisibilityModel={{
            id: false,
          }}
          processRowUpdate={handleProcessRowUpdate}
          onRowSelectionModelChange={(selections) =>
            handleSelectIds(selections)
          }
          rowSelectionModel={selectedIds}
          rows={rows}
          columns={getColumnsForContentType()}
          checkboxSelection={true}
          disableRowSelectionOnClick={true}
          slots={{
            footer: ButtonFooter,
          }}
          onResize={triggerScroll}
          slotProps={{
            footer: {
              selectedRows: selectedIds.map((selectedId) => {
                return rows.find(
                  (row) => row.internalId === selectedId
                ) as GridRowModel;
              }),
            },
          }}
        />
        <Typography variant="caption" sx={{ color: 'red', fontWeight: 'bold' }}>
          You may add up to {getMaxAllowableNewContent()} items to the ad group.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ContentDataGrid;
