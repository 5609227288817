import React from 'react';
import { Image as ImageIcon } from '@mui/icons-material';

const ImageUrlCell = (params: any) => (
  <React.Fragment>
    {params.value ? (
      <a href={params.value} target="_blank" rel="noreferrer">
        <ImageIcon color="success" />
      </a>
    ) : (
      <ImageIcon color="error" />
    )}
  </React.Fragment>
);

export default ImageUrlCell;
