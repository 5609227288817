import React, { useState } from 'react';

import { Typography, Grid, IconButton, Checkbox } from '@mui/material';

import {
  useApproveImageAssetsMutation,
  useUnapproveImageAssetsMutation,
  useFetchImageAssetsQuery,
  useDeleteImageAssetsMutation,
  useRegenerateImageAssetsMutation,
  useRegenerateAllImageAssetsMutation,
} from '../../../../slices/imageAssetSlice';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import {
  Circle as CircleIcon,
  Pending as PendingIcon,
  Recommend as RecommendIcon,
  RateReview as RateReviewIcon,
} from '@mui/icons-material';

import { getStatusColor } from '../../../../helpers/getStatusColor';

import ActionButtons from '../ActionButtons';
import CircularProgress from '@mui/material/CircularProgress';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { getApprovedCount } from '../../../../utils/getApprovedCount';
import { Approvable } from '../../../../types/adGroups';
const ImageAssetsSection: React.FC<{
  adgroup_id: string;
  togglePromptPreview: () => void;
}> = ({ adgroup_id, togglePromptPreview }) => {
  const { data: imageAssets } = useFetchImageAssetsQuery(adgroup_id);

  const [regenerateImageAssets, { isLoading: isLoadingRegenerate }] =
    useRegenerateImageAssetsMutation();
  const [regenerateAllImageAssets, { isLoading: isLoadingRegenerateAll }] =
    useRegenerateAllImageAssetsMutation({
      fixedCacheKey: 'regenerateImageAssets',
    });

  const isLoading = isLoadingRegenerate || isLoadingRegenerateAll;

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [deleteImageAssets] = useDeleteImageAssetsMutation();
  const [unapproveImageAssets] = useUnapproveImageAssetsMutation();
  const [approveImageAssets] = useApproveImageAssetsMutation();
  const approvedCount = getApprovedCount(imageAssets as Approvable[]);

  const handleRegenerateAllClick = () => {
    if (isLoading) {
      return;
    }
    regenerateAllImageAssets({
      ad_group_id: adgroup_id,
    });
  };

  const handleRegenerateClick = (ids: string[]) => {
    regenerateImageAssets({ ids, ad_group_id: adgroup_id });
  };

  const handleToggleSelectedId = (id: string) => {
    if (isLoading) {
      return;
    }
    if (!selectedIds.includes(id)) {
      setSelectedIds((prev) => [...prev, id]);
    } else {
      setSelectedIds((prev) => prev.filter((item) => item !== id));
    }
  };

  const handleDelete = (adgroup_id: string, ids: string[]) => {
    deleteImageAssets({ adgroup_id, ids });
    setSelectedIds([]);
  };

  const handleApprove = (ids: string[]) => {
    approveImageAssets({ ids, ad_group_id: adgroup_id });
    setSelectedIds([]);
  };

  const handleUnApprove = (ids: string[]) => {
    unapproveImageAssets({ ids, ad_group_id: adgroup_id });
    setSelectedIds([]);
  };

  const hasImageAssets = !!imageAssets && imageAssets.length > 0;

  const imageBaseStyle = {
    flexGrow: 'inherit',
    width: 250,
    height: 250,
  };

  const approvedPendingIconStyle = {
    position: 'relative',
    top: 5,
    left: -5,
  };

  return (
    <>
      <Typography variant="body1" sx={{ display: 'inline' }}>
        <span style={{ fontWeight: 'bold' }}>
          <CircleIcon color={getStatusColor(imageAssets)} fontSize="inherit" />{' '}
          Image Assets {hasImageAssets ? `(${approvedCount}/8)` : ''}
        </span>
      </Typography>
      <IconButton
        sx={{ color: 'white' }}
        aria-label="close"
        onClick={() => handleRegenerateAllClick()}
      >
        <RefreshIcon
          sx={{
            color: '#FF0000',
          }}
        />
      </IconButton>
      <IconButton onClick={togglePromptPreview}>
        <RateReviewIcon
          sx={
            !isLoading
              ? {
                  color: '#1976d2',
                }
              : {}
          }
        />
      </IconButton>
      {isLoading && (
        <>
          <IconButton sx={{ color: 'white' }} aria-label="close">
            <CircularProgress size={16} />
          </IconButton>
        </>
      )}
      <Grid alignItems="center" container sx={{ overflow: 'hidden' }}>
        <Grid item xs={12} sx={{ height: '400px', overflow: 'hidden' }}>
          {!!imageAssets?.length ? (
            <ImageList sx={{ width: '100%', height: '100%' }} cols={4} gap={8}>
              {imageAssets.map((item) => (
                <ImageListItem key={item.id}>
                  <img
                    src={item.thumbnail_url}
                    alt={item.alpine_id}
                    loading="lazy"
                    onClick={() => handleToggleSelectedId(item.id)}
                    style={
                      selectedIds.includes(item.id)
                        ? {
                            ...imageBaseStyle,
                            opacity: 0.7,
                            borderWidth: 1,
                            borderColor: '#e7e7e7',
                            borderStyle: 'solid',
                          }
                        : { ...imageBaseStyle }
                    }
                  />
                  <ImageListItemBar
                    title={`alpine_id: ${item.alpine_id}` || ''}
                    position="below"
                    onClick={() => handleToggleSelectedId(item.id)}
                    actionIcon={
                      <>
                        <IconButton sx={{ color: 'white' }}>
                          <Checkbox
                            checked={selectedIds.includes(item.id)}
                            disabled={isLoading}
                          />
                        </IconButton>
                        {item.approved ? (
                          <RecommendIcon
                            color="success"
                            sx={approvedPendingIconStyle}
                          />
                        ) : (
                          <PendingIcon
                            color="warning"
                            sx={approvedPendingIconStyle}
                          />
                        )}
                      </>
                    }
                    actionPosition="left"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%' }}
            >
              <Grid item>
                <Typography variant="body1">No Image Assets</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        {selectedIds.length > 0 && (
          <Grid item xs={12} sx={{ height: '70px' }}>
            <ActionButtons
              onDelete={() => handleDelete(adgroup_id, selectedIds)}
              onApprove={() => handleApprove(selectedIds)}
              onUnApprove={() => handleUnApprove(selectedIds)}
              onRegenerate={() => handleRegenerateClick(selectedIds)}
              numberSelected={selectedIds.length}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ImageAssetsSection;
