import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ListItemText,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

interface GoogleActiveResultsDialogProps {
  open: boolean;
  handleClose: () => void;
  results: string[];
  success: string[];
  failed: string[];
}

const GoogleActiveResultsDialog: React.FC<GoogleActiveResultsDialogProps> = ({
  open,
  handleClose,
  results,
  success,
  failed,
}) => {
  let status = '';
  let color = '';
  let titleIcon = <ErrorIcon />;
  if (success.length > 0 && failed.length > 0) {
    status = 'Partial Success';
    color = '#ffbb33';
    titleIcon = <WarningIcon />;
  } else if (success.length > 0) {
    status = 'Success';
    color = '#00C851';
    titleIcon = <CheckCircleIcon />;
  } else {
    status = 'Failed';
    color = '#ff4444';
  }

  const renderResults = () =>
    results.map((result) => {
      return (
        <li>
          <ListItemText primary={result} />
        </li>
      );
    });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ color, alignItems: 'center', display: 'flex' }}>
        {titleIcon}
        {status}
      </DialogTitle>
      <DialogContent>
        <ul style={{ paddingInlineStart: '20px' }}>{renderResults()}</ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GoogleActiveResultsDialog;
