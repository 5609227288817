import { Pinnable } from '../types/pinnable';

const getOrderedRows = (rows: Pinnable[]) => {
  const hasPinned = [];
  const noPinned = [];
  for (let row of rows) {
    row.pinned ? hasPinned.push(row) : noPinned.push(row);
  }
  hasPinned.sort((a, b) => (a.pinned > b.pinned ? 1 : -1));
  return hasPinned.concat(noPinned);
};
export default getOrderedRows;
