import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Tooltip } from '@mui/material';

interface CharacterCountCellProps {
  params: GridRenderCellParams;
  maxCharacters?: number;
}

const TextWithCharacterCountCell: React.FC<CharacterCountCellProps> = ({
  params,
  maxCharacters,
}) => {
  const keywordInsertionRegex = /{keyword:|}/gi;
  const valueWithKeywordInsertionRemoved = params.value?.replace(
    keywordInsertionRegex,
    ''
  );
  const valueLength = valueWithKeywordInsertionRemoved?.length;
  const isOverMaxCharacters = maxCharacters && valueLength > maxCharacters;
  return (
    <Tooltip
      arrow
      title={`${params.value} (${valueLength}/${maxCharacters})`}
      componentsProps={
        isOverMaxCharacters
          ? {
              tooltip: {
                sx: {
                  bgcolor: 'red',
                  '& .MuiTooltip-arrow': {
                    color: 'red',
                  },
                },
              },
            }
          : undefined
      }
    >
      <>{params.value}</>
    </Tooltip>
  );
};

export default TextWithCharacterCountCell;
