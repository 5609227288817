import { emptySplitApi } from './baseApi';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { PriceAssetGroup } from '../types/groups';
import { PriceAsset } from '../types/priceAssets';
import { GridRowId } from '@mui/x-data-grid-premium';

export const PriceAssetGroupApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllPriceAssetGroups: builder.query<
      {
        results: PriceAssetGroup[];
        total: number;
      },
      void
    >({
      query: () => {
        return {
          url: '/price_asset_group/',
          method: 'GET',
        };
      },
      providesTags: ['PriceAssetGroups'],
    }),
    fetchPriceAssetGroup: builder.query<
      {
        price_asset_group: PriceAssetGroup;
        members: PriceAsset[];
      },
      { priceAssetGroupId: string }
    >({
      query: ({ priceAssetGroupId }) => {
        return {
          url: `/price_asset_group/${priceAssetGroupId}`,
          method: 'GET',
        };
      },
      providesTags: ['PriceAssetGroup'],
    }),
    assignPriceAssetsToPriceAssetGroups: builder.mutation<
      null | string[],
      { priceAssetIds: GridRowId[]; priceAssetGroupIds: GridRowId[] }
    >({
      query: ({ priceAssetIds, priceAssetGroupIds }) => ({
        url: `/price_asset_group/assign`,
        method: 'POST',
        body: {
          price_asset_ids: priceAssetIds,
          price_asset_group_ids: priceAssetGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssetGroups',
          'PriceAssetGroup',
          'PriceAssets',
          'GlobalPriceAssets',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unassignPriceAssetsFromPriceAssetGroups: builder.mutation<
      void,
      { priceAssetIds: GridRowId[]; priceAssetGroupIds: GridRowId[] }
    >({
      query: ({ priceAssetIds, priceAssetGroupIds }) => ({
        url: `/price_asset_group/unassign`,
        method: 'POST',
        body: {
          price_asset_ids: priceAssetIds,
          price_asset_group_ids: priceAssetGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssetGroups',
          'PriceAssetGroup',
          'PriceAssets',
          'PromptPreviewElements',
          'GlobalPriceAssets',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    assignPriceAssetGroupsToAdGroups: builder.mutation<
      void,
      { priceAssetGroupIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ priceAssetGroupIds, adGroupIds }) => ({
        url: `/price_asset_group/ad_group/assign`,
        method: 'POST',
        body: {
          price_asset_group_ids: priceAssetGroupIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssetGroups',
          'PriceAssetGroup',
          'PriceAssets',
          'GlobalPriceAssets',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unassignPriceAssetGroupsFromAdGroups: builder.mutation<
      void,
      { priceAssetGroupIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ priceAssetGroupIds, adGroupIds }) => ({
        url: `/price_asset_group/ad_group/unassign`,
        method: 'POST',
        body: {
          price_asset_group_ids: priceAssetGroupIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssetGroups',
          'PriceAssetGroup',
          'PriceAssets',
          'PromptPreviewElements',
          'GlobalPriceAssets',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    createPriceAssetGroup: builder.mutation<
      PriceAssetGroup,
      {
        group_name: string;
      }
    >({
      query: (body) => ({
        url: `/price_asset_group/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['PriceAssetGroups']),
    }),
    updatePriceAssetGroup: builder.mutation<
      PriceAssetGroup,
      {
        id: string;
        group_name: string;
      }
    >({
      query: ({ id, group_name }) => ({
        url: `/price_asset_group/${id}`,
        method: 'PUT',
        body: { group_name },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssetGroup',
          'PriceAssetGroups',
          'AdGroups',
          'PriceAsset',
          'PriceAssets',
          'PromptPreviewElements',
          'GlobalPriceAssets',
          'GlobalPrimaryPriceAssets',
          'GlobalGeneralPriceAssets',
        ]),
    }),
    deletePriceAssetGroups: builder.mutation<void, { ids: GridRowId[] }>({
      query: (body) => ({
        url: `/price_asset_group/`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssetGroups',
          'PriceAssets',
          'GlobalPriceAssets',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
  }),
});

export const {
  useFetchPriceAssetGroupQuery,
  useFetchAllPriceAssetGroupsQuery,
  useCreatePriceAssetGroupMutation,
  useUpdatePriceAssetGroupMutation,
  useAssignPriceAssetsToPriceAssetGroupsMutation,
  useUnassignPriceAssetsFromPriceAssetGroupsMutation,
  useAssignPriceAssetGroupsToAdGroupsMutation,
  useUnassignPriceAssetGroupsFromAdGroupsMutation,
  useDeletePriceAssetGroupsMutation,
} = PriceAssetGroupApiSlice;
