import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { getByDayDateRange, getDateRange } from '../../utils/getDateRange';
import { PremadeDateRange } from '../../types/premadeDateRange';
import dayjs, { Dayjs } from 'dayjs';
import { Popover } from '@mui/material';

interface DateRangeFilterProps {
  dateRange: DateRange<Dayjs>;
  onChange: (dateRange: DateRange<Dayjs>) => void;
  initialDateRange?: DateRange<Dayjs>;
  daysInInitialRange?: number;
  disabled?: boolean;
  calendarOpen: boolean;
  setCalendarOpen: (open: boolean) => void;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  dateRange,
  onChange,
  initialDateRange,
  daysInInitialRange = 0,
  disabled = false,
  calendarOpen = false,
  setCalendarOpen,
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  const isEqualDateRange = (
    range1: DateRange<Dayjs> | null,
    range2: DateRange<Dayjs> | null
  ): boolean | undefined => {
    if (!range1 || !range2) {
      return false;
    }
    return (
      range1[0]?.isSame(range2[0], 'day') && range1[1]?.isSame(range2[1], 'day')
    );
  };

  const byDayPreSets = [
    PremadeDateRange.SamePeriodLastYear,
    PremadeDateRange.PreviousPeriod,
  ];

  const filteredPreSets = Object.values(PremadeDateRange).filter((rangeType) =>
    initialDateRange ? true : !byDayPreSets.includes(rangeType)
  );

  return (
    <Box display="flex" width="300px">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box position="relative">
          <TextField
            size="small"
            label="Start Date"
            value={dateRange[0]?.format('YYYY-MM-DD')}
            onChange={() => {}}
            onClick={() => setCalendarOpen(!calendarOpen)}
            focused
            autoComplete="off"
            ref={anchorRef}
            sx={{ minWidth: '118px' }}
            disabled={disabled}
          />
          <Popover
            open={calendarOpen}
            onClose={() => setCalendarOpen(false)}
            anchorEl={anchorRef.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Paper>
              <Box display="flex" alignItems="center">
                <IconButton onClick={() => setCalendarOpen(false)}>
                  <CloseIcon />
                </IconButton>
                <div style={{ width: 550 }}>
                  {filteredPreSets.map((rangeType) => {
                    const chipRange = byDayPreSets.includes(rangeType)
                      ? getByDayDateRange(
                          rangeType,
                          initialDateRange?.[0] || dayjs(),
                          daysInInitialRange
                        )
                      : getDateRange(rangeType);
                    if (!chipRange) return null;
                    return (
                      <Chip
                        key={rangeType}
                        size="small"
                        label={rangeType}
                        onClick={() => onChange(chipRange)}
                        color={
                          isEqualDateRange(chipRange, dateRange)
                            ? 'primary'
                            : 'default'
                        }
                        sx={{ margin: '2px' }}
                      />
                    );
                  })}
                </div>
              </Box>
              <DateRangeCalendar value={dateRange} onChange={onChange} />
            </Paper>
          </Popover>
        </Box>
        <Box>
          <TextField
            size="small"
            label="End Date"
            value={dateRange[1]?.format('YYYY-MM-DD')}
            onChange={() => {}}
            onClick={() => setCalendarOpen(!calendarOpen)}
            focused
            autoComplete="off"
            sx={{ minWidth: '118px' }}
            disabled={disabled}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

export default DateRangeFilter;
