import React from 'react';
import { Grid, TextareaAutosize, Typography } from '@mui/material';

interface ModalInputBoxProps {
  title: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
const ModalInputBox: React.FC<ModalInputBoxProps> = ({
  title,
  value,
  onChange,
}) => {
  return (
    <Grid item xs={12}>
      <Typography variant="body1">
        <span style={{ fontWeight: 'bold' }}>{title}:</span>
      </Typography>
      <TextareaAutosize
        minRows={10}
        maxRows={40}
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
      />
    </Grid>
  );
};

export default ModalInputBox;
