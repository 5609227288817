import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license-pro';
import { store } from './app/store';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Root from './routes/root';
import { TitleProvider } from './contexts/TitleContext';
import { createTheme } from './theme';
import AdGroupDetails from './routes/AdGroups';
import { AuthContext, AuthProvider } from './contexts/Auth';
import { SnackbarProvider } from 'notistack';
import WordGroupPrompts from './routes/WordGroupPrompts';
import WordGroupCategoryPrompts from './routes/WordGroupCategoryPrompts';
import CategoryComboPrompts from './routes/CategoryComboPrompts';
import SitelinkTool from './routes/SitelinkTool';
import PriceAssetTool from './routes/PriceAssetTool';
import HeadlineTool from './routes/HeadlineTool';
import DescriptionTool from './routes/DescriptionTool';
import BidManagementTool from './routes/BidManagementTool';
import Container from '@mui/material/Container';
import { Provider as RollBarProvider, ErrorBoundary } from '@rollbar/react';
import './main.css';
import GlobalSettings from './routes/GlobalSettings';

const rollbarConfig = {
  accessToken: 'bf578f2c6e3a4bc58de9d8dafd113d7f',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.REACT_APP_ROLL_BAR_ENV,
  enabled: process.env.REACT_APP_ROLL_BAR_ENV === 'production',
};

const defaultTheme = createTheme();

if (process.env.REACT_APP_MATERIAL_UI_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIAL_UI_KEY);
}

const PrivateRoutes = () => {
  const authContext = React.useContext(AuthContext);

  if (!authContext) {
    return <div>Loading...</div>;
  }

  const { isAuthenticated, isLoading } = authContext;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    const redirectUrl = window.location.href;
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/partnernet/security/logon.cfm?redirectUrl=${redirectUrl}`;
    return null;
  }

  return (
    <Container maxWidth={false} sx={{ mt: 6, mb: 4 }}>
      <Outlet />
    </Container>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: <PrivateRoutes />,
        children: [
          {
            path: '/',
            element: <AdGroupDetails />,
          },
          {
            path: '/bid-management-tool/',
            element: <BidManagementTool />,
          },
          {
            path: '/global-settings/',
            element: <GlobalSettings />,
          },
          {
            path: '/word-group-prompts/',
            element: <WordGroupPrompts />,
          },
          {
            path: '/word-group-category-prompts/',
            element: <WordGroupCategoryPrompts />,
          },
          {
            path: '/category-combo-prompts/',
            element: <CategoryComboPrompts />,
          },
          {
            path: '/headline-tool/',
            element: <HeadlineTool />,
          },
          {
            path: '/description-tool/',
            element: <DescriptionTool />,
          },
          {
            path: '/sitelink-tool/',
            element: <SitelinkTool />,
          },
          {
            path: '/price-asset-tool/',
            element: <PriceAssetTool />,
          },
        ],
      },
    ],
  },
]);

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <RollBarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Provider store={store}>
          <AuthProvider>
            <TitleProvider>
              <ThemeProvider theme={defaultTheme}>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <CssBaseline />
                  <RouterProvider router={router} />
                </SnackbarProvider>
              </ThemeProvider>
            </TitleProvider>
          </AuthProvider>
        </Provider>
      </ErrorBoundary>
    </RollBarProvider>
  </React.StrictMode>
);
