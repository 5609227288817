import React, { useEffect, useRef } from 'react';

import { Box, Button, LinearProgress } from '@mui/material';
import { Refresh as RefreshIcon, Stop as StopIcon } from '@mui/icons-material';
import { useRegenerateDisplayPathMutation } from '../../../slices/adSlice';
import { useRegenerateAllHeadlinesMutation } from '../../../slices/headlineSlice';
import { useRegenerateAllDescriptionsMutation } from '../../../slices/descriptionSlice';
import { useRegenerateAllSitelinksMutation } from '../../../slices/sitelinkSlice';
import { useRegenerateAllPriceAssetsMutation } from '../../../slices/priceAssetSlice';
import { useGenerateFromPriceAssetsMutation } from '../../../slices/imageAssetSlice';
interface RegenerateAllProps {
  adgroup_id: string;
  disabled?: boolean;
}

const RegenerateAll: React.FC<RegenerateAllProps> = ({ adgroup_id }) => {
  const isCancelled = useRef(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const updateProgress = (index: number, total: number) => {
    const newProgress = (index / total) * 100;
    const diff = Math.random() * 10;
    const diff2 = Math.random() * 10;
    setProgress(newProgress + diff);
    setBuffer(newProgress + diff + diff2);
  };

  const [regenerateDisplayPath] = useRegenerateDisplayPathMutation({
    fixedCacheKey: 'regenerateDisplayPath',
  });

  const [regenerateAllHeadlines] = useRegenerateAllHeadlinesMutation({
    fixedCacheKey: 'regenerateHeadlines',
  });

  const [regenerateAllDescriptions] = useRegenerateAllDescriptionsMutation({
    fixedCacheKey: 'regenerateDescriptions',
  });

  const [regenerateAllSitelinks] = useRegenerateAllSitelinksMutation({
    fixedCacheKey: 'regenerateSitelinks',
  });

  const [regenerateAllPriceAssets] = useRegenerateAllPriceAssetsMutation({
    fixedCacheKey: 'regeneratePriceAssets',
  });

  const [regenerateAllImageAssets] = useGenerateFromPriceAssetsMutation({
    fixedCacheKey: 'regenerateImageAssets',
  });

  useEffect(() => {
    return () => {
      isCancelled.current = true;
    };
  }, []);

  const handleRegenerateAll = async () => {
    if (isLoading) {
      isCancelled.current = true;
      setIsLoading(false);
      return;
    }

    isCancelled.current = false;
    setIsLoading(true);

    const regenerateFunctions = [
      regenerateDisplayPath,
      regenerateAllHeadlines,
      regenerateAllDescriptions,
      regenerateAllSitelinks,
      regenerateAllPriceAssets,
      regenerateAllImageAssets,
    ];

    let i = 0;
    for (const regenerateFunction of regenerateFunctions) {
      try {
        if (isCancelled.current) break;
        await regenerateFunction({ ad_group_id: adgroup_id });
        updateProgress(i + 1, regenerateFunctions.length);
        i++;
      } catch (error) {
        isCancelled.current = true;
      }
    }
    setProgress(0);
    setBuffer(10);
    setIsLoading(false);
  };

  return (
    <Box>
      {isLoading && (
        <LinearProgress
          sx={{ mb: 2 }}
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
        />
      )}

      <Button
        variant="contained"
        color={isLoading ? 'error' : 'warning'}
        startIcon={isLoading ? <StopIcon /> : <RefreshIcon />}
        onClick={handleRegenerateAll}
      >
        {isLoading ? 'Regenerating...' : 'Regenerate All'}
      </Button>
    </Box>
  );
};

export default RegenerateAll;
