import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RefreshIcon from '@mui/icons-material/Refresh';
import DynamicPromptModal from '../../DynamicPromptModal';
import {
  Save as SaveIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  useRegenerateDisplayPathMutation,
  useUpdateDisplayPathMutation,
} from '../../../slices/adSlice';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { displayPathValidationSchema } from '../../../utils/validation';
import { DisplayPath as DisplayPathType } from '../../../types/adGroups';

interface DisplayPathProps {
  display_path: DisplayPathType;
  adgroup_id: string;
  togglePromptPreview: () => void;
}

const DisplayPath: React.FC<DisplayPathProps> = ({
  display_path,
  adgroup_id,
  togglePromptPreview,
}) => {
  const [regenerateDisplayPath, { isLoading: isLoadingRegenerate }] =
    useRegenerateDisplayPathMutation({
      fixedCacheKey: 'regenerateDisplayPath',
    });

  const [updateDisplayPath] = useUpdateDisplayPathMutation();

  const [promptModalOpen, setPromptModalOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [hasValidationError, setHasValidationError] = React.useState(false);

  const validationSchema = Yup.object({
    first_part: displayPathValidationSchema,
    second_part: displayPathValidationSchema,
  });

  React.useEffect(() => {
    if (!display_path) return;

    validationSchema
      .validate(display_path)
      .then(() => setHasValidationError(false))
      .catch((_err: any) => {
        setHasValidationError(true);
      });
  }, [display_path, validationSchema]);

  const handleEditClick = () => {
    if (isLoadingRegenerate) return;
    setIsEditing(true);
  };

  const handleCloseClick = () => {
    if (isLoadingRegenerate) return;
    setIsEditing(false);
  };

  const handleRegenerateClick = (adgroup_id: string) => {
    if (isLoadingRegenerate) return;
    regenerateDisplayPath({ ad_group_id: adgroup_id });
  };
  return (
    <>
      <Box>
        <Typography variant="body1" component="div">
          {!!hasValidationError && (
            <Tooltip title="Failed validation" arrow>
              <IconButton>
                <WarningIcon color="error" />
              </IconButton>
            </Tooltip>
          )}
          <span style={{ fontWeight: 'bold' }}>Display Path:</span>{' '}
          <span>https://www.alpinehomeair.com/</span>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              first_part: display_path?.first_part || '',
              second_part: display_path?.second_part || '',
            }}
            enableReinitialize={true}
            onSubmit={({ first_part, second_part }) => {
              updateDisplayPath({
                id: adgroup_id,
                first_part,
                second_part,
              });
              setIsEditing(false);
            }}
          >
            {({ errors, handleSubmit }) => (
              <>
                {isEditing ? (
                  <>
                    <Form style={{ display: 'inline-flex' }}>
                      <Tooltip
                        title={errors.first_part}
                        open={!!errors.first_part}
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: 'red',
                              '& .MuiTooltip-arrow': {
                                color: 'red',
                              },
                            },
                          },
                        }}
                      >
                        <Field name="first_part" type="text" />
                      </Tooltip>
                      /
                      <Tooltip
                        title={errors.second_part}
                        open={!!errors.second_part}
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: 'red',
                              '& .MuiTooltip-arrow': {
                                color: 'red',
                              },
                            },
                          },
                        }}
                      >
                        <Field name="second_part" type="text" />
                      </Tooltip>
                    </Form>
                  </>
                ) : (
                  <>
                    {display_path?.first_part?.toLowerCase() || ''}/
                    {display_path?.second_part?.toLowerCase() || ''}
                  </>
                )}
                {isEditing ? (
                  <>
                    <IconButton
                      disabled={
                        isLoadingRegenerate ||
                        !!errors.first_part ||
                        !!errors.second_part
                      }
                      onClick={() => handleSubmit()}
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleCloseClick}
                      disabled={isLoadingRegenerate}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      onClick={handleEditClick}
                      disabled={isLoadingRegenerate}
                    >
                      <EditIcon />
                    </IconButton>
                  </>
                )}
                {!isEditing && (
                  <>
                    <IconButton onClick={togglePromptPreview}>
                      <RateReviewIcon
                        sx={
                          !isLoadingRegenerate
                            ? {
                                color: '#1976d2',
                              }
                            : {}
                        }
                      />
                    </IconButton>
                    <IconButton
                      sx={{ color: 'white' }}
                      aria-label="close"
                      onClick={() => handleRegenerateClick(adgroup_id)}
                      disabled={isLoadingRegenerate}
                    >
                      <RefreshIcon
                        sx={
                          !isLoadingRegenerate
                            ? {
                                color: '#FF0000',
                              }
                            : {}
                        }
                      />
                    </IconButton>
                    {isLoadingRegenerate && (
                      <>
                        <IconButton sx={{ color: 'white' }} aria-label="close">
                          <CircularProgress size={16} />
                        </IconButton>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Formik>
        </Typography>
      </Box>
      <DynamicPromptModal
        open={promptModalOpen}
        handleClose={() => setPromptModalOpen((prev) => !prev)}
        adGroupId={adgroup_id}
        promptName={'display_path'}
        type="Display Path"
      />
    </>
  );
};

export default DisplayPath;
