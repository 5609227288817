import React, { useEffect, useState } from 'react';
import {
  GridFilterModel,
  GridCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import {
  useFetchAllGlobalDescriptionsQuery,
  useUpdateDescriptionMutation,
  useDeleteDescriptionsMutation,
  useCreateDescriptionsMutation,
  useFetchDescriptionQuery,
} from '../../slices/descriptionSlice';
import { useTitle } from '../../contexts/TitleContext';
import { descriptionValidationSchema } from '../../utils/validation';
import { IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BaseAssignToolDataTable from '../BaseAssignToolDataTable';
import buildTextWithCharacterCountColumn from '../../utils/buildTextWithCharacterCountColumn';
import buildCharacterCountColumn from '../../utils/buildCharacterCountColumn';
import {
  GlobalAssetType,
  CharacterLimitForContent,
  PinType,
  MaxAllowableContent,
} from '../../types/enums';
import PinCell from '../TableCells/PinCell';
import PinDropdown from '../TableCells/PinDropdown';
import { Description } from '../../types/pinnable';
import * as Yup from 'yup';
import AssignToDescriptionGroupModal from './AssignToDescriptionGroupModal';

interface GlobalDescriptionSectionProps {
  data: any;
  isFetching?: boolean;
  setQueryOptions?: any;
  paginationModel?: any;
  setPaginationModel?: any;
  isGroupView?: boolean;
}

const DescriptionTool: React.FC<GlobalDescriptionSectionProps> = ({
  data,
  isFetching = false,
  setQueryOptions,
  paginationModel,
  setPaginationModel,
  isGroupView = false,
}) => {
  const [updateDescription] = useUpdateDescriptionMutation();
  const [deleteDescriptions] = useDeleteDescriptionsMutation();
  const [createDescriptions] = useCreateDescriptionsMutation();
  const [selectedId, setSelectedId] = useState('');
  const { setTitle } = useTitle();
  const initialValues = {
    id: '',
    description: '',
    pinned: '',
    is_global: true,
  } as Description;
  const { data: selectedIdData = initialValues } = useFetchDescriptionQuery(
    { descriptionId: selectedId },
    {
      skip: !selectedId,
    }
  );

  useEffect(() => {
    setTitle(`Description Tool`);
  }, [setTitle]);

  const getColumns = (handleOpen: (id: string) => void) => [
    {
      field: 'id',
      headerName: 'Edit',
      width: 90,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <IconButton
            onClick={() => {
              handleOpen(params.row.id);
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        );
      },
    },
    buildTextWithCharacterCountColumn(
      'description',
      'Description',
      CharacterLimitForContent.Description,
      descriptionValidationSchema
    ),
    {
      field: 'global_pinned',
      headerName: 'Pinned',
      width: 150,
      editable: true,
      renderCell: (params: GridCellParams) => <PinCell {...params} />,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <PinDropdown {...params} pinType={PinType.DESCRIPTION} />
      ),
    },
    buildCharacterCountColumn(
      'description',
      'Characters',
      CharacterLimitForContent.Description
    ),
    {
      field: 'assigned_to_count',
      headerName: 'Assigned To',
      width: 90,
    },
  ];

  const validationSchema = Yup.object().shape({
    description: descriptionValidationSchema,
  });

  const fieldNames = ['description', 'global_pinned'];

  return (
    <BaseAssignToolDataTable
      data={data}
      isFetching={isFetching}
      assetType={GlobalAssetType.Description}
      getColumns={getColumns}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      setQueryOptions={setQueryOptions}
      updateAsset={async (asset: any) => {
        await updateDescription(asset as Description).unwrap();
      }}
      deleteAssets={deleteDescriptions}
      createAsset={async (asset: any) => {
        await createDescriptions({ descriptions: [asset] as Description[] });
      }}
      selectedIdData={selectedIdData}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      validationSchema={validationSchema}
      fieldNames={fieldNames}
      maxAllowableContent={MaxAllowableContent.Descriptions}
      isGroupView={isGroupView}
      AssignToAssetGroupModal={AssignToDescriptionGroupModal}
    />
  );
};

export default DescriptionTool;
