import { Pinnable } from '../types/pinnable';

const getFilteredSelectedPins = (items?: Pinnable[]) => {
  if (!items) return [];
  const selectedPins = items
    .filter((item: Pinnable) => item.pinned)
    .map((item: Pinnable) => {
      return { id: item.id, pinned: item.pinned };
    });
  return selectedPins;
};
export default getFilteredSelectedPins;
