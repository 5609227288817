export enum UrlType {
  Content = 'CONTENT',
  Final = 'FINAL',
  PreCreatedSitelink = 'PRECREATEDSITELINK',
  Sitelink = 'SITELINK',
  PriceAsset = 'PRICEASSET',
  SearchTerm = 'SEARCHTERM',
}

export enum StatusEnum {
  Pending = 'PENDING',
  ReadyToApprove = 'READY_TO_APPROVE',
  Approved = 'APPROVED',
  Paused = 'PAUSED',
}

export enum ContentType {
  PrimaryHeadline = 'primary_headline',
  Headline = 'headlines',
  Description = 'descriptions',
  PriceAsset = 'price_assets',
  ImageAsset = 'image_assets',
  Sitelink = 'sitelinks',
  DisplayPath = 'display_path',
}

export enum MaxAllowableContent {
  Headlines = 15,
  Descriptions = 4,
  Sitelinks = 20,
  PriceAssets = 8,
  ImageAssets = 8,
}

export enum CharacterLimitForContent {
  Headline = 30,
  Description = 90,
  DisplayPathPart = 15,
  SitelinkHeader = 25,
  SitelinkDescription = 35,
  PriceAssetHeader = 25,
  PriceAssetDescription = 25,
}

export enum AIModel {
  ChatGPT4 = 'CHAT_GPT_4',
  ChatGPT4o = 'CHAT_GPT_4_O',
  Claude3Opus = 'CLAUDE_3_OPUS',
  Claude3Sonnet = 'CLAUDE_3_SONNET',
  Claude35Sonnet = 'CLAUDE_3_5_SONNET',
  Claude3Haiku = 'CLAUDE_3_HAIKU',
}

export enum PromptingButtonType {
  StartConversation = 'START_CONVERSATION',
  AskAI = 'ASK_AI',
}

export enum PinType {
  HEADLINE = 'HEADLINE',
  GENERAL_HEADLINE = 'GENERAL_HEADLINE',
  PRIMARY_HEADLINE = 'PRIMARY_HEADLINE',
  DESCRIPTION = 'DESCRIPTION',
}

export enum GlobalAssetType {
  GeneralHeadline = 'General Headline',
  PrimaryHeadline = 'Primary Headline',
  Description = 'Description',
  PriceAsset = 'Price Asset',
  Sitelink = 'Sitelink',
  HeadlineGroup = 'Headline Group',
  DescriptionGroup = 'Description Group',
  SitelinkGroup = 'Sitelink Group',
  PriceAssetGroup = 'Price Asset Group',
}

export enum PriceAssetType {
  Brands = 'BRANDS',
  ProductTiers = 'PRODUCT_TIERS',
  ProductCategories = 'PRODUCT_CATEGORIES',
}

export enum PriceAssetPriceQualifier {
  From = 'FROM',
  UpTo = 'UP_TO',
  Average = 'AVERAGE',
}
