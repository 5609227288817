import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { PriceAssetType } from '../../types/enums';
const PriceAssetTypeDropdown = (props: GridRenderEditCellParams) => {
  const { id, value, field } = props;

  const apiRef = useGridApiContext();

  const handleChange = (event: SelectChangeEvent<any>) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
  };

  const getTypes = () => {
    let priceAssetTypes = [
      PriceAssetType.Brands,
      PriceAssetType.ProductTiers,
      PriceAssetType.ProductCategories,
    ];

    return priceAssetTypes.map((priceAssetType) => (
      <MenuItem key={priceAssetType} value={priceAssetType}>
        {priceAssetType.replace(
          /^(.)(.*)$/,
          (_, firstChar, restOfString) =>
            firstChar.toUpperCase() +
            restOfString.toLowerCase().replace(/_/g, ' ')
        )}
      </MenuItem>
    ));
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId={`pinned-position-select-label-${id}`}
        id={`pinned-position-select-${id}`}
        value={value || ''}
        label="Pinned Position"
        onChange={handleChange}
        variant="standard"
      >
        <MenuItem value="">No Type</MenuItem>
        {getTypes()}
      </Select>
    </FormControl>
  );
};

export default PriceAssetTypeDropdown;
