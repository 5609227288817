import React, { useState } from 'react';
import { SearchTerm } from '../../../types/adGroups';
import StripedListItemButton from '../../StripedListItemButton';
import PublicIcon from '@mui/icons-material/Public';
import {
  ListItemText,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import DeleteSearchTermFinalURLModal from './DeleteSearchTermFinalUrlModal';

interface SearchTermItemProps {
  searchTerm: SearchTerm;
  index: number;
  setSelectedId: (val: string) => void;
  setSelectedUrl: (val: string) => void;
  setSelectedSearchTerm: (val: string) => void;
  setSearchTermSiteSurfModalOpen: (val: boolean) => void;
}

const SearchTermItem: React.FC<SearchTermItemProps> = ({
  searchTerm,
  index,
  setSelectedId,
  setSelectedUrl,
  setSelectedSearchTerm,
  setSearchTermSiteSurfModalOpen,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenSiteSurfModal = (searchTerm: SearchTerm) => {
    setSelectedId(searchTerm.id);
    setSelectedUrl(searchTerm.final_url);
    setSelectedSearchTerm(searchTerm.search_term);
    setSearchTermSiteSurfModalOpen(true);
  };

  return (
    <>
      <StripedListItemButton index={index}>
        <ListItemText
          primary={searchTerm.search_term}
          secondary={`Clicks: ${searchTerm.clicks}`}
        />
        <Tooltip
          title={
            searchTerm.final_url ? searchTerm.final_url : 'No Final URL set'
          }
        >
          <IconButton
            color={searchTerm.final_url ? 'primary' : 'default'}
            onClick={(e) => {
              e.stopPropagation();
              if (searchTerm.final_url) {
                handleOpenMenu(e);
              } else {
                handleOpenSiteSurfModal(searchTerm);
              }
            }}
          >
            <PublicIcon />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{
            mt: '45px',
          }}
          id="action-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleOpenSiteSurfModal(searchTerm);
              handleCloseMenu();
            }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Update URL" />
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setDeleteModalOpen(true);
              handleCloseMenu();
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Delete URL" />
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleCloseMenu();
            }}
          >
            <ListItemIcon>
              <CloseIcon />
            </ListItemIcon>
            <ListItemText primary="Close" />
          </MenuItem>
        </Menu>
      </StripedListItemButton>
      <DeleteSearchTermFinalURLModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        name={searchTerm.search_term}
        searchTermId={searchTerm.id}
      />
    </>
  );
};

export default SearchTermItem;
