import React from 'react';
import { Button, Stack } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Delete as DeleteIcon } from '@mui/icons-material';
import BackupIcon from '@mui/icons-material/Backup';

interface ActionButtonsProps {
  onApprove?: () => void;
  onUnApprove?: () => void;
  onRegenerate?: () => void;
  onDelete?: () => void;
  onAddToGlobal?: () => void;
  numberSelected?: number | null;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  onApprove,
  onRegenerate,
  onDelete,
  onUnApprove,
  numberSelected = null,
  onAddToGlobal,
}) => {
  return (
    <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
      {!!onApprove && (
        <Button
          variant="contained"
          color="success"
          startIcon={<ThumbUpIcon />}
          onClick={onApprove}
        >
          Approve {numberSelected ? `(${numberSelected})` : ''}
        </Button>
      )}
      {!!onUnApprove && (
        <Button
          variant="contained"
          color="warning"
          startIcon={<ThumbDownIcon />}
          onClick={onUnApprove}
        >
          UnApprove {numberSelected ? `(${numberSelected})` : ''}
        </Button>
      )}
      {!!onRegenerate && (
        <Button
          variant="contained"
          color="error"
          startIcon={<RefreshIcon />}
          onClick={onRegenerate}
        >
          Regenerate {numberSelected ? `(${numberSelected})` : ''}
        </Button>
      )}
      {!!onDelete && (
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={onDelete}
        >
          Delete {numberSelected ? `(${numberSelected})` : ''}
        </Button>
      )}
      {!!onAddToGlobal && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<BackupIcon />}
          onClick={onAddToGlobal}
        >
          Convert {`(${numberSelected}) to Global`}
        </Button>
      )}
    </Stack>
  );
};

export default ActionButtons;
