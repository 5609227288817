import { useState } from 'react';
import BaseAssignToolDataTable from '../BaseAssignToolDataTable';
import {
  GridCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import {
  useUpdateHeadlineMutation,
  useDeleteHeadlinesMutation,
  useCreateHeadlinesMutation,
  useFetchHeadlineQuery,
} from '../../slices/headlineSlice';
import { headlineValidationSchema } from '../../utils/validation';
import { IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import buildTextWithCharacterCountColumn from '../../utils/buildTextWithCharacterCountColumn';
import buildCharacterCountColumn from '../../utils/buildCharacterCountColumn';
import {
  GlobalAssetType,
  CharacterLimitForContent,
  PinType,
  MaxAllowableContent,
} from '../../types/enums';
import PinCell from '../TableCells/PinCell';
import PinDropdown from '../TableCells/PinDropdown';
import { Headline } from '../../types/pinnable';
import * as Yup from 'yup';
import AssignToHeadlineGroupModal from './AssignToHeadlineGroupModal';

interface GlobalHeadlineSectionProps {
  data: any;
  isFetching?: boolean;
  setQueryOptions?: any;
  paginationModel?: any;
  setPaginationModel?: any;
  assetType: GlobalAssetType;
  isGroupView?: boolean;
}

const GlobalHeadlineSection: React.FC<GlobalHeadlineSectionProps> = ({
  data,
  isFetching = false,
  setQueryOptions,
  paginationModel,
  setPaginationModel,
  assetType,
  isGroupView = false,
}) => {
  const [updateHeadline] = useUpdateHeadlineMutation();
  const [deleteHeadlines] = useDeleteHeadlinesMutation();
  const [createHeadlines] = useCreateHeadlinesMutation();
  const [selectedId, setSelectedId] = useState('');

  const initialValues = {
    id: '',
    headline: '',
    pinned: '',
    is_global: true,
    is_primary: false,
  } as Headline;

  const { data: selectedIdData = initialValues } = useFetchHeadlineQuery(
    { headlineId: selectedId },
    {
      skip: !selectedId,
    }
  );

  const getColumns = (handleOpen: (id: string) => void) => [
    {
      field: 'id',
      headerName: 'Edit',
      width: 90,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <IconButton
            onClick={() => {
              handleOpen(params.row.id);
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        );
      },
    },
    buildTextWithCharacterCountColumn(
      'headline',
      'Headline',
      CharacterLimitForContent.Headline,
      headlineValidationSchema
    ),
    {
      field: 'global_pinned',
      headerName: 'Pinned',
      width: 150,
      editable: true,
      renderCell: (params: GridCellParams) => <PinCell {...params} />,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <PinDropdown {...params} pinType={PinType.GENERAL_HEADLINE} />
      ),
    },
    buildCharacterCountColumn(
      'headline',
      'Characters',
      CharacterLimitForContent.Headline
    ),
    {
      field: 'assigned_to_count',
      headerName: 'Assigned To',
      width: 90,
    },
  ];

  const validationSchema = Yup.object().shape({
    headline: headlineValidationSchema,
  });

  const fieldNames = ['headline', 'global_pinned'];
  return (
    <BaseAssignToolDataTable
      data={data}
      isFetching={isFetching}
      assetType={assetType}
      getColumns={getColumns}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      setQueryOptions={setQueryOptions}
      updateAsset={async (asset: any) => {
        await updateHeadline(asset as Headline).unwrap();
      }}
      deleteAssets={deleteHeadlines}
      createAsset={async (asset: any) => {
        await createHeadlines({ headlines: [asset] as Headline[] });
      }}
      selectedIdData={selectedIdData}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      validationSchema={validationSchema}
      fieldNames={fieldNames}
      maxAllowableContent={MaxAllowableContent.Headlines}
      AssignToAssetGroupModal={AssignToHeadlineGroupModal}
      isGroupView={isGroupView}
    />
  );
};

export default GlobalHeadlineSection;
