import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Paper,
  PaperProps,
} from '@mui/material';
import { Save as SaveIcon, Close as CloseIcon } from '@mui/icons-material';
import { UrlType } from '../../types/enums';

const defaultUrl = `${process.env.REACT_APP_BASE_URL}`;
const defaultIframeSrc = `${process.env.REACT_APP_BASE_IFRAME_URL || process.env.REACT_APP_BASE_URL}`;

interface SiteSurfModalProps {
  setModalOpen: (val: boolean) => void;
  title: string;
  urlType: UrlType;
  url?: string;
  initialMaxProducts?: number;
  exportCallback: (url: string, maxProduct?: number) => void;
}

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

const doWorkOnURL = (final_url: string) => {
  let url = '';
  let parsedUrl = null;
  try {
    parsedUrl = new URL(final_url);
    url = `${process.env.REACT_APP_BASE_URL}${parsedUrl.pathname}${parsedUrl.search}`;
  } catch (e) {
    url = `${process.env.REACT_APP_BASE_URL}${final_url}`;
  }

  return url;
};

const SiteSurfModal: React.FC<SiteSurfModalProps> = ({
  setModalOpen,
  url,
  initialMaxProducts,
  urlType,
  exportCallback,
  title,
}) => {
  const [editedUrl, setEditedUrl] = useState('');
  const [editedMaxProducts, setEditedMaxProducts] = useState(
    initialMaxProducts || 100
  );
  const [iframeSrc, setIframeSrc] = useState('');

  useEffect(() => {
    if (url) {
      const newUrl = doWorkOnURL(url);
      setEditedUrl(newUrl);
      setIframeSrc(newUrl.replace(defaultUrl, defaultIframeSrc));
    }
    if (url === '') {
      const newUrl = doWorkOnURL('/');
      setEditedUrl(newUrl);
      setIframeSrc(newUrl.replace(defaultUrl, defaultIframeSrc));
    }
    if (initialMaxProducts !== undefined) {
      setEditedMaxProducts(initialMaxProducts);
    }
  }, [url, initialMaxProducts]);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.data.hasOwnProperty('reactLocation')) {
        setEditedUrl(doWorkOnURL(event.data.reactLocation));
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [setEditedUrl]);

  const getHelperText = () => {
    switch (urlType) {
      case UrlType.Final:
        return 'Final URL for this ad group';
      case UrlType.Content:
        return 'Content URL for this ad group';
      case UrlType.Sitelink:
        return 'Sitelink URL';
      case UrlType.PriceAsset:
        return 'Price Sitelink URL';
      case UrlType.PreCreatedSitelink:
        return 'Pre-created Sitelink URL';
      case UrlType.SearchTerm:
        return 'Search Term Final URL';
    }
  };
  const getInputLabel = () => {
    switch (urlType) {
      case UrlType.Final:
        return 'Final URL';
      case UrlType.Content:
        return 'Content URL';
      case UrlType.Sitelink:
        return 'Sitelink URL';
      case UrlType.PriceAsset:
        return 'Price Asset URL';
      case UrlType.SearchTerm:
        return 'Search Term Final URL';
    }
  };

  const handleSaveClick = async () => {
    const parsedUrl = new URL(editedUrl);
    let searchParams = new URLSearchParams(parsedUrl.search);
    const searchParamsToRemove = [
      'approvedForUSRegionS',
      'approvedForUSRegionSOptions',
      'limit',
    ];

    searchParamsToRemove.forEach((param) => {
      if (searchParams.has(param)) {
        searchParams.delete(param);
      }
    });

    let modifiedUrl = `${process.env.REACT_APP_BASE_URL}${parsedUrl.pathname}?${searchParams.toString()}`;
    setIframeSrc(modifiedUrl);
    if (exportCallback && (UrlType.Content || UrlType.Final)) {
      exportCallback(modifiedUrl, editedMaxProducts);
    } else if (exportCallback) {
      exportCallback(modifiedUrl);
    }
  };

  const handleGoClick = () => {
    setIframeSrc(editedUrl);
  };
  return (
    <Dialog
      open
      onClose={() => setModalOpen(false)}
      fullWidth
      maxWidth={false}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      sx={{ zIndex: 1501 }}
    >
      <DialogTitle
        sx={{ bgcolor: 'primary.main', color: 'white' }}
        style={{
          cursor: 'move',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
        id="draggable-dialog-title"
      >
        {title}
        <IconButton
          sx={{ color: 'white' }}
          aria-label="close"
          onClick={() => setModalOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid container sx={{ mt: 4 }}>
        <Grid
          item
          xs={
            urlType === UrlType.Content ||
            urlType === UrlType.Final ||
            urlType === UrlType.PriceAsset
              ? 9
              : 10
          }
        >
          <Box ml={3}>
            <FormControl fullWidth>
              <InputLabel htmlFor="url">{getInputLabel()}</InputLabel>
              <Input
                id="url"
                aria-describedby="url-helper-text"
                value={editedUrl}
                onChange={(e) => setEditedUrl(e.target.value)}
                fullWidth
              />
              <FormHelperText id="url-helper-text">
                {getHelperText()}
              </FormHelperText>
            </FormControl>
          </Box>
        </Grid>
        {(urlType === UrlType.Content ||
          urlType === UrlType.Final ||
          urlType === UrlType.PriceAsset) && (
          <Grid item xs={1}>
            <Box ml={3}>
              <FormControl>
                <InputLabel htmlFor="max-products">Max Products</InputLabel>
                <Input
                  id="max-products"
                  aria-describedby="max-products-helper-text"
                  value={editedMaxProducts}
                  onChange={(e) =>
                    setEditedMaxProducts(parseInt(e.target.value))
                  }
                  type="number"
                  inputProps={{ style: { textAlign: 'center' } }}
                />
                <FormHelperText id="max-products-helper-text">
                  Set max products retrieved from this url
                </FormHelperText>
              </FormControl>
            </Box>
          </Grid>
        )}
        <Grid item xs={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            p={1}
          >
            <Button onClick={handleGoClick} color="primary" variant="contained">
              View URL
            </Button>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box display="flex" justifyContent="flex-start" width="100%" p={1}>
            <Button onClick={handleSaveClick} startIcon={<SaveIcon />}>
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 1, pl: 3 }} spacing={2}>
        <Grid item xs={12}>
          <iframe src={iframeSrc} title="testing" width="98%" height="600px" />
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default SiteSurfModal;
