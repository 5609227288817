import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Divider,
  Checkbox,
  IconButton,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { Help as HelpIcon } from '@mui/icons-material';
import {
  Prompt,
  PromptType,
  useFetchPromptAssignmentQuery,
  useFetchPromptPreviewElementsQuery,
  useFetchPromptQuery,
  useFetchPromptTemplateForAdGroupQuery,
  useFetchPromptsQuery,
  useSavePromptMutation,
  useSavePromptAssignmentMutation,
} from '../../slices/promptSlice';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import SelectPrompt from './components/SelectPrompt';
import AssignedPrompt from './components/AssignedPrompt';
import PromptActionButtons from './components/PromptActionButtons';
import { useFetchAdQuery } from '../../slices/adSlice';
import { PromptPreviewElements } from '../../types/prompts';
import { useSnackbar } from 'notistack';
import TextArea from '../StyledMUIComponents/TextArea';

interface EditPromptContentProps {
  adGroupId: string;
  promptName: string;
}

const EditPromptContent: React.FC<EditPromptContentProps> = ({
  adGroupId,
  promptName,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [currentPrompt, setCurrentPrompt] = useState<Prompt>();
  const [currentPromptId, setCurrentPromptId] = useState<string>('');
  const [includedAdContent, setIncludedAdContent] = useState<string>('');
  const [savePrompt] = useSavePromptMutation();
  const [assignPrompt] = useSavePromptAssignmentMutation();
  const { data: promptAssignment } = useFetchPromptAssignmentQuery({
    promptName,
    adGroupId,
  });
  const { isFetching } = useFetchPromptQuery({
    promptName,
    adGroupId,
  });
  const {
    data: prompts = {
      prompts: [],
      default_prompt_id: '',
    },
  } = useFetchPromptsQuery({
    promptName: promptName,
    adGroupId,
  });
  const {
    data = {
      adgroup_name: '',
    },
  } = useFetchAdQuery(adGroupId);
  const { data: promptTemplate } = useFetchPromptTemplateForAdGroupQuery({
    adGroupId,
    contentType: promptName,
  });

  const { data: promptPreviewElements } = useFetchPromptPreviewElementsQuery({
    adGroupId,
  });

  useEffect(() => {
    setCurrentPromptId(currentPrompt?._id || '');
  }, [currentPrompt]);

  const handleUpdate = (key: string, value: any) => {
    setCurrentPrompt((prev: any) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const getIncludedAdContent = useCallback(() => {
    if (currentPrompt) {
      const keys = [
        'include_product_data',
        'include_keywords',
        'include_headlines',
        'include_descriptions',
        'include_final_url',
        'include_sitelinks',
        'include_price_assets',
        'include_ad_group_details',
        'include_global_sitelinks',
        'include_global_price_assets',
        'include_category_links',
        'values_multiplier',
      ];

      const filteredKeys = keys.filter(
        (key) => currentPrompt[key as keyof Prompt]
      );
      const content = filteredKeys.map((key) =>
        promptPreviewElements
          ? promptPreviewElements[
              key.replace('include_', '') as keyof PromptPreviewElements
            ]
          : ''
      );
      const indexOfValuesMultiplier = filteredKeys.indexOf('values_multiplier');
      if (indexOfValuesMultiplier !== -1) {
        const formatedString =
          currentPrompt.values_multiplier > 1
            ? content[indexOfValuesMultiplier].replace(
                '{x}',
                currentPrompt.values_multiplier.toString()
              )
            : '';
        content[indexOfValuesMultiplier] = formatedString;
      }
      return content.join('\n\n');
    }
    return '';
  }, [currentPrompt, promptPreviewElements]);
  useEffect(() => {
    setIncludedAdContent(getIncludedAdContent());
  }, [getIncludedAdContent]);

  const includAdContentFormsControl = [
    ['Keyword', 'include_keywords'],
    ['Headline', 'include_headlines'],
    ['Description', 'include_descriptions'],
    ['Final URL', 'include_final_url'],
    ['Sitelinks', 'include_sitelinks'],
    ['Price Assets', 'include_price_assets'],
    ['AdGroup Group + Name', 'include_ad_group_details'],
  ];
  const includePreCreatedContent = [
    ['Global Sitelinks', 'include_global_sitelinks'],
    ['Global Price Assets', 'include_global_price_assets'],
    ['Category Links', 'include_category_links'],
  ];

  const handleSetPrompt = (promptData: Prompt) => {
    setCurrentPrompt(promptData);
  };
  const handleSavePrompt = async () => {
    try {
      await savePrompt({
        prompt: {
          ...currentPrompt,
          type: PromptType.Global,
        } as Prompt,
        saveId: currentPrompt?._id || '',
      }).unwrap();
      enqueueSnackbar('Prompt saved successfully', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Error saving prompt', {
        variant: 'error',
      });
    }
  };

  const handleSaveNewPrompt = async () => {
    try {
      const payload = await savePrompt({
        prompt: {
          ...currentPrompt,
          type: PromptType.Global,
        } as Prompt,
        saveId: '',
      }).unwrap();
      enqueueSnackbar('New prompt saved successfully', {
        variant: 'success',
      });
      setCurrentPrompt({ ...payload });
    } catch (e) {
      enqueueSnackbar('Error saving new prompt', {
        variant: 'error',
      });
    }
  };

  const handleAssignPrompt = async () => {
    if (currentPrompt?._id && currentPrompt?.prompt_name) {
      try {
        await assignPrompt({
          prompt_name: currentPrompt.prompt_name,
          adgroup_id: adGroupId,
          prompt_id: currentPrompt?._id,
        }).unwrap();
        enqueueSnackbar('Prompt assigned successfully', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar('Error assigning prompt', {
          variant: 'error',
        });
      }
    }
  };
  return (
    <Box
      sx={{
        boxShadow: 24,
        p: 4,
        gridTemplateRows: 'repeat(3, 1fr)',
        position: 'relative',
      }}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1" component="div">
              <span style={{ fontWeight: 'bold' }}>Ad Group Name:</span>{' '}
              {data.adgroup_name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <AssignedPrompt
              prompts={prompts.prompts}
              promptAssignment={promptAssignment}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectPrompt
              prompts={prompts.prompts}
              promptName={promptName}
              setPrompt={handleSetPrompt}
              selectedPromptId={currentPromptId || prompts.default_prompt_id}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Box>
      {isFetching && <CircularProgress />}
      {!!currentPrompt && (
        <>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>Custom Name:</span>
              </Typography>
              <TextField
                variant="outlined"
                value={currentPrompt?.custom_name || ''}
                onChange={(e) => {
                  handleUpdate('custom_name', e.target.value);
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item container xs={12}>
                <Grid item xs={3}>
                  <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>
                      Use Pre-Made Template:
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={
                      'Word Group + Word Group Category + Category Combination'
                    }
                    checked={currentPrompt?.use_prompt_template}
                    onChange={(_event, checked) => {
                      handleUpdate('use_prompt_template', checked);
                    }}
                  />
                </Grid>
              </Grid>
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>Goal:</span>
              </Typography>
              <TextArea
                minRows={10}
                maxRows={20}
                style={{ width: '100%' }}
                value={
                  currentPrompt?.use_prompt_template
                    ? promptTemplate?.goal_prompt +
                      '\n\n' +
                      promptTemplate?.example_prompt
                    : currentPrompt?.prompt_goal
                }
                disabled={currentPrompt?.use_prompt_template}
                onChange={(e) => {
                  handleUpdate('prompt_goal', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>
                  Pre-Made Ad Inclusions:
                </span>
              </Typography>
              <TextArea
                disabled
                minRows={10}
                maxRows={20}
                style={{ width: '100%' }}
                value={includedAdContent}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>Randomness:</span>
              </Typography>
              <Slider
                aria-label="Temperature"
                defaultValue={Number(0)}
                value={Number(currentPrompt?.temperature)}
                valueLabelDisplay="auto"
                step={0.1}
                marks
                min={0}
                max={2}
                onChange={(_event, value) => {
                  handleUpdate('temperature', `${value}`);
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>
                  Include Product, Category, and Filter Data:
                </span>
              </Typography>
            </Grid>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label={'Product Data'}
                checked={currentPrompt?.include_product_data}
                onChange={(_event, checked) => {
                  handleUpdate('include_product_data', checked);
                }}
              />
            </Box>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>Include Ad Content:</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box>
                {includAdContentFormsControl.map(([label, key]) => (
                  <FormControlLabel
                    key={key}
                    control={<Checkbox />}
                    label={label}
                    checked={currentPrompt[key as keyof Prompt] as boolean}
                    onChange={(_event, checked) => {
                      handleUpdate(key, checked);
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>
                  Include Pre-Created Content:
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box>
                {includePreCreatedContent.map(
                  ([label, key]) =>
                    (
                      <FormControlLabel
                        key={key}
                        control={<Checkbox />}
                        label={label}
                        checked={currentPrompt[key as keyof Prompt] as boolean}
                        onChange={(_event, checked) => {
                          handleUpdate(key, checked);
                        }}
                      />
                    ) as any
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>
                  Return Values Multiplier
                </span>
                <Tooltip
                  title={
                    'Increase the number of values generated by AI. Since all content is validated, increasing this multiplier increases the chances of receiving all requested content in a single request.'
                  }
                >
                  <IconButton>
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
                <span style={{ fontWeight: 'bold' }}>:</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Select
                labelId="return-values-multiplier"
                id="return-values-multiplier"
                value={currentPrompt?.values_multiplier || 1}
                onChange={(event) => {
                  handleUpdate('values_multiplier', event.target.value);
                }}
              >
                <MenuItem value={1}>No Multiplier</MenuItem>
                <MenuItem value={2}>2x</MenuItem>
                <MenuItem value={3}>3x</MenuItem>
                <MenuItem value={4}>4x</MenuItem>
                <MenuItem value={5}>5x</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </>
      )}
      <PromptActionButtons
        prompt={currentPrompt}
        selectedPromptId={currentPromptId}
        promptAssignment={promptAssignment}
        onSave={handleSavePrompt}
        onSaveNew={handleSaveNewPrompt}
        onAssignPrompt={handleAssignPrompt}
      />
    </Box>
  );
};
export default EditPromptContent;
