import { ContentType } from '../types/enums';
export const formatReadableContentType = (
  contentType: ContentType,
  length: number = 0
) => {
  switch (contentType) {
    case ContentType.PrimaryHeadline:
    case ContentType.Headline:
      return length > 1 ? 'Headlines' : 'Headline';
    case ContentType.Description:
      return length > 1 ? 'Descriptions' : 'Description';
    case ContentType.PriceAsset:
      return length > 1 ? 'Price Assets' : 'Price Asset';
    case ContentType.ImageAsset:
      return length > 1 ? 'Image Assets' : 'Image Asset';
    case ContentType.Sitelink:
      return length > 1 ? 'Sitelinks' : 'Sitelink';
    case ContentType.DisplayPath:
      return length > 1 ? 'Display Paths' : 'Display Path';
  }
};
