import { emptySplitApi } from './baseApi';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { ImageAsset } from '../types/imageAssets';
import { GridRowId } from '@mui/x-data-grid-premium';

export const imageAssetApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchImageAssets: builder.query<ImageAsset[], string | void>({
      query(adgroup_id) {
        return `/image_assets/${adgroup_id}`;
      },
      providesTags: ['ImageAssets'],
    }),
    generateFromPriceAssets: builder.mutation<
      ImageAsset[],
      { ad_group_id: string }
    >({
      query: (body) => ({
        url: `/image_assets/generate_from_price_assets`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'ImageAssets',
          'AdGroupCounts',
          'PromptPreviewElements',
        ]),
    }),
    regenerateImageAssets: builder.mutation<
      ImageAsset[],
      { ad_group_id: string; ids: string[] | GridRowId[] }
    >({
      query: (body) => ({
        url: `/image_assets/regenerate`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['ImageAssets', 'AdGroupCounts', 'AdGroups']),
    }),
    regenerateAllImageAssets: builder.mutation<
      ImageAsset[],
      { ad_group_id: string }
    >({
      query: (body) => ({
        url: `/image_assets/regenerate_all`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['ImageAssets', 'AdGroupCounts', 'AdGroups']),
    }),
    approveImageAssets: builder.mutation<
      ImageAsset[],
      { ids: string[]; ad_group_id: string }
    >({
      query: (body) => ({
        url: `/image_assets/approve`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['ImageAssets', 'AdGroupCounts']),
    }),
    unapproveImageAssets: builder.mutation<
      ImageAsset[],
      { ids: string[]; ad_group_id: string }
    >({
      query: (body) => ({
        url: `/image_assets/unapprove`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['ImageAssets', 'AdGroupCounts']),
    }),
    deleteImageAsset: builder.mutation<ImageAsset[], string>({
      query: (id) => ({
        url: `/image_assets/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'ImageAssets',
          'AdGroupCounts',
          'PromptPreviewElements',
        ]),
    }),
    deleteImageAssets: builder.mutation<
      ImageAsset[],
      { adgroup_id: string; ids: string[] }
    >({
      query: (body) => ({
        url: `/image_assets/`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'ImageAssets',
          'AdGroupCounts',
          'PromptPreviewElements',
        ]),
    }),
    createImageAssets: builder.mutation<
      ImageAsset[],
      {
        ad_group_id?: string;
        image_assets: Pick<
          ImageAsset,
          'alpine_id' | 'thumbnail_url' | 'image_url' | 'approved' | 'is_global'
        >[];
        approved?: boolean;
      }
    >({
      query: ({ image_assets, ad_group_id, approved }) => ({
        url: `/image_assets/many`,
        method: 'POST',
        body: {
          image_assets,
          ad_group_id,
          approved,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator(['ImageAssets', 'AdGroupCounts', 'AdGroups']),
    }),
  }),
});

export const {
  useFetchImageAssetsQuery,
  useApproveImageAssetsMutation,
  useUnapproveImageAssetsMutation,
  useGenerateFromPriceAssetsMutation,
  useRegenerateImageAssetsMutation,
  useRegenerateAllImageAssetsMutation,
  useDeleteImageAssetsMutation,
  useCreateImageAssetsMutation,
} = imageAssetApiSlice;
