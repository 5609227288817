import React, { useState } from 'react';
import { Box, IconButton, Typography, TextField } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

import SiteSurfModal from '../../SiteSurfModal';
import { UrlType } from '../../../types/enums';
import { useUpdateFinalUrlMutation } from '../../../slices/adSlice';
import { useSnackbar } from 'notistack';
interface FinalUrlProps {
  final_url?: {
    url: string;
    max_products?: number;
  };
  adgroup_id: string;
}

const FinalUrl: React.FC<FinalUrlProps> = ({ final_url, adgroup_id }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [updateFinalUrl] = useUpdateFinalUrlMutation();
  const { enqueueSnackbar } = useSnackbar();
  const handleExport = (url: string, maxProducts?: number) => {
    updateFinalUrl({
      adgroup_id,
      url,
      max_products: maxProducts as number,
    })
      .unwrap()
      .then(() => {
        setModalOpen(false);
        enqueueSnackbar('Final URL updated successfully', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Failed to update Final URL', {
          variant: 'error',
        });
      });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" width="100%">
          <Typography
            sx={{ whiteSpace: 'nowrap', paddingRight: '1rem' }}
            fontWeight="bold"
          >
            Final URL:
          </Typography>
          <TextField
            variant="standard"
            value={final_url?.url}
            sx={{ flex: 1 }}
            disabled
          />
          <Typography sx={{ whiteSpace: 'nowrap' }} pl={2} variant="body1">
            (Max: {final_url?.max_products})
          </Typography>
        </Box>
        <Box display="flex">
          <IconButton onClick={() => setModalOpen(true)}>
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
      {modalOpen && (
        <SiteSurfModal
          setModalOpen={setModalOpen}
          initialMaxProducts={final_url?.max_products}
          url={final_url?.url}
          urlType={UrlType.Final}
          title="Edit Final URL"
          exportCallback={handleExport}
        />
      )}
    </>
  );
};

export default FinalUrl;
