import React, { useEffect } from 'react';
import { useTitle } from '../contexts/TitleContext';
import { Box } from '@mui/material';
import BidManagementDataGrid from '../components/BidManagementDataGrid/BidManagementDataGrid';

const BidManagementTool: React.FC = () => {
  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle(`Bid Management Tool`);
  }, [setTitle]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <BidManagementDataGrid />
    </Box>
  );
};

export default BidManagementTool;
