import { emptySplitApi } from './baseApi';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { DescriptionGroup } from '../types/groups';
import { Description } from '../types/pinnable';
import { GridRowId } from '@mui/x-data-grid-premium';

export const descriptionGroupApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllDescriptionGroups: builder.query<
      {
        results: DescriptionGroup[];
        total: number;
      },
      void
    >({
      query: () => {
        return {
          url: '/description_group/',
          method: 'GET',
        };
      },
      providesTags: ['DescriptionGroups'],
    }),
    fetchDescriptionGroup: builder.query<
      {
        description_group: DescriptionGroup;
        members: Description[];
      },
      { descriptionGroupId: string }
    >({
      query: ({ descriptionGroupId }) => {
        return {
          url: `/description_group/${descriptionGroupId}`,
          method: 'GET',
        };
      },
      providesTags: ['DescriptionGroup'],
    }),
    assignDescriptionsToDescriptionGroups: builder.mutation<
      null | string[],
      { descriptionIds: GridRowId[]; descriptionGroupIds: GridRowId[] }
    >({
      query: ({ descriptionIds, descriptionGroupIds }) => ({
        url: `/description_group/assign`,
        method: 'POST',
        body: {
          description_ids: descriptionIds,
          description_group_ids: descriptionGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'DescriptionGroups',
          'DescriptionGroup',
          'Descriptions',
          'GlobalDescriptions',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unassignDescriptionsFromDescriptionGroups: builder.mutation<
      void,
      { descriptionIds: GridRowId[]; descriptionGroupIds: GridRowId[] }
    >({
      query: ({ descriptionIds, descriptionGroupIds }) => ({
        url: `/description_group/unassign`,
        method: 'POST',
        body: {
          description_ids: descriptionIds,
          description_group_ids: descriptionGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'DescriptionGroups',
          'DescriptionGroup',
          'Descriptions',
          'PromptPreviewElements',
          'GlobalDescriptions',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    assignDescriptionGroupsToAdGroups: builder.mutation<
      void,
      { descriptionGroupIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ descriptionGroupIds, adGroupIds }) => ({
        url: `/description_group/ad_group/assign`,
        method: 'POST',
        body: {
          description_group_ids: descriptionGroupIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'DescriptionGroups',
          'DescriptionGroup',
          'Descriptions',
          'GlobalDescriptions',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unassignDescriptionGroupsFromAdGroups: builder.mutation<
      void,
      { descriptionGroupIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ descriptionGroupIds, adGroupIds }) => ({
        url: `/description_group/ad_group/unassign`,
        method: 'POST',
        body: {
          description_group_ids: descriptionGroupIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'DescriptionGroups',
          'DescriptionGroup',
          'Descriptions',
          'PromptPreviewElements',
          'GlobalDescriptions',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    createDescriptionGroup: builder.mutation<
      DescriptionGroup,
      {
        group_name: string;
      }
    >({
      query: (body) => ({
        url: `/description_group/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['DescriptionGroups']),
    }),
    updateDescriptionGroup: builder.mutation<
      DescriptionGroup,
      {
        id: string;
        group_name: string;
      }
    >({
      query: ({ id, group_name }) => ({
        url: `/description_group/${id}`,
        method: 'PUT',
        body: { group_name },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'DescriptionGroup',
          'DescriptionGroups',
          'AdGroups',
          'Description',
          'Descriptions',
          'PromptPreviewElements',
          'GlobalDescriptions',
          'GlobalPrimaryDescriptions',
          'GlobalGeneralDescriptions',
        ]),
    }),
    deleteDescriptionGroups: builder.mutation<void, { ids: GridRowId[] }>({
      query: (body) => ({
        url: `/description_group/`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'DescriptionGroups',
          'Descriptions',
          'GlobalDescriptions',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
  }),
});

export const {
  useFetchDescriptionGroupQuery,
  useFetchAllDescriptionGroupsQuery,
  useCreateDescriptionGroupMutation,
  useUpdateDescriptionGroupMutation,
  useAssignDescriptionsToDescriptionGroupsMutation,
  useUnassignDescriptionsFromDescriptionGroupsMutation,
  useAssignDescriptionGroupsToAdGroupsMutation,
  useUnassignDescriptionGroupsFromAdGroupsMutation,
  useDeleteDescriptionGroupsMutation,
} = descriptionGroupApiSlice;
