import React from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Slide,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import EditPromptContent from '../EditPromptContent';

interface DynamicPromptModalProps {
  open: boolean;
  handleClose: () => void;
  adGroupId: string;
  promptName: string;
  type: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DynamicPromptModal: React.FC<DynamicPromptModalProps> = ({
  open,
  handleClose,
  adGroupId,
  promptName,
  type,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      TransitionComponent={Transition}
      scroll="paper"
      fullScreen
    >
      <DialogTitle
        sx={{ bgcolor: 'primary.main', color: 'white' }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        Edit Prompt for {type}
        <IconButton
          sx={{ color: 'white' }}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {open && (
          <EditPromptContent adGroupId={adGroupId} promptName={promptName} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(DynamicPromptModal);
