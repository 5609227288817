import { emptySplitApi } from './baseApi';

interface FetchFiltersResponse {
  word_group_filters: string[];
  word_group_category_filters: string[];
  word_combination_filters: string[];
}

export const filterApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchFilters: builder.query<FetchFiltersResponse, {}>({
      query: () => ({
        url: `/filters/`,
        method: 'GET',
      }),
      providesTags: ['Filters'],
    }),
  }),
});

export const { useFetchFiltersQuery } = filterApiSlice;
