import React, { useEffect, useState } from 'react';
import { useTitle } from '../contexts/TitleContext';
import { Box, Tabs, Tab } from '@mui/material';
import CustomTabPanel from '../components/CustomTabPanel/CustomTabPanel';
import GeneralHeadlineSection from '../components/HeadlineToolComponents/GeneralHeadlineSection';
import PrimaryHeadlineSection from '../components/HeadlineToolComponents/PrimaryHeadlineSection';
import HeadlineGroupSection from '../components/HeadlineToolComponents/HeadlineGroupSection';

const HeadlineTool: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle(`Headline Tool`);
  }, [setTitle]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '15px' }}>
        <Tabs value={tabValue} onChange={handleChangeTab} centered>
          <Tab label="General" id="tab-1" aria-controls="tab-1" value={0} />
          <Tab label="Primary" id="tab-2" aria-controls="tab-2" value={1} />
          <Box
            sx={{
              alignSelf: 'center',
              borderLeft: 1,
              borderWidth: 2,
              borderColor: 'divider',
              height: '32px',
            }}
          />
          <Tab label="Groups" id="tab-3" aria-controls="tab-3" value={2} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <GeneralHeadlineSection />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <PrimaryHeadlineSection />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <HeadlineGroupSection />
      </CustomTabPanel>
    </Box>
  );
};

export default HeadlineTool;
