import React from 'react';
import { GridRowId } from '@mui/x-data-grid-premium';
import AssignToAssetGroupModal, {
  AssignToSpecificAssetGroupModalProps,
} from '../AssignToAssetGroupModal';
import {
  useFetchAllSitelinkGroupsQuery,
  useAssignSitelinksToSitelinkGroupsMutation,
  useUnassignSitelinksFromSitelinkGroupsMutation,
} from '../../slices/sitelinkGroupSlice';

const AssignToSitelinkGroupModal: React.FC<
  AssignToSpecificAssetGroupModalProps
> = ({ setModalOpen, assetIds, assetType }) => {
  const [assignSitelinks] = useAssignSitelinksToSitelinkGroupsMutation();
  const [unassignSitelinks] = useUnassignSitelinksFromSitelinkGroupsMutation();
  const defaultData = { total: 0, results: [] };
  const { data = defaultData, isFetching } = useFetchAllSitelinkGroupsQuery();
  const handleAssign = (assetIds: GridRowId[], assetGroupIds: GridRowId[]) => {
    return assignSitelinks({
      sitelinkIds: assetIds,
      sitelinkGroupIds: assetGroupIds,
    }).unwrap();
  };
  const handleUnassign = (
    assetIds: GridRowId[],
    assetGroupIds: GridRowId[]
  ) => {
    return unassignSitelinks({
      sitelinkIds: assetIds,
      sitelinkGroupIds: assetGroupIds,
    }).unwrap();
  };
  return (
    <AssignToAssetGroupModal
      setModalOpen={setModalOpen}
      assetIds={assetIds}
      assetType={assetType}
      data={data}
      isFetching={isFetching}
      assignAssets={handleAssign}
      unassignAssets={handleUnassign}
    />
  );
};

export default AssignToSitelinkGroupModal;
