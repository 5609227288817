import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Box,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Slide,
  CircularProgress,
  Typography,
  Button,
  LinearProgress,
} from '@mui/material';
import ChooseMessageAI from './ChooseAIButton/ChooseMessageAI';
import { Close as CloseIcon } from '@mui/icons-material';
import { useFetchPromptPreviewQuery } from '../../slices/promptSlice';
import TextArea from '../StyledMUIComponents/TextArea';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import EditIcon from '@mui/icons-material/Edit';
import { AuthContext } from '../../contexts/Auth';
import { PromptMessage, PromptMessageType } from '../../types/conversations';
import ContentView from './ContentView';
import { AIModel, ContentType, PromptingButtonType } from '../../types/enums';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
import ConversationHistoryDrawer from './ConversationHistoryDrawer';
import EditPromptDrawer from './EditPromptDrawer';
import {
  useAppendMessagesToConversationMutation,
  useCreateConversationMutation,
  useDeleteConversationMessageMutation,
  useFetchConversationQuery,
  usePostConversationToAIMutation,
  useUpdateConversationNameMutation,
} from '../../slices/conversationSlice';
import { Prompt, useFetchPromptsQuery } from '../../slices/promptSlice';
import SelectPrompt from '../EditPromptContent/components/SelectPrompt';

interface InteractivePromptModalProps {
  open: boolean;
  handleClose: () => void;
  adGroupId: string;
  adGroupName: string;
  contentType: ContentType;
}

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const InteractivePromptingModal: React.FC<InteractivePromptModalProps> = ({
  open,
  handleClose,
  contentType,
  adGroupId,
  adGroupName,
}) => {
  const [currentUserPrompt, setCurrentUserPrompt] = useState('');
  const [currentPrompt, setCurrentPrompt] = useState<Prompt>();
  const [currentPromptId, setCurrentPromptId] = useState<string>('');
  const [selectedAIModel, setSelectedAIModel] = useState<AIModel>(
    AIModel.ChatGPT4o
  );
  const [conversationId, setConversationId] = useState('');
  const [promptMessages, setPromptMessages] = useState<PromptMessage[]>([]);
  const [showBasePrompt, setShowBasePrompt] = useState(false);
  const [conversationHistoryDrawerOpen, setConversationHistoryDrawerOpen] =
    useState(false);
  const [editPromptDrawerOpen, setEditPromptDrawerOpen] = useState(false);
  const [triggerScroll, setTiggerScroll] = useState(false);
  const endOfMessagesRef = useRef<HTMLDivElement>(null);
  const [updateName] = useUpdateConversationNameMutation();
  useEffect(() => {
    setCurrentPromptId(currentPrompt?._id || '');
  }, [currentPrompt]);
  useEffect(() => {
    if (endOfMessagesRef?.current) {
      endOfMessagesRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [promptMessages, triggerScroll]);

  const { user } = useContext(AuthContext);

  const {
    data = { system_prompt: '', user_prompt: '', temperature: 1 },
    isFetching,
  } = useFetchPromptPreviewQuery({
    adGroupId,
    contentType,
    promptId: currentPromptId,
  });
  const {
    data: prompts = {
      prompts: [],
      default_prompt_id: '',
    },
  } = useFetchPromptsQuery({
    promptName: contentType,
    adGroupId: adGroupId,
  });
  const {
    data: conversationData = {
      name: '',
      ad_group_id: '',
      content_type: '',
      messages: [],
    },
    isFetching: isFetchingConversation,
  } = useFetchConversationQuery(
    {
      conversationId: conversationId,
    },
    { skip: !conversationId }
  );
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(conversationData.name);

  const titleNameInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isEditingTitle) {
      titleNameInputRef?.current?.focus();
    }
  }, [isEditingTitle]);
  useEffect(() => {
    setEditedTitle(conversationData.name);
  }, [isFetchingConversation, conversationData.name]);

  const [editableSystemPrompt, setEditableSystemPrompt] = useState(
    data.system_prompt
  );
  const [editableUserPrompt, setEditableUserPrompt] = useState(
    data.user_prompt
  );
  const [editableTemperature, seteditableTemperature] = useState(
    data.temperature
  );

  const [deleteConversationMessage] = useDeleteConversationMessageMutation();
  const [postConversationToAI, { isLoading: isLoadingPostConversationToAI }] =
    usePostConversationToAIMutation();

  const [createConversation, { isLoading: isCreatingConversation }] =
    useCreateConversationMutation();
  const [appendMessagesToConversation] =
    useAppendMessagesToConversationMutation();

  useEffect(() => {
    if (conversationData.messages.length > 0) {
      setPromptMessages(conversationData.messages);
    }
  }, [conversationData]);

  useEffect(() => {
    setEditableSystemPrompt(data.system_prompt);
    setEditableUserPrompt(data.user_prompt);
    seteditableTemperature(data.temperature);
  }, [data]);

  const handlePostConversationToAI = useCallback(() => {
    postConversationToAI({
      conversationId: conversationId,
    });
  }, [postConversationToAI, conversationId]);

  useEffect(() => {
    const isLastPromptUserPrompt =
      promptMessages.slice(-1)[0]?.role === PromptMessageType.User;

    if (isLastPromptUserPrompt) {
      setCurrentUserPrompt('');
      handlePostConversationToAI();
    }
  }, [promptMessages, handlePostConversationToAI]);

  const handleStartConversation = () => {
    createConversation({
      adGroupId,
      contentType,
      messages: [
        { role: PromptMessageType.System, content: editableSystemPrompt },
        { role: PromptMessageType.User, content: editableUserPrompt },
      ],
      temperature: editableTemperature,
      preferredAIModel: selectedAIModel,
      name: adGroupName,
      createdBy: user?.userID || 0,
      createdByName: user?.name || 'unknown',
    })
      .unwrap()
      .then((res: any) => {
        setShowBasePrompt(false);
        setConversationId(res.id);
      });
  };
  const handleSetPrompt = (promptData: Prompt) => {
    setCurrentPromptId(promptData?._id);
    setCurrentPrompt(promptData);
  };
  const handleAddUserMessageToConversation = (model: AIModel) => {
    appendMessagesToConversation({
      conversationId: conversationId,
      messages: [
        {
          role: PromptMessageType.User,
          content: currentUserPrompt,
          model,
        },
      ],
    });
    setCurrentUserPrompt('');
  };

  const handleRemoveConversationMessage = (messageIndex: number) => {
    deleteConversationMessage({
      conversationId: conversationId,
      messageIndex: messageIndex,
    });
  };

  const handleSaveTitleName = () => {
    updateName({ name: editedTitle, conversationId });
    setIsEditingTitle(false);
  };
  const checkForTitleEnterKey = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      handleSaveTitleName();
    }
  };

  const checkForEnterKey = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleAddUserMessageToConversation(selectedAIModel);
    }
  };

  const handleSetConversationId = (id: string) => {
    setConversationId(id);
    if (!id) {
      setPromptMessages([]);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        TransitionComponent={Transition}
        scroll="paper"
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          sx={{ bgcolor: 'primary.main', color: 'white' }}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
          id="draggable-dialog-title"
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              {isEditingTitle ? (
                <input
                  style={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: '2.125rem',
                    lineHeight: 1.235,
                    fontWeight: 400,
                    width: '100%',
                  }}
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  ref={titleNameInputRef}
                  onBlur={handleSaveTitleName}
                  onKeyDown={checkForTitleEnterKey}
                />
              ) : (
                <>
                  <Typography variant="h4">{conversationData?.name}</Typography>
                  {conversationId && (
                    <IconButton
                      sx={{ color: 'white' }}
                      aria-label="Edit"
                      onClick={() => setIsEditingTitle(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </>
              )}
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 300 }}>
              {contentType.slice(0, 1).toUpperCase() +
                contentType.slice(1, contentType.length)}{' '}
              for {adGroupName}
            </Typography>
          </Box>
          <IconButton
            sx={{ color: 'white' }}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box display="flex">
              {!conversationId && (
                <Button
                  sx={{ flexGrow: 1 }}
                  variant="contained"
                  color="success"
                  startIcon={<WysiwygIcon />}
                  onClick={() => setShowBasePrompt(!showBasePrompt)}
                >
                  Show Initial Messages
                </Button>
              )}
              <Button
                sx={{ flexGrow: 1 }}
                variant="contained"
                color="primary"
                onClick={() => setEditPromptDrawerOpen((prev) => !prev)}
              >
                Show / Edit Prompts
              </Button>
              <Button
                sx={{ flexGrow: 1 }}
                variant="contained"
                color="secondary"
                onClick={() =>
                  setConversationHistoryDrawerOpen((prev) => !prev)
                }
              >
                Show Conversation History
              </Button>
            </Box>

            <Box
              sx={{
                boxShadow: 24,
                p: 4,
                gridTemplateRows: 'repeat(3, 1fr)',
                position: 'relative',
                width: '100%',
                minHeight: '600px',
                flexGrow: 1,
              }}
            >
              {isFetching ? (
                <Grid
                  spacing={2}
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  marginY="40px"
                  height="100%"
                >
                  <Typography variant="h6">
                    Loading prompts please wait
                  </Typography>
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  {showBasePrompt && (
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Temperature: {data.temperature}
                        </Typography>
                      </Grid>
                      <Divider />
                      <Typography variant="h6">System Prompt:</Typography>
                      <TextArea
                        name="system_prompt"
                        minRows={4}
                        maxRows={20}
                        style={{ width: '100%' }}
                        value={editableSystemPrompt}
                        onChange={(e) => {
                          setEditableSystemPrompt(e.target.value);
                        }}
                      />

                      <Divider />
                      <Typography variant="h6">User Prompt:</Typography>
                      <TextArea
                        name="user_prompt"
                        minRows={4}
                        maxRows={20}
                        style={{ width: '100%' }}
                        value={editableUserPrompt}
                        onChange={(e) => {
                          setEditableUserPrompt(e.target.value);
                        }}
                      />
                    </Grid>
                  )}
                  {promptMessages.length === 0 && (
                    <Grid
                      spacing={2}
                      container
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      marginY="40px"
                      height="100%"
                    >
                      {isCreatingConversation ? (
                        <>
                          <Typography variant="h6">
                            'Starting Conversation please wait'
                          </Typography>
                          <CircularProgress />
                        </>
                      ) : (
                        <Box>
                          <Typography variant="h6">
                            'No conversation started yet'
                          </Typography>
                          <Typography variant="h6">
                            Select a prompt to start a conversation:
                          </Typography>
                          <SelectPrompt
                            promptName={contentType}
                            prompts={prompts.prompts}
                            setPrompt={handleSetPrompt}
                            selectedPromptId={
                              currentPromptId || prompts?.default_prompt_id
                            }
                          />
                        </Box>
                      )}
                    </Grid>
                  )}
                  {isFetchingConversation && (
                    <Box width="100%" display="flex" justifyContent="center">
                      <LinearProgress />
                    </Box>
                  )}
                  {promptMessages.length > 0 && (
                    <>
                      {promptMessages.map((message, index) => (
                        <ContentView
                          key={`${conversationId}_${index}`}
                          content={message}
                          contentType={contentType}
                          adGroupId={adGroupId}
                          messageIndex={index}
                          conversationId={conversationId}
                          handleDelete={handleRemoveConversationMessage}
                          triggerScroll={() => setTiggerScroll((prev) => !prev)}
                        />
                      ))}
                    </>
                  )}
                  {isLoadingPostConversationToAI && <LinearProgress />}
                </>
              )}
              {(contentType === ContentType.PriceAsset ||
                contentType === ContentType.Sitelink) && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <p>
                    Ensure that global assets have been created to pull from.
                  </p>
                </Box>
              )}
              <Box sx={{ marginTop: 'auto' }}>
                {promptMessages.length === 0 ? (
                  <Grid
                    spacing={2}
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: '100%' }}
                  >
                    <Grid item>
                      {!isFetching && (
                        <>
                          <ChooseMessageAI
                            handleSetModel={setSelectedAIModel}
                            selectedAIModel={selectedAIModel}
                            handleClick={() => handleStartConversation()}
                            disabled={false}
                            buttonType={PromptingButtonType.StartConversation}
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid spacing={2} container>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                      <TextArea
                        name="user_prompt1"
                        minRows={1}
                        maxRows={100}
                        style={{ width: '100%' }}
                        value={currentUserPrompt}
                        onChange={(e) => setCurrentUserPrompt(e.target.value)}
                        onKeyDown={checkForEnterKey}
                      />
                      <ChooseMessageAI
                        handleSetModel={setSelectedAIModel}
                        selectedAIModel={selectedAIModel}
                        disabled={!currentUserPrompt}
                        handleClick={() =>
                          handleAddUserMessageToConversation(selectedAIModel)
                        }
                        buttonType={PromptingButtonType.AskAI}
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
              <div ref={endOfMessagesRef} />
            </Box>
          </Box>
        </DialogContent>
        <Dialog open={conversationHistoryDrawerOpen || editPromptDrawerOpen}>
          <ConversationHistoryDrawer
            drawerOpen={conversationHistoryDrawerOpen}
            setDrawerOpen={setConversationHistoryDrawerOpen}
            adGroupId={adGroupId}
            contentType={contentType}
            setConversationId={(value) => {
              handleSetConversationId(value);
              setShowBasePrompt(false);
            }}
          />
          <EditPromptDrawer
            drawerOpen={editPromptDrawerOpen}
            setDrawerOpen={setEditPromptDrawerOpen}
            adGroupId={adGroupId}
            contentType={contentType}
          />
        </Dialog>
      </Dialog>
    </>
  );
};

export default React.memo(InteractivePromptingModal);
