import React from 'react';
import {
  Typography,
  InputAdornment,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { Error as ErrorIcon } from '@mui/icons-material';

import { useGridApiContext } from '@mui/x-data-grid-premium';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
  },
}));

function TableCellWithValidation(props: GridRenderEditCellParams) {
  const { id, value, field, hasFocus, validationSchema, limit = null } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();
  const [error, setError] = React.useState<string>('');

  React.useLayoutEffect(() => {
    if (ref.current && hasFocus) {
      // @ts-ignore
      ref.current.focus();
    }
  }, [hasFocus]);

  React.useEffect(() => {
    validationSchema
      .validate(value)
      .then(() => setError(''))
      .catch((err: any) => setError(err.message));
  }, [value, field, setError, validationSchema]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    const isFieldEligibleForDynamicKeyword =
      field === 'headline' || field === 'description';
    const isDeleting = newValue.length < value.length;
    const hasKeywordInsertion = !!newValue.match(/{keyword:/i);
    let isAutoCompleting = false;
    let newCursorIndex = 0;

    const addedChar = !isDeleting ? findAddedChar(newValue) : undefined;
    const brackIndex = newValue.indexOf('{');

    if (addedChar === '{' && value.indexOf('{') !== -1) {
      return;
    } else if (addedChar === '}' && value.indexOf('}') !== -1) {
      return;
    }

    if (brackIndex !== -1 && isFieldEligibleForDynamicKeyword) {
      if (addedChar === '{' && !hasKeywordInsertion) {
        newValue =
          newValue.slice(0, brackIndex) +
          '{KeyWord:' +
          newValue.slice(brackIndex + 1) +
          '}';

        isAutoCompleting = true;
        newCursorIndex = brackIndex + 9;
      } else if (addedChar === '}') {
        newValue = newValue.slice(0, brackIndex) + newValue.slice(brackIndex);
      }
    }

    if (
      replaceDynamicKeywordText(newValue).length <= limit ||
      !limit ||
      isDeleting
    ) {
      const res = apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue,
      });

      if (res) {
        res.then(() => {
          if (isAutoCompleting && newCursorIndex) {
            event.target.setSelectionRange(newCursorIndex, newCursorIndex);
          }
        });
      }
    }
  };

  const findAddedChar = (newValue: string) => {
    for (let i = 0; i < newValue.length; i++) {
      if (newValue[i] !== value[i]) {
        return newValue[i];
      }
    }
  };

  const replaceDynamicKeywordText = (text: string) => {
    return text.replace(/{KeyWord:|}/gi, '');
  };

  return (
    <HtmlTooltip
      title={
        <Box display="flex" alignItems="center">
          <ErrorIcon color="error" sx={{ mr: 0.5 }} />
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        </Box>
      }
      arrow
      open={!!error}
      placement="top"
    >
      <TextField
        size="small"
        required
        InputProps={{
          ref,
          endAdornment: (
            <InputAdornment position="end">
              {limit !== null ? (
                <>
                  {replaceDynamicKeywordText(value).length}/{limit}
                </>
              ) : null}
            </InputAdornment>
          ),
        }}
        fullWidth
        value={value}
        onChange={handleValueChange}
      />
    </HtmlTooltip>
  );
}

export default TableCellWithValidation;
