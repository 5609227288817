import React from 'react';
import { GridRowId } from '@mui/x-data-grid-premium';
import AssignToAssetGroupModal, {
  AssignToSpecificAssetGroupModalProps,
} from '../AssignToAssetGroupModal';
import {
  useFetchAllPriceAssetGroupsQuery,
  useAssignPriceAssetsToPriceAssetGroupsMutation,
  useUnassignPriceAssetsFromPriceAssetGroupsMutation,
} from '../../slices/priceAssetGroupSlice';

const AssignToPriceAssetGroupModal: React.FC<
  AssignToSpecificAssetGroupModalProps
> = ({ setModalOpen, assetIds, assetType }) => {
  const [assignPriceAssets] = useAssignPriceAssetsToPriceAssetGroupsMutation();
  const [unassignPriceAssets] =
    useUnassignPriceAssetsFromPriceAssetGroupsMutation();
  const defaultData = { total: 0, results: [] };
  const { data = defaultData, isFetching } = useFetchAllPriceAssetGroupsQuery();
  const handleAssign = (assetIds: GridRowId[], assetGroupIds: GridRowId[]) => {
    return assignPriceAssets({
      priceAssetIds: assetIds,
      priceAssetGroupIds: assetGroupIds,
    }).unwrap();
  };
  const handleUnassign = (
    assetIds: GridRowId[],
    assetGroupIds: GridRowId[]
  ) => {
    return unassignPriceAssets({
      priceAssetIds: assetIds,
      priceAssetGroupIds: assetGroupIds,
    }).unwrap();
  };
  return (
    <AssignToAssetGroupModal
      setModalOpen={setModalOpen}
      assetIds={assetIds}
      assetType={assetType}
      data={data}
      isFetching={isFetching}
      assignAssets={handleAssign}
      unassignAssets={handleUnassign}
    />
  );
};

export default AssignToPriceAssetGroupModal;
