import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import NotesIcon from '@mui/icons-material/Notes';
import LinkIcon from '@mui/icons-material/Link';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PsychologyIcon from '@mui/icons-material/Psychology';
import DescriptionIcon from '@mui/icons-material/Description';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsIcon from '@mui/icons-material/Settings';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { NavLink } from 'react-router-dom';

interface NavListItemsProps {
  open: boolean;
}

const NavListItems: React.FC<NavListItemsProps> = ({ open }) => {
  return (
    <List component="nav">
      <NavLink
        to="/"
        className={({ isActive, isPending, isTransitioning }) =>
          isActive || isPending || isTransitioning ? 'activeLink link' : 'link'
        }
      >
        {({ isActive, isPending, isTransitioning }) => (
          <ListItemButton
            className={
              isActive || isPending || isTransitioning
                ? 'activeLink link'
                : 'link'
            }
          >
            <ListItemIcon className={isActive ? 'activeIcon' : ''}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        )}
      </NavLink>
      <NavLink
        to="/bid-management-tool"
        className={({ isActive, isPending, isTransitioning }) =>
          isActive || isPending || isTransitioning ? 'activeLink link' : 'link'
        }
      >
        {({ isActive, isPending, isTransitioning }) => (
          <ListItemButton
            className={
              isActive || isPending || isTransitioning
                ? 'activeLink link'
                : 'link'
            }
          >
            <ListItemIcon className={isActive ? 'activeIcon' : ''}>
              <RequestQuoteIcon />
            </ListItemIcon>
            <ListItemText primary="Bid Management Tool" />
          </ListItemButton>
        )}
      </NavLink>
      <NavLink
        to="/global-settings"
        className={({ isActive, isPending, isTransitioning }) =>
          isActive || isPending || isTransitioning ? 'activeLink link' : 'link'
        }
      >
        {({ isActive, isPending, isTransitioning }) => (
          <ListItemButton
            className={
              isActive || isPending || isTransitioning
                ? 'activeLink link'
                : 'link'
            }
          >
            <ListItemIcon className={isActive ? 'activeIcon' : ''}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Global Settings" />
          </ListItemButton>
        )}
      </NavLink>
      <Divider />
      <List
        subheader={
          <ListSubheader
            className={open ? 'no-select' : 'no-select closed-subheader'}
            component="div"
          >
            Prompt Tools
          </ListSubheader>
        }
      />
      <NavLink
        className={({ isActive }) => (isActive ? 'activeLink link' : 'link')}
        to="/word-group-prompts"
      >
        {({ isActive, isPending, isTransitioning }) => (
          <ListItemButton
            className={
              isActive || isPending || isTransitioning
                ? 'activeLink link'
                : 'link'
            }
          >
            <ListItemIcon className={isActive ? 'activeIcon' : ''}>
              <WorkspacesIcon />
            </ListItemIcon>
            <ListItemText primary="Word Group Prompts" />
          </ListItemButton>
        )}
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? 'activeLink link' : 'link')}
        to="/word-group-category-prompts"
      >
        {({ isActive, isPending, isTransitioning }) => (
          <ListItemButton
            className={
              isActive || isPending || isTransitioning
                ? 'activeLink link'
                : 'link'
            }
          >
            <ListItemIcon className={isActive ? 'activeIcon' : ''}>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary="Word Group Category Prompts" />
          </ListItemButton>
        )}
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? 'activeLink link' : 'link')}
        to="/category-combo-prompts"
      >
        {({ isActive, isPending, isTransitioning }) => (
          <ListItemButton
            className={
              isActive || isPending || isTransitioning
                ? 'activeLink link'
                : 'link'
            }
          >
            <ListItemIcon className={isActive ? 'activeIcon' : ''}>
              <NotesIcon />
            </ListItemIcon>
            <ListItemText primary="Category Combo Prompts" />
          </ListItemButton>
        )}
      </NavLink>

      <Divider />
      <List
        subheader={
          <ListSubheader
            className={open ? 'no-select' : 'no-select closed-subheader'}
            component="div"
          >
            Global Assets
          </ListSubheader>
        }
      >
        <NavLink
          className={({ isActive }) => (isActive ? 'activeLink link' : 'link')}
          to="/headline-tool"
        >
          {({ isActive, isPending, isTransitioning }) => (
            <ListItemButton
              className={
                isActive || isPending || isTransitioning
                  ? 'activeLink link'
                  : 'link'
              }
            >
              <ListItemIcon className={isActive ? 'activeIcon' : ''}>
                <PsychologyIcon />
              </ListItemIcon>
              <ListItemText primary="Headline Tool" />
            </ListItemButton>
          )}
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? 'activeLink link' : 'link')}
          to="/description-tool"
        >
          {({ isActive, isPending, isTransitioning }) => (
            <ListItemButton
              className={
                isActive || isPending || isTransitioning
                  ? 'activeLink link'
                  : 'link'
              }
            >
              <ListItemIcon className={isActive ? 'activeIcon' : ''}>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Description Tool" />
            </ListItemButton>
          )}
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? 'activeLink link' : 'link')}
          to="/sitelink-tool"
        >
          {({ isActive, isPending, isTransitioning }) => (
            <ListItemButton
              className={
                isActive || isPending || isTransitioning
                  ? 'activeLink link'
                  : 'link'
              }
            >
              <ListItemIcon className={isActive ? 'activeIcon' : ''}>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="Sitelink Tool" />
            </ListItemButton>
          )}
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? 'activeLink link' : 'link')}
          to="/price-asset-tool"
        >
          {({ isActive, isPending, isTransitioning }) => (
            <ListItemButton
              className={
                isActive || isPending || isTransitioning
                  ? 'activeLink link'
                  : 'link'
              }
            >
              <ListItemIcon className={isActive ? 'activeIcon' : ''}>
                <PriceChangeIcon />
              </ListItemIcon>
              <ListItemText primary="Price Asset Tool" />
            </ListItemButton>
          )}
        </NavLink>
      </List>
    </List>
  );
};
export default NavListItems;
