import React, { useState } from 'react';
import {
  DataGridPremium,
  GridFilterModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridRowId,
  GridRenderCellParams,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {
  Card,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  PaperProps,
  Button,
} from '@mui/material';
import { useFetchAdGroupsQuery } from '../../slices/adGroupSlice';
import {
  useAssignSitelinksToAdgroupMutation,
  useUnassignSitelinksFromAdgroupMutation,
} from '../../slices/sitelinkSlice';
import {
  useAssignPriceAssetsToAdgroupMutation,
  useUnassignPriceAssetsFromAdgroupMutation,
} from '../../slices/priceAssetSlice';
import {
  useAssignHeadlinesToAdgroupMutation,
  useUnassignHeadlinesFromAdgroupMutation,
} from '../../slices/headlineSlice';
import {
  useAssignDescriptionsToAdgroupMutation,
  useUnassignDescriptionsFromAdgroupMutation,
} from '../../slices/descriptionSlice';
import {
  useAssignHeadlineGroupsToAdGroupsMutation,
  useUnassignHeadlineGroupsFromAdGroupsMutation,
} from '../../slices/headlineGroupSlice';
import {
  useAssignDescriptionGroupsToAdGroupsMutation,
  useUnassignDescriptionGroupsFromAdGroupsMutation,
} from '../../slices/descriptionGroupSlice';
import {
  useAssignSitelinkGroupsToAdGroupsMutation,
  useUnassignSitelinkGroupsFromAdGroupsMutation,
} from '../../slices/sitelinkGroupSlice';
import {
  useAssignPriceAssetGroupsToAdGroupsMutation,
  useUnassignPriceAssetGroupsFromAdGroupsMutation,
} from '../../slices/priceAssetGroupSlice';
import { useFetchFiltersQuery } from '../../slices/filterSlice';
import {
  getExclusiveFilterOperator,
  getInclusiveFilterOperator,
} from '../../components/MultiselectInputValue';
import {
  filterColumns,
  getColumnForNewFilter,
} from '../../utils/filterColumns';
import Draggable from 'react-draggable';
import AssignedCell from '../TableCells/AssignedCell';
import getNumberFilterOperators from '../../utils/getNumberFilterOperators';
import { GlobalAssetType, MaxAllowableContent } from '../../types/enums';

interface FilterToolbarProps {
  assetIds: GridRowId[];
  adgroupIds: GridRowId[];
  setModalOpen: (val: boolean) => void;
  assetType: GlobalAssetType;
}

const FilterToolbar: React.FC<FilterToolbarProps> = ({
  assetIds,
  adgroupIds,
  setModalOpen,
  assetType,
}) => {
  const countToUpdate = adgroupIds?.length || 0;
  const [assignSitelinks] = useAssignSitelinksToAdgroupMutation();
  const [unassignSitelinks] = useUnassignSitelinksFromAdgroupMutation();
  const [assignPriceAssets] = useAssignPriceAssetsToAdgroupMutation();
  const [unassignPriceAssets] = useUnassignPriceAssetsFromAdgroupMutation();
  const [assignHeadlines] = useAssignHeadlinesToAdgroupMutation();
  const [unassignHeadlines] = useUnassignHeadlinesFromAdgroupMutation();
  const [assignDescriptions] = useAssignDescriptionsToAdgroupMutation();
  const [unassignDescriptions] = useUnassignDescriptionsFromAdgroupMutation();
  const [assignHeadlineGroups] = useAssignHeadlineGroupsToAdGroupsMutation();
  const [unassignHeadlineGroups] =
    useUnassignHeadlineGroupsFromAdGroupsMutation();
  const [assignDescriptionGroups] =
    useAssignDescriptionGroupsToAdGroupsMutation();
  const [unassignDescriptionGroups] =
    useUnassignDescriptionGroupsFromAdGroupsMutation();
  const [assignSitelinkGroups] = useAssignSitelinkGroupsToAdGroupsMutation();
  const [unassignSitelinkGroups] =
    useUnassignSitelinkGroupsFromAdGroupsMutation();
  const [assignPriceAssetGroups] =
    useAssignPriceAssetGroupsToAdGroupsMutation();
  const [unassignPriceAssetGroups] =
    useUnassignPriceAssetGroupsFromAdGroupsMutation();

  const getAssignAction = async (
    assetType: GlobalAssetType,
    assetIds: GridRowId[],
    adGroupIds: GridRowId[]
  ) => {
    switch (assetType) {
      case GlobalAssetType.Sitelink:
        return await assignSitelinks({
          sitelinkIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.PriceAsset:
        return assignPriceAssets({
          priceAssetIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.PrimaryHeadline:
      case GlobalAssetType.GeneralHeadline:
        return assignHeadlines({ headlineIds: assetIds, adGroupIds }).unwrap();
      case GlobalAssetType.Description:
        return assignDescriptions({
          descriptionIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.HeadlineGroup:
        return assignHeadlineGroups({
          headlineGroupIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.DescriptionGroup:
        return assignDescriptionGroups({
          descriptionGroupIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.SitelinkGroup:
        return await assignSitelinkGroups({
          sitelinkGroupIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.PriceAssetGroup:
        return await assignPriceAssetGroups({
          priceAssetGroupIds: assetIds,
          adGroupIds,
        }).unwrap();
    }
  };

  const getUnassignAction = async (
    assetType: GlobalAssetType,
    assetIds: GridRowId[],
    adGroupIds: GridRowId[]
  ) => {
    switch (assetType) {
      case GlobalAssetType.Sitelink:
        return await unassignSitelinks({
          sitelinkIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.PriceAsset:
        return await unassignPriceAssets({
          priceAssetIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.PrimaryHeadline:
      case GlobalAssetType.GeneralHeadline:
        return await unassignHeadlines({
          headlineIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.Description:
        return await unassignDescriptions({
          descriptionIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.HeadlineGroup:
        return await unassignHeadlineGroups({
          headlineGroupIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.DescriptionGroup:
        return await unassignDescriptionGroups({
          descriptionGroupIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.SitelinkGroup:
        return await unassignSitelinkGroups({
          sitelinkGroupIds: assetIds,
          adGroupIds,
        }).unwrap();
      case GlobalAssetType.PriceAssetGroup:
        return await unassignPriceAssetGroups({
          priceAssetGroupIds: assetIds,
          adGroupIds,
        }).unwrap();
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      {!!countToUpdate && (
        <>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() =>
              getAssignAction(assetType, assetIds, adgroupIds)
                .then(() => {
                  enqueueSnackbar(`${assetType} assigned successfully`, {
                    variant: 'success',
                  });
                  setModalOpen(false);
                })
                .catch(() => {
                  enqueueSnackbar('Something went wrong', {
                    variant: 'error',
                  });
                })
            }
          >
            Assign To {countToUpdate} AdGroups
          </Button>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() =>
              getUnassignAction(assetType, assetIds, adgroupIds)
                .then(() => {
                  enqueueSnackbar(`${assetType} unassigned successfully`, {
                    variant: 'success',
                  });
                  setModalOpen(false);
                })
                .catch(() => {
                  enqueueSnackbar('Something went wrong', {
                    variant: 'error',
                  });
                })
            }
          >
            Unassign From {countToUpdate} AdGroups
          </Button>
        </>
      )}
    </GridToolbarContainer>
  );
};

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

interface AssignToAdGroupModalProps {
  setModalOpen: (val: boolean) => void;
  assetIds: GridRowId[];
  assetType: GlobalAssetType;
  maxAllowableContent: MaxAllowableContent;
}

const AssignToAdGroupModal: React.FC<AssignToAdGroupModalProps> = ({
  setModalOpen,
  assetIds,
  assetType,
}) => {
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 24,
  });

  const [queryOptions, setQueryOptions] = useState<{
    filterModel?: GridFilterModel;
  }>();

  const { data = { total: 0, results: [] }, isFetching } =
    useFetchAdGroupsQuery({
      ...paginationModel,
      ...queryOptions,
      showApproved: false,
      showEnabled: false,
      showInactive: false,
      showOutOfDate: false,
      includesIds: assetIds,
    });

  const {
    data: filters = {
      word_group_filters: [],
      word_combination_filters: [],
      word_group_category_filters: [],
    },
  } = useFetchFiltersQuery({});
  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const getFieldNames = (
    assetType: GlobalAssetType
  ): [string, string, string] | [string, string] => {
    switch (assetType) {
      case GlobalAssetType.Sitelink:
        return [
          'sitelinks_count',
          'sitelinks_approved',
          'sitelinks_is_assigned',
        ];
      case GlobalAssetType.PriceAsset:
        return [
          'price_assets_count',
          'price_assets_approved',
          'price_assets_is_assigned',
        ];
      case GlobalAssetType.GeneralHeadline:
        if (assetIds.length === 1) {
          return [
            'headlines_count',
            'headlines_approved',
            'headlines_is_assigned',
          ];
        }
        return ['headlines_count', 'headlines_approved'];
      case GlobalAssetType.PrimaryHeadline:
        return [
          'headlines_count',
          'headlines_approved',
          'headlines_is_assigned',
        ];
      case GlobalAssetType.Description:
        return [
          'descriptions_count',
          'descriptions_approved',
          'descriptions_is_assigned',
        ];
      case GlobalAssetType.HeadlineGroup:
        return [
          'headline_groups',
          'headline_groups',
          'headline_group_is_assigned',
        ];
      case GlobalAssetType.DescriptionGroup:
        return [
          'description_groups',
          'description_groups',
          'description_group_is_assigned',
        ];
      case GlobalAssetType.SitelinkGroup:
        return [
          'sitelink_groups',
          'sitelink_groups',
          'sitelink_group_is_assigned',
        ];
      case GlobalAssetType.PriceAssetGroup:
        return [
          'price_asset_groups',
          'price_asset_groups',
          'price_asset_group_is_assigned',
        ];
    }
  };

  const [assetCount, assetApproved, assetIsAssigned] = getFieldNames(assetType);

  const columns = [
    {
      field: 'adgroup_name',
      headerName: 'Ad Group',
      flex: 1,
      filterOperators: [
        ...getInclusiveFilterOperator(filters.word_combination_filters),
        ...getExclusiveFilterOperator(filters.word_combination_filters),
      ],
    },
    {
      field: assetCount,
      width: 104,
      type: 'number',
      headerName: '# Approved',
      filterOperators: getNumberFilterOperators(),
      valueGetter: (params: GridValueGetterParams) => {
        if (assetCount.includes('groups')) {
          return params.row[assetCount].length;
        }
        return params.row[assetCount];
      },
    },
    {
      field: 'word_category_combination',
      headerName: 'Word Category',
      filterOperators: [
        ...getExclusiveFilterOperator(filters.word_group_category_filters),
        ...getInclusiveFilterOperator(filters.word_group_category_filters),
      ],
      flex: 1,
    },
    {
      field: assetIsAssigned as string,
      width: 88,
      headerName: 'Assigned',
      type: 'boolean',
      filterable: true,
      valueGetter: (params: GridValueGetterParams) => {
        for (const assetId of assetIds) {
          if (assetCount.includes('groups')) {
            return params.row[assetApproved].includes(assetId.toString());
          }
          return Object.keys(params.row[assetApproved]).includes(
            assetId.toString()
          );
        }
      },
      renderCell: (params: GridRenderCellParams) => (
        <AssignedCell params={params} />
      ),
    },
  ];

  const getColumns = () => {
    return assetIds.length === 1 ? columns : columns.slice(0, 3);
  };

  return (
    <>
      <Dialog
        open
        onClose={() => setModalOpen(false)}
        fullWidth
        maxWidth={'md'}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          sx={{ bgcolor: 'primary.main', color: 'white' }}
          style={{
            cursor: 'move',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
          id="draggable-dialog-title"
        >
          Assign {assetIds.length} To AdGroups - {assetType}
          <IconButton
            sx={{ color: 'white' }}
            aria-label="close"
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card sx={{ overflow: 'auto' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ width: '100%' }}>
                  <DataGridPremium
                    apiRef={apiRef}
                    checkboxSelection
                    autoHeight={true}
                    rows={data.results}
                    columns={getColumns()}
                    rowCount={data.total}
                    paginationMode="server"
                    loading={isFetching}
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    pageSizeOptions={[24, 48, 96, 200, 500, 1000, 2000]}
                    pagination={true}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    onRowSelectionModelChange={(selections) =>
                      setSelectedIds(selections)
                    }
                    slots={{ toolbar: FilterToolbar }}
                    slotProps={{
                      filterPanel: {
                        filterFormProps: {
                          filterColumns: (params) =>
                            filterColumns({
                              ...params,
                              uniqueFilterColumns: [
                                'adgroup_name',
                                'word_category_combination',
                              ],
                            }),
                        },
                        sx: {
                          '& .MuiDataGrid-filterFormValueInput   ': {
                            width: '500px',
                          },
                          position: 'absolute',
                          top: `-110px !important`,
                          left: '105px',
                          backgroundColor: '#fff',
                          border: '1px solid #ccc',
                        },
                        getColumnForNewFilter: (params) =>
                          getColumnForNewFilter({
                            ...params,
                            uniqueFilterColumns: [
                              'adgroup_name',
                              'word_category_combination',
                            ],
                          }),

                        onKeyDown: (event: KeyboardEvent) => {
                          if (
                            event.key === 'Escape' ||
                            (event.key === 'Enter' && !event.shiftKey)
                          ) {
                            apiRef.current.hideFilterPanel();
                          }
                        },
                      },
                      toolbar: {
                        assetIds,
                        adgroupIds: selectedIds,
                        setModalOpen,
                        assetType,
                      },
                    }}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Card>
      </Dialog>
    </>
  );
};
export default AssignToAdGroupModal;
