import { GlobalAssetType } from '../types/enums';
export const formatReadableGlobalAssetType = (
  assetType: GlobalAssetType,
  count: number = 0
) => {
  switch (assetType) {
    case GlobalAssetType.GeneralHeadline:
      return count > 1 ? 'Headlines' : 'Headline';
    case GlobalAssetType.PrimaryHeadline:
      return count > 1 ? 'Headlines' : 'Headline';
    case GlobalAssetType.Description:
      return count > 1 ? 'Descriptions' : 'Description';
    case GlobalAssetType.PriceAsset:
      return count > 1 ? 'Price Assets' : 'Price Asset';
    case GlobalAssetType.Sitelink:
      return count > 1 ? 'Sitelinks' : 'Sitelink';
    case GlobalAssetType.DescriptionGroup:
      return count > 1 ? 'Description Groups' : 'Description Group';
    case GlobalAssetType.HeadlineGroup:
      return count > 1 ? 'Headline Groups' : 'Headline Group';
    case GlobalAssetType.PriceAssetGroup:
      return count > 1 ? 'Price Asset Groups' : 'Price Asset Group';
    case GlobalAssetType.SitelinkGroup:
      return count > 1 ? 'Sitelink Groups' : 'Sitelink Group';
  }
};
