import React, { useCallback, useState, useEffect } from 'react';
import { Typography, Grid, Button, IconButton } from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { Circle as CircleIcon, Add as AddIcon } from '@mui/icons-material';
import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModesModel,
  GridToolbarContainer,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import { GridRowId } from '@mui/x-data-grid-premium';
import { GridRowModes } from '@mui/x-data-grid-premium';
import { GridEventListener } from '@mui/x-data-grid-premium';

import DynamicPromptModal from '../../../DynamicPromptModal';

import {
  useRegenerateDescriptionsMutation,
  useApproveDescriptionsMutation,
  useUnapproveDescriptionsMutation,
  useFetchDescriptionsQuery,
  useDeleteDescriptionsMutation,
  useUpdateDescriptionMutation,
  useRegenerateAllDescriptionsMutation,
  useCreateDescriptionsMutation,
  useUnassignDescriptionsFromAdgroupMutation,
  useConvertDescriptionsToGlobalMutation,
} from '../../../../slices/descriptionSlice';
import ApprovedCell from '../table/ApprovedCell';
import ButtonFooter from '../table/ButtonFooter';
import { getActions } from '../../../../helpers/getActions';
import { GridRowsProp } from '@mui/x-data-grid-premium';
import { getStatusColor } from '../../../../helpers/getStatusColor';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import PinDropdown from '../../../TableCells/PinDropdown';
import PinCell from '../../../TableCells/PinCell';
import getFilteredSelectedPins from '../../../../helpers/getFilteredSelectedPins';
import getOrderedRows from '../../../../helpers/getOrderedRows';
import { descriptionValidationSchema } from '../../../../utils/validation';
import buildCharacterCountColumn from '../../../../utils/buildCharacterCountColumn';
import { CharacterLimitForContent, PinType } from '../../../../types/enums';
import buildTextWithCharacterCountColumn from '../../../../utils/buildTextWithCharacterCountColumn';
import { getApprovedCount } from '../../../../utils/getApprovedCount';
import { Approvable } from '../../../../types/adGroups';
import { Description } from '../../../../types/pinnable';
import { useSnackbar } from 'notistack';

interface DataRow extends Description {
  isNew?: boolean;
}

interface EditToolbarProps {
  approvedCount: number;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, approvedCount } = props;

  const handleClick = () => {
    const id = Math.floor(Math.random() * 1000000000);
    setRows((oldRows) => [
      ...oldRows,
      { id, approved: false, description: '', isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'description' },
    }));
  };

  if (approvedCount >= 4) {
    return null;
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add manual description
      </Button>
    </GridToolbarContainer>
  );
}

const DescriptionsSection: React.FC<{
  ad_group_id: string;
  togglePromptPreview: () => void;
}> = ({ ad_group_id, togglePromptPreview }) => {
  const [promptModalOpen, setPromptModalOpen] = React.useState(false);
  const { data, isFetching } = useFetchDescriptionsQuery(ad_group_id);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [currentDescriptions, setCurrentDescriptions] = useState<DataRow[]>([]);
  const [updateDescription] = useUpdateDescriptionMutation();
  const [createDescriptions] = useCreateDescriptionsMutation();
  const [deleteDescriptions] = useDeleteDescriptionsMutation();
  const [unapproveDescriptions] = useUnapproveDescriptionsMutation();
  const [approveDescriptions] = useApproveDescriptionsMutation();
  const [unassignDescriptions] = useUnassignDescriptionsFromAdgroupMutation();
  const [convertToGlobal] = useConvertDescriptionsToGlobalMutation();
  const [regenerateDescriptions, { isLoading: isLoadingRegenerate }] =
    useRegenerateDescriptionsMutation();
  const [regenerateAllDescriptions, { isLoading: isLoadingRegenerateAll }] =
    useRegenerateAllDescriptionsMutation({
      fixedCacheKey: 'regenerateDescriptions',
    });
  const { enqueueSnackbar } = useSnackbar();

  const isLoading = isLoadingRegenerate || isLoadingRegenerateAll || isFetching;
  const approvedCount = getApprovedCount(currentDescriptions as Approvable[]);
  useEffect(() => {
    if (data) {
      setCurrentDescriptions(data);
    }
  }, [data]);

  const handleRegenerateClick = useCallback(
    (ids: GridRowId[]) => () => {
      regenerateDescriptions({
        ids,
        ad_group_id,
      });
    },
    [regenerateDescriptions, ad_group_id]
  );

  const handleRegenerateAllClick = useCallback(() => {
    regenerateAllDescriptions({
      ad_group_id,
    });
  }, [regenerateAllDescriptions, ad_group_id]);

  const handleApproveClick = useCallback(
    (ids: GridRowId[]) => () => {
      approveDescriptions({
        ids,
        ad_group_id,
      });
    },
    [approveDescriptions, ad_group_id]
  );

  const handleUnapproveClick = useCallback(
    (ids: GridRowId[]) => () => {
      unapproveDescriptions({
        ids,
        ad_group_id,
      });
    },
    [unapproveDescriptions, ad_group_id]
  );

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [setRowModesModel, rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [setRowModesModel, rowModesModel]
  );

  const doCreateDescriptions = useCallback(
    async (row: any) => {
      await createDescriptions({
        descriptions: [
          {
            description: row.description,
            pinned: row.pinned,
            is_global: false,
          },
        ],
        adgroup_id: ad_group_id,
      }).unwrap();
    },
    [createDescriptions, ad_group_id]
  );

  const handleConvertLocalClick = useCallback(
    (id: GridRowId, row: any) => async () => {
      await unassignDescriptions({
        descriptionIds: [id],
        adGroupIds: [ad_group_id],
      });
      await doCreateDescriptions(row);
      enqueueSnackbar('Price Asset successfully converted to Local', {
        variant: 'success',
      });
    },
    [doCreateDescriptions, enqueueSnackbar, unassignDescriptions, ad_group_id]
  );

  const handleConvertToGlobalClick = useCallback(
    (ids: GridRowId[]) => async () => {
      await convertToGlobal({
        descriptionIds: ids,
        ad_group_id,
      });
      enqueueSnackbar('Description successfully converted to Global', {
        variant: 'success',
      });
    },
    [convertToGlobal, enqueueSnackbar, ad_group_id]
  );

  const handleDeleteClick = useCallback(
    (ids: GridRowId[]) => async () => {
      const rows = currentDescriptions.filter((row) => ids.includes(row.id));
      const idsToDelete = [];
      const idsToUnAssign = [];
      const updatedRowModesModel = { ...rowModesModel };

      for (const row of rows) {
        if (row.is_global) {
          idsToUnAssign.push(row.id);
        } else {
          idsToDelete.push(row.id);
        }
        updatedRowModesModel[row.id] = { mode: GridRowModes.View };
      }

      if (idsToUnAssign.length) {
        await unassignDescriptions({
          descriptionIds: idsToUnAssign,
          adGroupIds: [ad_group_id as GridRowId],
        });

        enqueueSnackbar(
          `${idsToUnAssign.length} descriptions unassigned successfully`,
          {
            variant: 'success',
          }
        );
      }

      if (idsToDelete.length) {
        await deleteDescriptions({ ids: idsToDelete });
        enqueueSnackbar(
          `${idsToDelete.length} descriptions deleted successfully`,
          {
            variant: 'success',
          }
        );
      }

      setRowModesModel({ ...updatedRowModesModel });
    },
    [
      deleteDescriptions,
      unassignDescriptions,
      rowModesModel,
      currentDescriptions,
      ad_group_id,
      enqueueSnackbar,
    ]
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = currentDescriptions.find((row) => row.id === id);
      if (editedRow!.isNew) {
        setCurrentDescriptions((oldRows) =>
          oldRows.filter((row) => row.id !== id)
        );
      }
    },
    [setRowModesModel, rowModesModel, currentDescriptions]
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    if (updatedRow!.isNew) {
      doCreateDescriptions(updatedRow);
    } else {
      updateDescription({
        id: updatedRow.id,
        description: updatedRow.description,
        pinned: updatedRow.pinned,
        ad_group_id: ad_group_id,
      });
    }

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const actions = getActions(rowModesModel, {
    handleRegenerateClick,
    handleApproveClick,
    handleUnapproveClick,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    handleDeleteClick,
    handleConvertLocalClick,
  });

  const columns: GridColDef[] = [
    {
      field: 'approved',
      headerName: 'Approved',
      renderCell: (params: GridCellParams) => <ApprovedCell {...params} />,
    },
    buildTextWithCharacterCountColumn(
      'description',
      'Description',
      CharacterLimitForContent.Description,
      descriptionValidationSchema,
      true
    ),
    {
      field: 'pinned',
      headerName: 'Pinned',
      width: 150,
      editable: true,
      renderCell: (params: GridCellParams) => <PinCell {...params} />,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <PinDropdown
          {...params}
          selectedPins={getFilteredSelectedPins(data)}
          pinType={PinType.DESCRIPTION}
        />
      ),
    },
    buildCharacterCountColumn(
      'description',
      'Characters',
      CharacterLimitForContent.Description
    ),
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Action',
      cellClassName: 'actions',
      width: 190,
      getActions: actions,
    },
  ];

  return (
    <React.Fragment>
      <Typography variant="body1" sx={{ display: 'inline' }}>
        <span style={{ fontWeight: 'bold' }}>
          <CircleIcon
            color={getStatusColor(currentDescriptions)}
            fontSize="inherit"
          />{' '}
          Descriptions {approvedCount > 0 ? `(${approvedCount}/4)` : ''}
        </span>
      </Typography>
      <IconButton onClick={togglePromptPreview}>
        <RateReviewIcon
          sx={
            !isLoadingRegenerate
              ? {
                  color: '#1976d2',
                }
              : {}
          }
        />
      </IconButton>
      <IconButton
        sx={{ color: 'white' }}
        aria-label="close"
        onClick={() => handleRegenerateAllClick()}
      >
        <RefreshIcon
          sx={{
            color: '#FF0000',
          }}
        />
      </IconButton>
      {isLoading && !currentDescriptions?.length && (
        <>
          <IconButton sx={{ color: 'white' }} aria-label="close">
            <CircularProgress size={16} />
          </IconButton>
        </>
      )}
      <Grid alignItems="center" container>
        <Grid item xs={12} sx={{ minHeight: 100, overflow: 'hidden' }}>
          <DataGridPremium
            density="compact"
            loading={isLoading}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            isCellEditable={(params) => !params.row.is_global}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            columnVisibilityModel={{
              id: false,
            }}
            rows={getOrderedRows(currentDescriptions)}
            columns={columns}
            checkboxSelection={true}
            disableRowSelectionOnClick={true}
            slots={{
              footer: ButtonFooter,
              toolbar: EditToolbar,
            }}
            slotProps={{
              footer: {
                onRegenerate: (ids: GridRowId[]) => {
                  handleRegenerateClick(ids)();
                },
                onApprove: (ids: GridRowId[]) => {
                  handleApproveClick(ids)();
                },
                onUnApprove: (ids: GridRowId[]) => {
                  handleUnapproveClick(ids)();
                },
                onDelete: (ids: GridRowId[]) => {
                  handleDeleteClick(ids)();
                },
                onAddToGlobal: (ids: GridRowId[]) => {
                  handleConvertToGlobalClick(ids)();
                },
                adgroup_id: ad_group_id,
              },
              toolbar: {
                setRows: setCurrentDescriptions,
                setRowModesModel,
                approvedCount,
              },
            }}
          />
        </Grid>
      </Grid>
      <DynamicPromptModal
        open={promptModalOpen}
        handleClose={() => setPromptModalOpen((prev) => !prev)}
        adGroupId={ad_group_id}
        promptName={'descriptions'}
        type="Descriptions"
      />
    </React.Fragment>
  );
};

export default DescriptionsSection;
