import React, { useEffect, useState } from 'react';

import Link from '@mui/material/Link';
import {
  DataGridPremium,
  GridFilterModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import AdGroupModal from '../AdGroupModal';
import { Box, Card, Grid, Paper } from '@mui/material';
import { useTitle } from '../../contexts/TitleContext';
import {
  useFetchAdGroupsQuery,
  useFetchGroupNamesQuery,
} from '../../slices/adGroupSlice';
import { useFetchFiltersQuery } from '../../slices/filterSlice';
import {
  getExclusiveFilterOperator,
  getInclusiveFilterOperator,
  getInclusiveGroupFilterOperator,
  getExclusiveGroupFilterOperator,
} from '../MultiselectInputValue';
import {
  filterColumns,
  getColumnForNewFilter,
} from '../../utils/filterColumns';

function FilterToolbar() {
  return (
    <>
      <GridToolbarContainer>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    </>
  );
}

export default function AssociatedGroupsSection() {
  const apiRef = useGridApiRef();
  const { setTitle } = useTitle();
  const [open, setOpen] = useState(false);
  const [modalAdgroupId, setModelAdGroupId] = useState('');

  useEffect(() => {
    setTitle('Ad Groups');
  }, [setTitle]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 24,
  });

  const [queryOptions, setQueryOptions] = useState<{
    filterModel?: GridFilterModel;
  }>();

  const {
    data: groupNames = {
      headline_group: {},
      description_group: {},
      sitelink_group: {},
      price_asset_group: {},
    },
  } = useFetchGroupNamesQuery();

  const { data = { total: 0, results: [] }, isFetching } =
    useFetchAdGroupsQuery({
      ...paginationModel,
      ...queryOptions,
    });

  const {
    data: filters = {
      word_combination_filters: [],
      word_group_category_filters: [],
    },
  } = useFetchFiltersQuery({});

  const handleOpen = (adGroupId: string) => {
    setModelAdGroupId(adGroupId);
    setOpen(true);
  };

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const renderGroupNames = (params: any, assetType: string) => (
    <Box
      sx={{
        overflowY: 'scroll',
        height: '100%',
        width: '100%',
      }}
    >
      {params.value.map((id: string) => (
        <p key={id} style={{ margin: '5px 0' }}>
          {groupNames[assetType][id]}
        </p>
      ))}
    </Box>
  );

  const columns = [
    {
      field: 'adgroup_name',
      headerName: 'Ad Group',
      flex: 1,
      filterOperators: [
        ...getInclusiveFilterOperator(filters.word_combination_filters),
        ...getExclusiveFilterOperator(filters.word_combination_filters),
      ],
      renderCell: (params: any) => (
        <Link component="button" onClick={() => handleOpen(params.id)}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'word_category_combination',
      headerName: 'Word Category',
      filterOperators: [
        ...getExclusiveFilterOperator(filters.word_group_category_filters),
        ...getInclusiveFilterOperator(filters.word_group_category_filters),
      ],
      flex: 1,
    },
    {
      field: 'headline_groups',
      headerName: 'Headline Groups',
      flex: 1,
      renderCell: (params: any) => renderGroupNames(params, 'headline_group'),
      filterOperators: [
        ...getInclusiveGroupFilterOperator(groupNames.headline_group),
        ...getExclusiveGroupFilterOperator(groupNames.headline_group),
      ],
    },
    {
      field: 'description_groups',
      headerName: 'Description Groups',
      flex: 1,
      renderCell: (params: any) =>
        renderGroupNames(params, 'description_group'),
      filterOperators: [
        ...getInclusiveGroupFilterOperator(groupNames.description_group),
      ],
    },
    {
      field: 'sitelink_groups',
      headerName: 'Sitelink Groups',
      flex: 1,
      renderCell: (params: any) => renderGroupNames(params, 'sitelink_group'),
      filterOperators: [
        ...getInclusiveGroupFilterOperator(groupNames.sitelink_group),
      ],
    },
    {
      field: 'price_asset_groups',
      headerName: 'Price Asset Groups',
      flex: 1,
      renderCell: (params: any) =>
        renderGroupNames(params, 'price_asset_group'),
      filterOperators: [
        ...getInclusiveGroupFilterOperator(groupNames.price_asset_group),
      ],
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card sx={{ marginTop: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ width: '100%' }}>
              <DataGridPremium
                autoHeight={true}
                getRowHeight={() => 80}
                rows={data.results}
                columns={columns}
                rowCount={data.total}
                paginationMode="server"
                loading={isFetching}
                apiRef={apiRef}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                pageSizeOptions={[24, 48, 96]}
                pagination={true}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                slots={{ toolbar: FilterToolbar }}
                slotProps={{
                  filterPanel: {
                    filterFormProps: {
                      filterColumns: (params) =>
                        filterColumns({
                          ...params,
                          uniqueFilterColumns: [
                            'adgroup_name',
                            'word_category_combination',
                          ],
                        }),
                    },
                    sx: {
                      '& .MuiDataGrid-filterFormValueInput   ': {
                        width: '500px',
                      },
                      position: 'absolute',
                      top: `-110px !important`,
                      left: '100px',
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                    },
                    getColumnForNewFilter: (params) =>
                      getColumnForNewFilter({
                        ...params,
                        uniqueFilterColumns: [
                          'adgroup_name',
                          'word_category_combination',
                        ],
                      }),
                    onKeyDown: (event: KeyboardEvent) => {
                      if (
                        event.key === 'Escape' ||
                        (event.key === 'Enter' && !event.shiftKey)
                      ) {
                        apiRef.current.hideFilterPanel();
                      }
                    },
                  },
                }}
              />
            </div>
            <AdGroupModal
              open={open}
              handleClose={handleClose}
              adgroup_id={modalAdgroupId}
            />
          </Paper>
        </Grid>
      </Grid>
    </Card>
  );
}
