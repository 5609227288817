import { ContentType, AIModel } from './enums';

export type PromptMessage = {
  role: PromptMessageType;
  content: string;
  model?: AIModel;
};

export enum PromptMessageType {
  System = 'system',
  User = 'user',
  Assistant = 'assistant',
}

export interface Conversation {
  id: string;
  ad_group_id: string;
  content_type: ContentType;
  messages: PromptMessage[];
  created_by: number;
  created_by_name: string;
  last_update: string;
  name: string;
}
