import { GridFilterModel, GridRowId } from '@mui/x-data-grid-premium';
import { emptySplitApi } from './baseApi';
import { AdGroup, AdGroupContentCounts } from '../types/adGroups';

interface AdGroupData {
  results: AdGroup[];
  total: number;
}

interface GroupNames {
  [id: string]: string;
}

interface GroupNamesResponse {
  [group_type: string]: GroupNames;
}
export const adGroupApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAdGroups: builder.query<
      AdGroupData,
      {
        page: number;
        pageSize: number;
        filterModel?: GridFilterModel;
        showApproved?: boolean;
        showEnabled?: boolean;
        showInactive?: boolean;
        showOutOfDate?: boolean;
        includesIds?: GridRowId[];
      }
    >({
      query: ({
        page,
        pageSize,
        filterModel,
        showApproved,
        showEnabled,
        showInactive,
        showOutOfDate,
        includesIds,
      }) => {
        const filters: any[] = [];
        if (filterModel) {
          filterModel.items.forEach((item) => {
            const assignedContentTypes = [
              'sitelinks_is_assigned',
              'price_assets_is_assigned',
              'headlines_is_assigned',
              'descriptions_is_assigned',
            ];
            if (assignedContentTypes.includes(item.field) && includesIds) {
              for (const id of includesIds) {
                if (item.value) {
                  filters.push({
                    field: item.field.replace('_is_assigned', '_approved'),
                    operator:
                      item.value === 'true' ? 'is_assigned' : 'is_not_assigned',
                    value: id,
                  });
                }
              }
            } else {
              filters.push({
                field: item.field,
                operator: item.operator,
                value: item.value,
              });
            }
          });
        }

        if (showApproved) {
          filters.push({
            field: 'status',
            operator: 'equals',
            value: 'APPROVED',
          });
        }

        if (showEnabled) {
          filters.push({
            field: 'google_status',
            operator: 'equals',
            value: 'ENABLED',
          });
        }

        if (showInactive) {
          filters.push({
            field: 'google_status',
            operator: 'notEquals',
            value: 'ENABLED',
          });
        }

        if (showOutOfDate) {
          filters.push({
            field: 'last_update',
            operator: 'date_compare',
            value: 'google_last_update',
          });
        }

        return {
          url: `/adgroups`,
          method: 'POST',
          params: {
            page,
            pageSize,
            logic_operator: filterModel?.logicOperator || 'and',
          },
          body: {
            filters,
          },
        };
      },
      providesTags: ['AdGroups'],
    }),
    fetchAdGroupCounts: builder.query<AdGroupContentCounts, string>({
      query(ad_group_id) {
        return `/${ad_group_id}/content-counts`;
      },
      providesTags: ['AdGroupCounts'],
    }),
    fetchGroupNames: builder.query<GroupNamesResponse, void>({
      query() {
        return `/get-group-names`;
      },
      providesTags: [
        'HeadlineGroup',
        'HeadlineGroups',
        'DescriptionGroup',
        'DescriptionGroups',
        'SitelinkGroup',
        'SitelinkGroups',
        'PriceAssetGroup',
        'PriceAssetGroups',
      ],
    }),
  }),
});

export const {
  useFetchAdGroupsQuery,
  useFetchAdGroupCountsQuery,
  useFetchGroupNamesQuery,
} = adGroupApiSlice;
