import React from 'react';
import { PriceAsset } from '../../types/priceAssets';
import GlobalPriceAssetSection from '../PriceAssetToolComponents/GlobalPriceAssetSection';

interface AssignedPriceAssetProps {
  priceAssets: PriceAsset[];
}

const AssignedPriceAsset: React.FC<AssignedPriceAssetProps> = ({
  priceAssets,
}) => {
  const data = { results: priceAssets, total: priceAssets.length };
  return <GlobalPriceAssetSection data={data} isGroupView={true} />;
};

export default AssignedPriceAsset;
