import { emptySplitApi } from './baseApi';
import { GridRowId } from '@mui/x-data-grid-premium';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { AdGroup, DisplayPath, SearchTerm } from '../types/adGroups';

interface UpdateFinalUrlArg {
  adgroup_id: string;
  url: string;
  max_products: number;
}

interface UpdateAdGroupBid {
  adgroup_id: string;
  bid: number;
}

export interface ResponseWithError {
  error?: string;
}

export const adApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAd: builder.query<AdGroup, string | void>({
      query(adgroup_name) {
        return `/adgroups/${adgroup_name}`;
      },
      providesTags: ['AdGroup'],
    }),
    updateFinalUrl: builder.mutation<void, UpdateFinalUrlArg>({
      query: ({ adgroup_id, ...body }) => ({
        url: `/${adgroup_id}/final-url`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'AdGroup',
          'PromptPreviewElements',
          'PromptPreview',
        ]),
    }),
    updateAdGroupStatus: builder.mutation<
      void,
      { adgroup_id: string; status: string }
    >({
      query: ({ adgroup_id, ...body }) => ({
        url: `/${adgroup_id}/status`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['AdGroup', 'AdGroups']),
    }),
    addContentUrl: builder.mutation<
      void,
      { adGroupId: string; contentUrlId: string }
    >({
      query: ({ adGroupId, contentUrlId }) => ({
        url: `/${adGroupId}/content_url`,
        method: 'POST',
        body: { content_url_id: contentUrlId },
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['AdGroup']),
    }),
    removeContentUrl: builder.mutation<
      void,
      { adGroupId: string; contentUrlId: string }
    >({
      query: ({ adGroupId, contentUrlId }) => ({
        url: `/${adGroupId}/content_url`,
        method: 'DELETE',
        body: { content_url_id: contentUrlId },
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['AdGroup']),
    }),
    createAd: builder.mutation<ResponseWithError, { adgroup_id: string }>({
      query: ({ adgroup_id }) => ({
        url: `/${adgroup_id}`,
        method: 'POST',
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['AdGroup', 'AdGroups']),
    }),
    createAds: builder.mutation<any, { ad_group_ids: GridRowId[] }>({
      query: ({ ad_group_ids }) => ({
        url: `/bulk`,
        method: 'POST',
        body: { ad_group_ids },
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['AdGroup', 'AdGroups']),
    }),
    updateDisplayPath: builder.mutation<
      DisplayPath,
      DisplayPath & { id: string }
    >({
      query: ({ id, ...body }) => ({
        url: `/${id}/display-path`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['AdGroup']),
    }),
    updatePriceAssetsOptions: builder.mutation<
      void,
      {
        price_assets_type: string;
        ad_group_id: string;
        price_qualifier: string;
      }
    >({
      query: ({ ad_group_id, price_assets_type, price_qualifier }) => ({
        url: `/${ad_group_id}/price-asset-options`,
        method: 'PATCH',
        body: { price_assets_type, price_qualifier },
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['AdGroup']),
    }),
    regenerateDisplayPath: builder.mutation<
      DisplayPath,
      { ad_group_id: string }
    >({
      query: (body) => ({
        url: `/display_paths/regenerate`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['AdGroup']),
    }),
    fetchSearchTerms: builder.query<SearchTerm[], string | void>({
      query(ad_group_id) {
        return `/${ad_group_id}/search-terms`;
      },
      providesTags: ['SearchTerms'],
    }),
    updateSearchTermFinalUrl: builder.mutation<
      void,
      { id: string; final_url: string }
    >({
      query: ({ id, final_url }) => ({
        url: `/ad_groups/search-terms/${id}/final-url`,
        method: 'PATCH',
        body: { final_url },
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['SearchTerms']),
    }),
    deleteSearchTermFinalUrl: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/ad_groups/search-terms/${id}/final-url`,
        method: 'DELETE',
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['SearchTerms']),
    }),
    syncAllAdGroupsGoogleStatus: builder.mutation<any, void>({
      query: () => ({
        url: `/automation/sync_all_google_status`,
        method: 'POST',
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['AdGroup', 'AdGroups']),
    }),
  }),
});

export const {
  useFetchAdQuery,
  useUpdateFinalUrlMutation,
  useUpdateAdGroupStatusMutation,
  useAddContentUrlMutation,
  useRemoveContentUrlMutation,
  useCreateAdMutation,
  useCreateAdsMutation,
  useUpdateDisplayPathMutation,
  useRegenerateDisplayPathMutation,
  useUpdatePriceAssetsOptionsMutation,
  useFetchSearchTermsQuery,
  useUpdateSearchTermFinalUrlMutation,
  useDeleteSearchTermFinalUrlMutation,
  useSyncAllAdGroupsGoogleStatusMutation,
} = adApiSlice;
