import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

interface CurrencyInputProps {
  value: string;
  onChange: (value: string) => void;
  onEnter?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  onChange,
  onEnter,
  onBlur,
  disabled,
}) => {
  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    let input = event.target.value.replace(/[^0-9.]/g, '');

    if (input.includes('.')) {
      const parts = input.split('.');
      input = parts[0] + '.' + parts[1].slice(0, 2);
    }

    let [integerPart, decimalPart] = input.split('.');
    if (integerPart) {
      integerPart = parseInt(integerPart, 10).toLocaleString();
    }

    if (decimalPart !== undefined) {
      input = integerPart + '.' + decimalPart;
    } else {
      input = integerPart;
    }
    onChange(input);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter();
    }
  };

  return (
    <FormControl sx={{ m: 1 }}>
      <InputLabel htmlFor="outlined-adornment-amount">New Bid</InputLabel>
      <OutlinedInput
        id="outlined-adornment-amount"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        autoComplete="off"
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        label="Amount"
        size="small"
        sx={{ width: '100px' }}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default CurrencyInput;
