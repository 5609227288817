import { useState } from 'react';
import { GridFilterModel } from '@mui/x-data-grid-premium';
import { useFetchAllGlobalPriceAssetsQuery } from '../../slices/priceAssetSlice';
import GlobalPriceAssetSection from './GlobalPriceAssetSection';
import { PriceAssetType } from '../../types/enums';

const AllGlobalDescriptionsSection: React.FC = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 24,
  });
  const [queryOptions, setQueryOptions] = useState<{
    filterModel?: GridFilterModel;
  }>();
  const [priceAssetType, setPriceAssetType] = useState(PriceAssetType.Brands);
  const { data = { total: 0, results: [] }, isFetching } =
    useFetchAllGlobalPriceAssetsQuery({
      ...paginationModel,
      ...queryOptions,
      priceAssetType,
    });

  return (
    <GlobalPriceAssetSection
      data={data}
      isFetching={isFetching}
      setQueryOptions={setQueryOptions}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      priceAssetType={priceAssetType}
      setPriceAssetType={setPriceAssetType}
    />
  );
};
export default AllGlobalDescriptionsSection;
