import React, { useState, useRef, useEffect } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { Conversation } from '../../../types/conversations';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import DeleteConversationModal from './DeleteConversationModal';
import { useUpdateConversationNameMutation } from '../../../slices/conversationSlice';

interface DrawerItemProps {
  thread: Conversation;
  setConversationId: (conversationId: string) => void;
  setDrawerOpen: (isOpen: boolean) => void;
}

const DrawerItem: React.FC<DrawerItemProps> = ({
  thread,
  setConversationId,
  setDrawerOpen,
}) => {
  const [updateName] = useUpdateConversationNameMutation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (isEditing) {
      inputRef?.current?.focus();
    }
  }, [isEditing]);
  const [name, setName] = useState(thread.name);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleRename = () => {
    setIsEditing(true);
    handleCloseMenu();
  };
  const handleSave = () => {
    updateName({ name, conversationId: thread.id });
    setIsEditing(false);
  };
  const checkForEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const handleSelectConversation = () => {
    setConversationId(thread.id);
    setDrawerOpen(false);
  };
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={handleSelectConversation}>
          <ListItemText
            primary={
              isEditing ? (
                <input
                  style={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: '1rem',
                    width: '100%',
                  }}
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  ref={inputRef}
                  onBlur={handleSave}
                  onKeyDown={checkForEnterKey}
                />
              ) : (
                name
              )
            }
            secondary={
              'updated ' + new Date(thread.last_update).toLocaleDateString()
            }
            secondaryTypographyProps={{ sx: { fontSize: '0.7rem' } }}
          />
          <IconButton aria-label="More Actions" onClick={handleOpenMenu}>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            sx={{
              mt: '45px',
            }}
            id="action-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleRename();
              }}
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Rename" />
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setModalOpen(true);
                handleCloseMenu();
              }}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
              }}
            >
              <ListItemIcon>
                <CloseIcon />
              </ListItemIcon>
              <ListItemText primary="Close" />
            </MenuItem>
          </Menu>
        </ListItemButton>
      </ListItem>
      <DeleteConversationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        name={name}
        conversationId={thread.id}
      />
    </>
  );
};

export default DrawerItem;
