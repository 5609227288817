import React, { useState } from 'react';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import {
  Help as HelpIcon,
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  useRemoveContentUrlMutation,
  useAddContentUrlMutation,
} from '../../../slices/adSlice';
import {
  ContentUrl,
  useCreateContentUrlMutation,
  useUpdateContentUrlMutation,
  ContentUrlType,
  useFetchContentUrlsQuery,
} from '../../../slices/contentUrlSlice';
import SiteSurfModal from '../../SiteSurfModal';
import { UrlType } from '../../../types/enums';
import { useSnackbar } from 'notistack';

interface ContentUrlProps {
  contentUrlIds: string[];
  adGroupId: string;
}

const ContentUrls: React.FC<ContentUrlProps> = ({
  contentUrlIds,
  adGroupId,
}) => {
  const { data: contentUrls } = useFetchContentUrlsQuery({
    contentUrlIds,
  });

  const baseContentUrl: ContentUrl = {
    url: `${process.env.REACT_APP_BASE_URL}`,
    type: ContentUrlType.Product,
    max_products: 100,
  };
  const { enqueueSnackbar } = useSnackbar();
  const [removeContentUrlFromAdGroup] = useRemoveContentUrlMutation();
  const [actionType, setActionType] = useState('CREATE');
  const [modalOpen, setModalOpen] = useState(false);
  const [createContentUrl] = useCreateContentUrlMutation();
  const [updateContentUrl] = useUpdateContentUrlMutation();
  const [addContentUrlToAdGroup] = useAddContentUrlMutation();

  const [currentContentUrl, setCurrentContentUrl] =
    useState<ContentUrl>(baseContentUrl);

  const handleSiteSurfModalClick = (
    createNew = false,
    contentUrl = baseContentUrl
  ) => {
    if (createNew) {
      setActionType('CREATE');
    } else {
      setActionType('UPDATE');
    }
    setCurrentContentUrl(contentUrl);
    setModalOpen(true);
  };

  const handleRemoveContentUrl = (contentUrlId: string) => {
    removeContentUrlFromAdGroup({
      adGroupId,
      contentUrlId,
    });
  };

  const handleSaveNewContentUrl = async (url: string, maxProducts: number) => {
    createContentUrl({
      contentUrl: {
        url: url,
        type: ContentUrlType.Product,
        max_products: maxProducts,
      },
    })
      .unwrap()
      .then((data) => {
        addContentUrlToAdGroup({
          adGroupId,
          contentUrlId: data.id as string,
        }).unwrap();
      });
  };

  const handleSaveUpdatedContentUrl = async (
    url: string,
    maxProducts: number
  ) => {
    updateContentUrl({
      contentUrl: {
        id: currentContentUrl.id,
        url: url,
        type: ContentUrlType.Product,
        max_products: maxProducts,
      },
    }).unwrap();
  };

  const handleExport = async (url: string, maxProducts?: number) => {
    try {
      if (actionType === 'CREATE') {
        await handleSaveNewContentUrl(url, maxProducts as number);
      } else if (actionType === 'UPDATE') {
        await handleSaveUpdatedContentUrl(url, maxProducts as number);
      }
      setModalOpen(false);
      enqueueSnackbar(`Content URL ${actionType.toLowerCase()}d successfully`, {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(`Failed to ${actionType.toLowerCase()} Content URLl`, {
        variant: 'error',
      });
    }
  };

  return (
    <Box>
      <span style={{ fontWeight: 'bold' }}>Content URLs:</span>
      <Tooltip
        title={
          'Data will be gathered from these URLs for prompts that use content urls.'
        }
      >
        <IconButton>
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <IconButton onClick={() => handleSiteSurfModalClick(true)}>
        <AddCircleIcon />
      </IconButton>

      {contentUrls?.map((contentUrl) => {
        return (
          <Box key={contentUrl.id} sx={{ display: 'flex' }}>
            <IconButton
              onClick={() => handleRemoveContentUrl(contentUrl.id as string)}
            >
              <RemoveCircleIcon />
            </IconButton>
            <TextField
              id={contentUrl.id}
              variant="standard"
              value={contentUrl.url}
              sx={{ flex: 1 }}
              disabled
            />
            <Typography pl={2}>(Max: {contentUrl.max_products})</Typography>
            <IconButton
              onClick={() => handleSiteSurfModalClick(false, contentUrl)}
            >
              <EditIcon />
            </IconButton>
          </Box>
        );
      })}
      {modalOpen && (
        <SiteSurfModal
          setModalOpen={setModalOpen}
          url={currentContentUrl.url}
          urlType={UrlType.Content}
          initialMaxProducts={currentContentUrl.max_products}
          title={
            actionType === 'CREATE' ? 'Create Content URL' : 'Edit Content URL'
          }
          exportCallback={handleExport}
        />
      )}
    </Box>
  );
};

export default ContentUrls;
