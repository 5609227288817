import React from 'react';
import {
  GridFooterContainer,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { GridSlotsComponentsProps } from '@mui/x-data-grid-premium';
import ActionButtons from '../ActionButtons';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    onRegenerate: any;
    onApprove: any;
    onDelete: any;
    onAddToGlobal?: any;
    onUnApprove: any;
    adgroup_name: string;
    adgroup_id: string;
  }
}

const ButtonFooter = (
  props: NonNullable<GridSlotsComponentsProps['footer']>
) => {
  const apiRef = useGridApiContext();
  const selectedRows = apiRef.current.getSelectedRows();

  return (
    <>
      {selectedRows.size > 0 && (
        <GridFooterContainer>
          <ActionButtons
            numberSelected={selectedRows.size}
            onApprove={async () => {
              const selected = Array.from(apiRef.current.getSelectedRows()).map(
                ([_, row]) => {
                  return row._id || row.id;
                }
              );

              await props.onApprove(selected);
              apiRef.current.setRowSelectionModel([]);
            }}
            onRegenerate={async () => {
              const selected = Array.from(apiRef.current.getSelectedRows()).map(
                ([_, row]) => {
                  return row._id || row.id;
                }
              );

              await props.onRegenerate(selected);
              apiRef.current.setRowSelectionModel([]);
            }}
            onDelete={async () => {
              const selected = Array.from(apiRef.current.getSelectedRows()).map(
                ([_, row]) => {
                  return row._id || row.id;
                }
              );
              await props.onDelete(selected);
              apiRef.current.setRowSelectionModel([]);
            }}
            onUnApprove={async () => {
              const selected = Array.from(apiRef.current.getSelectedRows()).map(
                ([_, row]) => {
                  return row._id || row.id;
                }
              );
              await props.onUnApprove(selected);
              apiRef.current.setRowSelectionModel([]);
            }}
            onAddToGlobal={async () => {
              const selected = Array.from(apiRef.current.getSelectedRows()).map(
                ([_, row]) => {
                  return row._id || row.id;
                }
              );
              await props.onAddToGlobal(selected);
              apiRef.current.setRowSelectionModel([]);
            }}
          />
        </GridFooterContainer>
      )}
    </>
  );
};

export default ButtonFooter;
