import PushPinIcon from '@mui/icons-material/PushPin';
import { IconButton } from '@mui/material';

const PinCell = (params: any) => {
  return (
    <>
      {params.value ? (
        <IconButton sx={{ position: 'relative' }}>
          <PushPinIcon color="primary" />
          <span
            style={{
              position: 'absolute',
              color: 'white',
              right: '0',
              top: '4px',
              fontSize: '12px',
              height: '13px',
              width: '13px',
              borderRadius: '50%',
              backgroundColor: '#1976d2',
            }}
          >
            {params.value.slice(-1)}
          </span>
        </IconButton>
      ) : null}
    </>
  );
};

export default PinCell;
