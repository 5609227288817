import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  List,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Slide,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import HeadlinesSection from './components/sections/HeadlinesSection';
import DescriptionsSection from './components/sections/DescriptionsSection';
import GoogleIcon from '@mui/icons-material/Google';
import SiteLinksSection from './components/sections/SiteLinksSection';
import { useCreateAdMutation, useFetchAdQuery } from '../../slices/adSlice';
import FinalUrl from './components/FinalUrl';
import DisplayPath from './components/DisplayPath';
import SearchTermSection from './components/SearchTermSection';
import { TransitionProps } from '@mui/material/transitions';
import PriceAssetsSection from './components/sections/PriceAssetsSection';
import ImageAssetsSection from './components/sections/ImageAssetsSection';
import RegenerateAll from './components/RegenerateAll';
import AdGroupStatusSelect from './components/AdGroupStatusSelect';
import ContentUrls from './components/ContentUrls';
import AdGroupBid from './components/AdGroupBid';
import {
  StatusEnum,
  ContentType,
  PriceAssetType,
  PriceAssetPriceQualifier,
} from '../../types/enums';

import InteractivePromptingModal from '../InteractivePromptingModal';

interface AdGroupModalProps {
  open: boolean;
  handleClose: () => void;
  adgroup_id: string;
}

const style = {
  boxShadow: 24,
  p: 4,
};

const modalStyle = {
  ...style,
  gridTemplateRows: 'repeat(3, 1fr)',
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdGroupModal: React.FC<AdGroupModalProps> = ({
  open,
  handleClose,
  adgroup_id,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [promptPreviewOpen, setPromptPreviewOpen] = useState(false);
  const [contentType, setContentType] = useState<ContentType>();

  const togglePromptPreview = (contentType: ContentType) => {
    setPromptPreviewOpen((prev) => !prev);
    setContentType(contentType);
  };

  const {
    data = {
      adgroup_name: '',
      final_url: undefined,
      display_path: {
        first_part: '',
        second_part: '',
      },
      status: '',
      google_status: '',
      google_status_phrase: '',
      google_status_broad: '',
      content_urls: [],
      ad_group_bid: 0.0,
      price_assets_type: PriceAssetType.Brands,
      price_assets_price_qualifier: PriceAssetPriceQualifier.From,
    },
  } = useFetchAdQuery(adgroup_id, {
    skip: !open,
  });

  const [createAd, { isLoading }] = useCreateAdMutation();

  const handleCreateAd = () => {
    createAd({ adgroup_id })
      .unwrap()
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        enqueueSnackbar('Ad created successfully', {
          variant: 'success',
        });
      })
      .catch((err) => {
        let errorMessage = 'Failed to create ad.';
        if (err.message) {
          errorMessage = `${errorMessage} ${err.message}`;
        } else if (err.data && err.data.detail) {
          errorMessage = `${errorMessage} ${err.data.detail}`;
        }
        return enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      });
  };

  return (
    <Dialog
      open={open}
      scroll="body"
      onClose={handleClose}
      maxWidth="xl"
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogTitle
        sx={{ bgcolor: 'primary.main', color: 'white' }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        Edit {' - '} {data.adgroup_name}
        <IconButton
          sx={{ color: 'white' }}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          minWidth: 800,
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            ...modalStyle,
            position: 'relative',
          }}
        >
          <RegenerateAll adgroup_id={adgroup_id} disabled={!data.final_url} />
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <FinalUrl final_url={data.final_url} adgroup_id={adgroup_id} />
              <ContentUrls
                contentUrlIds={data.content_urls}
                adGroupId={adgroup_id}
              />
              <DisplayPath
                adgroup_id={adgroup_id}
                display_path={data.display_path}
                togglePromptPreview={() =>
                  togglePromptPreview(ContentType.DisplayPath)
                }
              />
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexWrap="wrap">
                <AdGroupStatusSelect
                  adgroupId={adgroup_id}
                  status={data.status || StatusEnum.Pending}
                />
                {(data.status === StatusEnum.Approved ||
                  data.status === StatusEnum.Paused) && (
                  <Button
                    onClick={() => handleCreateAd()}
                    variant="contained"
                    color="success"
                    startIcon={<GoogleIcon />}
                    disabled={isLoading}
                    sx={{ minWidth: 120 }}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <>
                        {data.google_status === 'ENABLED' ||
                        data.google_status === 'PAUSED'
                          ? 'Update'
                          : 'Activate'}
                      </>
                    )}
                  </Button>
                )}
                <Box sx={{ width: 1 }}>
                  <Typography variant="body1" mt={2}>
                    <span style={{ fontWeight: 'bold' }}>
                      Google Ads Status:
                    </span>{' '}
                    {data.google_status}
                  </Typography>
                  <Typography variant="body1" mt={2}>
                    <span style={{ fontWeight: 'bold' }}>
                      Phrase Match Status:
                    </span>{' '}
                    {data.google_status_phrase}
                  </Typography>
                  <Typography variant="body1" mt={2}>
                    <span style={{ fontWeight: 'bold' }}>
                      Broad Match Status:
                    </span>{' '}
                    {data.google_status_broad}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={3} lg={2}>
              <AdGroupBid adgroupId={adgroup_id} bid={data.ad_group_bid} />
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>Search Terms:</span>
              </Typography>
              <List
                sx={{
                  maxHeight: 1000,
                  overflow: 'auto',
                  border: 1,
                  borderColor: '#9e9e9e',
                }}
              >
                <SearchTermSection ad_group_id={adgroup_id} />
              </List>
            </Grid>
            <Grid item xs={9} lg={10}>
              <Grid>
                <HeadlinesSection
                  adgroup_id={adgroup_id}
                  togglePromptPreview={togglePromptPreview}
                />
                <Divider sx={{ mt: 1, mb: 1 }} />
                <DescriptionsSection
                  ad_group_id={adgroup_id}
                  togglePromptPreview={() =>
                    togglePromptPreview(ContentType.Description)
                  }
                />
                <Divider sx={{ mt: 1, mb: 1 }} />
                <SiteLinksSection
                  adgroup_id={adgroup_id}
                  togglePromptPreview={() =>
                    togglePromptPreview(ContentType.Sitelink)
                  }
                />
                <Divider sx={{ mt: 1, mb: 1 }} />
                <PriceAssetsSection
                  adgroup_id={adgroup_id}
                  togglePromptPreview={() =>
                    togglePromptPreview(ContentType.PriceAsset)
                  }
                  priceAssetsType={data.price_assets_type}
                  priceQualifier={data.price_assets_price_qualifier}
                />
                <Divider sx={{ mt: 1, mb: 1 }} />
                <ImageAssetsSection
                  adgroup_id={adgroup_id}
                  togglePromptPreview={() =>
                    togglePromptPreview(ContentType.ImageAsset)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {promptPreviewOpen && (
          <InteractivePromptingModal
            adGroupId={adgroup_id}
            adGroupName={data.adgroup_name}
            contentType={contentType as ContentType}
            handleClose={() => setPromptPreviewOpen(false)}
            open={promptPreviewOpen}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AdGroupModal;
