import { Avatar, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { PromptMessage, PromptMessageType } from '../../types/conversations';
import { ReactComponent as GPTLogo } from '../../images/gpt-logo.svg';
import React, { useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/Auth';
import { lightBlue } from '@mui/material/colors';
import ContentDataGrid from './ContentDataGrid';
import { ContentType } from '../../types/enums';
import TextArea from '../StyledMUIComponents/TextArea';
import { useUpdateConversationMessagesMutation } from '../../slices/conversationSlice';
import { AIModel } from '../../types/enums';

interface ContentViewProps {
  content: PromptMessage;
  contentType: ContentType;
  adGroupId: string;
  messageIndex: number;
  conversationId: string;
  handleDelete: (messageIndex: number) => void;
  triggerScroll: () => void;
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 35,
      height: 35,
      color: 'white',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const ContentView: React.FC<ContentViewProps> = ({
  content,
  contentType,
  adGroupId,
  messageIndex,
  conversationId,
  handleDelete,
  triggerScroll,
}) => {
  const { user } = React.useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content.content);
  const [showMoreText, setShowMoreText] = useState(false);
  useEffect(() => {
    setEditedContent(content.content);
  }, [content]);
  const [updateConversationMessages] = useUpdateConversationMessagesMutation();

  const handleUpdateUserConversationMessage = () => {
    updateConversationMessages({
      conversationId,
      messageIndex,
      content: editedContent,
    });
    setIsEditing(false);
  };

  const getAvatar = (model: AIModel) => {
    switch (model) {
      case AIModel.ChatGPT4:
      case AIModel.ChatGPT4o:
        return <GPTLogo style={{ width: '1.5rem', height: '1.5rem' }} />;
      case AIModel.Claude3Opus:
        return 'Opus';
      case AIModel.Claude3Sonnet:
        return 'Sonnet';
      case AIModel.Claude35Sonnet:
        return 'Sonnet';
      case AIModel.Claude3Haiku:
        return 'Haiku';
    }
  };

  const displayUserMessageContent = () => {
    if (editedContent.length > 200) {
      return (
        <>
          {editedContent.slice(0, 200)}
          {showMoreText ? editedContent.slice(200) : '...'}
          <Typography
            component="span"
            onClick={() => setShowMoreText(!showMoreText)}
            sx={{ cursor: 'pointer', fontWeight: 'bold' }}
          >
            {showMoreText ? 'Show less' : 'Show more'}
          </Typography>
        </>
      );
    }
    return editedContent;
  };

  return (
    <Grid
      container
      key={`view_${conversationId}_${messageIndex}`}
      wrap="nowrap"
      alignItems="flex-start"
      sx={{ marginBottom: 2 }}
      spacing={2}
    >
      {content.role === PromptMessageType.Assistant ? (
        <>
          <Grid xs={1} item>
            <Avatar
              sx={{
                width: 35,
                height: 35,
                bgcolor:
                  content.model === AIModel.ChatGPT4 ||
                  AIModel.ChatGPT4o ||
                  !content.model
                    ? lightBlue[500]
                    : '#D87756',
                color: 'white',
                fontSize: '.7rem',
              }}
            >
              {getAvatar(content.model ?? AIModel.ChatGPT4o)}
            </Avatar>
            <IconButton onClick={() => handleDelete(messageIndex)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs={11}>
            <ContentDataGrid
              contentString={content.content}
              contentType={contentType}
              adGroupId={adGroupId}
              messageIndex={messageIndex}
              conversationId={conversationId}
              triggerScroll={triggerScroll}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid xs={1} item>
            <Avatar {...stringAvatar(user?.name || '')} />
            <IconButton onClick={() => handleDelete(messageIndex)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs={11}>
            {isEditing ? (
              <TextArea
                minRows={4}
                maxRows={30}
                style={{ width: '100%' }}
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
                onBlur={handleUpdateUserConversationMessage}
              />
            ) : (
              <>
                <Typography onDoubleClick={() => setIsEditing(true)}>
                  {displayUserMessageContent()}
                </Typography>
                {content.model && (
                  <Typography variant="caption" sx={{ color: '#808080' }}>
                    (Asked {content.model})
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ContentView;
