import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CurrencyInput from '../StyledMUIComponents/CurrencyInput';

interface BulkNewBidInputProps {
  handleClick: (newBid: string) => void;
}

const BulkNewBidInput: React.FC<BulkNewBidInputProps> = ({ handleClick }) => {
  const [amount, setAmount] = useState('');

  return (
    <Box display="flex" alignItems="center" ml={2}>
      <CurrencyInput value={amount} onChange={setAmount} />
      <Button variant="outlined" onClick={() => handleClick(amount)}>
        Update Bids
      </Button>
    </Box>
  );
};

export default BulkNewBidInput;
