import React from 'react';
import { Sitelink } from '../../types/sitelinks';
import GlobalSitelinkSection from '../SitelinkToolComponents/GlobalSitelinkSection';

interface AssignedSitelinkProps {
  sitelinks: Sitelink[];
}

const AssignedSitelink: React.FC<AssignedSitelinkProps> = ({ sitelinks }) => {
  const data = { results: sitelinks, total: sitelinks.length };
  return <GlobalSitelinkSection data={data} isGroupView={true} />;
};

export default AssignedSitelink;
