import { Autocomplete, TextField } from '@mui/material';
import {
  GridFilterOperator,
  GridTypeFilterInputValueProps,
} from '@mui/x-data-grid-premium';

const getInclusiveGroupFilterOperator = (filterOptions: {
  [id: string]: string;
}): GridFilterOperator[] => {
  return [
    {
      label: 'inclusive',
      value: 'inclusive_group',
      getApplyFilterFn: () => {
        return null;
      },
      InputComponent: (props) =>
        MultiselectGroupInputValue(props, filterOptions),
    },
  ];
};

const getExclusiveGroupFilterOperator = (filterOptions: {
  [id: string]: string;
}): GridFilterOperator[] => {
  return [
    {
      label: 'exclusive',
      value: 'exclusive_group',
      getApplyFilterFn: () => {
        return null;
      },
      InputComponent: (props) =>
        MultiselectGroupInputValue(props, filterOptions),
    },
  ];
};

const getExclusiveFilterOperator = (
  filterOptions: string[]
): GridFilterOperator[] => {
  return [
    {
      label: 'exclusive',
      value: 'exclusive',
      getApplyFilterFn: () => {
        return null;
      },
      InputComponent: (props) =>
        MultiselectInputValue(props, filterOptions, false),
    },
  ];
};

const getInclusiveFilterOperator = (
  filterOptions: string[]
): GridFilterOperator[] => {
  return [
    {
      label: 'inclusive',
      value: 'inclusive',
      getApplyFilterFn: () => {
        return null;
      },
      InputComponent: (props) => MultiselectInputValue(props, filterOptions),
    },
  ];
};

const MultiselectGroupInputValue = (
  props: GridTypeFilterInputValueProps,
  filterOptions: { [id: string]: string },
  freeSolo = true
) => {
  return (
    <Autocomplete
      onChange={(_e, value): void => {
        const { item, applyValue } = props;
        applyValue({ ...item, value });
      }}
      multiple
      filterSelectedOptions
      freeSolo={freeSolo}
      id="tags-standard"
      options={Object.keys(filterOptions)}
      getOptionLabel={(option) => filterOptions[option]}
      value={props.item.value ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Multiple values"
          placeholder="filter"
        />
      )}
    />
  );
};

const MultiselectInputValue = (
  props: GridTypeFilterInputValueProps,
  wordCombinations: string[],
  freeSolo = true
) => {
  return (
    <Autocomplete
      onChange={(_e, value): void => {
        const { item, applyValue } = props;
        applyValue({ ...item, value });
      }}
      multiple
      filterSelectedOptions
      freeSolo={freeSolo}
      id="tags-standard"
      options={wordCombinations}
      getOptionLabel={(option) => option}
      value={props.item.value ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Multiple values"
          placeholder="filter"
        />
      )}
    />
  );
};

export {
  getExclusiveFilterOperator,
  getInclusiveFilterOperator,
  getInclusiveGroupFilterOperator,
  getExclusiveGroupFilterOperator,
};
