import React, { useEffect, useState } from 'react';

import Link from '@mui/material/Link';
import {
  GridFilterModel,
  GridRowModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import { Card, Grid, Paper } from '@mui/material';
import { useTitle } from '../../contexts/TitleContext';
import {
  PromptTemplate,
  useFetchPromptTemplatesQuery,
  useSavePromptTemplateMutation,
} from '../../slices/promptSlice';
import { useFetchFiltersQuery } from '../../slices/filterSlice';
import PromptTemplateModal from '../PromptTemplateModal';
import { enqueueSnackbar } from 'notistack';
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';
import {
  getExclusiveFilterOperator,
  getInclusiveFilterOperator,
} from '../../components/MultiselectInputValue';
import {
  filterColumns,
  getColumnForNewFilter,
} from '../../utils/filterColumns';
import { formatTemplateTypeString } from '../../utils/formatTemplateTypeString';

function FilterToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

interface PromptTemplateDataTableProps {
  templateType: string;
}

const PromptTemplateDataTable: React.FC<PromptTemplateDataTableProps> = ({
  templateType,
}) => {
  const apiRef = useGridApiRef();
  const { setTitle } = useTitle();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [savePromptTemplate] = useSavePromptTemplateMutation();
  useEffect(() => {
    setTitle(`${formatTemplateTypeString(templateType)} Prompts`);
  }, [setTitle, templateType]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 24,
  });

  const [queryOptions, setQueryOptions] = useState<{
    filterModel?: GridFilterModel;
  }>();

  const { data = { total: 0, results: [] }, isFetching } =
    useFetchPromptTemplatesQuery({
      type: templateType,
      ...paginationModel,
      ...queryOptions,
    });

  const {
    data: filters = {
      word_group_category_filters: [],
      word_combination_filters: [],
      word_group_filters: [],
    },
  } = useFetchFiltersQuery({});

  const handleSave = async (promptTemplate: PromptTemplate) => {
    try {
      await savePromptTemplate({
        templateType,
        promptTemplate,
      }).unwrap();
      enqueueSnackbar(
        `${formatTemplateTypeString(templateType)} prompt saved successfully`,
        {
          variant: 'success',
        }
      );
    } catch (e) {
      enqueueSnackbar(
        `Error saving ${formatTemplateTypeString(templateType)} prompt`,
        {
          variant: 'error',
        }
      );
    }
  };

  const handleOpen = (wordGroupId: string) => {
    setSelectedId(wordGroupId);
    setOpen(true);
  };

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const getFiltersByType = () => {
    if (templateType === 'word_group_category') {
      return filters.word_group_category_filters;
    } else if (templateType === 'word_group') {
      return filters.word_group_filters;
    } else if (templateType === 'category_combo') {
      return filters.word_combination_filters;
    }
    return [];
  };

  const columns = [
    {
      field: 'name',
      headerName: formatTemplateTypeString(templateType),
      flex: 1,
      filterOperators: [
        ...getInclusiveFilterOperator(getFiltersByType()),
        ...getExclusiveFilterOperator(getFiltersByType()),
      ],
      renderCell: (params: any) => {
        return (
          <Link component="button" onClick={() => handleOpen(params.id)}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'headlines_prompt_goal',
      flex: 1,
      headerName: 'Headlines Prompt Goal',
      editable: true,
    },
    {
      field: 'headlines_prompt_examples',
      flex: 1,
      headerName: 'Headlines Prompt Examples',
      editable: true,
    },
    {
      field: 'descriptions_prompt_goal',
      flex: 1,
      headerName: 'Descriptions Prompt Goal',
      editable: true,
    },
    {
      field: 'descriptions_prompt_examples',
      flex: 1,
      headerName: 'Descriptions Prompt Examples',
      editable: true,
    },
    {
      field: 'site_links_prompt_goal',
      flex: 1,
      headerName: 'Site Links Prompt Goal',
      editable: true,
    },
    {
      field: 'site_links_prompt_examples',
      flex: 1,
      headerName: 'Site Links Prompt Examples',
      editable: true,
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    handleSave(updatedRow as PromptTemplate);
    return updatedRow;
  };

  return (
    <Card sx={{ marginTop: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ width: '100%' }}>
              <DataGridPremium
                apiRef={apiRef}
                autoHeight={true}
                rows={data.results}
                columns={columns}
                rowCount={data.total}
                pagination={true}
                paginationMode="server"
                loading={isFetching}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                pageSizeOptions={[24, 48, 96]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                slots={{ toolbar: FilterToolbar }}
                processRowUpdate={processRowUpdate}
                editMode="row"
                columnVisibilityModel={{
                  headlines_prompt_examples:
                    templateType === 'category_combo' ? true : false,
                  descriptions_prompt_examples:
                    templateType === 'category_combo' ? true : false,
                  site_links_prompt_examples:
                    templateType === 'category_combo' ? true : false,
                }}
                slotProps={{
                  filterPanel: {
                    filterFormProps: {
                      filterColumns: (params) =>
                        filterColumns({
                          ...params,
                          uniqueFilterColumns: ['word_group'],
                        }),
                    },
                    sx: {
                      '& .MuiDataGrid-filterFormValueInput   ': {
                        width: '500px',
                      },
                      position: 'absolute',
                      top: `-110px !important`,
                      left: '105px',
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                    },
                    getColumnForNewFilter: (params) =>
                      getColumnForNewFilter({
                        ...params,
                        uniqueFilterColumns: ['word_group'],
                      }),
                    onKeyDown: (event: KeyboardEvent) => {
                      if (
                        event.key === 'Escape' ||
                        (event.key === 'Enter' && !event.shiftKey)
                      ) {
                        apiRef.current.hideFilterPanel();
                      }
                    },
                  },
                }}
              />
            </div>
            {!!selectedId && (
              <PromptTemplateModal
                open={open}
                handleClose={handleClose}
                id={selectedId}
                templateType={templateType}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Card>
  );
};
export default PromptTemplateDataTable;
