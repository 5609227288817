import { GridFilterModel } from '@mui/x-data-grid-premium';

export const formatFilters = (filterModel: GridFilterModel | undefined) => {
  const filters: {
    field: string;
    operator: string;
    value?: any;
  }[] = [];
  if (filterModel) {
    filterModel.items.forEach((item) => {
      filters.push({
        field: item.field,
        operator: item.operator,
        value: item.value,
      });
    });
  }
  return filters;
};
