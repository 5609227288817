import { useState } from 'react';
import { GridFilterModel } from '@mui/x-data-grid-premium';
import { useFetchAllGlobalGeneralHeadlinesQuery } from '../../slices/headlineSlice';
import { GlobalAssetType } from '../../types/enums';
import GlobalHeadlineSection from './GlobalHeadlineSection';

const GeneralHeadlineSection: React.FC = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 24,
  });
  const [queryOptions, setQueryOptions] = useState<{
    filterModel?: GridFilterModel;
  }>();
  const { data = { total: 0, results: [] }, isFetching } =
    useFetchAllGlobalGeneralHeadlinesQuery({
      ...paginationModel,
      ...queryOptions,
    });

  return (
    <GlobalHeadlineSection
      data={data}
      isFetching={isFetching}
      setQueryOptions={setQueryOptions}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      assetType={GlobalAssetType.GeneralHeadline}
    />
  );
};
export default GeneralHeadlineSection;
