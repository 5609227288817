import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import { useToggleIncludeDynamicKeywordMutation } from '../../slices/headlineSlice';

const ToggleKeywordInsertionCell = (params: any) => {
  const [checked, setChecked] = useState(params.row.includes_dynamic_keyword);
  const [toggleIncludeDynamicKeyword] =
    useToggleIncludeDynamicKeywordMutation();

  useEffect(() => {
    setChecked(params.row.includes_dynamic_keyword);
  }, [params.row.includes_dynamic_keyword]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (params.row.id) {
      toggleIncludeDynamicKeyword({ headlineId: params.row.id });
    } else {
      let headline = params.row.headline;
      if (event.target.checked) {
        headline = `{KeyWord:${headline}}`;
      } else {
        const pattern = /\{(Keyword|keyword|KeyWord):(.+?)\}/;
        headline = headline.replace(
          pattern,
          (_match: string, _p1: string, p2: string) => p2
        );
      }
      const newRow = {
        ...params.row,
        includes_dynamic_keyword: event.target.checked,
        headline,
      };

      params.processRowUpdate(newRow);
    }
  };

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
};

export default ToggleKeywordInsertionCell;
