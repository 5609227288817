import React, { useCallback, useState, useEffect } from 'react';

import {
  Typography,
  Grid,
  Button,
  IconButton,
  Box,
  Checkbox,
} from '@mui/material';
import { PriceAsset } from '../../../../types/priceAssets';
import DynamicPromptModal from '../../../DynamicPromptModal';
import {
  useRegeneratePriceAssetsMutation,
  useApprovePriceAssetsMutation,
  useUnapprovePriceAssetsMutation,
  useFetchPriceAssetsQuery,
  useDeletePriceAssetsMutation,
  useUpdatePriceAssetMutation,
  useRegenerateAllPriceAssetsMutation,
  useCreatePriceAssetsMutation,
  useUnassignPriceAssetsFromAdgroupMutation,
  useConvertPriceAssetsToGlobalMutation,
} from '../../../../slices/priceAssetSlice';
import {
  Circle as CircleIcon,
  Add as AddIcon,
  Refresh as RefreshIcon,
  RateReview as RateReviewIcon,
} from '@mui/icons-material';
import {
  GridRowModes,
  GridRowId,
  GridApi,
  GridRowsProp,
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModesModel,
  GridToolbarContainer,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import ButtonFooter from '../table/ButtonFooter';
import ApprovedCell from '../table/ApprovedCell';
import { getActions } from '../../../../helpers/getActions';
import { getStatusColor } from '../../../../helpers/getStatusColor';
import UrlCell from '../../../TableCells/UrlCell';
import PriceCell from '../../../TableCells/PriceCell';
import ImageUrlCell from '../table/ImageUrlCell';
import CircularProgress from '@mui/material/CircularProgress';
import TableCellWithValidation from '../../../TableCells/TableCellWithValidation';
import SiteSurfModal from '../../../SiteSurfModal';
import {
  UrlType,
  PriceAssetType,
  PriceAssetPriceQualifier,
} from '../../../../types/enums';
import {
  priceAssetAndSitelinkHeaderValidationSchema,
  priceAssetShortDescriptionValidationSchema,
  urlValidationSchema,
} from '../../../../utils/validation';
import { useSnackbar } from 'notistack';
import buildTextWithCharacterCountColumn from '../../../../utils/buildTextWithCharacterCountColumn';
import { CharacterLimitForContent } from '../../../../types/enums';
import { getApprovedCount } from '../../../../utils/getApprovedCount';
import { Approvable } from '../../../../types/adGroups';
import PriceAssetTypeDropdown from '../../../TableCells/PriceAssetTypeDropdown';
import PriceAssetTypeCell from '../../../TableCells/PriceAssetTypeCell';
import ChoosePriceAssetType from '../ChoosePriceAssetType';
import ChoosePriceAssetPriceQualifier from '../ChoosePriceAssetPriceQualifier';
import { useUpdatePriceAssetsOptionsMutation } from '../../../../slices/adSlice';

interface DataRow extends PriceAsset {
  isNew?: boolean;
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
  approvedCount: number;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, approvedCount } = props;

  const handleClick = () => {
    const id = Math.floor(Math.random() * 1000000000);
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        approved: false,
        alpine_id: '',
        header: '',
        description: '',
        short_description: '',
        price: 0,
        url: '',
        image_url: '',
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'header' },
    }));
  };

  if (approvedCount >= 8) {
    return null;
  }
  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add manual price asset
      </Button>
    </GridToolbarContainer>
  );
}

const PriceAssetsSection: React.FC<{
  adgroup_id: string;
  togglePromptPreview: () => void;
  priceAssetsType: PriceAssetType;
  priceQualifier: PriceAssetPriceQualifier;
}> = ({ adgroup_id, togglePromptPreview, priceAssetsType, priceQualifier }) => {
  const [selectedPriceAssetType, setSelectedPriceAssetType] =
    useState(priceAssetsType);
  const [
    selectedPriceAssetPriceQualifier,
    setSelectedPriceAssetPriceQualifier,
  ] = useState(priceQualifier);

  useEffect(() => {
    setSelectedPriceAssetType(priceAssetsType);
    setSelectedPriceAssetPriceQualifier(priceQualifier);
  }, [priceAssetsType, priceQualifier]);
  const [promptModalOpen, setPromptModalOpen] = useState(false);
  const [siteSurfModalOpen, setSiteSurfModalOpen] = useState(false);
  const { data, isFetching } = useFetchPriceAssetsQuery(adgroup_id);
  const [dataGridApiRef, setDataGridApiRef] =
    useState<React.MutableRefObject<GridApi>>();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [currentPriceAssets, setCurrentPriceAssets] = useState<DataRow[]>([]);
  const [showDetailedView, setShowDetailedView] = useState(false);
  const [updatePriceAssetsOptions] = useUpdatePriceAssetsOptionsMutation();
  const [unassignPriceAssets] = useUnassignPriceAssetsFromAdgroupMutation();
  const [createPriceAssets] = useCreatePriceAssetsMutation();
  const [updatePriceAsset] = useUpdatePriceAssetMutation();
  const [deletePriceAssets] = useDeletePriceAssetsMutation();
  const [unapprovePriceAssets] = useUnapprovePriceAssetsMutation();
  const [approvePriceAssets] = useApprovePriceAssetsMutation();
  const [convertToGlobal] = useConvertPriceAssetsToGlobalMutation();
  const [selectedId, setSelectedId] = useState('');
  const [selectedUrl, setSelectedUrl] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [regeneratePriceAssets, { isLoading: isLoadingRegenerate }] =
    useRegeneratePriceAssetsMutation();
  const [regenerateAllPriceAssets, { isLoading: isLoadingRegenerateAll }] =
    useRegenerateAllPriceAssetsMutation({
      fixedCacheKey: 'regeneratePriceAssets',
    });

  const isLoading = isLoadingRegenerate || isLoadingRegenerateAll || isFetching;
  let approvedCount = getApprovedCount(currentPriceAssets as Approvable[]);
  useEffect(() => {
    if (data) {
      setCurrentPriceAssets(data);
    }
  }, [data]);

  const handleOpenSiteSurfModal = (
    row: GridRowModel,
    apiRef?: React.MutableRefObject<GridApi>
  ) => {
    if (apiRef) {
      setDataGridApiRef(apiRef);
    }
    setSelectedId(row.id as string);
    setSelectedUrl(row.url);
    setSiteSurfModalOpen(true);
  };

  const handleRegenerateClick = useCallback(
    (ids: GridRowId[]) => () => {
      regeneratePriceAssets({
        ids,
        ad_group_id: adgroup_id,
      });
    },
    [regeneratePriceAssets, adgroup_id]
  );

  const handleRegenerateAllClick = useCallback(() => {
    regenerateAllPriceAssets({
      ad_group_id: adgroup_id,
    });
  }, [regenerateAllPriceAssets, adgroup_id]);

  const handleApproveClick = useCallback(
    (ids: GridRowId[]) => () => {
      approvePriceAssets({
        ids,
        ad_group_id: adgroup_id,
      });
    },
    [approvePriceAssets, adgroup_id]
  );

  const handleUnapproveClick = useCallback(
    (ids: GridRowId[]) => () => {
      unapprovePriceAssets({
        ids,
        ad_group_id: adgroup_id,
      });
    },
    [unapprovePriceAssets, adgroup_id]
  );

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [setRowModesModel, rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [setRowModesModel, rowModesModel]
  );

  const doCreatePriceAssets = useCallback(
    async (row: any) => {
      await createPriceAssets({
        price_assets: [
          {
            short_description: row.short_description,
            header: row.header,
            price: row.price,
            url: row.url,
            max_products: row.max_products,
            image_url: row.image_url,
            alpine_id: row.alpine_id,
            is_global: false,
            price_asset_type: row.price_asset_type,
          },
        ],
        ad_group_id: adgroup_id,
      }).unwrap();
    },
    [createPriceAssets, adgroup_id]
  );

  const handleConvertLocalClick = useCallback(
    (id: GridRowId, row: any) => async () => {
      await unassignPriceAssets({
        priceAssetIds: [id],
        adGroupIds: [adgroup_id],
      });
      await doCreatePriceAssets(row);
      enqueueSnackbar('Price Asset successfully converted to Local', {
        variant: 'success',
      });
    },
    [doCreatePriceAssets, enqueueSnackbar, unassignPriceAssets, adgroup_id]
  );

  const handleConvertToGlobalClick = useCallback(
    (ids: GridRowId[]) => async () => {
      await convertToGlobal({
        priceAssetIds: ids,
      });
      enqueueSnackbar('Price Assets successfully converted to Global', {
        variant: 'success',
      });
    },
    [convertToGlobal, enqueueSnackbar]
  );

  const handleDeleteClick = useCallback(
    (ids: GridRowId[]) => async () => {
      const rows = currentPriceAssets.filter((row) => ids.includes(row.id));
      const idsToDelete = [];
      const idsToUnAssign = [];
      const updatedRowModesModel = { ...rowModesModel };

      for (const row of rows) {
        if (row.is_global) {
          idsToUnAssign.push(row.id);
        } else {
          idsToDelete.push(row.id);
        }
        updatedRowModesModel[row.id] = { mode: GridRowModes.View };
      }

      if (idsToUnAssign.length) {
        await unassignPriceAssets({
          priceAssetIds: idsToUnAssign,
          adGroupIds: [adgroup_id as GridRowId],
        });

        enqueueSnackbar(
          `${idsToUnAssign.length} price assets unassigned successfully`,
          {
            variant: 'success',
          }
        );
      }

      if (idsToDelete.length) {
        await deletePriceAssets({ ids: idsToDelete });
        enqueueSnackbar(
          `${idsToDelete.length} price assets deleted successfully`,
          {
            variant: 'success',
          }
        );
      }

      setRowModesModel({ ...updatedRowModesModel });
    },
    [
      currentPriceAssets,
      deletePriceAssets,
      unassignPriceAssets,
      rowModesModel,
      adgroup_id,
      enqueueSnackbar,
    ]
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      const editedRow = currentPriceAssets.find((row) => row.id === id);
      if (editedRow!.isNew) {
        setCurrentPriceAssets((oldRows) =>
          oldRows.filter((row) => row.id !== id)
        );
      }
    },
    [setRowModesModel, rowModesModel, currentPriceAssets]
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSetPriceAssetType = async (priceAssetType: PriceAssetType) => {
    setSelectedPriceAssetType(priceAssetType);
    try {
      await updatePriceAssetsOptions({
        ad_group_id: adgroup_id,
        price_assets_type: priceAssetType,
        price_qualifier: selectedPriceAssetPriceQualifier,
      }).unwrap();
      enqueueSnackbar('Price Asset Type updated successfully', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar('Failed to update Price Asset Type', {
        variant: 'error',
      });
    }
  };

  const handleSetPriceAssetPriceQualifier = async (
    priceQualifier: PriceAssetPriceQualifier
  ) => {
    setSelectedPriceAssetPriceQualifier(priceQualifier);
    try {
      await updatePriceAssetsOptions({
        ad_group_id: adgroup_id,
        price_assets_type: selectedPriceAssetType,
        price_qualifier: priceQualifier,
      }).unwrap();
      enqueueSnackbar('Price Asset Price Qualifier updated successfully', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar('Failed to update Price Asset Price Qualifier', {
        variant: 'error',
      });
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    try {
      if (updatedRow!.isNew) {
        await doCreatePriceAssets(updatedRow);
      } else {
        await updatePriceAsset({
          id: updatedRow.id,
          header: updatedRow.header,
          short_description: updatedRow.short_description,
          alpine_id: updatedRow.alpine_id,
          price: updatedRow.price,
          url: updatedRow.url,
          max_products: updatedRow.max_products,
          image_url: updatedRow.image_url,
          approved: updatedRow.approved,
          price_asset_type: updatedRow.price_asset_type ?? '',
        }).unwrap();
      }
      setSiteSurfModalOpen(false);
      enqueueSnackbar(
        `Price Asset ${updatedRow!.isNew ? 'created' : 'updated '} successfully`,
        {
          variant: 'success',
        }
      );
    } catch (err) {
      enqueueSnackbar(
        `Failed to ${updatedRow!.isNew ? 'create' : 'update'} Price Asset`,
        {
          variant: 'error',
        }
      );
    }

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const modifyRowUrl = (id: string, url: string) => {
    let modifiedRow: DataRow | undefined;
    const rows = [];
    for (const priceAsset of currentPriceAssets) {
      if (priceAsset.id === id) {
        modifiedRow = { ...priceAsset, url };
        rows.push(modifiedRow);
      } else {
        rows.push(priceAsset);
      }
    }
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    if (modifiedRow && isInEditMode && dataGridApiRef) {
      dataGridApiRef.current.setEditCellValue({
        id: modifiedRow.id,
        field: 'url',
        value: modifiedRow.url,
      });
      setSiteSurfModalOpen(false);
    } else if (modifiedRow && !modifiedRow.isNew) {
      processRowUpdate(modifiedRow);
    }
    setCurrentPriceAssets(rows);
  };

  const actions = getActions(rowModesModel, {
    handleRegenerateClick,
    handleApproveClick,
    handleUnapproveClick,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    handleDeleteClick,
    handleConvertLocalClick,
  });

  const columns: GridColDef[] = [
    {
      field: 'approved',
      headerName: 'Approved',
      renderCell: (params: GridCellParams) => <ApprovedCell {...params} />,
    },
    { field: 'alpine_id', headerName: 'AlpineID', flex: 1, editable: true },
    buildTextWithCharacterCountColumn(
      'header',
      'Header',
      CharacterLimitForContent.PriceAssetHeader,
      priceAssetAndSitelinkHeaderValidationSchema,
      true
    ),
    {
      field: 'price',
      headerName: 'Price',
      width: 125,
      editable: true,
      renderCell: (params: GridCellParams) => (
        <PriceCell
          {...params}
          priceAssetPriceQualifier={selectedPriceAssetPriceQualifier}
        />
      ),
    },
    buildTextWithCharacterCountColumn(
      'short_description',
      'Short Description',
      CharacterLimitForContent.PriceAssetDescription,
      priceAssetShortDescriptionValidationSchema
    ),
    {
      field: 'price_asset_type',
      headerName: 'Type',
      width: 150,
      editable: true,
      renderCell: (params: GridCellParams) => (
        <PriceAssetTypeCell {...params} />
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <PriceAssetTypeDropdown
          {...params}
          priceAssetType={PriceAssetType.Brands}
        />
      ),
    },
    {
      field: 'url',
      headerName: 'URL',
      width: 125,
      editable: true,
      renderCell: (params: GridRenderCellParams) => (
        <UrlCell params={params} handleEditClick={handleOpenSiteSurfModal} />
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        let hasError = false;

        try {
          urlValidationSchema.validateSync(params.props.value);
        } catch (e) {
          hasError = true;
        }

        return { ...params, error: hasError };
      },
      renderEditCell: (params: GridRenderCellParams) => (
        <>
          <UrlCell params={params} handleEditClick={handleOpenSiteSurfModal} />
          <TableCellWithValidation
            {...params}
            validationSchema={urlValidationSchema}
          />
        </>
      ),
    },
    {
      field: 'image_url',
      headerName: 'Image URL',
      width: 125,
      editable: true,
      renderCell: (params: GridCellParams) => <ImageUrlCell {...params} />,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Action',
      cellClassName: 'actions',
      width: 190,
      getActions: actions,
    },
  ];

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        gap="10px"
        paddingY="10px"
      >
        <Typography variant="body1" sx={{ display: 'inline' }}>
          <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
            <CircleIcon
              color={getStatusColor(currentPriceAssets)}
              fontSize="inherit"
            />{' '}
            Price Assets {approvedCount > 0 ? `(${approvedCount}/8)` : ''}
          </span>
        </Typography>
        <IconButton onClick={togglePromptPreview}>
          <RateReviewIcon
            sx={
              !isLoadingRegenerate
                ? {
                    color: '#1976d2',
                  }
                : {}
            }
          />
        </IconButton>
        <IconButton
          sx={{ color: 'white' }}
          aria-label="close"
          onClick={() => handleRegenerateAllClick()}
        >
          <RefreshIcon
            sx={{
              color: '#FF0000',
            }}
          />
        </IconButton>
        {isLoading && !currentPriceAssets?.length && (
          <>
            <IconButton sx={{ color: 'white' }} aria-label="close">
              <CircularProgress size={16} />
            </IconButton>
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            whiteSpace: 'nowrap',
            alignItems: 'center',
            marginRight: '10px',
          }}
        >
          <Checkbox
            checked={showDetailedView}
            onChange={() => setShowDetailedView((prev) => !prev)}
          />
          <Typography variant="body1" sx={{ display: 'inline' }}>
            Show Detailed View
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <ChoosePriceAssetType
            handleSetPriceAssetType={handleSetPriceAssetType}
            selectedPriceAssetType={selectedPriceAssetType}
          />
          <ChoosePriceAssetPriceQualifier
            handleSetPriceAssetPriceQualifier={
              handleSetPriceAssetPriceQualifier
            }
            selectedPriceAssetPriceQualifier={selectedPriceAssetPriceQualifier}
          />
        </Box>
      </Box>

      <Grid alignItems="center" container>
        <Grid item xs={12} sx={{ minHeight: 100, overflow: 'hidden' }}>
          <DataGridPremium
            density="compact"
            loading={isLoading}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            isCellEditable={(params) => !params.row.is_global}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            getRowClassName={(params) =>
              `price_assets-section-${params.row.price_asset_type !== selectedPriceAssetType ? 'warning' : 'regular'}`
            }
            columnVisibilityModel={{
              id: false,
              alpine_id: showDetailedView,
              price: true,
              header: true,
              short_description: true,
              url: true,
              image_url: showDetailedView,
              actions: true,
            }}
            rows={currentPriceAssets}
            columns={columns}
            checkboxSelection={true}
            disableRowSelectionOnClick={true}
            slots={{
              footer: ButtonFooter,
              toolbar: EditToolbar,
            }}
            slotProps={{
              footer: {
                onRegenerate: (ids: GridRowId[]) => {
                  handleRegenerateClick(ids)();
                },
                onApprove: (ids: GridRowId[]) => {
                  handleApproveClick(ids)();
                },
                onUnApprove: (ids: GridRowId[]) => {
                  handleUnapproveClick(ids)();
                },
                onDelete: (ids: GridRowId[]) => {
                  handleDeleteClick(ids)();
                },
                onAddToGlobal: (ids: GridRowId[]) => {
                  handleConvertToGlobalClick(ids)();
                },
                adgroup_id,
              },
              toolbar: {
                setRows: setCurrentPriceAssets,
                setRowModesModel,
                approvedCount,
              },
            }}
          />
        </Grid>
      </Grid>
      <DynamicPromptModal
        open={promptModalOpen}
        handleClose={() => setPromptModalOpen((prev) => !prev)}
        adGroupId={adgroup_id}
        promptName={'price_assets'}
        type="Price Assets"
      />
      {siteSurfModalOpen && (
        <SiteSurfModal
          setModalOpen={setSiteSurfModalOpen}
          url={selectedUrl}
          urlType={UrlType.PreCreatedSitelink}
          title="Price Asset URL"
          exportCallback={(value) => {
            modifyRowUrl(selectedId, value);
          }}
        />
      )}
    </>
  );
};

export default PriceAssetsSection;
