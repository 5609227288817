import React from 'react';

import { Prompt, PromptAssignment } from '../../../slices/promptSlice';

interface AssignedPromptProps {
  prompts?: Prompt[];
  promptAssignment?: PromptAssignment;
}

const AssignedPrompt: React.FC<AssignedPromptProps> = ({
  prompts,
  promptAssignment,
}) => {
  const prompt = prompts?.find(
    (prompt) => prompt._id === promptAssignment?.prompt_id
  );
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>Assigned Prompt:</span>{' '}
      {prompt?.custom_name || 'None - (Base Prompt)'}
    </>
  );
};

export default AssignedPrompt;
