import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { PinType } from '../../types/enums';

const PinDropdown = (props: GridRenderEditCellParams) => {
  const { id, value, field, pinType } = props;

  const apiRef = useGridApiContext();

  const handleChange = (event: SelectChangeEvent<any>) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
  };

  const getAvailablePins = () => {
    let pins: string[];
    switch (pinType) {
      case PinType.HEADLINE:
        pins = ['HEADLINE_1', 'HEADLINE_2', 'HEADLINE_3'];
        break;
      case PinType.DESCRIPTION:
        pins = ['DESCRIPTION_1', 'DESCRIPTION_2'];
        break;
      case PinType.GENERAL_HEADLINE:
        pins = ['HEADLINE_2', 'HEADLINE_3'];
        break;
      case PinType.PRIMARY_HEADLINE:
        pins = ['HEADLINE_1'];
        break;
      default:
        pins = [];
    }

    return pins.map((pin) => (
      <MenuItem key={pin} value={pin}>
        {pin.replace(
          /^(.)(.*)$/,
          (_, firstChar, restOfString) =>
            firstChar.toUpperCase() +
            restOfString.toLowerCase().replace(/_/g, ' ')
        )}
      </MenuItem>
    ));
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId={`pinned-position-select-label-${id}`}
        id={`pinned-position-select-${id}`}
        value={value || ''}
        label="Pinned Position"
        onChange={handleChange}
        variant="standard"
      >
        <MenuItem value="">No pin</MenuItem>
        {getAvailablePins()}
      </Select>
    </FormControl>
  );
};

export default PinDropdown;
