import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { broadcastChannel } from '../utils/broadcastCacheInvalidator';
import { store } from '../app/store';

export const emptySplitApi = createApi({
  tagTypes: [
    'AdGroup',
    'AdGroups',
    'Descriptions',
    'DisplayPath',
    'Headlines',
    'ImageAssets',
    'PriceAssets',
    'Prompt',
    'Prompts',
    'PromptAssignment',
    'Sitelinks',
    'ContentUrl',
    'ContentUrls',
    'PromptTemplateForAdGroup',
    'Filters',
    'PreCreatedSitelinks',
    'PreCreatedSitelink',
    'PromptTemplate',
    'PromptTemplates',
    'PromptPreview',
    'Conversation',
    'Conversations',
    'AdGroupCounts',
    'PromptPreviewElements',
    'Sitelink',
    'PriceAsset',
    'Description',
    'Headline',
    'GlobalPriceAssets',
    'GlobalSitelinks',
    'GlobalDescriptions',
    'GlobalHeadlines',
    'GlobalPrimaryHeadlines',
    'GlobalGeneralHeadlines',
    'HeadlineGroups',
    'HeadlineGroup',
    'SearchTerms',
    'DescriptionGroups',
    'DescriptionGroup',
    'SitelinkGroups',
    'SitelinkGroup',
    'PriceAssetGroups',
    'PriceAssetGroup',
    'AggregatedPerformanceReports',
    'GlobalSettings',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/googleads`,
  }),
  endpoints: () => ({}),
});
broadcastChannel.addEventListener('message', ({ data }) => {
  store.dispatch(emptySplitApi.util.invalidateTags(data));
});
