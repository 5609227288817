import React from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { TextareaAutosizeProps } from '@mui/material/TextareaAutosize';
const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
const StyledTextarea = styled(BaseTextareaAutosize)(
  ({ theme, disabled }) => `
    resize: none;
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 12px 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    font-size: 1rem;
    background: ${disabled ? grey[200] : theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${disabled ? grey[400] : theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${disabled ? 'none' : theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    opacity: ${disabled ? 0.75 : 1};

    &:hover {
      border-color: ${disabled ? grey[400] : blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const TextArea: React.FC<
  TextareaAutosizeProps & React.RefAttributes<Element>
> = ({ ...props }) => {
  return <StyledTextarea {...props} />;
};
export default TextArea;
