import { GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import CharacterCountCell from '../components/TableCells/CharacterCountCell';

const buildCharacterCountColumn = (
  field: string,
  headerName: string,
  maxCharacters?: number
): GridColDef => {
  return {
    field: `${field}_count`,
    headerName,
    width: 125,
    renderCell: (params: GridRenderEditCellParams) => (
      <CharacterCountCell
        params={params}
        fieldName={field}
        maxCharacters={maxCharacters}
      />
    ),
  };
};
export default buildCharacterCountColumn;
