import React, { useEffect, useState } from 'react';
import { useTitle } from '../contexts/TitleContext';
import { Box, Tabs, Tab } from '@mui/material';
import CustomTabPanel from '../components/CustomTabPanel/CustomTabPanel';
import AdGroupDashboard from '../components/AdGroupPageComponents/AdGroupDashboard';
import AssociatedGroupsSection from '../components/AdGroupPageComponents/AssociatedGroupsSection';

const AdGroupDetails: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle(`Ad Groups Tool`);
  }, [setTitle]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '15px' }}>
        <Tabs value={tabValue} onChange={handleChangeTab} centered>
          <Tab label="Dashboard" id="tab-1" aria-controls="tab-1" value={0} />
          <Box
            sx={{
              alignSelf: 'center',
              borderLeft: 1,
              borderWidth: 2,
              borderColor: 'divider',
              height: '32px',
            }}
          />
          <Tab
            label="Associated Groups"
            id="tab-2"
            aria-controls="tab-2"
            value={1}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <AdGroupDashboard />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <AssociatedGroupsSection />
      </CustomTabPanel>
    </Box>
  );
};

export default AdGroupDetails;
