import { emptySplitApi } from './baseApi';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { HeadlineGroup } from '../types/groups';
import { Headline } from '../types/pinnable';
import { GridRowId } from '@mui/x-data-grid-premium';

export const headlineGroupApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllHeadlineGroups: builder.query<
      {
        results: HeadlineGroup[];
        total: number;
      },
      void
    >({
      query: () => {
        return {
          url: '/headline_group/',
          method: 'GET',
        };
      },
      providesTags: ['HeadlineGroups'],
    }),
    fetchHeadlineGroup: builder.query<
      {
        headline_group: HeadlineGroup;
        members: Headline[];
      },
      { headlineGroupId: string }
    >({
      query: ({ headlineGroupId }) => {
        return {
          url: `/headline_group/${headlineGroupId}`,
          method: 'GET',
        };
      },
      providesTags: ['HeadlineGroup'],
    }),
    assignHeadlinesToHeadlineGroups: builder.mutation<
      null | string[],
      { headlineIds: GridRowId[]; headlineGroupIds: GridRowId[] }
    >({
      query: ({ headlineIds, headlineGroupIds }) => ({
        url: `/headline_group/assign`,
        method: 'POST',
        body: {
          headline_ids: headlineIds,
          headline_group_ids: headlineGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'HeadlineGroups',
          'HeadlineGroup',
          'Headlines',
          'GlobalHeadlines',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unassignHeadlinesFromHeadlineGroups: builder.mutation<
      void,
      { headlineIds: GridRowId[]; headlineGroupIds: GridRowId[] }
    >({
      query: ({ headlineIds, headlineGroupIds }) => ({
        url: `/headline_group/unassign`,
        method: 'POST',
        body: {
          headline_ids: headlineIds,
          headline_group_ids: headlineGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'HeadlineGroups',
          'HeadlineGroup',
          'Headlines',
          'PromptPreviewElements',
          'GlobalHeadlines',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    assignHeadlineGroupsToAdGroups: builder.mutation<
      void,
      { headlineGroupIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ headlineGroupIds, adGroupIds }) => ({
        url: `/headline_group/ad_group/assign`,
        method: 'POST',
        body: {
          headline_group_ids: headlineGroupIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'HeadlineGroups',
          'HeadlineGroup',
          'Headlines',
          'GlobalHeadlines',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unassignHeadlineGroupsFromAdGroups: builder.mutation<
      void,
      { headlineGroupIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ headlineGroupIds, adGroupIds }) => ({
        url: `/headline_group/ad_group/unassign`,
        method: 'POST',
        body: {
          headline_group_ids: headlineGroupIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'HeadlineGroups',
          'HeadlineGroup',
          'Headlines',
          'PromptPreviewElements',
          'GlobalHeadlines',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    createHeadlineGroup: builder.mutation<
      HeadlineGroup,
      {
        group_name: string;
      }
    >({
      query: (body) => ({
        url: `/headline_group/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => broadcastCacheInvalidator(['HeadlineGroups']),
    }),
    updateHeadlineGroup: builder.mutation<
      HeadlineGroup,
      {
        id: string;
        group_name: string;
      }
    >({
      query: ({ id, group_name }) => ({
        url: `/headline_group/${id}`,
        method: 'PUT',
        body: { group_name },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'HeadlineGroup',
          'HeadlineGroups',
          'AdGroups',
          'Headline',
          'Headlines',
          'PromptPreviewElements',
          'GlobalHeadlines',
          'GlobalPrimaryHeadlines',
          'GlobalGeneralHeadlines',
        ]),
    }),
    deleteHeadlineGroups: builder.mutation<void, { ids: GridRowId[] }>({
      query: (body) => ({
        url: `/headline_group/`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'HeadlineGroups',
          'Headlines',
          'GlobalHeadlines',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
  }),
});

export const {
  useFetchHeadlineGroupQuery,
  useFetchAllHeadlineGroupsQuery,
  useCreateHeadlineGroupMutation,
  useUpdateHeadlineGroupMutation,
  useAssignHeadlinesToHeadlineGroupsMutation,
  useUnassignHeadlinesFromHeadlineGroupsMutation,
  useAssignHeadlineGroupsToAdGroupsMutation,
  useUnassignHeadlineGroupsFromAdGroupsMutation,
  useDeleteHeadlineGroupsMutation,
} = headlineGroupApiSlice;
