import React, { useContext } from 'react';
import {
  Drawer,
  Box,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { ContentType } from '../../../types/enums';
import { AuthContext } from '../../../contexts/Auth';
import DrawerItem from './DrawerItem';
import { useFetchConversationsByUserQuery } from '../../../slices/conversationSlice';

interface ConversationHistoryDrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  adGroupId: string;
  contentType: ContentType;
  setConversationId: (conversationId: string) => void;
}
const ConversationHistoryDrawer: React.FC<ConversationHistoryDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  adGroupId,
  contentType,
  setConversationId,
}) => {
  const { user } = useContext(AuthContext);
  const { data, isFetching } = useFetchConversationsByUserQuery({
    adGroupId,
    contentType,
    userId: user?.userID || 0,
  });

  const displayConversations = () => {
    if (data?.length === 0)
      return (
        <Box padding={10} textAlign="center">
          <Typography>No conversations found</Typography>
        </Box>
      );
    return data?.map((thread) => (
      <DrawerItem
        key={thread.id}
        thread={thread}
        setConversationId={setConversationId}
        setDrawerOpen={setDrawerOpen}
      />
    ));
  };
  const handleCreateNewConversation = () => {
    setConversationId('');
    setDrawerOpen(false);
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={drawerOpen}
      sx={{
        width: '70vw',
        zIndex: 1300,
      }}
      onClose={() => setDrawerOpen(false)}
    >
      <Box>
        <IconButton onClick={() => setDrawerOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box minWidth="400px">
        {isFetching && <LinearProgress />}
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={handleCreateNewConversation}>
              <ListItemText primary="Start a New Conversation" />
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <Divider />
          {displayConversations()}
        </List>
      </Box>
    </Drawer>
  );
};

export default ConversationHistoryDrawer;
