import { useState, useEffect } from 'react';
import { GridCellParams } from '@mui/x-data-grid-premium';
import CurrencyInput from '../StyledMUIComponents/CurrencyInput';
import { useUpdateBidsMutation } from '../../slices/bidManagementSlice';
import { useSnackbar } from 'notistack';

const InlineNewBidInput = (params: GridCellParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const [newBid, setNewBid] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [updateBids] = useUpdateBidsMutation();

  const { match_type: matchType } = params.row;

  useEffect(() => {
    setDisabled(matchType !== 'EXACT');
  }, [matchType]);

  const handleEnter = async () => {
    if (newBid === '') {
      return;
    }
    const bid = parseFloat(newBid);
    if (isNaN(bid)) {
      return;
    }
    try {
      await updateBids({
        bidUpdates: [{ ad_group_id: params.row.ad_group_id as string, bid }],
      }).unwrap();
      enqueueSnackbar('Bid updated successfully', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Failed to update bid', { variant: 'error' });
    }
    setNewBid('');
  };
  return (
    <CurrencyInput
      value={newBid}
      onChange={setNewBid}
      onEnter={handleEnter}
      onBlur={handleEnter}
      disabled={disabled}
    />
  );
};

export default InlineNewBidInput;
