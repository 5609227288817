import { emptySplitApi } from './baseApi';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';

export const globalSettingApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchGlobalSettings: builder.query<{}, void>({
      query: () => ({
        url: `/global_settings/`,
        method: 'GET',
      }),
      providesTags: ['GlobalSettings'],
    }),
    updateGlobalSettings: builder.mutation<
      {},
      { globalSettings: { [key: string]: string } }
    >({
      query: ({ globalSettings }) => ({
        url: `/global_settings/`,
        method: 'PATCH',
        body: { global_settings: globalSettings },
      }),
      invalidatesTags: broadcastCacheInvalidator([
        'AggregatedPerformanceReports',
        'AdGroups',
        'AdGroup',
        'GlobalSettings',
      ]),
    }),
  }),
});

export const { useFetchGlobalSettingsQuery, useUpdateGlobalSettingsMutation } =
  globalSettingApiSlice;
