import React from 'react';
import { Public as PublicIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import {
  GridRenderCellParams,
  GridRowModel,
  useGridApiContext,
  GridApi,
} from '@mui/x-data-grid-premium';

interface UrlCellProps {
  params: GridRenderCellParams;
  handleEditClick?: (
    row: GridRowModel,
    apiRef?: React.MutableRefObject<GridApi>
  ) => void;
  allowGlobalEdit?: boolean;
}

const UrlCell: React.FC<UrlCellProps> = ({
  params,
  handleEditClick,
  allowGlobalEdit = false,
}) => {
  const apiRef = useGridApiContext();

  return (
    <>
      {params.value ? (
        <>
          <Tooltip title={params.value} arrow>
            <PublicIcon
              color="success"
              onClick={() => {
                if (!params.row.is_global || allowGlobalEdit) {
                  !!handleEditClick && handleEditClick(params.row, apiRef);
                }
              }}
            />
          </Tooltip>
        </>
      ) : (
        <PublicIcon
          color="error"
          onClick={() => {
            if (!params.row.is_global || allowGlobalEdit) {
              !!handleEditClick &&
                handleEditClick(
                  {
                    ...params.row,
                    url: `${process.env.REACT_APP_BASE_URL}`,
                  },
                  apiRef
                );
            }
          }}
        />
      )}
    </>
  );
};

export default UrlCell;
