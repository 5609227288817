import { emptySplitApi } from './baseApi';
import { Description } from '../types/pinnable';
import { GridRowId, GridFilterModel } from '@mui/x-data-grid-premium';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { formatFilters } from '../utils/formatFilters';

export const descriptionApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchDescriptions: builder.query<Description[], string | void>({
      query(adgroup_id) {
        return `/descriptions/${adgroup_id}`;
      },
      providesTags: ['Descriptions'],
    }),
    createDescriptions: builder.mutation<
      void,
      {
        adgroup_id?: string;
        descriptions: Pick<
          Description,
          'description' | 'is_global' | 'pinned'
        >[];
        approved?: boolean;
      }
    >({
      query: ({ descriptions, adgroup_id, approved }) => ({
        url: `/descriptions/many`,
        method: 'POST',
        body: { descriptions, ad_group_id: adgroup_id, approved },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Descriptions',
          'GlobalDescriptions',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    updateDescription: builder.mutation<
      Description,
      {
        id: string;
        description: string;
        pinned: string;
        ad_group_id?: string;
        global_pinned?: string;
      }
    >({
      query: ({ id, ...body }) => ({
        url: `/descriptions/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Description',
          'Descriptions',
          'DescriptionGroup',
          'DescriptionGroups',
          'AdGroups',
          'PromptPreviewElements',
          'GlobalDescriptions',
        ]),
    }),
    regenerateDescriptions: builder.mutation<
      Description[],
      { ad_group_id: string; ids: string[] | GridRowId[] }
    >({
      query: (body) => ({
        url: `/descriptions/regenerate`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Descriptions',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    regenerateAllDescriptions: builder.mutation<
      Description[],
      { ad_group_id: string }
    >({
      query: (body) => ({
        url: `/descriptions/regenerate_all`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Descriptions',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    approveDescriptions: builder.mutation<
      Description[],
      { ids: string[] | GridRowId[]; ad_group_id: string }
    >({
      query: (body) => ({
        url: `/descriptions/approve`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Descriptions',
          'AdGroup',
          'AdGroupCounts',
          'GlobalDescriptions',
          'AdGroups',
        ]),
    }),
    unapproveDescriptions: builder.mutation<
      Description[],
      { ids: string[] | GridRowId[]; ad_group_id: string }
    >({
      query: (body) => ({
        url: `/descriptions/unapprove`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Descriptions',
          'AdGroup',
          'AdGroupCounts',
          'GlobalDescriptions',
          'AdGroups',
        ]),
    }),
    deleteDescriptions: builder.mutation<void, { ids: GridRowId[] }>({
      query: (body) => ({
        url: `/descriptions/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Descriptions',
          'GlobalDescriptions',
          'DescriptionGroup',
          'DescriptionGroups',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    fetchDescription: builder.query<Description, { descriptionId: string }>({
      query: ({ descriptionId }) => ({
        url: `/descriptions/`,
        method: 'GET',
        params: { description_id: descriptionId },
      }),
      providesTags: ['Description'],
    }),
    fetchAllGlobalDescriptions: builder.query<
      {
        results: Description[];
        total: number;
      },
      {
        page: number;
        pageSize: number;
        filterModel?: GridFilterModel;
      }
    >({
      query: ({ page, pageSize, filterModel }) => {
        return {
          url: '/descriptions/global',
          method: 'POST',
          params: {
            page,
            pageSize,
            logic_operator: filterModel?.logicOperator || 'and',
          },
          body: {
            filters: formatFilters(filterModel),
          },
        };
      },
      providesTags: ['GlobalDescriptions'],
    }),
    assignDescriptionsToAdgroup: builder.mutation<
      void,
      { descriptionIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ descriptionIds, adGroupIds }) => ({
        url: `/descriptions/assign`,
        method: 'POST',
        body: {
          description_ids: descriptionIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Descriptions',
          'GlobalDescriptions',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unassignDescriptionsFromAdgroup: builder.mutation<
      void,
      { descriptionIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ descriptionIds, adGroupIds }) => ({
        url: `/descriptions/unassign`,
        method: 'POST',
        body: {
          description_ids: descriptionIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Descriptions',
          'PromptPreviewElements',
          'GlobalDescriptions',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    convertDescriptionsToGlobal: builder.mutation<
      void,
      { descriptionIds: GridRowId[]; ad_group_id: string }
    >({
      query: ({ descriptionIds, ad_group_id }) => ({
        url: `/descriptions/global/convert`,
        method: 'PUT',
        body: {
          description_ids: descriptionIds,
          ad_group_id,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'Description',
          'Descriptions',
          'GlobalDescriptions',
        ]),
    }),
  }),
});

export const {
  useFetchDescriptionQuery,
  useFetchDescriptionsQuery,
  useUpdateDescriptionMutation,
  useCreateDescriptionsMutation,
  useApproveDescriptionsMutation,
  useUnapproveDescriptionsMutation,
  useRegenerateDescriptionsMutation,
  useRegenerateAllDescriptionsMutation,
  useDeleteDescriptionsMutation,
  useFetchAllGlobalDescriptionsQuery,
  useAssignDescriptionsToAdgroupMutation,
  useUnassignDescriptionsFromAdgroupMutation,
  useConvertDescriptionsToGlobalMutation,
} = descriptionApiSlice;
