import React from 'react';

import { Button, Stack } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SaveIcon from '@mui/icons-material/Save';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import {
  Prompt,
  PromptAssignment,
  PromptType,
} from '../../../slices/promptSlice';

interface ActionButtonsProps {
  prompt?: Prompt;
  selectedPromptId?: string;
  promptAssignment?: PromptAssignment;
  onSave: () => void;
  onSaveNew: () => void;
  onAssignPrompt: () => void;
}

const PromptActionButtons: React.FC<ActionButtonsProps> = ({
  prompt,
  promptAssignment,
  selectedPromptId,
  onSave,
  onSaveNew,
  onAssignPrompt,
}) => {
  if (!prompt) return null;
  const showAssign =
    !promptAssignment ||
    (promptAssignment && promptAssignment.prompt_id !== selectedPromptId);
  return (
    <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
      <Button
        variant="contained"
        sx={{ backgroundColor: '#f44336' }}
        startIcon={<SaveIcon />}
        onClick={onSave}
      >
        Save {prompt.type === PromptType.Base ? 'Base Prompt' : ''}
      </Button>
      <Button
        variant="contained"
        startIcon={<MapsUgcIcon />}
        sx={{ backgroundColor: '#0288d15' }}
        onClick={onSaveNew}
      >
        Save As New
      </Button>
      {showAssign && (
        <Button
          variant="contained"
          color="success"
          startIcon={<ThumbUpIcon />}
          onClick={onAssignPrompt}
        >
          Assign Prompt
        </Button>
      )}
    </Stack>
  );
};

export default PromptActionButtons;
