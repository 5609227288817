import React, { useState } from 'react';
import NumberInput from '../StyledMUIComponents/NumberInput';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

interface BulkNewBidPercentInputProps {
  handleClick: (percent: number) => void;
}
const InputAdornment = styled('div')(
  () => `
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-row: 1/3;
`
);
const BulkAdjustBidsByPercentageInput: React.FC<
  BulkNewBidPercentInputProps
> = ({ handleClick }) => {
  const [percentage, setPercentage] = useState(0);
  return (
    <Box display="flex" alignItems="center">
      <NumberInput
        value={percentage}
        onChange={(_, val) => setPercentage(val?.valueOf() || 0)}
        endAdornment={<InputAdornment>%</InputAdornment>}
      />
      <Box ml={1}>
        <Button
          variant="outlined"
          onClick={() => handleClick(percentage)}
          disabled={percentage === 0}
        >
          {percentage > -1 ? 'Increase' : 'Reduce'} Bids by %
        </Button>
      </Box>
    </Box>
  );
};

export default BulkAdjustBidsByPercentageInput;
