import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

interface NumberInputProps {
  value: string;
  onChange: (value: string) => void;
  onEnter?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
}

const BidPercentageInput: React.FC<NumberInputProps> = ({
  value,
  onChange,
  onEnter,
  onBlur,
  disabled,
}) => {
  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    let input = event.target.value.replace(/[^0-9.]/g, '');

    if (input.includes('.')) {
      const parts = input.split('.');
      input = parts[0] + '.' + parts[1].slice(0, 2);
    }

    if (input === '0') {
      input = '1';
    }

    onChange(input);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter();
    }
  };

  return (
    <FormControl sx={{ m: 1 }}>
      <InputLabel htmlFor="outlined-adornment-amount">Bid %</InputLabel>
      <OutlinedInput
        id="outlined-adornment-amount"
        type="number"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        autoComplete="off"
        label="Amount"
        size="small"
        sx={{ width: '100px' }}
        inputProps={{ step: '0.5', min: '1' }}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default BidPercentageInput;
