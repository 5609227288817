import React, { useEffect, useState } from 'react';
import {
  GridApi,
  GridRowModel,
  GridCellParams,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import {
  useUpdateSitelinkMutation,
  useDeleteSitelinksMutation,
  useCreateSitelinksMutation,
  useFetchSitelinkQuery,
} from '../../slices/sitelinkSlice';
import { IconButton } from '@mui/material';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { useTitle } from '../../contexts/TitleContext';
import BaseAssignToolDataTable from '../BaseAssignToolDataTable';
import UrlCell from '../TableCells/UrlCell';
import TableCellWithValidation from '../TableCells/TableCellWithValidation';
import {
  priceAssetAndSitelinkHeaderValidationSchema,
  sitelinkDescriptionValidationSchema,
  urlValidationSchema,
} from '../../utils/validation';
import {
  GlobalAssetType,
  MaxAllowableContent,
  CharacterLimitForContent,
} from '../../types/enums';
import { Sitelink } from '../../types/sitelinks';
import * as Yup from 'yup';
import buildTextWithCharacterCountColumn from '../../utils/buildTextWithCharacterCountColumn';
import AssignToSitelinkGroupModal from './AssignToSitelinkGroupModal';

interface GlobalSitelinkToolProps {
  data: any;
  isFetching?: boolean;
  setQueryOptions?: any;
  paginationModel?: any;
  setPaginationModel?: any;
  isGroupView?: boolean;
}

const GlobalSitelinkTool: React.FC<GlobalSitelinkToolProps> = ({
  data,
  isFetching = false,
  setQueryOptions,
  paginationModel,
  setPaginationModel,
  isGroupView = false,
}) => {
  const [updateSitelink] = useUpdateSitelinkMutation();
  const [deleteSitelinks] = useDeleteSitelinksMutation();
  const [createSitelinks] = useCreateSitelinksMutation();
  const [selectedId, setSelectedId] = useState('');
  const initialValues = {
    id: '',
    header: '',
    description_one: '',
    description_two: '',
    url: '',
    meta_description: '',
    approved: false,
    is_global: true,
  } as Sitelink;
  const { data: selectedIdData = initialValues } = useFetchSitelinkQuery(
    { sitelinkId: selectedId },
    {
      skip: !selectedId,
    }
  );
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle(`Sitelinks Tool`);
  }, [setTitle]);

  const validationSchema = Yup.object().shape({
    header: priceAssetAndSitelinkHeaderValidationSchema,
    description_one: sitelinkDescriptionValidationSchema,
    description_two: sitelinkDescriptionValidationSchema,
    url: urlValidationSchema,
  });

  const getColumns = (
    handleOpen: (id: string) => void,
    handleOpenSiteSurfModal?: (
      row: GridRowModel,
      apiRef?: React.MutableRefObject<GridApi>
    ) => void
  ) => [
    {
      field: 'id',
      headerName: 'Edit',
      width: 90,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <IconButton
            onClick={() => {
              handleOpen(params.row.id);
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        );
      },
    },
    buildTextWithCharacterCountColumn(
      'header',
      'Header',
      CharacterLimitForContent.SitelinkHeader,
      priceAssetAndSitelinkHeaderValidationSchema
    ),
    {
      field: 'description_one',
      headerName: 'Description 1',
      editable: true,
      flex: 1,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <TableCellWithValidation
          {...params}
          limit={35}
          validationSchema={sitelinkDescriptionValidationSchema}
        />
      ),
    },
    {
      field: 'description_two',
      headerName: 'Description 2',
      editable: true,
      flex: 1,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <TableCellWithValidation
          {...params}
          limit={35}
          validationSchema={sitelinkDescriptionValidationSchema}
        />
      ),
    },
    {
      field: 'meta_description',
      headerName: 'Meta Description',
      editable: true,
      flex: 1,
    },
    {
      field: 'url',
      headerName: 'Url',
      editable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <UrlCell
          params={params}
          handleEditClick={handleOpenSiteSurfModal}
          allowGlobalEdit={true}
        />
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <>
          <UrlCell
            params={params}
            handleEditClick={handleOpenSiteSurfModal}
            allowGlobalEdit={true}
          />
          <TableCellWithValidation
            {...params}
            validationSchema={urlValidationSchema}
          />
        </>
      ),
    },
    {
      field: 'assigned_to_count',
      headerName: 'Assigned To',
      width: 90,
    },
  ];

  const fieldNames = [
    'header',
    'description_one',
    'description_two',
    'url',
    'meta_description',
  ];

  return (
    <BaseAssignToolDataTable
      data={data}
      isFetching={isFetching}
      assetType={GlobalAssetType.Sitelink}
      getColumns={getColumns}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      setQueryOptions={setQueryOptions}
      updateAsset={async (asset: any) => {
        await updateSitelink(asset as Sitelink).unwrap();
      }}
      deleteAssets={deleteSitelinks}
      createAsset={async (asset: any) => {
        await createSitelinks({ sitelinks: [asset] as Sitelink[] });
      }}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      selectedIdData={selectedIdData}
      validationSchema={validationSchema}
      fieldNames={fieldNames}
      maxAllowableContent={MaxAllowableContent.Sitelinks}
      AssignToAssetGroupModal={AssignToSitelinkGroupModal}
      isGroupView={isGroupView}
    />
  );
};

export default GlobalSitelinkTool;
