import {
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
} from '@mui/x-data-grid-premium';

interface CustomFilterColumnsArgs extends FilterColumnsArgs {
  uniqueFilterColumns: string[];
}

export const filterColumns = ({
  field,
  columns,
  currentFilters,
  uniqueFilterColumns,
}: CustomFilterColumnsArgs) => {
  const filteredFields = currentFilters?.map((item) => item.field);
  return columns
    .filter(
      (colDef) =>
        (colDef.filterable &&
          (colDef.field === field || !filteredFields.includes(colDef.field))) ||
        !uniqueFilterColumns.includes(colDef.field)
    )
    .map((column) => column.field);
};

interface CustomGetColumnForNewFilterArgs extends GetColumnForNewFilterArgs {
  uniqueFilterColumns: string[];
}

export const getColumnForNewFilter = ({
  currentFilters,
  columns,
  uniqueFilterColumns,
}: CustomGetColumnForNewFilterArgs) => {
  const filteredFields = currentFilters?.map(({ field }) => field);
  const columnForNewFilter = columns
    .filter(
      (colDef) =>
        (colDef.filterable &&
          uniqueFilterColumns.includes(colDef.field) &&
          !filteredFields.includes(colDef.field)) ||
        (colDef.filterable && !uniqueFilterColumns.includes(colDef.field))
    )
    .find((colDef) => colDef.filterOperators?.length);
  return columnForNewFilter?.field ?? null;
};
