import React, { PropsWithChildren } from 'react';

import { ListItemButton } from '@mui/material';
import styles from './StripedListItemButton.module.scss';

const StripedListItemButton: React.FC<PropsWithChildren<{ index: number }>> = ({
  index,
  children,
}) => {
  return (
    <ListItemButton className={index % 2 === 0 ? styles.even : styles.odd}>
      {children}
    </ListItemButton>
  );
};

export default StripedListItemButton;
