import React, { useState } from 'react';
import { Field, Formik, FormikProps } from 'formik';
import {
  Box,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Slide,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { enqueueSnackbar } from 'notistack';
import SiteSurfModal from '../SiteSurfModal';
import { UrlType, GlobalAssetType, PriceAssetType } from '../../types/enums';
import { Sitelink } from '../../types/sitelinks';
import { PriceAsset } from '../../types/priceAssets';
import { Headline, Description } from '../../types/pinnable';
import TextFieldInput from './TextFieldInput';
import URLFieldInput from './URLFieldInput';
import formatReadableFromSnakeCase from '../../utils/formatReadableFromSnakeCase';
import { formatReadableGlobalAssetType } from '../../utils/formatReadableGlobalAssetType';
import AssignedHeadline from './AssignedHeadline';
import AssignedDescription from './AssignedDescription';
import AssignedPriceAsset from './AssignedPriceAsset';
import AssignedSitelink from './AssignedSitelink';

interface CreateOrUpdateGlobalContentModalProps {
  assetType: GlobalAssetType;
  open: boolean;
  handleClose: () => void;
  id: string;
  updateAsset: (asset: any) => Promise<void>;
  createAsset: (asset: any) => Promise<void>;
  selectedIdData: any;
  validationSchema: any;
  fieldNames: string[];
  priceAssetType?: PriceAssetType;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateOrUpdateGlobalContentModal: React.FC<
  CreateOrUpdateGlobalContentModalProps
> = ({
  open,
  handleClose,
  id,
  assetType,
  updateAsset,
  createAsset,
  selectedIdData,
  validationSchema,
  fieldNames,
  priceAssetType,
}) => {
  const hasUrlField =
    assetType === GlobalAssetType.Sitelink ||
    assetType === GlobalAssetType.PriceAsset;
  const getUrl = () => {
    if ('url' in selectedIdData) {
      return selectedIdData.url;
    }
    return process.env.REACT_APP_BASE_URL;
  };

  const [siteSurfModalOpen, setSiteSurfModalOpen] = useState(false);

  const handleSave = async (
    asset: Sitelink | PriceAsset | Headline | Description
  ) => {
    try {
      if (id) {
        await updateAsset(asset);
      } else {
        if (
          assetType === GlobalAssetType.HeadlineGroup ||
          assetType === GlobalAssetType.DescriptionGroup ||
          assetType === GlobalAssetType.SitelinkGroup ||
          assetType === GlobalAssetType.PriceAssetGroup
        ) {
          await createAsset(asset);
        } else {
          const assetToCreate: any = { ...asset };
          assetToCreate.is_global = true;
          if (assetType === GlobalAssetType.PrimaryHeadline) {
            assetToCreate.global_pinned = 'HEADLINE_1';
          }
          await createAsset(assetToCreate);
        }
      }
      enqueueSnackbar(`Global ${assetType} saved successfully`, {
        variant: 'success',
      });
      handleClose();
    } catch (e: any) {
      if (e.data?.detail?.message) {
        enqueueSnackbar(e.data?.detail?.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(`Error saving Global ${assetType}`, {
          variant: 'error',
        });
      }
    }
  };
  const closeModal = () => {
    handleClose();
  };

  const loadForm: React.FC<FormikProps<any>> = ({
    errors,
    touched,
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    setTouched,
    setFieldValue,
  }) => {
    const getPinOptions = () => {
      switch (assetType) {
        case GlobalAssetType.PrimaryHeadline:
          return ['HEADLINE_1'];
        case GlobalAssetType.GeneralHeadline:
          return ['HEADLINE_2', 'HEADLINE_3'];
        case GlobalAssetType.Description:
          return ['DESCRIPTION_1', 'DESCRIPTION_2'];
        default:
          return [];
      }
    };
    return (
      <>
        <DialogTitle
          sx={{ bgcolor: 'primary.main', color: 'white' }}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {id ? 'Edit' : 'Add'} Global{' '}
          {formatReadableGlobalAssetType(assetType, 1)} {' - '} {id}
          <IconButton
            sx={{ color: 'white' }}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              boxShadow: 24,
              p: 4,
              gridTemplateRows: 'repeat(3, 1fr)',
              position: 'relative',
            }}
          >
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {fieldNames.map((field) => {
                switch (field) {
                  case 'url':
                    return (
                      <Grid item xs={12} key={field}>
                        <URLFieldInput
                          key={field}
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setTouched={setTouched}
                          setSiteSurfModalOpen={setSiteSurfModalOpen}
                        />
                      </Grid>
                    );
                  case 'max_products':
                    return (
                      <Grid item xs={12} key={field}>
                        <TextFieldInput
                          key={field}
                          fieldName={field}
                          errors={errors}
                          touched={touched}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setTouched={setTouched}
                          type="number"
                        />
                      </Grid>
                    );
                  case 'global_pinned':
                    return (
                      <Grid item xs={12} key={field}>
                        <Typography variant="body1">
                          <span style={{ fontWeight: 'bold' }}>
                            {' '}
                            Global Pinned:{' '}
                          </span>
                        </Typography>
                        <Field
                          as="select"
                          name="global_pinned"
                          style={{ padding: '.75rem', borderRadius: '5px' }}
                        >
                          {assetType !== GlobalAssetType.PrimaryHeadline && (
                            <option value="">No Pin</option>
                          )}
                          {getPinOptions().map((pin) => (
                            <option key={pin} value={pin}>
                              {formatReadableFromSnakeCase(pin)}
                            </option>
                          ))}
                        </Field>
                      </Grid>
                    );
                  case 'price':
                    return (
                      <Grid item xs={12} key={field}>
                        <TextFieldInput
                          key={field}
                          fieldName={field}
                          errors={errors}
                          touched={touched}
                          placeholder="Leave Blank for Auto-Pricing"
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setTouched={setTouched}
                        />
                      </Grid>
                    );
                  case 'price_asset_type':
                    return (
                      <Grid item xs={12} key={field}>
                        <Typography variant="body1">
                          <span style={{ fontWeight: 'bold' }}>
                            {' '}
                            Price Asset Type:{' '}
                          </span>
                        </Typography>
                        <Field
                          as="select"
                          name="price_asset_type"
                          style={{ padding: '.75rem', borderRadius: '5px' }}
                          defaultValue={priceAssetType}
                        >
                          <option value={PriceAssetType.Brands}>Brands</option>
                          <option value={PriceAssetType.ProductCategories}>
                            Product Categories
                          </option>
                          <option value={PriceAssetType.ProductTiers}>
                            Product Tiers
                          </option>
                        </Field>
                      </Grid>
                    );
                  default:
                    return (
                      <Grid item xs={12} key={field}>
                        <TextFieldInput
                          key={field}
                          fieldName={field}
                          errors={errors}
                          touched={touched}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setTouched={setTouched}
                        />
                      </Grid>
                    );
                }
              })}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleSubmit()}
            >
              {!!id ? 'Save' : 'Add'}
            </Button>
          </Box>
        </DialogActions>

        {siteSurfModalOpen && hasUrlField && (
          <SiteSurfModal
            setModalOpen={setSiteSurfModalOpen}
            url={getUrl()}
            urlType={UrlType.PreCreatedSitelink}
            title={
              !!id
                ? `Edit Global ${assetType} URL`
                : `Create Global ${assetType} URL`
            }
            exportCallback={(value) => {
              setFieldValue('url', value);
              setSiteSurfModalOpen(false);
            }}
          />
        )}
      </>
    );
  };

  return (
    <Dialog
      open={open}
      scroll="body"
      onClose={closeModal}
      maxWidth="xl"
      fullScreen
      TransitionComponent={Transition}
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={selectedIdData}
        enableReinitialize={true}
        onSubmit={handleSave}
      >
        {loadForm}
      </Formik>
      <Box>
        {assetType === GlobalAssetType.HeadlineGroup && selectedIdData.id && (
          <AssignedHeadline headlines={selectedIdData.headlines} />
        )}
        {assetType === GlobalAssetType.DescriptionGroup &&
          selectedIdData.id && (
            <AssignedDescription descriptions={selectedIdData.descriptions} />
          )}
        {assetType === GlobalAssetType.PriceAssetGroup && selectedIdData.id && (
          <AssignedPriceAsset priceAssets={selectedIdData.priceAssets} />
        )}
        {assetType === GlobalAssetType.SitelinkGroup && selectedIdData.id && (
          <AssignedSitelink sitelinks={selectedIdData.sitelinks} />
        )}
      </Box>
    </Dialog>
  );
};

export default CreateOrUpdateGlobalContentModal;
