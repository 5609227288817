import { useState } from 'react';
import { GridFilterModel } from '@mui/x-data-grid-premium';
import { useFetchAllGlobalDescriptionsQuery } from '../../slices/descriptionSlice';
import GlobalDescriptionSection from './GlobalDescriptionSection';

const AllGlobalDescriptionsSection: React.FC = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 24,
  });
  const [queryOptions, setQueryOptions] = useState<{
    filterModel?: GridFilterModel;
  }>();
  const { data = { total: 0, results: [] }, isFetching } =
    useFetchAllGlobalDescriptionsQuery({
      ...paginationModel,
      ...queryOptions,
    });

  return (
    <GlobalDescriptionSection
      data={data}
      isFetching={isFetching}
      setQueryOptions={setQueryOptions}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
    />
  );
};
export default AllGlobalDescriptionsSection;
