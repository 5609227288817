import { emptySplitApi } from './baseApi';
import { PriceAsset } from '../types/priceAssets';
import { PriceAssetType } from '../types/enums';
import { GridFilterModel, GridRowId } from '@mui/x-data-grid-premium';
import { broadcastCacheInvalidator } from '../utils/broadcastCacheInvalidator';
import { formatFilters } from '../utils/formatFilters';

export const priceAssetApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchPriceAssets: builder.query<PriceAsset[], string | void>({
      query(adgroup_id) {
        return `/price_assets/${adgroup_id}`;
      },
      providesTags: ['PriceAssets'],
    }),
    createPriceAssets: builder.mutation<
      void,
      {
        ad_group_id?: string;
        price_assets: Pick<
          PriceAsset,
          | 'header'
          | 'short_description'
          | 'price'
          | 'url'
          | 'max_products'
          | 'is_global'
          | 'alpine_id'
          | 'image_url'
          | 'price_asset_type'
        >[];
      }
    >({
      query: ({ price_assets, ad_group_id }) => ({
        url: `/price_assets/many`,
        method: 'POST',
        body: { price_assets, ad_group_id },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssets',
          'GlobalPriceAssets',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    updatePriceAsset: builder.mutation<
      PriceAsset,
      Pick<
        PriceAsset,
        | 'header'
        | 'short_description'
        | 'price'
        | 'alpine_id'
        | 'url'
        | 'max_products'
        | 'image_url'
        | 'id'
        | 'approved'
        | 'price_asset_type'
      >
    >({
      query: (body) => ({
        url: `/price_assets/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssets',
          'PriceAsset',
          'PriceAssetGroup',
          'PriceAssetGroups',
          'PromptPreviewElements',
          'GlobalPriceAssets',
          'AdGroups',
        ]),
    }),
    regeneratePriceAssets: builder.mutation<
      PriceAsset[],
      { ad_group_id: string; ids: string[] | GridRowId[] }
    >({
      query: (body) => ({
        url: `/price_assets/regenerate`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssets',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    regenerateAllPriceAssets: builder.mutation<
      PriceAsset[],
      { ad_group_id: string }
    >({
      query: (body) => ({
        url: `/price_assets/regenerate_all`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssets',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    approvePriceAssets: builder.mutation<
      PriceAsset[],
      { ids: string[] | GridRowId[]; ad_group_id: string }
    >({
      query: (body) => ({
        url: `/price_assets/approve`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssets',
          'AdGroup',
          'AdGroupCounts',
          'GlobalPriceAssets',
          'AdGroups',
        ]),
    }),
    unapprovePriceAssets: builder.mutation<
      PriceAsset[],
      { ids: string[] | GridRowId[]; ad_group_id: string }
    >({
      query: (body) => ({
        url: `/price_assets/unapprove`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssets',
          'AdGroup',
          'AdGroupCounts',
          'GlobalPriceAssets',
          'AdGroups',
        ]),
    }),
    deletePriceAssets: builder.mutation<PriceAsset[], { ids: GridRowId[] }>({
      query: (body) => ({
        url: `/price_assets/`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssets',
          'GlobalPriceAssets',
          'PriceAssetGroup',
          'PriceAssetGroups',
          'AdGroupCounts',
          'PromptPreviewElements',
          'AdGroups',
        ]),
    }),
    fetchPriceAsset: builder.query<PriceAsset, { priceAssetId: string }>({
      query: ({ priceAssetId }) => ({
        url: `/price_assets/`,
        method: 'GET',
        params: { price_asset_id: priceAssetId },
      }),
      providesTags: ['PriceAsset'],
    }),
    fetchAllGlobalPriceAssets: builder.query<
      { results: PriceAsset[]; total: number },
      {
        page: number;
        pageSize: number;
        priceAssetType: PriceAssetType;
        filterModel?: GridFilterModel;
      }
    >({
      query: ({ page, pageSize, priceAssetType, filterModel }) => {
        const filters = formatFilters(filterModel);
        filters.push({
          field: 'price_asset_type',
          operator: 'equals',
          value: priceAssetType,
        });
        return {
          url: '/price_assets/global',
          method: 'POST',
          params: {
            page,
            pageSize,
            logic_operator: filterModel?.logicOperator || 'and',
          },
          body: {
            filters,
          },
        };
      },
      providesTags: ['GlobalPriceAssets'],
    }),
    assignPriceAssetsToAdgroup: builder.mutation<
      void,
      { priceAssetIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ priceAssetIds, adGroupIds }) => ({
        url: `/price_assets/assign`,
        method: 'POST',
        body: {
          price_asset_ids: priceAssetIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssets',
          'GlobalPriceAssets',
          'PromptPreviewElements',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    unassignPriceAssetsFromAdgroup: builder.mutation<
      void,
      { priceAssetIds: GridRowId[]; adGroupIds: GridRowId[] }
    >({
      query: ({ priceAssetIds, adGroupIds }) => ({
        url: `/price_assets/unassign`,
        method: 'POST',
        body: {
          price_asset_ids: priceAssetIds,
          ad_group_ids: adGroupIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAssets',
          'PromptPreviewElements',
          'GlobalPriceAssets',
          'AdGroupCounts',
          'AdGroups',
        ]),
    }),
    convertPriceAssetsToGlobal: builder.mutation<
      void,
      { priceAssetIds: GridRowId[] }
    >({
      query: ({ priceAssetIds }) => ({
        url: `/price_assets/global/convert`,
        method: 'PUT',
        body: {
          price_asset_ids: priceAssetIds,
        },
      }),
      invalidatesTags: () =>
        broadcastCacheInvalidator([
          'PriceAsset',
          'PriceAssets',
          'GlobalPriceAssets',
        ]),
    }),
  }),
});

export const {
  useFetchPriceAssetQuery,
  useFetchPriceAssetsQuery,
  useCreatePriceAssetsMutation,
  useUpdatePriceAssetMutation,
  useApprovePriceAssetsMutation,
  useUnapprovePriceAssetsMutation,
  useRegeneratePriceAssetsMutation,
  useRegenerateAllPriceAssetsMutation,
  useDeletePriceAssetsMutation,
  useFetchAllGlobalPriceAssetsQuery,
  useAssignPriceAssetsToAdgroupMutation,
  useUnassignPriceAssetsFromAdgroupMutation,
  useConvertPriceAssetsToGlobalMutation,
} = priceAssetApiSlice;
