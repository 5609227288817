import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ReportLoadingDialogProps {
  open: boolean;
  onClose: () => void;
}

const ReportLoadingDialog: React.FC<ReportLoadingDialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="loading-dialog-title"
      aria-describedby="loading-dialog-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 8,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="loading-dialog-title" variant="h6" component="h2">
          Loading..
        </Typography>
        <Typography id="loading-dialog-description" sx={{ mt: 2 }}>
          Please wait while the report data is queried.
        </Typography>
      </Box>
    </Modal>
  );
};

export default ReportLoadingDialog;
