import React from 'react';
import { MenuItem, InputLabel, FormControl } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { PriceAssetPriceQualifier } from '../../../types/enums';

interface ChoosePriceAssetPriceQualifierProps {
  handleSetPriceAssetPriceQualifier: (
    priceAssetPriceQualifier: PriceAssetPriceQualifier
  ) => void;
  selectedPriceAssetPriceQualifier: PriceAssetPriceQualifier;
}

const ChoosePriceAssetPriceQualifier: React.FC<
  ChoosePriceAssetPriceQualifierProps
> = ({
  handleSetPriceAssetPriceQualifier,
  selectedPriceAssetPriceQualifier,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    handleSetPriceAssetPriceQualifier(
      event.target.value as PriceAssetPriceQualifier
    );
  };

  return (
    <FormControl sx={{ minWidth: '200px' }}>
      <InputLabel
        id="price-asset-price-qualifier-select-label"
        sx={{ backgroundColor: 'white' }}
      >
        Ad Group Price Qualifier
      </InputLabel>
      <Select
        labelId="price-asset-price-qualifier-select-label"
        id="price-asset-price-qualifier-select"
        value={selectedPriceAssetPriceQualifier}
        label="Price Qualifier"
        onChange={handleChange}
      >
        <MenuItem value={PriceAssetPriceQualifier.From}>From</MenuItem>
        <MenuItem value={PriceAssetPriceQualifier.UpTo}>Up to</MenuItem>
        <MenuItem value={PriceAssetPriceQualifier.Average}>Average</MenuItem>
      </Select>
    </FormControl>
  );
};
export default ChoosePriceAssetPriceQualifier;
