import {
  GridActionsCellItem,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import {
  Refresh as RefreshIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Edit as EditIcon,
  NetworkPing as NetworkPingIcon,
  LockOpen as LockOpenIcon,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';

type Handlers = {
  handleApproveClick: (ids: GridRowId[]) => () => void;
  handleUnapproveClick: (ids: GridRowId[]) => () => void;
  handleRegenerateClick: (ids: GridRowId[]) => () => void;
  handleEditClick: (id: GridRowId) => () => void;
  handleCancelClick: (id: GridRowId) => () => void;
  handleSaveClick: (id: GridRowId) => () => void;
  handleDeleteClick: (ids: GridRowId[]) => () => void;
  handleConvertLocalClick?: (id: GridRowId, row?: any) => () => void;
  handleToggleKeywordInsertionClick?: (id: GridRowId) => () => void;
};

export const getActions =
  (rowModesModel: GridRowModesModel, handlers: Handlers) =>
  ({ id, row }: GridRowParams) => {
    const isPrimaryHeadline = row.pinned === 'HEADLINE_1';

    const actions = [];
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      return [
        <GridActionsCellItem
          color="primary"
          icon={<SaveIcon fontSize="small" />}
          label="Save"
          onClick={handlers.handleSaveClick(id)}
        />,
        <GridActionsCellItem
          color="error"
          icon={<CancelIcon fontSize="small" />}
          label="Cancel"
          onClick={handlers.handleCancelClick(id)}
        />,
      ];
    }
    if (isPrimaryHeadline && handlers.handleToggleKeywordInsertionClick) {
      actions.push(
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Toggle keyword insertion for this headline." arrow>
              <NetworkPingIcon fontSize="small" />
            </Tooltip>
          }
          label="Toggle Keyword Insertion"
          onClick={handlers.handleToggleKeywordInsertionClick(id)}
        />
      );
    }

    row.is_global && handlers.handleConvertLocalClick
      ? actions.push(
          <GridActionsCellItem
            icon={
              <Tooltip
                title="Convert this global sitelink into a local/editable one. The original global sitelink will be unaffected."
                arrow
              >
                <LockOpenIcon fontSize="small" />
              </Tooltip>
            }
            label="Make Local and Editable"
            onClick={handlers.handleConvertLocalClick(id, row)}
          />
        )
      : actions.push(
          <GridActionsCellItem
            icon={<EditIcon fontSize="small" />}
            label="Edit"
            onClick={handlers.handleEditClick(id)}
          />
        );

    if (row.approved) {
      actions.push(
        <GridActionsCellItem
          color="warning"
          icon={<ThumbDownIcon fontSize="small" />}
          label="Unapprove"
          onClick={handlers.handleUnapproveClick([id])}
        />
      );

      return actions;
    }

    actions.push(
      <GridActionsCellItem
        color="success"
        icon={<ThumbUpIcon fontSize="small" />}
        label="Approve"
        onClick={handlers.handleApproveClick([id])}
      />
    );

    actions.push(
      <GridActionsCellItem
        color="warning"
        icon={<RefreshIcon fontSize="small" />}
        label="Regenerate"
        onClick={handlers.handleRegenerateClick([id])}
      />
    );

    actions.push(
      <GridActionsCellItem
        color="error"
        icon={<DeleteIcon fontSize="small" />}
        label="Delete"
        onClick={handlers.handleDeleteClick([id])}
      />
    );

    return actions;
  };
