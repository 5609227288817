import React, { useCallback, useState } from 'react';
import {
  Card,
  Grid,
  Paper,
  Button,
  Modal,
  Box,
  Typography,
} from '@mui/material';
import {
  DataGridPremium,
  GridRowId,
  GridApi,
  GridRowModesModel,
  GridRowModes,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import {
  GridFilterModel,
  GridRowModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import { Add as AddIcon } from '@mui/icons-material';
import CreateOrUpdateGlobalContentModal from '../../components/CreateOrUpdateGlobalContentModal';
import SiteSurfModal from '../../components/SiteSurfModal';
import AssignToAdGroupModal from '../../components/AssignToAdGroupModal';
import {
  UrlType,
  GlobalAssetType,
  MaxAllowableContent,
  PriceAssetType,
} from '../../types/enums';
import { formatReadableGlobalAssetType } from '../../utils/formatReadableGlobalAssetType';

declare module '@mui/x-data-grid' {
  interface FilterPanelPropsOverrides {
    onKeyDown: (event: KeyboardEvent) => void;
    anchorEl: HTMLElement | null;
    placement: string;
  }
}

interface FilterToolbarProps {
  selectedIds: GridRowId[];
  assetType: GlobalAssetType;
  setAdGroupModalOpen: (open: boolean) => void;
  setAssetGroupModalOpen: (open: boolean) => void;
  deleteAssets: any;
  handleOpen: (id: string) => void;
  isGroupView?: boolean;
}

const FilterToolbar: React.FC<FilterToolbarProps> = ({
  selectedIds,
  assetType,
  deleteAssets,
  handleOpen,
  setAdGroupModalOpen,
  setAssetGroupModalOpen,
  isGroupView,
}) => {
  const [deletModalOpen, setDeleteModalOpen] = useState(false);
  const count = selectedIds.length;
  return (
    <>
      <GridToolbarContainer>
        {!isGroupView && <GridToolbarFilterButton />}
        {!isGroupView && (
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => handleOpen('')}
          >
            Add
          </Button>
        )}

        {!!count && (
          <>
            {!isGroupView && (
              <Button
                variant="outlined"
                onClick={() => setAdGroupModalOpen(true)}
              >
                Assign / Unassign
              </Button>
            )}
            {(assetType === GlobalAssetType.GeneralHeadline ||
              assetType === GlobalAssetType.PrimaryHeadline ||
              assetType === GlobalAssetType.Description ||
              assetType === GlobalAssetType.Sitelink ||
              assetType === GlobalAssetType.PriceAsset) && (
              <Button
                variant="outlined"
                onClick={() => setAssetGroupModalOpen(true)}
              >
                Add/Remove from Group
              </Button>
            )}
            {!isGroupView && (
              <Button
                variant="outlined"
                onClick={() => {
                  setDeleteModalOpen(true);
                }}
              >
                Delete {count} {formatReadableGlobalAssetType(assetType, count)}
              </Button>
            )}
          </>
        )}
        <Modal
          open={deletModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          aria-labelledby="delete-asset-modal-title"
          aria-describedby="delete-asset-modal-description"
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="delete-modal-title" variant="h6" component="h2">
              Are you sure you want to delete?
            </Typography>
            <Typography id="delete-modal-description" sx={{ mt: 2 }}>
              This action cannot be undone.
            </Typography>
            <Box marginTop={1}>
              <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
              <Button
                color="error"
                variant="contained"
                onClick={() => {
                  deleteAssets({ ids: selectedIds as string[] });
                  setDeleteModalOpen(false);
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>
      </GridToolbarContainer>
    </>
  );
};

interface BaseAssignToolDataTableProps {
  data: any;
  isFetching: boolean;
  assetType: GlobalAssetType;
  getColumns: (
    handleOpen: (id: string) => void,
    handleOpenSiteSurfModal?: (
      row: GridRowModel,
      apiRef?: React.MutableRefObject<GridApi>
    ) => void
  ) => any;
  paginationModel: any;
  setPaginationModel: any;
  setQueryOptions: any;
  updateAsset: (asset: any) => Promise<void>;
  deleteAssets: any;
  createAsset: (asset: any) => Promise<void>;
  selectedId: string;
  setSelectedId: (id: string) => void;
  selectedIdData: any;
  validationSchema: any;
  fieldNames: string[];
  maxAllowableContent: MaxAllowableContent;
  priceAssetType?: PriceAssetType;
  isGroupView?: boolean;
  AssignToAssetGroupModal?: React.FC<any>;
}

const BaseAssignToolDataTable: React.FC<BaseAssignToolDataTableProps> = ({
  data,
  isFetching,
  assetType,
  getColumns,
  paginationModel,
  setPaginationModel,
  setQueryOptions,
  updateAsset,
  deleteAssets,
  createAsset,
  selectedId,
  setSelectedId,
  selectedIdData,
  validationSchema,
  fieldNames,
  maxAllowableContent,
  priceAssetType,
  isGroupView,
  AssignToAssetGroupModal,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [adGroupModalOpen, setAdGroupModalOpen] = useState(false);
  const [assetGroupModalOpen, setAssetGroupModalOpen] = useState(false);
  const [siteSurfModalOpen, setSiteSurfModalOpen] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [selectedMaxProducts, setSelectedMaxProducts] = useState(100);
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);
  const [selectedRow, setSelectedRow] = useState<GridRowModel | null>(null);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [dataGridApiRef, setDataGridApiRef] =
    useState<React.MutableRefObject<GridApi>>();
  const apiRef = useGridApiRef();

  const handleOpen = (id: string) => {
    setSelectedId(id);
    setSelectedRow(null);
    setSelectedUrl('');
    setModalOpen(true);
  };

  const handleOpenSiteSurfModal = (
    row: GridRowModel,
    apiRef?: React.MutableRefObject<GridApi>
  ) => {
    if (apiRef) {
      setDataGridApiRef(apiRef);
    }
    setSelectedId(row.id as string);
    setSelectedUrl(row.url);
    setSelectedMaxProducts(row.max_products);
    setSiteSurfModalOpen(true);
    setSelectedRow(row);
  };

  const onFilterChange = useCallback(
    (filterModel: GridFilterModel) => {
      setQueryOptions({ filterModel: { ...filterModel } });
    },
    [setQueryOptions]
  );

  const handleSave = async (updatedRow: any) => {
    try {
      await updateAsset(updatedRow);
      const message = `${formatReadableGlobalAssetType(assetType, 1)} saved successfully`;
      enqueueSnackbar(message, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(
        `Error saving Global ${formatReadableGlobalAssetType(assetType, 1)}`,
        {
          variant: 'error',
        }
      );
    }
  };
  const handleExport = (url: string, id: string, maxProduct?: number) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    if (isInEditMode && dataGridApiRef) {
      dataGridApiRef.current.setEditCellValue({
        id: id,
        field: 'url',
        value: url,
      });
      setSiteSurfModalOpen(false);
    } else {
      let obj = maxProduct
        ? { ...selectedRow, url: url, max_products: maxProduct }
        : { ...selectedRow, url: url };
      handleSave(obj).then(() => {
        setSiteSurfModalOpen(false);
      });
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    handleSave(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <Card sx={{ marginTop: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ width: '100%' }}>
              <DataGridPremium
                checkboxSelection
                disableMultipleRowSelection={true}
                autoHeight={true}
                rows={data.results}
                columns={getColumns(handleOpen, handleOpenSiteSurfModal)}
                rowCount={data.total}
                pagination={true}
                paginationMode="server"
                loading={isFetching}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                pageSizeOptions={[24, 48, 96]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                onRowSelectionModelChange={(selections) =>
                  setSelectedIds(selections)
                }
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                slots={{ toolbar: FilterToolbar }}
                processRowUpdate={processRowUpdate}
                apiRef={apiRef}
                slotProps={{
                  toolbar: {
                    selectedIds,
                    assetType,
                    deleteAssets,
                    handleOpen,
                    setAdGroupModalOpen,
                    setAssetGroupModalOpen,
                    isGroupView,
                  },
                  filterPanel: {
                    onKeyDown: (event: KeyboardEvent) => {
                      if (
                        event.key === 'Escape' ||
                        (event.key === 'Enter' && !event.shiftKey)
                      ) {
                        apiRef.current.hideFilterPanel();
                      }
                    },
                    sx: {
                      position: 'absolute',
                      top: `-110px !important`,
                      right: '-310px',
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                    },
                  },
                }}
              />
            </div>
            <CreateOrUpdateGlobalContentModal
              assetType={assetType}
              open={modalOpen}
              handleClose={() => setModalOpen(false)}
              id={selectedId}
              selectedIdData={selectedId !== '' ? selectedIdData : {}}
              updateAsset={updateAsset}
              createAsset={createAsset}
              validationSchema={validationSchema}
              fieldNames={fieldNames}
              priceAssetType={priceAssetType}
            />
            {siteSurfModalOpen && (
              <SiteSurfModal
                setModalOpen={setSiteSurfModalOpen}
                url={selectedUrl}
                urlType={
                  assetType === GlobalAssetType.PriceAsset
                    ? UrlType.PriceAsset
                    : UrlType.PreCreatedSitelink
                }
                title="Select URL"
                exportCallback={(value, maxProduct) => {
                  handleExport(value, selectedId, maxProduct);
                }}
                initialMaxProducts={selectedMaxProducts}
              />
            )}
            {adGroupModalOpen && (
              <AssignToAdGroupModal
                setModalOpen={setAdGroupModalOpen}
                assetType={assetType}
                assetIds={selectedIds}
                maxAllowableContent={maxAllowableContent}
              />
            )}
            {assetGroupModalOpen && AssignToAssetGroupModal && (
              <AssignToAssetGroupModal
                setModalOpen={setAssetGroupModalOpen}
                assetType={assetType}
                assetIds={selectedIds}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BaseAssignToolDataTable;
