import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

interface CharacterCountCellProps {
  params: GridRenderCellParams;
  fieldName: string;
  maxCharacters?: number;
}

const CharacterCountCell: React.FC<CharacterCountCellProps> = ({
  params,
  fieldName,
  maxCharacters,
}) => {
  const keywordInsertionRegex = /{keyword:|}/gi;
  const valueWithKeywordInsertionRemoved = params.row[fieldName].replace(
    keywordInsertionRegex,
    ''
  );
  const valueLength = valueWithKeywordInsertionRemoved.length;
  const isOverMaxCharacters = maxCharacters && valueLength > maxCharacters;
  return isOverMaxCharacters ? (
    <span style={{ color: 'red' }}>
      {valueLength} / {maxCharacters}
    </span>
  ) : (
    <>{valueLength}</>
  );
};

export default CharacterCountCell;
