import * as Yup from 'yup';

export const sitelinkDescriptionValidationSchema = Yup.string()
  .required('Description is required')
  .max(35, 'Max 35 characters');

const urlPattern = new RegExp(
  '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'
);

export const urlValidationSchema = Yup.string()
  .required('URL is required')
  .matches(urlPattern, 'Please enter a valid URL');

export const headlineValidationSchema = Yup.string()
  .required('Headline is required')
  .matches(/^[^!]*$/, 'Invalid Character: Exclamation point (!)')
  .test({
    test: (val: string) => {
      const regex = /{keyword:|}/gi;
      return val.replace(regex, '').length <= 30;
    },
    message: 'Headline cannot be longer than 30 characters',
  });

export const descriptionValidationSchema = Yup.string()
  .required('Description is required')
  .max(90);

export const priceAssetAndSitelinkHeaderValidationSchema = Yup.string()
  .required('Header is required')
  .max(25);

export const priceAssetShortDescriptionValidationSchema = Yup.string()
  .required('Short description is required')
  .max(25);

export const displayPathValidationSchema = Yup.string()
  .required('Required')
  .matches(/^[0-9a-zA-Z-_]*$/, 'Invalid character')
  .max(15, 'Max 15 characters');

export const groupNameValidationSchema = Yup.string().required();
