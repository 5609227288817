import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Slide,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import {
  useFetchPromptTemplateQuery,
  useSavePromptTemplateMutation,
} from '../../slices/promptSlice';
import { enqueueSnackbar } from 'notistack';
import { formatTemplateTypeString } from '../../utils/formatTemplateTypeString';
import ModalInputBox from './ModalInputBox';

interface PromptTemplateModalProps {
  open: boolean;
  handleClose: () => void;
  id: string;
  templateType: string;
}

const style = {
  boxShadow: 24,
  p: 4,
};

const modalStyle = {
  ...style,
  gridTemplateRows: 'repeat(3, 1fr)',
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PromptTemplateModal: React.FC<PromptTemplateModalProps> = ({
  open,
  handleClose,
  id,
  templateType,
}) => {
  const {
    data = {
      id: '',
      name: '',
      headlines_prompt_goal: '',
      headlines_prompt_examples: '',
      descriptions_prompt_goal: '',
      descriptions_prompt_examples: '',
      site_links_prompt_goal: '',
      site_links_prompt_examples: '',
    },
  } = useFetchPromptTemplateQuery({
    templateType,
    templateId: id,
  });
  const [savePromptTemplate] = useSavePromptTemplateMutation();

  const [currentPrompts, setCurrentPrompts] = useState({ ...data });

  useEffect(() => {
    setCurrentPrompts(data);
  }, [data]);

  const handleSave = async () => {
    try {
      await savePromptTemplate({
        templateType,
        promptTemplate: currentPrompts,
      }).unwrap();
      enqueueSnackbar(
        `${formatTemplateTypeString(templateType)} prompt saved successfully`,
        {
          variant: 'success',
        }
      );
    } catch (e) {
      enqueueSnackbar(
        `Error saving ${formatTemplateTypeString(templateType)} prompt`,
        {
          variant: 'error',
        }
      );
    }
  };

  const handleUpdate = (key: string, value: any) => {
    setCurrentPrompts((prev: any) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  return (
    <Dialog
      open={open}
      scroll="body"
      onClose={handleClose}
      maxWidth="xl"
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogTitle
        sx={{ bgcolor: 'primary.main', color: 'white' }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        Edit {formatTemplateTypeString(templateType)} Prompts {' - '}{' '}
        {data.name}
        <IconButton
          sx={{ color: 'white' }}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ ...modalStyle, position: 'relative' }}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <ModalInputBox
              title="Headlines Prompt"
              value={currentPrompts.headlines_prompt_goal}
              onChange={(e) => {
                handleUpdate('headlines_prompt_goal', e.target.value);
              }}
            />
            {templateType === 'category_combo' && (
              <ModalInputBox
                title="Headlines Prompt Examples"
                value={currentPrompts.headlines_prompt_examples}
                onChange={(e) => {
                  handleUpdate('headlines_prompt_examples', e.target.value);
                }}
              />
            )}
            <ModalInputBox
              title="Descriptions Prompt"
              value={currentPrompts.descriptions_prompt_goal}
              onChange={(e) => {
                handleUpdate('descriptions_prompt_goal', e.target.value);
              }}
            />
            {templateType === 'category_combo' && (
              <ModalInputBox
                title="Descriptions Prompt Examples"
                value={currentPrompts.descriptions_prompt_examples}
                onChange={(e) => {
                  handleUpdate('descriptions_prompt_examples', e.target.value);
                }}
              />
            )}
            <ModalInputBox
              title="Site Links Prompt"
              value={currentPrompts.site_links_prompt_goal}
              onChange={(e) => {
                handleUpdate('site_links_prompt_goal', e.target.value);
              }}
            />
            {templateType === 'category_combo' && (
              <ModalInputBox
                title="Site Links Prompt Examples"
                value={currentPrompts.site_links_prompt_examples}
                onChange={(e) => {
                  handleUpdate('site_links_prompt_examples', e.target.value);
                }}
              />
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button variant="contained" color="success" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PromptTemplateModal;
