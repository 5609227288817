import React from 'react';
import {
  FormControl,
  TextField,
  FormHelperText,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface InputTextFieldProps {
  label: string;
  value: string | number;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: string;
  tooltip?: string;
  multiline?: boolean;
}

const InputTextField: React.FC<InputTextFieldProps> = ({
  label,
  value,
  onChange,
  disabled,
  error,
  tooltip,
  multiline,
}) => {
  const handleChange = (event: { target: { value: string } }) => {
    onChange(event.target.value);
  };

  return (
    <Box sx={{ position: 'relative' }} pt={1}>
      <FormControl fullWidth variant="outlined">
        <TextField
          id="outlined-basic"
          label={label}
          variant="outlined"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          multiline={multiline}
          inputProps={{
            style: { paddingRight: '1rem' },
          }}
        />
        {!!error && (
          <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>
        )}
      </FormControl>
      {tooltip && (
        <Tooltip
          title={tooltip}
          placement="top"
          sx={{ position: 'absolute', top: '20px', right: '10px' }}
        >
          <IconButton size="small">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default InputTextField;
